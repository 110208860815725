import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { MetaPagination, OverlayDataShower } from '@/components/Elements'
import { GeneralTimeEntriesTable } from '@/features/booking-tracking/components/TimeEntries/Tables'
import { BookingTrackingModel } from '@/features/booking-tracking/types/models'
import { BOOKING_STATUS } from '@/features/bookings/consts/booking'
import { useBookingData } from '@/features/bookings/hooks'
import { bookingsTrackingEntriesList } from '@/features/bookings/store'
import { useUser } from '@/features/user'
import { RootState } from '@/store'
import { TimeEntryAddControl } from './TimeEntryAddControl'

interface IProps {
  list: BookingTrackingModel[]
  loading: boolean
  error: any
  meta: any
  page: number
}

const BookingTrackerContainerComponent = ({ ...props }: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { booking } = useBookingData()

  const { isBabysitter } = useUser()

  const [syncCount, forceUpdate] = React.useReducer((x: number) => x + 1, 0)

  useEffect(() => {
    return () => {
      dispatch(bookingsTrackingEntriesList.cleanState())
    }
  }, [])

  useEffect(() => {
    fetchList()
  }, [props.page, syncCount])

  const syncData = () => {
    forceUpdate()
  }

  const fetchList = () => {
    const params = {
      page: props.page,
    }

    dispatch(bookingsTrackingEntriesList.getList({ id: booking?.id, params }))
  }

  const onPageChange = (page: number) => {
    dispatch(bookingsTrackingEntriesList.setPage(page))
  }

  const isActionAllow = useMemo(() => {
    return isBabysitter() && booking?.status === BOOKING_STATUS.UPCOMING
  }, [isBabysitter(), booking?.status])

  const getTableKeys = useCallback(() => {
    const keys: any[] = [
      { key: 'start-date' },
      { key: 'end-date' },
      { key: 'action', hidden: !isActionAllow },
    ]

    return keys.filter((item) => !item?.hidden).map((item) => item.key)
  }, [isActionAllow])

  return (
    <div>
      {isActionAllow && (
        <div className={'mb-4 flex justify-end'}>
          <TimeEntryAddControl onSuccess={fetchList} />
        </div>
      )}

      <OverlayDataShower isLoading={props.loading} isFailed={!!props.error} error={props.error}>
        <GeneralTimeEntriesTable
          keys={getTableKeys()}
          items={props.list}
          loading={props.loading}
          onEditSuccess={syncData}
          onRemoveSuccess={syncData}
        />

        <MetaPagination meta={props.meta} page={props.page} onChange={onPageChange} />
      </OverlayDataShower>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const { list, loading, error, meta, page } = state.bookings.tracking.entries.list
  return {
    list,
    loading,
    error,
    meta,
    page,
  }
}

export const BookingTrackerContainer = connect(mapStateToProps)(BookingTrackerContainerComponent)
