import React from 'react'
import { Group, Text } from '@mantine/core'
import { Rating } from '@/components/FormElements'

interface IProps {
  value?: number
  ratingProps?: any
  withValue?: boolean
  textProps?: any
}

export const RatingLabel = ({ value, ratingProps, withValue = true, textProps }: IProps) => {
  return (
    <>
      <Group spacing={4}>
        <Rating size={'sm'} {...ratingProps} value={value} readOnly />

        {withValue && value ? (
          <Text weight={600} size={'sm'} {...textProps}>
            {value}
          </Text>
        ) : null}
      </Group>
    </>
  )
}
