import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  wrap: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      height: '100%',
      padding: '0px 32px',
    },
  },
  whiteWrap: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      height: 'calc(100vh - 280px)',
      backgroundColor: 'white',
      padding: '32px 32px',
      borderTopLeftRadius: '32px',
      borderTopRightRadius: '32px',
    },
  },
  buttonsWrap: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      height: '100%',
      paddingBottom: '64px',
    },
  },
  imgWrap: {
    position: 'absolute',
    left: '-80px',
    bottom: '0',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      position: 'fixed',
      left: '0px',
    },
  },
}))
