import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { Anchor, Space, Title } from '@mantine/core'
import { Alert, Card } from '@/components/Elements'
import { useQuery } from '@/hooks'
import { authResetPasswordPA } from '../../../store'
import { FormValues, ResetPasswordForm } from './ResetPasswordForm'

type SubmitParams = FormValues & {
  token: string
  email: string
}

export const ResetPasswordContainer = () => {
  const { t } = useTranslation()

  const query = useQuery()
  const { token } = useParams()

  const dispatch = useDispatch()

  const [success, setSuccess] = useState<string | null>(null)

  const handleSubmit = async (values: FormValues) => {
    setSuccess(null)

    const params: SubmitParams = {
      ...values,
      token: token || '',
      email: query.get('email') || '',
    }

    const { message } = await dispatch(authResetPasswordPA.request(params))

    setSuccess(message || t('success'))
  }

  return (
    <Card>
      <Title order={3}>{t('reset_password')}</Title>

      <Space h={'lg'} />

      {success && (
        <Alert type={'success'} mb={'sm'}>
          {success}
        </Alert>
      )}

      <ResetPasswordForm onSubmit={handleSubmit} />

      <Space h={'lg'} />

      <div className={'mt-4 flex'}>
        <Title order={6}>
          {t('back_to')}{' '}
          <Anchor component={Link} to={'/'}>
            {t('login.access_here.text')}
          </Anchor>
        </Title>
      </div>
    </Card>
  )
}
