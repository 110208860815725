import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { ContentCard } from '@/components/Elements'

export const CheckInLayout = () => {
  const { t } = useTranslation()

  return (
    <div className="h-full">
      <ContentCard title={t('Check Ins')}>
        <Outlet />
      </ContentCard>
    </div>
  )
}
