import moment from 'moment'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'
import { DateUtils } from '@/utils'
import useStyles from './Date.styles'

interface IProps {
  value: string
}

export const Date = ({ value }: IProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  const dateFormat = useMemo(() => {
    if (value) {
      const localDate = DateUtils.utcToLocale(value)
      const mDate = moment(localDate)

      let format = 'DD/MM/YYYY'

      if (mDate.isSame(moment(), 'day')) {
        format = 'HH:mm'
      }
      return DateUtils.format(localDate, format)
    }

    return null
  }, [value])

  return (
    <Text color={'gray.6'} size={'xs'} className={classes.date}>
      {dateFormat}
    </Text>
  )
}
