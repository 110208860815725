import React, { useMemo } from 'react'
import { useAppState } from '@/features/app/hooks'
import { Pagination, PaginationProps } from '../Pagination'

interface IProps extends Omit<PaginationProps, 'total'> {
  page: number
  meta: {
    last_page: number
  }
  onChange: (page: number) => void
}

export const MetaPagination = ({
  page = 1,
  meta,
  onChange,
  position = 'right',
  ...props
}: IProps) => {
  const {
    appState: { mobileView },
  } = useAppState()

  const total = meta ? meta.last_page : 0

  const visible = useMemo(() => {
    return total > 1
  }, [total])

  return (
    <div className={mobileView ? 'my-4' : 'mt-4'}>
      {visible && (
        <Pagination {...props} position={position} page={page} total={total} onChange={onChange} />
      )}
    </div>
  )
}
