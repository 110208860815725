import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { bookingAPI } from '@/features/bookings/api'
import i18n from '@/i18n'
import { bookingsDaysList } from './slice'

function* getList({ payload: { id } }: PayloadAction<{ id: number }>) {
  try {
    const response: AxiosResponse = yield call(bookingAPI.getDays, id)

    const { data } = response

    yield put(bookingsDaysList.getListSuccess(data))
  } catch (error: any) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(bookingsDaysList.getListError(message))
  }
}

function* watchGetList() {
  yield takeLatest(bookingsDaysList.getList, getList)
}

export function* listSaga() {
  yield all([fork(watchGetList)])
}
