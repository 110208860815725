import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  arrow: {
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'rotate(90deg)',
    backgroundColor: '#02334b',
    border: '1px solid #02334b',
  },
  arrowFirst: {
    backgroundColor: 'transparent',
    border: '1px solid #02334b',
    opacity: '0.4',
  },
  arrowFirstSvgPath: {
    stroke: '#02334b',
  },
}))
