import React from 'react'
import { Text } from '@mantine/core'
import { BookingDay } from '@/features/bookings/types/models'
import { DateUtils } from '@/utils'

interface IProps {
  data: BookingDay
}

export const Title = ({ data }: IProps) => {
  const { date_start } = data

  return (
    <div className={'whitespace-nowrap'}>
      <Text weight={700} size={'sm'}>
        {date_start ? DateUtils.format(date_start, 'ddd, D MMM, YYYY') : null}
      </Text>
    </div>
  )
}
