import React, { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import { WeekDaysPickerController } from '@/features/booking/components/FormElements'
import { timetableHelper } from '@/features/booking/helpers'
import { DateUtils } from '@/utils'
import { WeekDayTimePeriodFormSection } from '../WeekDayTimePeriodFormSection'

const weekDays = timetableHelper.weekdays.getWeekDays()

export const DaysScheduleFormSection = () => {
  const { t } = useTranslation()

  const { control, watch } = useFormContext()

  const watchDaysSchedule = watch('timetable.days_schedule')

  const watchDateStart = watch('date_start')
  const watchDateEnd = watch('date_end')

  const watchSameDaysTime = watch('timetable.same_days_time')

  const {
    appState: { mobileView },
  } = useAppState()

  const renderWeeksLabel = useCallback(() => {
    const weekList = timetableHelper.weeks.getWeekListBetweenDates({
      start: watchDateStart,
      end: watchDateEnd,
    })

    const weeks = weekList.length

    const weeksLabel = weeks > 1 ? `${t('week')} 1 - ${t('week')} ${weeks}` : t('week')

    const startDate = weekList[0]?.startDate
    const startDateLabel = startDate ? DateUtils.format(startDate, 'D MMM YYYY') : null

    const endDate = weekList[weeks - 1]?.endDate
    const endDateLabel = endDate ? DateUtils.format(endDate, 'D MMM YYYY') : null

    return (
      <Text weight={700}>
        {weeksLabel}{' '}
        <Text
          component="span"
          color={'gray.6'}
          weight={500}
        >{`(${startDateLabel} - ${endDateLabel})`}</Text>
      </Text>
    )
  }, [watchDateStart, watchDateEnd])

  return (
    <>
      <div className={'mb-3'}>{renderWeeksLabel()}</div>

      <div className={mobileView ? '' : 'my-4'}>
        <WeekDaysPickerController control={control} name={'timetable.days_schedule'} />
      </div>

      {!watchSameDaysTime &&
        weekDays.map(({ key }) =>
          watchDaysSchedule[key]?.enabled ? (
            <div className={'mt-3'} key={key}>
              <WeekDayTimePeriodFormSection
                namePrefix={'timetable.days_schedule'}
                weekDayKey={key}
              />
            </div>
          ) : null
        )}
    </>
  )
}
