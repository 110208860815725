import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.md,
    display: 'flex',
    backgroundColor: theme.white,
    flexDirection: 'column',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      margin: '8px 0px',
    },
  },

  body: {
    display: 'flex',
    gap: theme.spacing.md,
    padding: '20px',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      flexDirection: 'column',
      padding: '0px',
      gap: '0',
    },
  },

  infoBlock: {
    width: '100%',
  },

  actionBar: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      display: 'flex',
      alignItems: 'center',
      padding: '12px 16px',
    },
  },

  infoTopBar: {
    display: 'flex',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      borderBottom: '1px solid lightgrey',
      padding: '12px 16px',
      display: 'block',
    },
  },
  infoBlockItem: {
    marginTop: '1rem',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      marginTop: '0',
      padding: '12px 16px',
    },
  },
  infoContentBlock: {},

  footer: {
    display: 'flex',
    padding: '20px',
    borderTop: `1px solid ${theme.colors.gray[4]}`,
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      padding: '12px 16px',
    },
  },
}))
