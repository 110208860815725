import React from 'react'
import { BookingCancelContainer } from '../BookingCancelContainer'

interface IProps {
  id: number
  onSuccess?: (data: object) => void
  onCancel: () => void
}

export const BookingCancelProvider = ({ id, onSuccess, onCancel }: IProps) => {
  return <BookingCancelContainer id={id} onSuccess={onSuccess} onCancel={onCancel} />
}
