import React from 'react'
import { Rating as MantineRating, RatingProps as MantineRatingProps } from '@mantine/core'

export interface RatingProps extends MantineRatingProps {
  label?: React.ReactNode
  fieldRef?: any
  normalize?: (value: string) => string
  translateParams?: object
  meta?: any
}

export const Rating = ({
  label,
  normalize,
  meta,
  fieldRef,
  translateParams,
  ...props
}: RatingProps) => {
  return <MantineRating {...props} />
}
