import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { PasswordInputController } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'
import { useFormSubmit } from '@/hooks'
import { validationSchema } from './validation'

interface IProps {
  onSubmit: (values: object) => Promise<void>
  onCancel: () => void
}

const passwordMinLength = 8

export const PasswordForm = ({ onCancel, ...props }: IProps) => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()
  const methods = useForm({
    defaultValues: {
      current_password: '',
      password: '',
      password_confirmation: '',
    },
    resolver: yupResolver(validationSchema),
  })

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      await onFormSubmit(data)
      reset()
    } catch (error) {}
  }

  return (
    <>
      <FormProvider {...methods}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.message || t('error')}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <PasswordInputController
            control={control}
            name={'current_password'}
            id={'current_password'}
            label={t('current_password')}
            placeholder={t('current_password')}
            size={mobileView ? 'md' : 'lg'}
            mb={mobileView ? '0' : 'md'}
            translateParams={{ count: passwordMinLength }}
          />

          <PasswordInputController
            control={control}
            name={'password'}
            id={'password'}
            label={t('new_password')}
            placeholder={t('new_password')}
            size={mobileView ? 'md' : 'lg'}
            mb={mobileView ? '0' : 'md'}
            translateParams={{ count: passwordMinLength }}
          />

          <PasswordInputController
            control={control}
            name={'password_confirmation'}
            id={'password_confirmation'}
            label={t('confirm_new_password')}
            placeholder={t('confirm_new_password')}
            size={mobileView ? 'md' : 'lg'}
            mb={mobileView ? '0' : 'md'}
            translateParams={{ count: passwordMinLength }}
          />

          <div className={mobileView ? 'flex w-100 mt-2' : 'flex justify-end'}>
            <Group className={mobileView ? 'w-100' : ''}>
              {!mobileView && (
                <Button variant="default" color="dark" onClick={onCancel}>
                  {t('cancel')}
                </Button>
              )}
              <Button loading={isSubmitting} fullWidth={mobileView} type={'submit'}>
                {t('change_password')}
              </Button>
            </Group>
          </div>
        </form>
      </FormProvider>
    </>
  )
}
