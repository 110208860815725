import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  TimeInput as MantineTimeInput,
  TimeInputProps as MantineTimeInputProps,
} from '@mantine/dates'
import { FormUtils } from '@/utils'

export interface TimeInputProps extends MantineTimeInputProps {
  fieldRef?: any
  normalize?: (value: string) => string
  translateParams?: object
  meta?: any
}

export const TimeInput = ({
  normalize,
  meta,
  fieldRef,
  translateParams,
  ...props
}: TimeInputProps) => {
  const { t } = useTranslation()

  const { error } = meta

  const showError = Boolean(error)

  const getErrorMessage = () => {
    return FormUtils.getErrorMessage(error, {
      t,
      translateParams: translateParams,
    })
  }

  return <MantineTimeInput {...props} data-hj-allow error={showError ? getErrorMessage() : null} />
}
