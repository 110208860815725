import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink as RouterNavLink } from 'react-router-dom'
import { Group, Text } from '@mantine/core'
import { Icon, IconNameType } from '@/components/Elements'
import useStyles from './NavLink.styles'

export type NavLinkType = {
  path: string
  label: string
  icon: IconNameType
  allow: boolean
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  className?: string
  renderRightSection?: (data: any) => React.ReactNode
}

export const NavLink = ({
  path,
  label,
  icon,
  onClick,
  className,
  renderRightSection,
}: NavLinkType) => {
  const { t } = useTranslation()
  const { classes, cx } = useStyles()

  return (
    <RouterNavLink
      to={path}
      key={path}
      className={({ isActive }) =>
        isActive ? cx(classes.link, classes.linkActive, className) : cx(classes.link, className)
      }
      onClick={(e) => onClick && onClick(e)}
    >
      {({ isActive }) => (
        <Group className={'w-full'}>
          <Icon name={icon} className={classes.linkIcon} />
          <div className={'flex-1'}>
            <Text>{label}</Text>
          </div>

          {renderRightSection && (
            <div className={classes.rightSection}>{renderRightSection({ isActive })}</div>
          )}
        </Group>
      )}
    </RouterNavLink>
  )
}
