import { createSlice } from '@reduxjs/toolkit'
import { ChatModel, Group, Participant } from '@/features/chat/types/models'

interface IState {
  data: null | {
    id?: number
    participants?: Participant[]
    group?: Group
  }
  backgroundUnreadMessages: boolean
  showRating: boolean
  activeChat: null | ChatModel
}

const initialState: IState = {
  data: null,
  backgroundUnreadMessages: false,
  activeChat: null,
  showRating: false,
}

const chatConversationDataSlice = createSlice({
  name: 'chatConversationData',
  initialState,
  reducers: {
    initChat(state, action) {
      state.data = {
        ...action.payload,
      }
      state.activeChat = action.payload
    },
    initParticipants(state, action) {
      state.data = {
        participants: [...action.payload],
      }
    },
    setShowRating(state, action) {
      state.showRating = action.payload
    },
    setBackgroundUnreadMessages(state, action) {
      state.backgroundUnreadMessages = action.payload
    },
    updateData(state, action) {
      state.data = { ...state.data, ...action.payload }
    },
    updateActiveChat(state, action) {
      state.activeChat = { ...state.activeChat, ...action.payload }
    },
    updateActiveChatBooking(state, action) {
      if (state.activeChat?.booking) {
        state.activeChat.booking = { ...state.activeChat.booking, ...action.payload }
      }
    },
    cleanState() {
      return initialState
    },
  },
})

/**
 * @namespace
 * @property {function} initChat
 * @property {function} initParticipants
 * @property {function} setBackgroundUnreadMessages
 * @property {function} updateData
 * @property {function} cleanState
 */

export const chatConversationData = chatConversationDataSlice.actions

export default chatConversationDataSlice.reducer
