import { IconCirclePlus } from '@tabler/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Anchor, Group } from '@mantine/core'
import { TimeEntryAdd } from '@/features/booking-tracking/components/TimeEntry'
import { useBookingData } from '@/features/bookings/hooks'
import { BookingModel } from '@/features/bookings/types/models'
import { usePopup } from '@/hooks'

interface IProps {
  onSuccess: () => void
}

export const TimeEntryAddControl = ({ onSuccess }: IProps) => {
  const { t } = useTranslation()

  const { booking } = useBookingData()

  const { visible, open, close } = usePopup()

  const onAdd = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    open(e)
  }

  const onAddSuccess = () => {
    close()

    onSuccess()
  }

  return (
    <>
      <div className={'flex self-end'}>
        <Anchor href="" onClick={onAdd} className={'font-bold'}>
          <Group>
            <IconCirclePlus />
            {t('add_time')}
          </Group>
        </Anchor>
      </div>

      <TimeEntryAdd
        booking={booking as BookingModel}
        visible={visible}
        onClose={close}
        onSuccess={onAddSuccess}
      />
    </>
  )
}
