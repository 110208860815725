import React from 'react'

interface IProps {
  width?: number
  height?: number
  className?: string
}

export const Underline = ({ width = 79, height = 10, ...props }: IProps) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox={`0 0 70 10`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.03938 4.11947C0.811815 4.11947 0.622892 3.87974 0.610011 3.5707C0.59713 3.25299 0.779618 2.98149 1.01792 2.96416C24.8501 1.37272 49.03 0.37627 72.8901 0.000794361C73.1284 -0.0165353 73.3216 0.252069 73.3237 0.572667C73.3259 0.890378 73.137 1.15322 72.8987 1.1561C49.0493 1.53158 24.8801 2.52803 1.05872 4.11947C1.05228 4.11947 1.04367 4.11947 1.03723 4.11947H1.03938Z"
      fill="#FDBCB5"
    />
    <path
      d="M3.72505 7.15794C3.49963 7.15794 3.3107 6.9211 3.29568 6.61494C3.28279 6.29723 3.46313 6.02284 3.69928 6.00551C28.5233 4.04726 53.7165 3.37429 78.5792 4.00394C78.8153 4.00971 79.0042 4.27255 78.9999 4.59315C78.9956 4.90797 78.8046 5.15925 78.5706 5.15925C78.5684 5.15925 78.5641 5.15925 78.562 5.15925C53.7208 4.5296 28.549 5.20257 3.74866 7.16082C3.74007 7.16082 3.73148 7.16082 3.7229 7.16082L3.72505 7.15794Z"
      fill="#FDBCB5"
    />
    <path
      d="M0.429657 10C0.199945 10 0.00887519 9.75738 0.000287806 9.44545C-0.00829958 9.12774 0.176339 8.85913 0.412492 8.84469C22.1729 7.70093 44.2447 7.45542 66.0158 8.11684C66.252 8.12262 66.4409 8.38834 66.4344 8.70605C66.4302 9.02087 66.2369 9.26927 66.0051 9.26927C66.0008 9.26927 65.9986 9.26927 65.9943 9.26927C44.2403 8.60785 22.188 8.85046 0.4447 9.99711C0.438259 9.99711 0.433949 9.99711 0.427508 9.99711L0.429657 10Z"
      fill="#FDBCB5"
    />
  </svg>
)
