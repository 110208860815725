export enum BOOKING_TYPE {
  ONETIME = 'onetime',
  REGULAR = 'regular',
  ANIMATION = 'animation',
  PERMANENT_NANNY = 'permanent_nanny',
  /*WEEKLY = 'weekly',
  PART_TIME = 'part_time',
  FULL_TIME = 'full_time',
  CHILD_BIRTH = 'child_birth',
  INTERNATIONAL = 'international',*/
}
