import React from 'react'
import { CITY_TYPE } from '@/const/city'
import { CityModelType } from '@/features/user/types'
import { cityHelper } from '@/helpers/city'
import { ModelTypes } from '@/types'

const isCityExtraOption = cityHelper.general.isCityExtraOption

interface IProps {
  data: {
    city: CityModelType | null
    city_description: string | null
    city_type: CITY_TYPE | null
    country: ModelTypes.CountryType | null
  }
  className?: string
}

export const LocationAreaDisplay = ({ data, className }: IProps) => {
  const renderArea = () => {
    const { city, city_type, city_description, country } = data

    let result = ''

    if (isCityExtraOption(city_type)) {
      result += city_description

      if (country) {
        result += `, ${country?.name}`
      }

      result += ` (${cityHelper.general.getExtraCity(city_type)?.label})`
    } else {
      result = city?.name || '-'
    }

    return result
  }
  return <span className={className}>{renderArea()}</span>
}
