import React from 'react'
import { useTranslation } from 'react-i18next'
import { BookingDay } from '@/features/bookings/types/models'
import useStyles from './Style.styles'
import { Table } from './Table'
import { TimePeriod } from './TimePeriod'
import { Title } from './Title'

interface IProps {
  data: BookingDay[]
}

export const BookingDaysTable = ({ data }: IProps) => {
  const { t } = useTranslation()
  const { classes, cx } = useStyles()

  const columns = [
    {
      accessorKey: 'title',
      id: 'title',
      cell: (info: any) => <Title data={info.row.original} />,
    },
    {
      accessorKey: 'time-period',
      id: 'time-period',
      cell: (info: any) => <TimePeriod data={info.row.original} />,
    },
  ]

  return (
    <>
      <Table data={data} columns={columns} />
    </>
  )
}
