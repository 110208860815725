import React from 'react'
import { BookingCandidate } from '@/features/booking-candidate/types/models'
import { BabysitterUser } from '@/features/user'
import { useUsersPermissions } from '@/features/users/hooks'
import { CandidateStatusBlock } from './Blocks'
import { FavoriteControl } from './Controls'

interface IProps {
  config: {
    favorite: boolean
    candidateStatusBlock: boolean
  }
  babysitter: BabysitterUser
  favoriteControlProps?: any
  candidateProps?: {
    candidate: BookingCandidate
    hireControlProps: {
      onSuccess?: (data: object) => void
    }
  }
}

export const ActionBar = ({ config, babysitter, favoriteControlProps, candidateProps }: IProps) => {
  const { isFavoriteAllow } = useUsersPermissions()

  return (
    <div className={'flex gap-4 items-center'}>
      {config?.favorite && isFavoriteAllow(babysitter) && (
        <FavoriteControl babysitter={babysitter} {...favoriteControlProps} />
      )}

      {config?.candidateStatusBlock && candidateProps && (
        <CandidateStatusBlock {...candidateProps} />
      )}
    </div>
  )
}
