import { IconChevronLeft, IconChevronRight } from '@tabler/icons'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip, useMantineTheme } from '@mantine/core'
import { Text } from '@mantine/core'
import { Calendar } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { BookingDay } from '@/features/bookings/types/models'
import { DateUtils } from '@/utils'
import useStyles from './BookingDaysCalendar.styles'

interface IProps {
  days: BookingDay[]
}

export const BookingDaysCalendar = ({ days }: IProps) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const [date, setDate] = useState(new Date())
  const { classes } = useStyles()

  const {
    appState: { mobileView },
  } = useAppState()
  const daysMap: any = useMemo(() => {
    return days.reduce((obj, item) => {
      const key = DateUtils.formatDateToServerFormat(item.date_start)
      return {
        ...obj,
        [key]: item,
      }
    }, {})
  }, [days])

  const value = useMemo(() => {
    return Object.keys(daysMap).map((item) => DateUtils.toDate(item))
  }, [daysMap])

  const getDayData = (date: Date) => {
    const key = DateUtils.formatDateToServerFormat(date)
    return daysMap?.[key]
  }

  const isSelectedDay = (date: Date) => {
    return getDayData(date)
  }

  const getTooltipLabel = (date: Date) => {
    const { date_start, date_end } = getDayData(date)

    return `${DateUtils.dateToTime(date_start)} - ${DateUtils.dateToTime(date_end)}`
  }

  const changeMonth = (shift: number) => {
    const nextDate = date
    nextDate.setMonth(date.getMonth() + shift)
    setDate(nextDate)
    console.log('MONTH: ' + nextDate)
  }

  return (
    <>
      {mobileView ? (
        <>
          <div className={'block w-100 mt-4'}>
            {/*<div className={'flex w-100'}>
              <div className={'w-50'}>
                <Text className={classes.title}>{DateUtils.formatDateMonthYear(date)}</Text>
              </div>
              <div className={'flex w-50 justify-end'}>
                <div className={classes.leftArrow} onClick={() => changeMonth(-1)}>
                  <IconChevronLeft size={20} color={theme.black} />
                </div>
                <div className={classes.rightArrow} onClick={() => changeMonth(1)}>
                  <IconChevronRight size={20} color={theme.black} />
                </div>
              </div>
            </div>*/}
            <Calendar
              value={value}
              month={date}
              onMonthChange={setDate}
              onChange={() => {}}
              renderDay={(date) => {
                const day = date.getDate()

                if (isSelectedDay(date)) {
                  return (
                    <Tooltip label={getTooltipLabel(date)} color="primary">
                      <div>{day}</div>
                    </Tooltip>
                  )
                }

                return day
              }}
            />
          </div>
        </>
      ) : (
        <Calendar
          value={value}
          onChange={() => {}}
          renderDay={(date) => {
            const day = date.getDate()

            if (isSelectedDay(date)) {
              return (
                <Tooltip label={getTooltipLabel(date)} color="primary">
                  <div>{day}</div>
                </Tooltip>
              )
            }

            return day
          }}
        />
      )}
    </>
  )
}
