import * as bookingModel from './booking-model'
import * as children from './children'
import * as status from './status'
import * as type from './type'

export const bookingHelper = {
  type,
  bookingModel,
  status,
  children,
}
