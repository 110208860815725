import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: `${theme.spacing.xs}px 0`,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      padding: 0,
      gap: '0.5rem',
    },
  },
}))
