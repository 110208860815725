import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  eventRootFinished: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      background: theme.colors.gray[0],
      fontSize: '10px',
      color: '#3f3f46',
      overflow: 'hidden',
    },
  },
  eventRoot: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      background: theme.colors.primary[0],
      fontSize: '10px',
      color: '#3f3f46',
      overflow: 'hidden',
    },
  },
}))
