import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CurrencyFormat, TableView } from '@/components/Elements'
import { DateUtils } from '@/utils'
import { Action } from './Action'
import { Period } from './Period'

type Key = 'amount' | 'period' | 'due-date' | 'action'

interface IProps {
  keys: Key[]
  items: any[]
  loading: boolean
  actionProps?: any
}

export const GeneralInvoicesTable = ({
  keys = [],
  items,
  loading,
  actionProps,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  const [columns, setColumns] = useState<any[]>([])

  const columnConfig: any = {
    amount: {
      accessorKey: 'amount',
      id: 'amount',
      header: t('amount'),
      cell: (info: any) => <CurrencyFormat value={info.getValue()} />,
    },
    period: {
      accessorKey: 'period',
      header: t('period'),
      cell: (info: any) => <Period bookingInvoice={info.row.original} />,
    },
    'due-date': {
      accessorKey: 'due_date',
      header: t('due_date'),
      cell: (info: any) => DateUtils.formatDateTime(info.getValue()) || '-',
    },
    action: {
      accessorKey: 'action',
      header: t('action'),
      cell: (info: any) => <Action {...actionProps} bookingInvoice={info.row.original} />,
    },
  }

  useEffect(() => {
    const config = []

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (columnConfig[key]) {
        config.push(columnConfig[key])
      }
    }

    setColumns(config)
  }, [])

  return (
    <>
      <TableView data={items} columns={columns} loading={loading} />
    </>
  )
}
