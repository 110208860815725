import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { userData } from '@/features/user/store'
import { FormTypes } from '@/types'
import { profileAvatarAPI } from '../../../api'
import {
  PROFILE_AVATAR_REMOVE_FAILED,
  PROFILE_AVATAR_REMOVE_REQUEST,
  PROFILE_AVATAR_REMOVE_SUCCESS,
} from './actionTypes'

export const profileAvatarRemovePA = createPromiseAction(
  PROFILE_AVATAR_REMOVE_REQUEST,
  PROFILE_AVATAR_REMOVE_SUCCESS,
  PROFILE_AVATAR_REMOVE_FAILED
)<undefined, unknown, AxiosError<FormTypes.ValidationErrors>>()

function* remove(action: PromiseAction<string, any, any>) {
  try {
    const response: AxiosResponse = yield call(profileAvatarAPI.deleteAvatar)
    const { data } = response

    yield put(profileAvatarRemovePA.success({}))

    yield put(userData.updateData({ avatar: null }))

    resolvePromiseAction(action, {})
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* removeSaga() {
  yield takeEvery(profileAvatarRemovePA.request, remove)
}
