import { Star } from '@/components/Elements/Icon/Star/Star'
import useStyles from './Review.styles'

interface IProps {
  name: string
  text: string
}
export const Review = ({ name, text }: IProps) => {
  const { classes } = useStyles()
  return (
    <div className={classes.container}>
      {/* <LargePill /> */}
      <div className={classes.content}>
        <div className={classes.stars}>
          <Star />
          <Star />
          <Star />
          <Star />
          <Star />
        </div>
        <div className={classes.row}>
          {/* {avatar.filename && <Image src={avatar.filename} alt={avatar.alt} width={56} height={56} />} */}
          <div>
            <p className={classes.name}>{name}</p>
          </div>
        </div>
        <p className={classes.text}>{text}</p>
      </div>
    </div>
  )
}
