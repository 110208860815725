import { CITY_TYPE } from '@/const/city'
import { LanguageTypeDTO } from '@/features/profile/api/dto'
import { LANG_TYPE } from '@/features/profile/consts/lang'
import { langHelper } from '@/features/profile/helpers'
import { CityModelType, LanguageType } from '@/features/user'
import { cityHelper } from '@/helpers'
import { ModelTypes } from '@/types'

export const getLangDefaultValue = (languages: Array<LanguageType>) => {
  return languages.map(({ id, language: { id: language_id, name, is_default } }) => {
    let values = {
      id,
      language_id: is_default ? language_id : LANG_TYPE.OTHER,
    }

    if (!is_default) {
      values = { ...values, name } as any
    }
    return values
  })
}

export const mapLangSubmitValue = (languages: Array<any>) => {
  return languages.map((item: any, i: number) => {
    const { language_id, name } = item

    let result: LanguageTypeDTO = {
      primary: i === 0,
    }

    if (langHelper.general.isLangExtraOption(language_id)) {
      result = { ...result, name }
    } else {
      result = { ...result, language_id }
    }
    return result
  })
}

export const getCityDefaultValue = ({
  city,
  city_type,
  city_description,
  country,
}: {
  city: CityModelType | null
  city_type: CITY_TYPE | null
  city_description: string | null
  country: ModelTypes.CountryType | null
}) => {
  const isCustomCity = cityHelper.general.isCityExtraOption(city_type)
  const isInternational = cityHelper.general.isInternational(city_type)

  return {
    city_id: isCustomCity ? city_type : city?.id,
    city_type: isCustomCity ? city_type : CITY_TYPE.DEFAULT,
    city_description: city_description || '',
    country_id: isInternational ? country?.id : null,
  }
}

export const mapCitySubmitValue = ({
  city_id,
  city_type,
  city_description,
  country_id,
}: {
  city_id: string | number
  city_type: CITY_TYPE
  city_description: string
  country_id: string | number
}) => {
  const isCustomCity = cityHelper.general.isCityExtraOption(city_id)
  const isInternational = cityHelper.general.isInternational(city_id)

  return {
    city_id: isCustomCity ? null : city_id,
    city_type: isCustomCity ? city_type : null,
    city_description: isCustomCity ? city_description : null,
    country_id: isInternational ? country_id : null,
  }
}
