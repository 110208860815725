import React from 'react'
import { Grid } from '@mantine/core'
import { GeneralBabysitterCard } from '@/features/babysitter/components/Babysitters/Cards'
import { BabysitterUser } from '@/features/user'

interface IProps {
  items: BabysitterUser[]
  onFavoriteSuccess: (data: any) => void
}

export const GridView: React.FC<IProps> = ({ items, ...props }) => {
  return (
    <>
      <Grid grow>
        {items.map((item, i) => (
          <Grid.Col key={i}>
            <GeneralBabysitterCard
              data={item}
              actionBarProps={{ config: { favorite: true } }}
              favoriteControlProps={{ onSuccess: props.onFavoriteSuccess }}
            />
          </Grid.Col>
        ))}
      </Grid>
    </>
  )
}
