import { IconChevronLeft, IconChevronRight, IconMinus, IconPlus } from '@tabler/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RangeSlider } from '@mantine/core'
import { experienceHelper } from '@/features/profile/helpers'
import useStyles from './Component.styles'
import { ControlButton } from './ControlButton'
import { InputToolbar } from './InputToolbar'
import { experienceAgeStepRangeSliderHelper } from './helpers'

const { convertStepToMonth } = experienceAgeStepRangeSliderHelper

export interface ExperienceAgeStepRangeSliderProps {
  value?: Array<number>
  onChange?: (value: Array<number>) => void
}

const STEP_RANGE = {
  MIN: 0,
  MAX: 27,
}

export const EXPERIENCE_AGE_STEP_RANGE = STEP_RANGE

export const ExperienceAgeStepRangeSlider = ({
  value = [STEP_RANGE.MIN, STEP_RANGE.MAX],
  onChange,
}: ExperienceAgeStepRangeSliderProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const renderMarkLabel = (step: number) => {
    const month = convertStepToMonth(step)
    const { value, postfix } = experienceHelper.age.getFormattedAge(month)
    return (
      <div className={'text-center'}>
        <span className={classes.markLabelValue}>
          {step !== STEP_RANGE.MAX ? value : `${value}+`}
        </span>
        <br />
        {postfix}
      </div>
    )
  }

  const handleChange = (value: Array<number>) => {
    onChange && onChange(value)
  }

  const marks = [
    { value: STEP_RANGE.MIN, label: renderMarkLabel(STEP_RANGE.MIN) },
    { value: 1 },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5 },
    { value: 6 },
    { value: 7 },
    { value: 8 },
    { value: 9 },
    { value: 10 },
    { value: 11 },
    { value: 12, label: renderMarkLabel(12) },
    { value: 13 },
    { value: 14 },
    { value: 15 },
    { value: 16 },
    { value: 17 },
    { value: 18 },
    { value: 19 },
    { value: 20 },
    { value: 21 },
    { value: 22 },
    { value: 23 },
    { value: 24 },
    { value: 25 },
    { value: 26 },
    { value: STEP_RANGE.MAX, label: renderMarkLabel(STEP_RANGE.MAX) },
  ]

  const increase = () => {
    const [start, end] = value
    if (end < STEP_RANGE.MAX) {
      onChange && onChange([start, end + 1])
    }
  }

  const decrease = () => {
    const [start, end] = value
    if (start > STEP_RANGE.MIN) {
      onChange && onChange([start - 1, end])
    }
  }

  return (
    <div>
      <InputToolbar value={value} />

      <div className={classes.controlsBlock}>
        <ControlButton onClick={decrease}>
          <IconMinus />
        </ControlButton>

        <div className={classes.sliderBlock}>
          <RangeSlider
            classNames={classes}
            value={value as any}
            onChange={handleChange}
            min={STEP_RANGE.MIN}
            max={STEP_RANGE.MAX}
            marks={marks}
            step={1}
            minRange={1}
            label={null}
            size={'xs'}
            thumbChildren={
              <div className={classes.thumbIcon}>
                <span className={classes.thumbIconLeft}>
                  <IconChevronLeft size={16} />
                </span>
                <span className={classes.thumbIconRight}>
                  <IconChevronRight size={16} />
                </span>
              </div>
            }
          />
        </div>

        <ControlButton onClick={increase}>
          <IconPlus />
        </ControlButton>
      </div>
    </div>
  )
}
