import { EventSourceInput } from '@fullcalendar/core'
import enLocale from '@fullcalendar/core/locales/en-gb'
import ptLocale from '@fullcalendar/core/locales/pt'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMantineTheme } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { useAppState, useEvents } from '@/features/app/hooks'
import { BookingDrawerManager } from '@/features/bookings/components/Booking'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import {
  BookingDrawerManagerProvider,
  BookingDrawerModuleProvider,
} from '@/features/bookings/contexts'
import { bookingHelper } from '@/features/bookings/helpers'
import { useBookingDrawerManager } from '@/features/bookings/hooks'
import {
  BookingUpdatedActionMeta,
  useBookingDrawerModule,
} from '@/features/bookings/hooks/useBookingDrawerModule'
import { calendarsAPI } from '@/features/calendar/api'
import { TAG_MANAGER_EVENT } from '@/features/tag-manager/consts/event'
import { CheckIn } from '@/interfaces/CheckIn'
import { DateUtils } from '@/utils'
import useStyles from './CalendarView.styles'

export const CalendarView = () => {
  const theme = useMantineTheme()
  const { i18n } = useTranslation()
  const event = useEvents()

  const { classes } = useStyles()

  const {
    appState: { mobileView },
  } = useAppState()

  const bookingDrawerManager = useBookingDrawerManager({})

  const onBookingUpdated = (booking: any, actionMeta: BookingUpdatedActionMeta) => {
    console.log('ok')
  }

  const bookingDrawerModule = useBookingDrawerModule({ onBookingUpdated })

  useEffect(() => {
    event.actions.generic(TAG_MANAGER_EVENT.BOOKING_CALENDAR)
    getCalendarEvents()
  }, [])

  const [events, setEvents] = useState<EventSourceInput>([])

  const getType = bookingHelper.type.getType

  const getCalendarEvents = async () => {
    const { data } = await calendarsAPI.getCheckinsList({})

    data.forEach(function (event: CheckIn) {
      const calendarEvent = {
        title: `Booking: ${event.booking.address}`,
        start: event.check_in_starts_at,
        end: event.check_in_ends_at,
        backgroundColor: '#e26247',
        borderColor: '#e26247',
        textColor: '#fff',
        ...event,
      }
      setEvents((oldArray: any) => [...oldArray, calendarEvent])

      if (
        (event.booking.type === BOOKING_TYPE.ONETIME ||
          event.booking.type === BOOKING_TYPE.ANIMATION) &&
        event.booking?.timetable?.onetime_ranges
      ) {
        event.booking.timetable.onetime_ranges.forEach(function (range: any) {
          const calendarEvent2 = {
            title: `Booking: ${event.booking.address}`,
            start: range.range_date_start + ' ' + range.range_time_start,
            end: range.range_date_end + ' ' + range.range_time_end,
            backgroundColor: '#e26247',
            borderColor: '#e26247',
            textColor: '#fff',
            ...event,
          }
          setEvents((oldArray: any) => [...oldArray, calendarEvent2])
        })
      }
    })
  }

  const getLocale = i18n.language === 'pt' ? ptLocale : enLocale

  const showBooking = ({ event }: any) => {
    bookingDrawerManager.navigation.onScreenView({ id: event.extendedProps.booking_id })
  }
  function renderEventContent(eventInfo: any) {
    return (
      <div
        className={
          eventInfo.event.extendedProps.booking.status === 'finished'
            ? classes.eventRootFinished
            : classes.eventRoot
        }
      >
        <Icon
          name={getType(eventInfo.event.extendedProps.booking.type)?.iconName}
          color={theme.colors.gray[6]}
          size={10}
        />
        <br />
        <b>{`${DateUtils.formatTime(eventInfo.event.start)} -`}</b>
        <br />
        <b>{`${DateUtils.formatTime(eventInfo.event.end)}`}</b>
        <br />
        <i>{eventInfo.event.title}</i>
      </div>
    )
  }
  return (
    <div className={mobileView ? 'h-screen p-4' : 'h-screen'}>
      {mobileView ? (
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          weekends={true}
          locale={getLocale}
          nowIndicator
          eventClick={showBooking}
          eventContent={renderEventContent}
          titleFormat={{ year: 'numeric', month: 'long' }}
          headerToolbar={{
            left: 'title',
            center: '',
            right: 'prev,next', // user can switch between the two
          }}
          events={events}
        />
      ) : (
        <FullCalendar
          plugins={[dayGridPlugin, listPlugin, timeGridPlugin]}
          initialView="dayGridMonth"
          weekends={true}
          locale={getLocale}
          nowIndicator
          eventClick={showBooking}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'timeGridDay timeGridWeek dayGridMonth dayGridYear listMonth', // user can switch between the two
          }}
          events={events}
        />
      )}

      <BookingDrawerManagerProvider {...bookingDrawerManager}>
        <BookingDrawerModuleProvider {...bookingDrawerModule}>
          <BookingDrawerManager />
        </BookingDrawerModuleProvider>
      </BookingDrawerManagerProvider>
    </div>
  )
}
