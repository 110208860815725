import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MobileHeader, MobileHeaderModal } from '@/components/Elements/Header/MobileHeader'
import { useAppState } from '@/features/app/hooks'
import { useHeaderContext } from '@/features/header/contexts/HeaderContext'

interface IProps {
  useOpacity?: boolean
  goToBookNow?: boolean
}

export const Header = ({ useOpacity, goToBookNow = false }: IProps) => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()
  const { base, border, title, left, right } = useHeaderContext()

  return (
    <>
      {mobileView && base && (
        <MobileHeader border={border} useOpacity={useOpacity} goToBookNow={goToBookNow} />
      )}
      {mobileView && !base && (
        <MobileHeaderModal border={border} left={left} right={right} title={`${t(title)}`} />
      )}
    </>
  )
}
