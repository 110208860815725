import { createSlice } from '@reduxjs/toolkit'
import { BookingDay } from '@/features/bookings/types/models'

interface IState {
  list: BookingDay[]
  loading: boolean
  error: any | null
}

const initialState: IState = {
  list: [],
  loading: false,
  error: null,
}

const bookingsDaysListSlice = createSlice({
  name: 'bookingsDaysList',
  initialState,
  reducers: {
    getList(state, action) {
      state.loading = true
      state.error = null
    },
    getListSuccess(state, action) {
      state.loading = false
      state.list = action.payload.data
    },
    getListError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})

export const bookingsDaysList = bookingsDaysListSlice.actions

export default bookingsDaysListSlice.reducer
