import React from 'react'
import { useTranslation } from 'react-i18next'
import { EditControl, SettingsControl } from './Controls'

export const Header = () => {
  const { t } = useTranslation()

  return (
    <div className={'flex flex-wrap gap-4'}>
      <SettingsControl />

      <EditControl />
    </div>
  )
}
