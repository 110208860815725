import { PayloadAction } from '@reduxjs/toolkit'
import { all, fork, put, select, takeEvery } from 'redux-saga/effects'
import { chatConversationMessagesList } from '@/features/chat/store/conversation/messages/list/slice'
import { RootState } from '@/store'
import { chatSocketOnChatSeen } from './actions'

const conversationState = (state: RootState) => state.chat.conversation.data.data

function* onChatSeen({ payload: { data } }: PayloadAction<{ data: any }>) {
  const { chat_id, user } = data

  const conversation: {
    id: number
  } = yield select(conversationState)

  const isCurrentChat = chat_id === conversation.id

  if (isCurrentChat) {
    // mark messages as seen
    yield put(chatConversationMessagesList.markMessagesSeen())
  }
}

function* watchOnChatSeen() {
  yield takeEvery(chatSocketOnChatSeen as any, onChatSeen)
}

export function* seenSaga() {
  yield all([fork(watchOnChatSeen)])
}
