import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { usersAPI } from '@/features/users/api'
import i18n from '@/i18n'
import { RequestUtils } from '@/utils'
import { babysitterBabysittersFavoritesList } from './slice'

function* getList({ payload: { params } }: PayloadAction<{ params: any }>) {
  try {
    let requestParams: any = {
      includes: ['avatar', 'babysitterProfile', 'myFavoriteBabysitter'],
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response: AxiosResponse = yield call(usersAPI.getFavoriteBabysitters, requestParams)

    const { data } = response

    const {
      meta: { current_page, last_page },
    } = data

    if (current_page && last_page && current_page > last_page) {
      yield put(
        babysitterBabysittersFavoritesList.getList({
          params: {
            ...params,
            page: last_page,
          },
        })
      )
    } else {
      yield put(babysitterBabysittersFavoritesList.getListSuccess(data))
    }
  } catch (error: any) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(babysitterBabysittersFavoritesList.getListError(message))
  }
}

function* watchGetList() {
  yield takeLatest(babysitterBabysittersFavoritesList.getList, getList)
}

export function* listSaga() {
  yield all([fork(watchGetList)])
}
