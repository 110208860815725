import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  const icon = getRef('icon')
  return {
    link: {
      ...theme.fn.focusStyles(),
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: theme.black,
      padding: `${theme.spacing.sm}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.md,
      fontWeight: 700,
      minHeight: '54px',

      '&:not(:last-child)': {
        marginBottom: theme.spacing.sm,
      },

      '&:hover': {
        color: theme.colors[theme.primaryColor][theme.fn.primaryShade()],

        // colored svg path with fill
        [`&:not(.link_icon_stroke) .${icon} > path`]: {
          fill: theme.colors[theme.primaryColor][theme.fn.primaryShade()],
        },
        // colored svg path with stroke
        [`&.link_icon_stroke .${icon} path`]: {
          stroke: theme.colors[theme.primaryColor][theme.fn.primaryShade()],
        },
      },
    },

    linkIcon: {
      ref: icon,
      color: theme.black,
      marginRight: theme.spacing.sm,
    },

    rightSection: {},

    linkActive: {
      '&, &:hover': {
        backgroundColor: theme.colors[theme.primaryColor][theme.fn.primaryShade()],
        color: theme.white,
        [`&:not(.link_icon_stroke) .${icon} > path`]: {
          fill: theme.white,
        },
      },
    },
  }
})
