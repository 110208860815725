import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { SpecificBookingsContainer } from '@/features/bookings/components/Bookings/SpecificBookings'
import { SPECIFIC_BOOKING_ALIAS } from '@/features/bookings/consts/specific-bookings'

export const History = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'history'} />

      <SpecificBookingsContainer alias={SPECIFIC_BOOKING_ALIAS.HISTORY} />
    </>
  )
}
