import React from 'react'

interface IProps {
  color: string
  size?: number
  viewPort?: number
  className?: string
}

export const CalendarSearch = ({ size = 24, viewPort = size, color, ...props }: IProps) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox={`0 0 ${viewPort} ${viewPort}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.75 9.5C1.75 9.08579 2.08579 8.75 2.5 8.75H21.5C21.9142 8.75 22.25 9.08579 22.25 9.5V16.5C22.25 16.9142 21.9142 17.25 21.5 17.25C21.0858 17.25 20.75 16.9142 20.75 16.5V10.25H3.25V20.5C3.25 20.6381 3.36192 20.75 3.5 20.75H12C12.4142 20.75 12.75 21.0858 12.75 21.5C12.75 21.9142 12.4142 22.25 12 22.25H3.5C2.53351 22.25 1.75 21.4665 1.75 20.5V9.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 4.75C3.36193 4.75 3.25 4.86193 3.25 5V8.75H20.75V5C20.75 4.86192 20.6381 4.75 20.5 4.75H3.5ZM1.75 5C1.75 4.0335 2.5335 3.25 3.5 3.25H20.5C21.4665 3.25 22.25 4.03351 22.25 5V9.5C22.25 9.91421 21.9142 10.25 21.5 10.25H2.5C2.08579 10.25 1.75 9.91421 1.75 9.5V5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.75C8.41421 1.75 8.75 2.08579 8.75 2.5V6.5C8.75 6.91421 8.41421 7.25 8 7.25C7.58579 7.25 7.25 6.91421 7.25 6.5V2.5C7.25 2.08579 7.58579 1.75 8 1.75Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 1.75C16.4142 1.75 16.75 2.08579 16.75 2.5V6.5C16.75 6.91421 16.4142 7.25 16 7.25C15.5858 7.25 15.25 6.91421 15.25 6.5V2.5C15.25 2.08579 15.5858 1.75 16 1.75Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 13.25C13.4812 13.25 12.25 14.4812 12.25 16C12.25 17.5188 13.4812 18.75 15 18.75C16.5188 18.75 17.75 17.5188 17.75 16C17.75 14.4812 16.5188 13.25 15 13.25ZM10.75 16C10.75 13.6528 12.6528 11.75 15 11.75C17.3472 11.75 19.25 13.6528 19.25 16C19.25 18.3472 17.3472 20.25 15 20.25C12.6528 20.25 10.75 18.3472 10.75 16Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4239 18.0199C17.689 17.7017 18.1619 17.6587 18.4802 17.9239L21.4802 20.4239C21.7984 20.689 21.8414 21.1619 21.5762 21.4802C21.311 21.7984 20.8381 21.8414 20.5199 21.5762L17.5199 19.0762C17.2017 18.811 17.1587 18.3381 17.4239 18.0199Z"
      fill={color}
    />
  </svg>
)
