export const createWhisperChannelName = (userId: number, participantId: number) => {
  let a = userId
  let b = participantId

  if (participantId < userId) {
    a = participantId
    b = userId
  }

  return `users.${a}.${b}`
}
