import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { CalendarLayout } from '@/features/calendar/components/Layouts'
import { useHeaderContext } from '@/features/header/contexts/HeaderContext'
import { CalendarView } from '../services'

export const CalendarRoot = () => {
  const { setKey } = useHeaderContext()
  const navigate = useNavigate()

  const taskClickHandler = (e: React.MouseEvent<SVGElement>) => {
    e.preventDefault()

    navigate(-1)
  }
  useEffect(() => {
    setKey({
      base: false,
      title: 'bookings',
      border: true,
      rightIcon: 'list_details',
      rightClick: taskClickHandler,
    })
  }, [])

  return (
    <Routes>
      <Route element={<CalendarLayout />}>
        <Route index element={<CalendarView />} />
      </Route>

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
