import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  const icon = getRef('icon')
  return {
    iconWhite: {
      padding: '2px',
      margin: '9px',
      cursor: 'pointer',
    },
    linkIcon: {
      ref: icon,
      color: theme.black,
      cursor: 'pointer',
    },
    text: {
      marginLeft: theme.spacing.sm,
    },
    imageBorder: {
      border: '36px solid white',
      borderRadius: '16px',
      width: '80%',
      display: 'flex',
    },
    infoBackColor: {
      backgroundColor: theme.colors[theme.primaryColor][theme.fn.primaryShade()],
      color: 'white',
      padding: '32px',
    },
    imageBackColor: {
      width: '100%',
      display: 'flex',
      background: `linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1)50%, ${theme.fn.rgba(
        theme.colors[theme.primaryColor][theme.fn.primaryShade()],
        1
      )} 50%)`,
    },
    footer: {
      background: 'white',
      padding: '12px 0',
    },
  }
})
