import { IconX } from '@tabler/icons'
import classNames from 'classnames'
import React from 'react'
import { ModalProps as MantineModalProps, Modal, Text } from '@mantine/core'
import { MobileHeaderModal } from '@/components/Elements/Header/MobileHeader'
import useStyles from './ImageView.styles'

export interface ImageViewProps extends MantineModalProps {
  opened: boolean
  onClose: () => void
  children: React.ReactNode
  scrollable?: boolean
}

export const ImageView = ({
  opened,
  onClose,
  children,
  scrollable = true,
  ...props
}: ImageViewProps) => {
  const { classes } = useStyles()

  const closeHandler = (e: any) => {
    e.preventDefault()

    onClose()
  }

  const renderModalTitle = () => (
    <div className={classes.header}>
      <MobileHeaderModal
        border={false}
        background={'transparent'}
        left={<Text></Text>}
        right={<IconX size={20} color={'black'} onClick={closeHandler} />}
        title={<></>}
      />
    </div>
  )

  return (
    <Modal
      withCloseButton={false}
      opened={opened}
      onClose={onClose}
      fullScreen
      radius={0}
      title={renderModalTitle()}
      transition="fade"
      transitionDuration={200}
      className={classes.modal}
    >
      <div
        className={classNames('h-full', 'max-vh', 'align-middle', classes.background, {
          'overflow-hidden': !scrollable,
        })}
      >
        {children}
      </div>
    </Modal>
  )
}
