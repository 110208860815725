import * as Yup from 'yup'

export const emailsValidation = {
  emails: Yup.array()
    .of(
      Yup.object().shape({
        email: Yup.string()
          .nullable()
          .email('field.error.invalid')
          .required('field.error.required'),
      })
    )
    .min(1, 'field.error.required'),
}
