import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { MetaPagination, OverlayDataShower } from '@/components/Elements'
import { babysitterBabysittersFavoritesList } from '@/features/babysitter/store'
import { BabysitterUser } from '@/features/user'
import { RootState } from '@/store'
import { EmptyData } from './EmptyData'
import { GridView } from './GridView'

interface IProps {
  list: BabysitterUser[]
  loading: boolean
  error: any
  meta: any
  page: number
}

const FavoritesBabysittersContainerComponent = (props: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(babysitterBabysittersFavoritesList.cleanState())
    }
  }, [])

  useEffect(() => {
    fetchList()
  }, [props.page])

  const fetchList = () => {
    const params = {
      page: props.page,
      sort: [{ id: 'favorite_date', desc: true }],
    }

    dispatch(babysitterBabysittersFavoritesList.getList({ params }))
  }

  const onPageChange = (page: number) => {
    dispatch(babysitterBabysittersFavoritesList.setPage(page))
  }

  const onFavoriteSuccess = () => {
    fetchList()
  }

  return (
    <>
      <OverlayDataShower isLoading={props.loading} isFailed={!!props.error} error={props.error}>
        {!!props.list.length && (
          <div className={'flex flex-col justify-between h-full'}>
            <GridView items={props.list} onFavoriteSuccess={onFavoriteSuccess} />

            <MetaPagination meta={props.meta} page={props.page} onChange={onPageChange} />
          </div>
        )}

        {!props.list.length && !props.loading && !props.error && <EmptyData />}
      </OverlayDataShower>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  const { list, loading, error, meta, page } = state.babysitter.babysitters.favorites.list
  return {
    list,
    loading,
    error,
    meta,
    page,
  }
}

export const FavoritesBabysittersContainer = connect(mapStateToProps)(
  FavoritesBabysittersContainerComponent
)
