import React from 'react'

interface IProps {
  color: string
  size?: number

  viewPort?: number
  className?: string
}

export const Check = ({ size = 24, viewPort = size, color, ...props }: IProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${viewPort} ${viewPort}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27 9.00049L13 22.9999L6 16.0005"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
