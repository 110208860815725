import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  wrapper: {
    paddingRight: '8px', // grid gutters
    paddingBottom: '8px', // grid gutters
  },
  cardWrapper: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      paddingBottom: '32px',
    },
  },
  price: {
    fontSize: '14px',
    width: '100%',
  },
  form: {
    color: 'grey.600',
    backgroundColor: 'white',
    borderRadius: '8px',
    fontSize: '14px',
    border: '1px solid grey',
    padding: '8px 16px',
    width: '100%',
  },
  footerPriceMobile: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      background: 'white',
      padding: '6px 16px',
    },
  },
}))
