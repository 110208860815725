import * as Yup from 'yup'
import { formHelper } from '@/features/profile/helpers'

const { generalValidation } = formHelper.general

const { detailsValidation, languagesValidation, skillsValidation, certificationsValidation } =
  formHelper.babysitterProfile

export const validationSchema = Yup.object().shape({
  ...generalValidation,
  ...detailsValidation,
  ...languagesValidation,
  ...skillsValidation,
  ...certificationsValidation,
})
