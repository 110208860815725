import { combineReducers } from 'redux'
import chats from './chats/reducers'
import conversation from './conversation/reducers'

const chatReducer = combineReducers({
  chats,
  conversation,
})

export default chatReducer
