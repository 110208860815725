import React, { useEffect } from 'react'
import { ChatsContainer } from './Chats'
import { Content } from './Content'
import useStyles from './Sidebar.styles'

interface IProps {
  openRoom?: () => void
}
export const Sidebar = ({ openRoom }: IProps) => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <Content>
        <ChatsContainer openRoom={openRoom} />
      </Content>
    </div>
  )
}
