import React from 'react'

interface IProps {
  color: string
  size?: number
  viewPort?: number
  className?: string
}

export const Profile = ({ size = 24, viewPort = size, color, ...props }: IProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${viewPort} ${viewPort}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7ZM14.5011 11.3305C15.995 10.4658 17 8.85025 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 8.85025 8.00501 10.4658 9.4989 11.3305C5.17331 12.4985 2 16.6255 2 21.5C2 21.7761 2.22386 22 2.5 22C2.77614 22 3 21.7761 3 21.5C3 16.2288 7.05329 12 12 12C16.9467 12 21 16.2288 21 21.5C21 21.7761 21.2239 22 21.5 22C21.7761 22 22 21.7761 22 21.5C22 16.6255 18.8267 12.4985 14.5011 11.3305Z"
      fill={color}
    />
  </svg>
)
