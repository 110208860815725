import Autoplay from 'embla-carousel-autoplay'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Carousel as MantineCarousel } from '@mantine/carousel'
import { Title } from '@mantine/core'
import { ExternalLinkButton } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { ROLE } from '@/features/user/types'
import useStyles from './Carousel.styles'
import img1 from './assets/img-1.png'
import img2 from './assets/img-2.png'
import img3 from './assets/img-3.jpg'
import img4 from './assets/img-4.jpg'

type SlideType = {
  title: string
  text: string
  link: string
  img: any
}

interface IProps {
  role?: ROLE
}

export const Carousel = ({ role }: IProps) => {
  const { classes } = useStyles()

  const { t } = useTranslation()

  const autoplay = useRef(Autoplay({ delay: 5000 }))

  const isBabysitter = role === ROLE.Babysitter

  const defaultSlides: Array<SlideType> = [
    {
      title: t('auth_carousel.slide_1.title'),
      text: t('auth_carousel.slide_1.text'),
      link: 'http://babysisters.pt/',
      img: img1,
    },
    {
      title: t('auth_carousel.slide_2.title'),
      text: t('auth_carousel.slide_2.text'),
      link: 'http://babysisters.pt/',
      img: img2,
    },
  ]

  const babysitterSlides: Array<SlideType> = [
    {
      title: t('auth_carousel.slide_1.title'),
      text: t('auth_carousel.slide_1.text'),
      link: 'http://babysisters.pt/',
      img: img3,
    },
    {
      title: t('auth_carousel.slide_2.title'),
      text: t('auth_carousel.slide_2.text'),
      link: 'http://babysisters.pt/',
      img: img4,
    },
  ]

  const slides: Array<SlideType> = isBabysitter ? babysitterSlides : defaultSlides

  const {
    appState: { mobileView },
  } = useAppState()

  const renderSlide = ({ title, text, link, img }: SlideType) => (
    <div className={classes.wrapper}>
      <div className={classes.background}>
        <div className={classes.overlay} />
        <div
          className={classes.bgImage}
          style={{
            backgroundImage: `url(${img})`,
            backgroundPosition: isBabysitter ? 'center' : 'right',
          }}
        />
      </div>
      <div className={classes.contentBlock}>
        <Title order={4} mb={'lg'}>
          {text}
        </Title>

        <Title mb={'lg'}>{title}</Title>

        <ExternalLinkButton href={link} variant="white">
          {t('learn_more')}
        </ExternalLinkButton>
      </div>
    </div>
  )

  return (
    <MantineCarousel
      withControls={false}
      withIndicators
      height={'100%'}
      draggable={false}
      plugins={[autoplay.current]}
      onMouseEnter={autoplay.current.stop}
      onMouseLeave={autoplay.current.reset}
      styles={(theme: any) => ({
        root: { height: '100%', overflow: 'hidden' },
        viewport: { height: '100%' },
        indicators: {
          bottom: '60px',
          right: mobileView ? 0 : '-80px',
        },
        indicator: {
          width: 12,
          height: 12,
          transition: 'width 250ms ease',
          border: `2px solid ${theme.white}`,
          background: 'transparent',
          opacity: 1,

          '&[data-active]': {
            background: theme.white,
          },
        },
      })}
    >
      {slides.map((item, i) => (
        <MantineCarousel.Slide key={i}>{renderSlide(item)}</MantineCarousel.Slide>
      ))}
    </MantineCarousel>
  )
}
