import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, Title } from '@mantine/core'
import { NoDataTextMessage } from '@/components/Elements'
import useStyles from './OtherInfoBlock.styles'

interface IProps {
  data: string | null | undefined
}

export const OtherInfoBlock = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <>
      <div>
        <Title order={5}>{t('other_info')}</Title>

        <div className={'mt-3'}>
          {data ? (
            <Text className={classes.info}>{data}</Text>
          ) : (
            <NoDataTextMessage variant={'info'} />
          )}
        </div>
      </div>
    </>
  )
}
