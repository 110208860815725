import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '352px',
    padding: '16px',
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center',
  },

  img: {
    maxWidth: '100%',
    height: 'auto',
  },
}))
