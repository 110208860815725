import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  block: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    minHeight: '90px',
    flexWrap: 'wrap',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      padding: '0px 32px',
    },
  },
}))
