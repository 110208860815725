import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from '@/components/Elements'
import { CandidateHireContainer } from '@/features/booking-candidate/components/CandidateHire'
import { BookingCandidate } from '@/features/booking-candidate/types/models'
import { usePopup } from '@/hooks'

interface IProps {
  candidate: BookingCandidate
  onSuccess?: (data: object) => void
  size?: any
}

export const HireControl = ({ candidate, onSuccess, size = 'sm' }: IProps) => {
  const { t } = useTranslation()

  const { visible, open, close } = usePopup()

  const onHireSuccess = (data: any) => {
    close()

    onSuccess && onSuccess(data)
  }

  return (
    <>
      <div>
        <Button onClick={open} size={size}>
          {t('choose')}
        </Button>
      </div>

      <Modal opened={visible} onClose={close} centered title={t('hire_babysitter')}>
        <CandidateHireContainer candidate={candidate} onSuccess={onHireSuccess} onCancel={close} />
      </Modal>
    </>
  )
}
