import axios from 'axios'

class WalletAPI {
  getWalletInfo = async () => {
    return axios.get(`api/profile/wallet-info`)
  }
  shareReferral = async (params: any) => {
    return axios.post(`api/profile/share-referral`, params)
  }
}

export const walletAPI = new WalletAPI()
