import * as Yup from 'yup'
import { formHelper, langHelper } from '@/features/profile/helpers'
import { cityHelper } from '@/helpers'
import { ValidationsUtils } from '@/utils'

const { generalValidation } = formHelper.general

export const validationSchema = Yup.object().shape({
  ...generalValidation,

  city_id: Yup.mixed().nullable().required('field.error.required'),
  city_description: Yup.string().when('city_id', {
    is: (city_id: string) => cityHelper.general.isCityExtraOption(city_id),
    then: Yup.string().required('field.error.required'),
  }),
  country_id: Yup.mixed()
    .nullable()
    .when('city_id', {
      is: (city_id: string) => cityHelper.general.isInternational(city_id),
      then: Yup.mixed().nullable().required('field.error.required'),
    }),
  address: Yup.string()
    .nullable()
    .required('field.error.required')
    .notOneOf([Yup.ref('zip'), null], 'field.address.no_one_of_zip'),
  zip: Yup.string()
    .required('field.error.required')
    .test('zip', 'field.error.invalid', ValidationsUtils.zipFormat),

  children: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('field.error.required'),
        birthday: Yup.date().nullable().required('field.error.required'),
      })
    )
    .min(1, 'field.error.required'),

  languages: Yup.array()
    .of(
      Yup.object().shape({
        language_id: Yup.mixed().nullable().required('field.error.required'),
        name: Yup.string().when('language_id', {
          is: (language_id: string) => langHelper.general.isLangExtraOption(language_id),
          then: Yup.string()
            .trim()
            .required('field.error.required')
            .min(3, 'field.error.min_length'),
        }),
      })
    )
    .min(1, 'field.error.required'),

  about_child: Yup.string().required('field.error.required'),
  other_info: Yup.string().nullable(),
})
