import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Alert, MegaTagTitle } from '@/components/Elements'
import { AccountNotApprovedInfo } from '@/features/auth/components/Elements'
import { LoginContainer } from '@/features/auth/components/Loging'
import { ROLE } from '@/features/user'
import { usePopup, useQuery } from '@/hooks'

interface IProps {
  role: ROLE
}

interface IAlert {
  type: string
  message: string
}

export const Login = ({ role }: IProps) => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const query = useQuery()

  const [alert, setAlert] = useState<IAlert | null>(null)

  const { visible, open, close } = usePopup()

  const setAlertMessage = (data: IAlert | null = null) => {
    setAlert(data)
  }

  const historyReplace = () => {
    navigate(
      {
        search: '',
      },
      { replace: true }
    )
  }

  const onApprovedInfo = () => {
    open({})
  }

  useEffect(() => {
    if (query.get('verified') === '1') {
      setAlertMessage({
        message: 'email_verification.success',
        type: 'success',
      })
      historyReplace()

      if (role === ROLE.Babysitter) {
        onApprovedInfo()
      }
    }
  }, [])

  return (
    <>
      <MegaTagTitle title={role === ROLE.Client ? 'client_login' : 'babysitter_login'} />

      {alert?.message && (
        <Alert type={'success'} mb={'md'}>
          {t(alert.message)}
        </Alert>
      )}

      <LoginContainer role={role} onApprovedInfo={onApprovedInfo} />

      <AccountNotApprovedInfo visible={visible} onClose={close} />
    </>
  )
}
