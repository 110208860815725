import React from 'react'
import { useTranslation } from 'react-i18next'
import { CertificationsFormSection } from '@/features/profile/components/FormSections/BabysitterProfileFormSections'

export const CertificationsForm = () => {
  const { t } = useTranslation()

  return (
    <div className={'mt-2'}>
      <CertificationsFormSection />
    </div>
  )
}
