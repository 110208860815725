import React from 'react'
import { GeneralTabs, GeneralTabsProps } from '../GeneralTabs'

// eslint-disable-next-line
interface IProps extends Omit<GeneralTabsProps, 'navbarOnly'> {}

export const NavTabs = ({ ...props }: IProps) => {
  return (
    <>
      <GeneralTabs {...props} navbarOnly={true} />
    </>
  )
}
