import React, { createContext, useContext, useState } from 'react'

const BookingFormTimetableContext = createContext({
  weeks: [] as Array<any>,
  setWeeks: (weeks: Array<any>) => {},
})

interface IProps {
  children: React.ReactNode
}

const BookingFormTimetableProvider = ({ children }: IProps) => {
  const [weeks, setWeeks] = useState<Array<any>>([])

  return (
    <BookingFormTimetableContext.Provider value={{ weeks, setWeeks }}>
      {children}
    </BookingFormTimetableContext.Provider>
  )
}

const useBookingFormTimetableContext = () => useContext(BookingFormTimetableContext)

export { BookingFormTimetableProvider, useBookingFormTimetableContext }
