import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Group, Text } from '@mantine/core'
import { AvatarProvider } from '@/components/Elements'
import { useChatConversationData } from '@/features/chat/hooks'
import { useUser } from '@/features/user'
import { useUsersModel } from '@/features/users/hooks'
import useStyles from './Info.styles'

export const Info = () => {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const { isClient, isBabysitter } = useUser()

  const { getParticipantsBabysitters, getParticipantsClient } = useChatConversationData()
  const participants = isBabysitter() ? getParticipantsClient() : getParticipantsBabysitters()

  const { getName } = useUsersModel()

  const config = {
    avatar: isClient(),
  }
  const viewProfile = (id: number) => {
    navigate('/babysitters/' + id)
  }

  return (
    <div className={'flex flex-wrap gap-4 items-center'}>
      {config.avatar && (
        <Group className={classNames('gap-0', classes.avatar)}>
          {(!participants || participants.length == 1) && (
            <AvatarProvider
              size={40}
              avatar={participants && participants.length == 1 ? participants[0]?.avatar : null}
            />
          )}
          {participants &&
            participants.length > 1 &&
            participants?.map((item, index) => (
              <div
                key={'info' + index}
                className={'cursor-pointer gap-0'}
                onClick={() => viewProfile(item.id)}
              >
                <AvatarProvider
                  size={40}
                  avatar={item ? item.avatar : null}
                  key={'avatar' + index}
                />
              </div>
            ))}
        </Group>
      )}

      {participants && participants.length == 1 && (
        <div>
          <Text weight={700} className={classes.name}>
            {getName(participants[0])}
          </Text>
        </div>
      )}
    </div>
  )
}
