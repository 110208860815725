import React from 'react'
import { BasicLayout, PanelLayout, PrivateLayout } from '@/components/Layouts'
import { useAppState } from '@/features/app/hooks'
import { BookRoot } from '@/features/book/routes'
import { BookRootNoLoginDesktop } from '@/features/book/routes/root/BookRootNoLoginDesktop'
import { PanelSwitch, RouteGuard } from '@/routes'
import { getBookRoutes } from '@/routes/configs/private/routes'

const mapChildrenRoutes = (routs: Array<any>, user: object | null) => [
  ...routs.map(({ path, index, element, roles, guards }, i) => {
    return {
      key: `${i}-${path}`,
      path,
      index,
      element: (
        <RouteGuard roles={roles} guards={guards} user={user}>
          {element}
        </RouteGuard>
      ),
    }
  }),
]

export const getBookNewRoutes = (isLoggedIn: boolean, user: object | null) => {
  const {
    appState: { mobileView },
  } = useAppState()

  return isLoggedIn
    ? [
        {
          path: '/*',
          element: <PrivateLayout />,
          children: [
            {
              index: isLoggedIn,
              element: <PanelSwitch user={user} />,
            },
            {
              element: <PanelLayout useOpacity={true} goToBookNow={true} />,
              children: [...mapChildrenRoutes(getBookRoutes(), user)],
            },
          ],
        },
      ]
    : [
        {
          path: '/book-now',
          element: (
            <BasicLayout header={mobileView} bookNow={mobileView} useOpacity={true}>
              {mobileView ? <BookRoot /> : <BookRootNoLoginDesktop />}
            </BasicLayout>
          ),
          children: [],
        },
      ]
}
