import * as React from 'react'
import { AppLangPicker, LogoIcon } from '@/components/Elements'
import useStyles from './Header.styles'

export const Header = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.block}>
      <LogoIcon />

      <AppLangPicker />
    </div>
  )
}
