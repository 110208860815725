import React from 'react'
import { useTranslation } from 'react-i18next'
import { Title } from '@mantine/core'
import { ConfirmationControl } from '@/components/Controls'
import { Button } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { useProfileDeactivate } from '@/features/profile/hooks'

export const ProfileDeactivation = () => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()
  const { deactivate, loading } = useProfileDeactivate()

  return (
    <>
      <Title order={mobileView ? 4 : 3} mb={'sm'}>
        {t('deactivation')}
      </Title>

      <ConfirmationControl
        onConfirm={deactivate}
        render={({ onOpen }) => (
          <Button
            loading={loading}
            color={'gray'}
            variant={'outline'}
            onClick={onOpen}
            fullWidth={mobileView}
          >
            {t('deactivate_account')}
          </Button>
        )}
      />
    </>
  )
}
