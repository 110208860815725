import _ from 'lodash'
import i18n from '@/i18n'

type weekDayOptionType = {
  key: string
  isoWeek: number
  symbol: string
  label: string
}

export const getWeekDays = (t = i18n.t) => {
  return [
    {
      key: 'monday',
      isoWeekday: 1,
      symbol: t('weekday.symbol.monday'),
      label: t('monday'),
    },
    {
      key: 'tuesday',
      isoWeekday: 2,
      symbol: t('weekday.symbol.tuesday'),
      label: t('tuesday'),
    },
    {
      key: 'wednesday',
      isoWeekday: 3,
      symbol: t('weekday.symbol.wednesday'),
      label: t('wednesday'),
    },
    {
      key: 'thursday',
      isoWeekday: 4,
      symbol: t('weekday.symbol.thursday'),
      label: t('thursday'),
    },
    {
      key: 'friday',
      isoWeekday: 5,
      symbol: t('weekday.symbol.friday'),
      label: t('friday'),
    },
    {
      key: 'saturday',
      isoWeekday: 6,
      symbol: t('weekday.symbol.saturday'),
      label: t('saturday'),
    },
    {
      key: 'sunday',
      isoWeekday: 7,
      symbol: t('weekday.symbol.sunday'),
      label: t('sunday'),
    },
  ]
}

export const getWeekDay = (key: string, t = i18n.t): weekDayOptionType | any => {
  const options = getWeekDays(t)
  return _.find(options, { key })
}
