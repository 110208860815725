import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  title: {
    width: '100%',
    margin: 0,
  },
  background: {
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    display: 'flex',
    padding: '16px !important',
    height: '100%',
    backgroundColor: 'white',
  },

  close: {
    position: 'absolute',
    right: '20px',
    top: '20px',
    color: theme.colors.dark,
  },
  header: {
    position: 'absolute',
  },
}))
