import { SCREEN } from '../consts'
import { CLEAN_STATE, RESET_STATE, SET_SCREEN, SET_SCREEN_DATA, SET_STATE } from './actionTypes'
import { State } from './reducer'

export const setState = (data: State) => {
  return {
    type: SET_STATE,
    payload: data,
  }
}

export const resetState = () => {
  return { type: RESET_STATE }
}

export const cleanState = () => {
  return { type: CLEAN_STATE }
}

export const setScreen = (data: SCREEN | null) => {
  return {
    type: SET_SCREEN,
    payload: data,
  }
}

export const setScreenData = (data: any) => {
  return {
    type: SET_SCREEN_DATA,
    payload: data,
  }
}
