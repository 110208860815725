import axios from 'axios'

class BookingTrackerAPI {
  getTracker = async (id: number) => {
    return axios.get(`api/booking-trackers/${id}`)
  }

  updateTracker = async (id: number, data: any) => {
    return axios.put(`api/booking-trackers/${id}`, data)
  }

  deleteTracker = async (id: number) => {
    return axios.delete(`api/booking-trackers/${id}`)
  }
}

export const bookingTrackerAPI = new BookingTrackerAPI()
