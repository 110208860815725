import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'

interface IProps {
  children?: React.ReactNode
  variant?: 'empty' | 'fill' | 'data' | 'info'
}

export const NoDataTextMessage = ({ children, variant = 'empty' }: IProps) => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()
  const message =
    variant === 'empty'
      ? t('empty')
      : variant === 'fill'
      ? t('not_filled')
      : variant === 'data'
      ? t('no_data')
      : variant === 'info'
      ? t('no_info')
      : ''

  return (
    <React.Fragment>
      {children ? children : <Text size={mobileView ? 14 : 16}>{message}</Text>}
    </React.Fragment>
  )
}
