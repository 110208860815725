import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  title: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: '24px',
      fontWeight: 700,
      textTransform: 'capitalize',
    },
  },
  leftArrow: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      border: '1px solid lightgrey',
      height: '36px',
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
      padding: '4px 16px',
    },
  },
  rightArrow: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      border: '1px solid lightgrey',
      height: '36px',
      borderBottomRightRadius: '8px',
      borderTopRightRadius: '8px',
      padding: '4px 16px',
    },
  },
}))
