import React from 'react'
import { ActionIcon } from '@mantine/core'

interface IProps {
  onClick: () => void
  children: React.ReactNode
}

export const ControlButton = ({ onClick, children }: IProps) => {
  return (
    <ActionIcon size={'lg'} onClick={onClick} radius="xl" variant="outline" color={'primary'}>
      {children}
    </ActionIcon>
  )
}
