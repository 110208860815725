import { IconCalendar, IconClock } from '@tabler/icons'
import { debounce } from 'lodash'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid, Title } from '@mantine/core'
import { ActionIconButton } from '@/components/Elements'
import {
  DatePickerController,
  FormControlActionBlock,
  TimeInputController,
} from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'

interface IProps {
  name: string
  control: any
  gridProps?: any
  index: number
  item: Record<'fieldId', string>
  onRemove: (index: number) => void
}

export const OnetimeRangesItem = ({ name, control, index, item, gridProps, onRemove }: IProps) => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()

  const { watch, setValue, getValues } = useFormContext()

  const watchType = watch('type')
  const watchDateEnd = watch('date_end')
  const watchTimeStart = watch(`${name}.${index}.range_time_start`)

  const calcEndDate = () => {
    const timeStart = getValues(`${name}.${index}.range_time_start`)
    const timeEnd = getValues(`${name}.${index}.range_time_end`)
    const type = getValues('type')
    if (
      timeStart &&
      type == BOOKING_TYPE.ONETIME &&
      (!timeEnd || !calcTimeDiffOk(timeStart, timeEnd))
    ) {
      setValue(`${name}.${index}.range_time_end`, moment(timeStart).add(3, 'hours').toDate())
    }
  }

  const debouncedCalcEndDate = React.useCallback(debounce(calcEndDate, 1000), [])
  useEffect(() => {
    if (watchTimeStart) {
      debouncedCalcEndDate()
    }
  }, [watchTimeStart])

  const removeRow = (index: number) => {
    onRemove(index)
  }

  const calcTimeDiffOk = (startTime: any, endTime: any) => {
    const diff = moment()
      .hour(moment(endTime).hour())
      .diff(moment().hour(moment(startTime).hour()))

    const duration = moment.duration(diff)
    return duration.asHours() >= 4 || (duration.asHours() < 0 && duration.asHours() >= -20)
  }

  const renderTitleSection = () => (
    <Title order={5} mb={'sm'} color={'primary'}>
      {t('added_date')}
    </Title>
  )

  return (
    <div key={item.fieldId} className={'flex flex-col'}>
      {renderTitleSection()}
      {watchType == BOOKING_TYPE.ONETIME ? (
        <>
          <Grid grow {...gridProps}>
            <Grid.Col span={mobileView ? 6 : 4}>
              <DatePickerController
                control={control}
                name={`${name}.${index}.range_date_start`}
                id={`${name}.${index}.range_date_start`}
                label={t('service_date')}
                size={mobileView ? 'md' : 'lg'}
                placeholder={t('service_date')}
                withinPortal
                clearable={false}
                mb={'md'}
                translateParams={{ date: t('today') }}
                minDate={new Date()}
                initialMonth={watchDateEnd || new Date()}
                icon={<IconCalendar />}
                styles={
                  mobileView
                    ? {
                        label: {
                          fontWeight: 'bold',
                          fontSize: '16px !important',
                        },
                      }
                    : {}
                }
              />
            </Grid.Col>

            <Grid.Col span={mobileView ? 5 : 3} />
            <Grid.Col span={mobileView ? 1 : 5} />
          </Grid>

          <Grid>
            <Grid.Col span={mobileView ? 6 : 4}>
              <TimeInputController
                control={control}
                name={`${name}.${index}.range_time_start`}
                id={`${name}.${index}.range_time_start`}
                size={mobileView ? 'md' : 'lg'}
                label={t('booking.start_hour')}
                icon={<IconClock />}
                mb={'md'}
                styles={
                  mobileView
                    ? {
                        label: {
                          fontWeight: 'bold',
                          fontSize: '16px !important',
                        },
                      }
                    : {}
                }
              />
            </Grid.Col>

            <Grid.Col span={mobileView ? 5 : 3}>
              <TimeInputController
                control={control}
                name={`${name}.${index}.range_time_end`}
                id={`${name}.${index}.range_time_end`}
                size={mobileView ? 'md' : 'lg'}
                label={t('booking.end_hour')}
                icon={<IconClock />}
                mb={'md'}
                className={mobileView ? 'ml-2' : ''}
                styles={
                  mobileView
                    ? {
                        label: {
                          fontWeight: 'bold',
                          fontSize: '16px !important',
                        },
                      }
                    : {}
                }
              />
            </Grid.Col>
            <Grid.Col span={mobileView ? 1 : 5}>
              <FormControlActionBlock className={mobileView ? 'mt-4' : 'mb-4'}>
                <ActionIconButton name={'remove'} onClick={() => removeRow(index)} />
              </FormControlActionBlock>
            </Grid.Col>
          </Grid>
        </>
      ) : (
        <>
          <Grid grow {...gridProps}>
            <Grid.Col span={mobileView ? 6 : 4}>
              <DatePickerController
                control={control}
                name={`${name}.${index}.range_date_start`}
                id={`${name}.${index}.range_date_start`}
                label={t('start_date')}
                size={mobileView ? 'md' : 'lg'}
                placeholder={t('start_date')}
                withinPortal
                clearable={false}
                mb={'md'}
                translateParams={{ date: t('today') }}
                minDate={new Date()}
                initialMonth={watchDateEnd || new Date()}
                icon={<IconCalendar />}
                styles={
                  mobileView
                    ? {
                        label: {
                          fontWeight: 'bold',
                          fontSize: '16px !important',
                        },
                      }
                    : {}
                }
              />
            </Grid.Col>

            <Grid.Col span={mobileView ? 5 : 3}>
              <TimeInputController
                control={control}
                name={`${name}.${index}.range_time_start`}
                id={`${name}.${index}.range_time_start`}
                size={mobileView ? 'md' : 'lg'}
                label={t('from')}
                icon={<IconClock />}
                mb={'md'}
                className={mobileView ? 'ml-2' : ''}
                styles={
                  mobileView
                    ? {
                        label: {
                          fontWeight: 'bold',
                          fontSize: '16px !important',
                        },
                      }
                    : {}
                }
              />
            </Grid.Col>
            <Grid.Col span={mobileView ? 1 : 5} />
          </Grid>

          <Grid>
            <Grid.Col span={mobileView ? 6 : 4}>
              <DatePickerController
                control={control}
                name={`${name}.${index}.range_date_end`}
                id={`${name}.${index}.range_date_end`}
                size={mobileView ? 'md' : 'lg'}
                label={t('end_date')}
                placeholder={t('end_date')}
                mb={'md'}
                withinPortal
                clearable={false}
                translateParams={{ date: t('start_date') }}
                minDate={new Date()}
                icon={<IconCalendar />}
                initialMonth={watchDateEnd || new Date()}
                styles={
                  mobileView
                    ? {
                        label: {
                          fontWeight: 'bold',
                          fontSize: '16px !important',
                        },
                      }
                    : {}
                }
              />
            </Grid.Col>

            <Grid.Col span={mobileView ? 5 : 3}>
              <TimeInputController
                control={control}
                name={`${name}.${index}.range_time_end`}
                id={`${name}.${index}.range_time_end`}
                size={mobileView ? 'md' : 'lg'}
                label={t('to')}
                icon={<IconClock />}
                mb={'md'}
                className={mobileView ? 'ml-2' : ''}
                styles={
                  mobileView
                    ? {
                        label: {
                          fontWeight: 'bold',
                          fontSize: '16px !important',
                        },
                      }
                    : {}
                }
              />
            </Grid.Col>
            <Grid.Col span={mobileView ? 1 : 5}>
              <FormControlActionBlock className={mobileView ? 'mt-4' : 'mb-4'}>
                <ActionIconButton name={'remove'} onClick={() => removeRow(index)} />
              </FormControlActionBlock>
            </Grid.Col>
          </Grid>
        </>
      )}
    </div>
  )
}
