import { useTranslation } from 'react-i18next'
import { useBookingPermissions } from '@/features/bookings/hooks'
import { BookingModel } from '@/features/bookings/types/models'
import { RatingEntryPopupManagerControl } from '@/features/rating/components/RatingEntryPopupManager'
import { RatingSubmittedActionMeta } from '@/features/rating/hooks/useRatingEntryPopupModule'
import {
  BabysitterRatingModel,
  ClientRatingModel,
  RatingModel,
} from '@/features/rating/types/models'
import { BabysitterUser } from '@/features/user'

interface IProps {
  booking: BookingModel
  babysitter: BabysitterUser
  onRatingSubmitted: (rating: any, actionMeta: any) => void
}

export const RatingControl = ({ booking, babysitter, onRatingSubmitted }: IProps) => {
  const { isRatingManageAllow } = useBookingPermissions()

  const onHandleRatingSubmitted = (
    rating: ClientRatingModel | RatingModel,
    actionMeta: RatingSubmittedActionMeta
  ) => {
    actionMeta = {
      ...actionMeta,
      payload: {
        bookingId: booking?.id,
      },
    }
    onRatingSubmitted(rating, actionMeta)
  }

  const rating = booking.rating?.babysitter_ratings?.filter(
    (item) => item.babysitter.id == babysitter.id
  ) as BabysitterRatingModel[]
  const babysitterRating = rating && rating?.length > 0 ? rating[0] : undefined

  return (
    <RatingEntryPopupManagerControl
      booking={booking}
      value={babysitterRating?.person}
      disabled={!isRatingManageAllow(booking, babysitter)}
      ratingControlProps={{ size: 'lg' }}
      onRatingSubmitted={onHandleRatingSubmitted}
    />
  )
}
