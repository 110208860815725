import React from 'react'
import { ScrollArea, Tabs } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import useStyles from './GeneralTabs.styles'

export interface GeneralTabProps {
  label: React.ReactNode
  value: string
  render?: () => void
  tabProps?: any
  panelProps?: any
}

export interface GeneralTabsProps {
  value: string
  onChange: (value: any) => void
  tabs: Array<GeneralTabProps>
  navbarOnly?: boolean
}

export const GeneralTabs = ({
  value,
  tabs,
  onChange,
  navbarOnly = false,
  ...props
}: GeneralTabsProps) => {
  const {
    appState: { mobileView },
  } = useAppState()
  const { classes } = useStyles()
  const onTabChange = (tab: string) => {
    if (tab !== value) {
      onChange(tab)
    }
  }

  return (
    <>
      <Tabs value={value} onTabChange={onTabChange} classNames={classes} {...props}>
        <ScrollArea type={'never'}>
          <Tabs.List grow={mobileView}>
            {tabs.map(({ label, value, tabProps }) => (
              <Tabs.Tab
                key={value}
                value={value}
                {...tabProps}
                className={mobileView ? 'mx-1' : ''}
              >
                {label}
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </ScrollArea>

        {!navbarOnly &&
          tabs.map(({ value, render, panelProps }) => (
            <Tabs.Panel key={value} value={value} {...panelProps}>
              {render && render()}
            </Tabs.Panel>
          ))}
      </Tabs>
    </>
  )
}
