import moment from 'moment'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { BookingModel } from '@/features/bookings/types/models'

export const getTimePeriod = (
  booking: BookingModel | any
): { time_start: string; time_end: string } | null => {
  const { date_end, time_start, time_end, type, timetable } = booking

  let result = null

  if (time_start && time_end) {
    let finalTimeEnd = time_end
    if (
      (type === BOOKING_TYPE.ONETIME || type === BOOKING_TYPE.ANIMATION) &&
      timetable.onetime_ranges &&
      timetable.onetime_ranges.length > 0
    ) {
      let maxDate = moment(date_end)
      timetable.onetime_ranges.forEach(function (range: any) {
        if (maxDate.isBefore(moment(range.range_date_end))) {
          maxDate = moment(range.range_date_end)
          if (finalTimeEnd < range.range_time_end) finalTimeEnd = range.range_time_end
        }
      })
    }

    result = {
      time_start: time_start as string,
      time_end: finalTimeEnd as string,
    }
  }

  return result
}
