import _ from 'lodash'
import { useEffect, useMemo } from 'react'
import { usePrevious } from 'react-use'
import { MessageType } from '@/features/chat/types/models'
import { ScrollbarUtils } from '@/utils'

interface IProps {
  messages: MessageType[]
  messagesLoading: boolean
  messagesIsMoreLoading: boolean
  scrollRef: any
  messagesRef: any
  lastMessageRef: any
}

export const useChatMessagesScroll = ({
  messages,
  messagesLoading,
  messagesIsMoreLoading,
  scrollRef,
  lastMessageRef,
  messagesRef,
}: IProps) => {
  const prevLoading = usePrevious(messagesLoading)
  const prevIsMoreLoading = usePrevious(messagesIsMoreLoading)
  const prevMessages = usePrevious(messages)

  // save scroll position before messages rerender
  const scrollPosition = useMemo(() => {
    if (messages.length > 0 && scrollRef.current) {
      return {
        offsetTop: scrollRef.current.scrollTop,
        offsetBottom: scrollRef.current.scrollHeight - scrollRef.current.scrollTop,
        isCloseToBottom: ScrollbarUtils.isCloseToBottom(scrollRef.current, 100),
      }
    }

    return null
  }, [messages])

  const processScroll = () => {
    //console.log('processScroll chat messages')
    if (messages.length > 0) {
      const scrollToBottom = () => {
        //console.log('scrollToBottom')
        //if (scrollRef.current) {
        //  console.log('scrollRef.current.scrollHeight:' + scrollRef.current.scrollHeight)
        //  scrollRef.current.scrollTop = scrollRef.current.scrollHeight
        //}
        if (lastMessageRef.current) {
          lastMessageRef.current.scrollIntoView({ behavior: 'smooth' })
        }
      }

      if (prevLoading === true) {
        const list = [...messages].reverse()

        // if has unread message => scroll to unread
        const unreadMessage = _.find(list, { is_my: false, seen: false })
        if (unreadMessage && messagesRef.current[unreadMessage.id]) {
          ScrollbarUtils.scrollToTarget(messagesRef.current[unreadMessage.id], scrollRef.current)
          return
        }

        scrollToBottom()
        return
      }

      if (prevIsMoreLoading === true) {
        if (scrollPosition) {
          ScrollbarUtils.scrollToTarget(
            scrollRef.current.scrollHeight - scrollPosition.offsetBottom,
            scrollRef.current
          )
        }

        return
      }

      if (prevMessages && messages.length !== prevMessages.length) {
        const previousLastMessage = prevMessages[0]
        const currentLastMessage = messages[0]

        const isNewMessage = previousLastMessage && previousLastMessage.id !== currentLastMessage.id

        // scroll to bottom if receive new message and prev position was in bottom and in not my message
        if (isNewMessage && currentLastMessage.is_my === false) {
          if (scrollPosition && scrollPosition.isCloseToBottom) {
            scrollToBottom()
            return
          }
        }

        // scroll to bottom if new message and is my messages
        if (isNewMessage && currentLastMessage.is_my === true) {
          scrollToBottom()
        }
      }
    }
  }
  useEffect(() => {
    setTimeout(() => {
      processScroll()
    }, 1000)
  }, [messages])
}
