import React from 'react'
import { BOOKING_CANDIDATE_STATUS } from '@/features/booking-candidate/consts/candidate'
import { BookingCandidate } from '@/features/booking-candidate/types/models'

export const useCandidatePermissions = () => {
  const isHireAllow = (candidate: BookingCandidate) => {
    const { status, babysitter } = candidate

    const allowStatuses = [BOOKING_CANDIDATE_STATUS.WAITING_HIRING]

    return allowStatuses.includes(status) && babysitter?.active
  }

  return {
    isHireAllow,
  }
}
