import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  controlsBlock: {
    padding: '24px 0 50px',
    display: 'flex',
    alignItems: 'center',
  },

  sliderBlock: {
    display: 'flex',
    width: '100%',
    margin: '0 20px',
  },

  root: {
    width: '100%',
  },

  track: {},

  thumb: {
    width: '28px',
    height: '28px',
    backgroundColor: theme.colors.primary[theme.fn.primaryShade()],

    ['& svg']: {
      stroke: theme.white,
    },
  },

  thumbIcon: {
    display: 'flex',
  },

  thumbIconLeft: {
    position: 'relative',
    left: '3px',
    top: '2px',
  },

  thumbIconRight: {
    position: 'relative',
    right: '3px',
    top: '2px',
  },

  markWrapper: {
    top: '-3px',
  },

  mark: {
    width: '10px',
    height: '10px',
    transform: 'translateX(-5px)',
    borderWidth: '2px',
  },

  markLabel: {
    marginTop: '10px',
  },

  markLabelValue: {
    fontWeight: 600,
    color: theme.black,
  },
}))
