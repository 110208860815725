import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  block: {
    display: 'flex',
    alignItems: 'center',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      marginBottom: '8px',
    },
  },

  avatarWrap: {
    marginRight: '1.5rem',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },

  infoItem: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.gray[7],
    fontWeight: 500,

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  rightSection: {
    position: 'relative',
    top: '55px',
    left: '-25px',
    width: '0px !important',
  },
}))
