import * as React from 'react'
import { Title } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import { Drawer, DrawerProps } from '../Drawer'
import useStyles from './GeneralDrawer.styles'

interface IProps extends DrawerProps {
  header?: React.ReactNode
  title?: React.ReactNode
  children: React.ReactNode
  opened: boolean
  onClose: () => void
  bodyClass?: string
}

export const GeneralDrawer = ({
  children,
  opened,
  onClose,
  header,
  title,
  position,
  bodyClass,
  ...props
}: IProps) => {
  const {
    appState: { mobileView },
  } = useAppState()

  const { classes, cx } = useStyles()

  const renderTitle = () =>
    header ? (
      header
    ) : title ? (
      <div className={'flex justify-center'}>
        <Title>{title}</Title>
      </div>
    ) : null

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      padding={0}
      classNames={classes}
      title={renderTitle()}
      position={'right'}
      size={mobileView ? '80%' : '60%'}
      {...props}
    >
      <div className={cx(classes.drawerBody, bodyClass)}>{children}</div>
    </Drawer>
  )
}
