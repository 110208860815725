import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    textTransform: 'none',
    color: theme.black,
    fontWeight: 500,
    // maxWidth: '200px',
  },

  tagMode: {
    fontSize: '15px',
    height: '42px',
    lineHeight: '40px',
  },
}))
