import _ from 'lodash'
import { BOOKING_CANDIDATE_STATUS } from '@/features/booking-candidate/consts/candidate'
import i18n from '@/i18n'

type OptionType = {
  label: string
  value: BOOKING_CANDIDATE_STATUS
  color: string
}

export const getStatuses = () => {
  return [
    {
      label: i18n.t('waiting'),
      value: BOOKING_CANDIDATE_STATUS.WAITING_HIRING,
      color: 'yellow',
    },
    {
      label: i18n.t('chosen'),
      value: BOOKING_CANDIDATE_STATUS.HIRED,
      color: 'primary',
    },
    {
      label: i18n.t('declined'),
      value: BOOKING_CANDIDATE_STATUS.DECLINED_CLIENT,
      color: 'gray',
    },
    {
      label: i18n.t('declined'),
      value: BOOKING_CANDIDATE_STATUS.DECLINED_BABYSITTER,
      color: 'gray',
    },
    {
      label: i18n.t('canceled'),
      value: BOOKING_CANDIDATE_STATUS.CANCELED_CLIENT,
      color: 'gray',
    },
    {
      label: i18n.t('canceled'),
      value: BOOKING_CANDIDATE_STATUS.CANCELED_BABYSITTER,
      color: 'gray',
    },
    {
      label: i18n.t('accepted'),
      value: BOOKING_CANDIDATE_STATUS.ACCEPTED,
      color: 'primary',
    },
    {
      label: i18n.t('inactive'),
      value: BOOKING_CANDIDATE_STATUS.INACTIVE,
      color: 'red',
    },
  ]
}

export const getStatus = (value: BOOKING_CANDIDATE_STATUS | string): OptionType | any => {
  const options = getStatuses()
  return _.find(options, { value })
}
