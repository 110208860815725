import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { IconFile, IconPlus, IconUpload, IconX } from '@tabler/icons'
import React, { useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ActionIcon, Button as MantineButton, useMantineTheme } from '@mantine/core'
import { Group, Text } from '@mantine/core'
import { Dropzone, PDF_MIME_TYPE } from '@mantine/dropzone'
import { Alert, Badge, Icon } from '@/components/Elements'
import { NumberInputController, TextInputController } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'
import { calendarsAPI } from '@/features/calendar/api'
import { validationSchema } from './validation'

export const UploadInvoiceDialog = (props: any) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const [loading, setLoading] = useState(false)
  const [dropzoneLoading, setDropzoneLoading] = useState(false)
  const [file, setFile] = useState<any>()
  const [alertError, setAlertError] = useState<string | null>(null)

  const {
    appState: { mobileView },
  } = useAppState()

  const defaultValues = {
    invoice_number: '',
    amount: null,
    file: '',
  }

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = async (formData: any) => {
    setAlertError(null)
    setLoading(true)
    try {
      await calendarsAPI.saveInvoice(formData)
      props.visibilityChange(false)
      props.onUploadSuccess()
    } catch (error) {
      setAlertError('Something went wrong, please try again!')
    } finally {
      setLoading(false)
    }
  }

  const [totalSize, setTotalSize] = useState(0)
  const dropzoneRef = useRef<() => void>(null)
  const onDropSelect = async (files: any) => {
    setDropzoneLoading(true)
    let _totalSize = totalSize
    const file = files[0]
    const formData = new FormData()
    formData.append('file', file)
    const { data } = await calendarsAPI.uploadInvoice(formData)

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0
    })

    setTotalSize(_totalSize)
    setValue('file', data)
    setDropzoneLoading(false)
    setFile(file)
  }
  const onRemoveSelect = (file: any) => {
    setTotalSize(totalSize - file.size)
    setValue('file', '')
    setFile(undefined)
  }

  return (
    <form
      className={mobileView ? 'flex flex-col gap-3 m-0 px-3 pt-2' : 'flex flex-col gap-4 m-0'}
      onSubmit={handleSubmit(onSubmit)}
    >
      {alertError && (
        <Alert type={'error'} mb={'sm'}>
          {alertError || t('error')}
        </Alert>
      )}

      <TextInputController
        control={control}
        name={'invoice_number'}
        id={'invoice_number'}
        label={t('invoice_number')}
        placeholder={t('invoice_number')}
        size={mobileView ? 'md' : 'lg'}
        mb={mobileView ? '0' : 'md'}
      />

      <NumberInputController
        control={control}
        name={'amount'}
        id={'amount'}
        size={mobileView ? 'md' : 'lg'}
        label={t('amount')}
        placeholder={t('amount')}
        precision={2}
        step={0.01}
        mb={mobileView ? '0' : 'md'}
      />
      {!file && (
        <div className={'w-20'}>
          <MantineButton
            size={mobileView ? 'sm' : 'md'}
            leftIcon={<IconPlus />}
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return dropzoneRef.current()
            }}
          >
            {t('upload')}
          </MantineButton>
        </div>
      )}

      <Controller
        name="file"
        control={control}
        render={({ field: { ref, ...field }, fieldState, formState }) => {
          return (
            <>
              {!file ? (
                <Dropzone
                  openRef={dropzoneRef}
                  loading={dropzoneLoading}
                  onDrop={(files: any) => onDropSelect(files)}
                  onReject={(files: any) => onRemoveSelect(files)}
                  maxSize={10 * 1024 ** 2}
                  accept={PDF_MIME_TYPE}
                  {...props}
                >
                  <Group
                    position="center"
                    spacing="xl"
                    style={{ minHeight: 220, pointerEvents: 'none' }}
                  >
                    <Dropzone.Accept>
                      <div className={'w-100'}>
                        <div className={'flex justify-center mb-4'}>
                          <IconFile
                            size={50}
                            stroke={1.5}
                            color={theme.colors.primary[theme.fn.primaryShade()]}
                          />
                        </div>
                        <Text className={'flex justify-center'} size="xl">
                          File acceptable
                        </Text>
                      </div>
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                      <div className={'w-100'}>
                        <div className={'flex justify-center mb-4'}>
                          <IconX
                            size={50}
                            stroke={1.5}
                            color={theme.colors.primary[theme.fn.primaryShade()]}
                          />
                        </div>
                        <Text className={'flex justify-center'} size="xl">
                          File not acceptable
                        </Text>
                      </div>
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                      <div className={'w-100'}>
                        <div className={'flex justify-center mb-4'}>
                          <IconUpload
                            size={50}
                            stroke={1.5}
                            color={theme.colors.primary[theme.fn.primaryShade()]}
                          />
                        </div>
                        <Text className={'flex justify-center'} size="xl">
                          Upload a file less than 10 MB
                        </Text>
                      </div>
                    </Dropzone.Idle>
                  </Group>
                </Dropzone>
              ) : (
                <div className="flex justify-between w-full items-center p-0 my-4 border-solid border-2 border-gray-300">
                  <div className="flex items-center w-full gap-3">
                    <div className="w-100 items-center p-2">
                      <div className="flex w-100 justify-end">
                        <ActionIcon
                          size={'sm'}
                          color={'primary'}
                          radius="xl"
                          variant={'filled'}
                          onClick={() => onRemoveSelect(file)}
                        >
                          <IconX color={theme.white} size={14} />
                        </ActionIcon>
                      </div>
                      <div className="flex w-100 justify-center">
                        <i className="pi pi-file-pdf text-6xl text-primary-500"></i>
                      </div>
                      <div className="flex w-100 justify-center py-4">
                        <Text className="flex flex-col text-center gap-2" size="md">
                          {file.name}
                          {/*<small>{new Date().toLocaleDateString()}</small>
                        <div>
                          <Badge
                            className={'w-auto p-2'}
                            color={theme.colors.primary[theme.fn.primaryShade()]}
                            variant={'filled'}
                          >
                            {(file.size / (1024 * 1024)).toFixed(2) + ' MB'}
                          </Badge>
                        </div>*/}
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )
        }}
      />
      {errors.file?.message && (
        <small id="amount-help" className={'form.error'}>
          {t(errors.file?.message + '')}
        </small>
      )}

      {mobileView ? (
        <div className="flex w-100">
          <MantineButton className={'w-100'} loading={loading} type="submit">
            {t('save')}
          </MantineButton>
        </div>
      ) : (
        <div className="flex justify-end gap-4">
          <MantineButton
            disabled={loading}
            variant="default"
            color="dark"
            type="button"
            onClick={() => props.visibilityChange(false)}
          >
            {t('cancel')}
          </MantineButton>
          <MantineButton loading={loading} type="submit">
            {t('save')}
          </MantineButton>
        </div>
      )}
    </form>
  )
}
