import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  imageTypeSelect: {
    maxHeight: '200px',
    maxWidth: '200px',
    width: '100%',
    height: '100%',
  },
  hoverWidgets: {
    position: 'fixed',
    bottom: '76px',
    height: '500px',
    display: 'flex',
    flexDirection: 'column-reverse',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      bottom: '65px',
      left: '17px',
    },
  },
  iconPanel: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
    height: '0px',
  },
  iconPicker: {
    display: 'flex',
    width: '93%',
    height: '30px',
    justifyContent: 'end',
  },
  removeIcon: {
    position: 'fixed',
    left: '-15px',
    width: '0',
  },
  previewImage: {
    display: 'flex',
    border: '1px solid lightgrey',
    borderRadius: '8px',
    background: 'white',
    padding: '8px',
    gap: '0px',
  },
  sectionInput: {
    width: '80%',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: 'calc(100vw - 122px)',
    },
  },
  sectionIcons: {
    width: '20%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: '0px',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: '90px',
    },
  },
}))
