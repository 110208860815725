import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { SpecificServicesContainer } from '@/features/booking-service/components/Services/SpecificServices'
import { SPECIFIC_SERVICE_ALIAS } from '@/features/booking-service/consts/specific-services'

export const History = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'history'} />

      <SpecificServicesContainer alias={SPECIFIC_SERVICE_ALIAS.HISTORY} />
    </>
  )
}
