import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    width: '40px',
    height: '40px',
    borderRadius: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 0,
    backgroundColor: theme.colors.gray[1],
    color: theme.black,
    border: '1px solid transparent',
    cursor: 'pointer',
    fontSize: '14px',
    fontFamily: theme.fontFamily,
    fontWeight: 600,
  },

  enabled: {
    backgroundColor: theme.black,
    color: theme.white,
  },

  disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
  },
}))
