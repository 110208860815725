import React from 'react'
import { ButtonProps, Button as MantineButton } from '@mantine/core'

interface IProps extends ButtonProps {
  children: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

export const Button: React.FC<IProps> = ({ children, size, ...props }) => {
  return (
    <MantineButton size={size ? size : 'sm'} {...props}>
      {children}
    </MantineButton>
  )
}
