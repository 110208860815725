import * as babysitterProfile from './babysitter-profile'
import * as clientProfile from './client-profile'
import * as emails from './emails'
import * as general from './general'

export const formHelper = {
  general,
  babysitterProfile,
  clientProfile,
  emails,
}
