import { createSlice } from '@reduxjs/toolkit'

interface IState {
  previousRequest: any
}

const initialState: IState = {
  previousRequest: null,
}

const previousRequestStateSlice = createSlice({
  name: 'previousRequestState',
  initialState,
  reducers: {
    setBooking(state, action) {
      state.previousRequest = action.payload
      // console.log('checkout set booking' + JSON.stringify(action.payload))
    },
  },
})

/**
 * @namespace
 * @property {function} windowFocus
 */

export const previousRequestState = previousRequestStateSlice.actions

export default previousRequestStateSlice.reducer
