import { IconSettings } from '@tabler/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mantine/core'
import { ProfileSettings } from '@/features/profile/components/Settings'
import { usePopup } from '@/hooks'

export const SettingsControl = () => {
  const { t } = useTranslation()

  const { visible, open, close } = usePopup()

  return (
    <>
      <Button color={'secondary'} variant={'outline'} onClick={open} leftIcon={<IconSettings />}>
        {t('settings')}
      </Button>

      <ProfileSettings visible={visible} onClose={close} />
    </>
  )
}
