import axios from 'axios'

class UsersAPI {
  getFavoriteBabysitters = async (params: any) => {
    return axios.get(`api/users/favorite-babysitters`, {
      params,
    })
  }
}

export const usersAPI = new UsersAPI()
