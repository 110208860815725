import React from 'react'
import { MegaTagTitle } from '@/components/Elements'
import { SpecificServicesContainer } from '@/features/booking-service/components/Services/SpecificServices'
import { SPECIFIC_SERVICE_ALIAS } from '@/features/booking-service/consts/specific-services'

export const Upcoming = () => {
  return (
    <>
      <MegaTagTitle title={'upcoming'} />

      <SpecificServicesContainer alias={SPECIFIC_SERVICE_ALIAS.UPCOMING} />
    </>
  )
}
