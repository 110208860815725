import React from 'react'
import { useTranslation } from 'react-i18next'
import { BookingInvoiceModel } from '@/features/booking-invoice/types/models'
import { DateUtils } from '@/utils'

interface IProps {
  bookingInvoice: BookingInvoiceModel
}

export const Period = ({ bookingInvoice }: IProps) => {
  const { t } = useTranslation()

  const { date_start, date_end } = bookingInvoice

  return (
    <>
      {date_start && date_end
        ? `${DateUtils.formatDateTime(date_start)} - ${DateUtils.formatDateTime(date_end)}`
        : '-'}
    </>
  )
}
