import { IconArrowLeft, IconX } from '@tabler/icons'
import classNames from 'classnames'
import React from 'react'
import { ModalProps as MantineModalProps, Modal, Text, useMantineTheme } from '@mantine/core'
import { MobileHeaderModal } from '@/components/Elements/Header/MobileHeader'
import { Support } from '@/features/header/layout/Support'
import useStyles from './MobileFullModal.styles'

export interface ModalProps extends MantineModalProps {
  opened: boolean
  onClose: () => void
  headerBorder: boolean
  header?: React.ReactNode
  leftIcon: string
  rightIcon?: string
  title?: any
  background?: any
  children: React.ReactNode
  scrollable?: boolean
  fromBooking?: boolean
}

export const MobileFullModal = ({
  opened,
  onClose,
  header,
  headerBorder,
  leftIcon,
  rightIcon = '',
  title,
  children,
  background = 'white',
  fromBooking = false,
  scrollable = true,
  ...props
}: ModalProps) => {
  const theme = useMantineTheme()
  const { classes } = useStyles()

  const closeHandler = (e: any) => {
    e.preventDefault()

    onClose()
  }

  const renderModalTitle = () =>
    header ? (
      header
    ) : title ? (
      <MobileHeaderModal
        border={headerBorder}
        background={background}
        left={
          leftIcon === 'close' ? (
            <IconX
              size={20}
              color={
                background == 'primary' ? 'white' : theme.colors.primary[theme.fn.primaryShade()]
              }
              onClick={closeHandler}
            />
          ) : (
            <IconArrowLeft
              size={20}
              color={
                background == 'primary' ? 'white' : theme.colors.primary[theme.fn.primaryShade()]
              }
              onClick={closeHandler}
            />
          )
        }
        right={rightIcon == 'help' ? <Support /> : <Text></Text>}
        title={
          <Text
            size={16}
            weight={600}
            className="mt-1"
            style={{ color: background == 'primary' ? 'white' : 'black' }}
          >
            {title}
          </Text>
        }
      />
    ) : null

  return (
    <Modal
      withCloseButton={false}
      opened={opened}
      onClose={onClose}
      fullScreen
      radius={0}
      title={renderModalTitle()}
      transition="fade"
      transitionDuration={200}
      className={classes.modal}
    >
      <div
        className={classNames('h-full', 'max-vh', {
          'overflow-hidden': !scrollable,
          'mantine-Modal-modal-padding': !fromBooking,
          'mantine-Modal-modal-padding-top': fromBooking,
        })}
      >
        {children}
      </div>
    </Modal>
  )
}
