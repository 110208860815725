import { useTranslation } from 'react-i18next'
import { Button } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import { BookingModel } from '@/features/bookings/types/models'

interface IProps {
  booking: BookingModel
}

export const BookingPay = ({ booking }: IProps) => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()
  const { booking_open_stripe_invoice } = booking as BookingModel

  return (
    <Button
      component={'a'}
      target={'_blank'}
      href={booking_open_stripe_invoice?.url}
      fullWidth={mobileView}
    >
      {t('pay')}
    </Button>
  )
}
