import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollArea } from '@mantine/core'
import { NoDataTextMessage } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { BookingDaysTable } from '@/features/bookings/components/Booking/BookingDetails'
import { BookingDay } from '@/features/bookings/types/models'

interface IProps {
  days: BookingDay[]
}

export const DaysSchedule = ({ days = [] }: IProps) => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <ScrollArea
      className={classNames('h-full', {
        'pt-4': mobileView,
      })}
    >
      {days.length ? (
        <BookingDaysTable data={days} />
      ) : (
        <NoDataTextMessage>{t('no_schedule')}</NoDataTextMessage>
      )}
    </ScrollArea>
  )
}
