import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionIcon } from '@mantine/core'
import { timetableHelper } from '@/features/booking/helpers'
import { DayButton } from './DayButton'
import useStyles from './WeekDayPicker.styles'

export interface WeekDaysPickerProps {
  value?: any
  onChange?: (value: any) => void
  isDayDisabled?: (item: any) => boolean
}

export const WeekDaysPicker = ({ value = {}, onChange, isDayDisabled }: WeekDaysPickerProps) => {
  const { t } = useTranslation()

  const weekDays = timetableHelper.weekdays.getWeekDays(t)

  const { classes } = useStyles()

  const onDayClick = (item: any) => {
    const { key } = item

    const currentEnabled = value[key]?.enabled

    const newValue = {
      ...value,
      [key]: {
        ...value[key],
        enabled: !currentEnabled,
      },
    }

    onChange && onChange(newValue)
  }

  const renderDay = (item: any) => {
    const { key, symbol } = item

    const enabled = value[key]?.enabled

    return (
      <DayButton
        enabled={enabled}
        disabled={isDayDisabled ? isDayDisabled(item) : false}
        onClick={() => onDayClick(item)}
      >
        {symbol}
      </DayButton>
    )
  }

  return (
    <>
      <div className={'flex flex-wrap gap-2.5'}>
        {weekDays.map((item) => (
          <React.Fragment key={item.key}>{renderDay(item)}</React.Fragment>
        ))}
      </div>
    </>
  )
}
