import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    height: 'calc(100vh - 32px)',
    borderLeft: `1px solid ${theme.colors.gray[4]}`,
    flex: '1 1',
    display: 'flex',
    flexDirection: 'column',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      flex: 'auto',
      height: '100%',
      borderLeft: 'none',
    },
  },
}))
