import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { FormTypes } from '@/types'
import { authAPI } from '../../api'
import { VerifyEmailDTO } from '../../api/dto'
import {
  AUTH_VERIFY_EMAIL_FAILED,
  AUTH_VERIFY_EMAIL_REQUEST,
  AUTH_VERIFY_EMAIL_SUCCESS,
} from './actionTypes'

export const authVerifyEmailPA = createPromiseAction(
  AUTH_VERIFY_EMAIL_REQUEST,
  AUTH_VERIFY_EMAIL_SUCCESS,
  AUTH_VERIFY_EMAIL_FAILED
)<VerifyEmailDTO, unknown, unknown>()

function* verify(action: PromiseAction<string, VerifyEmailDTO, any>) {
  try {
    const response: AxiosResponse = yield call(authAPI.verifyEmail, action.payload)
    const { data } = response

    yield put(authVerifyEmailPA.success({}))

    resolvePromiseAction(action, {})
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* verifyEmailSaga() {
  yield takeEvery(authVerifyEmailPA.request, verify)
}
