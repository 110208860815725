import React from 'react'
import { useTranslation } from 'react-i18next'
import { MessageType } from '@/features/chat/types/models'
import { Date } from './Date'
import { Read } from './Read'

interface IProps {
  data: MessageType
}

export const Meta = ({ data }: IProps) => {
  const { t } = useTranslation()

  return (
    <div className={'flex gap-2 items-center'}>
      <Date date={data?.created_at} />

      {data?.is_my && <Read seen={data?.seen} />}
    </div>
  )
}
