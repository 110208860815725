import React from 'react'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Center } from '@mantine/core'
import { Button, EmptyDataContentPlaceholder } from '@/components/Elements'
import { useEvents } from '@/features/app/hooks'
import { TAG_MANAGER_EVENT } from '@/features/tag-manager/consts/event'

export const EmptyData = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const events = useEvents()

  const handleNew = () => {
    navigate('/book-now')
  }

  return (
    <Center className={'h-full'}>
      <EmptyDataContentPlaceholder
        title={t('no_favorite_baby_sister')}
        message={t('baby_sisters.favorites.empty.message')}
        action={
          <Button color={'secondary'} onClick={handleNew}>
            {t('new_booking')}
          </Button>
        }
      />
    </Center>
  )
}
