import * as React from 'react'
import { Drawer } from '@mantine/core'
import { NavbarContent } from '../NavbarContent'

interface IProps {
  opened: boolean
  onClose: () => void
}

export const NavbarDrawer = ({ opened, onClose }: IProps) => {
  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      size={300}
      padding={'xl'}
      styles={(theme) => ({
        drawer: {
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.colors.mint[0],
        },
      })}
    >
      <NavbarContent onNavLinkClick={onClose} />
    </Drawer>
  )
}
