import * as React from 'react'
import { LogoIcon } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { LogoutLink } from '@/features/profile/components/CompleteProfile/Layout/LogoutLink'
import useStyles from './Header.styles'

export const Header = () => {
  const { classes } = useStyles()
  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <div className={classes.header}>
      <LogoIcon size={mobileView ? 'sm' : 'lg'} />

      {mobileView && <LogoutLink />}
    </div>
  )
}
