import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group, Stack, Text, Title } from '@mantine/core'
import { NoDataTextMessage } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { ChildType } from '@/features/user'
import { DateUtils } from '@/utils'
import useStyles from './ChildrenBlock.styles'

interface IProps {
  data: Array<ChildType>
}

export const ChildrenBlock = ({ data = [] }: IProps) => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()
  const { classes } = useStyles()

  return (
    <>
      <div>
        <Title order={5}>{t('children')}</Title>

        <div className={'my-3'}>
          {!data.length ? (
            <NoDataTextMessage variant={'info'} />
          ) : (
            <Stack className={'my-3'}>
              {data.map((item, i) => (
                <React.Fragment key={i}>
                  {mobileView ? (
                    <Group className={'flex'}>
                      <Text className={'mobile-info-view-title-bold'}>{item?.name}</Text>
                      <Text className={'mobile-info-view-content'}>
                        {item?.birthday ? DateUtils.format(item?.birthday, 'D MMM YYYY') : null}
                      </Text>
                    </Group>
                  ) : (
                    <Group className={classes.groupItem}>
                      <Text className={classes.groupItemLabel}>
                        {t('child')} {i + 1}
                      </Text>
                      <div>
                        <Text className={classes.groupItemName}>{item?.name}</Text>
                        <Text className={classes.groupItemDate}>
                          {item?.birthday ? DateUtils.format(item?.birthday, 'D MMM YYYY') : null}
                        </Text>
                      </div>
                    </Group>
                  )}
                </React.Fragment>
              ))}
            </Stack>
          )}
        </div>
      </div>
    </>
  )
}
