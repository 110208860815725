import classNames from 'classnames'
import { Arrow } from '@/components/Elements/Icon/Arrow'
import useStyles from './CarouselPrevArrow.styles'

interface IProps {
  currentSlide?: any
  slideCount?: any
  cn: any
  handleClick: () => any
}
export const CarouselPrevArrow = ({ currentSlide, slideCount, cn, handleClick }: IProps) => {
  const { classes } = useStyles()
  return (
    <span
      className={classNames(classes.arrow, currentSlide === 0 && classes.arrowFirst, cn)}
      onClick={handleClick}
    >
      <Arrow
        stroke={currentSlide == 0 ? '#02334b' : 'white'}
        style={{ transform: `scale(${20 / 24})` }}
      />
    </span>
  )
}
