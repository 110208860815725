import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, Title } from '@mantine/core'
import animal from '@/assets/images/illustrations/animal.png'
import useStyles from './Component.styles'

interface IProps {
  title?: React.ReactNode
  message?: React.ReactNode
  action?: React.ReactNode
}

export const EmptyDataContentPlaceholder = ({ title, message, action }: IProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <div className={'mb-4'}>
        <img className={classes.img} src={animal} alt={'icon'} />
      </div>

      {title && (
        <Title order={4} className={'mb-4'}>
          {title}
        </Title>
      )}

      {message && (
        <Text color={'gray.7'} size={'sm'} className={'mb-4'}>
          {message}
        </Text>
      )}

      {action && <div>{action}</div>}
    </div>
  )
}
