import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateUtils } from '@/utils'

interface IProps {
  startDate: string | null
  endDate: string | null
  format?: string
  className?: string
}

export const BookingDatePeriodDisplay = ({
  startDate,
  endDate,
  format = 'D MMM YYYY',
  className,
}: IProps) => {
  const { t } = useTranslation()

  const renderDate = (date: string | null) => DateUtils.format(date, format)

  return (
    <span className={className}>
      {startDate === endDate ? (
        renderDate(startDate)
      ) : (
        <>
          {renderDate(startDate)}
          {' - '}
          {renderDate(endDate)}
        </>
      )}
    </span>
  )
}
