import { createSlice } from '@reduxjs/toolkit'
import { ChatModel } from '@/features/chat/types/models'

interface IState {
  list: ChatModel[]
  loading: boolean
  error: any | null
  isLoaded: boolean
}

const initialState: IState = {
  list: [],
  loading: false,
  error: null,
  isLoaded: false,
}

const chatChatsListSlice = createSlice({
  name: 'chatChatsList',
  initialState,
  reducers: {
    getList(state, action: any) {
      if (!action.payload?.disableLoading) {
        state.loading = true
      }

      state.error = null
      state.isLoaded = false
    },
    getListSuccess(state, action) {
      state.loading = false
      state.list = action.payload.data
      state.isLoaded = true
    },
    getListError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    addItem(state, action) {
      state.list.push(action.payload)
    },
    updateItem(state, action) {
      state.list = state.list.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          }
        } else {
          return item
        }
      })
    },
    updateBookingFromRating(state, action) {
      state.list = state.list.map((item) => {
        if (item.id === action.payload.id) {
          const newCount = item.unread_count - 1
          const booking = {
            ...item.booking,
            client_rating: action.payload.client_rating,
            babysitter_ratings: action.payload.babysitter_ratings,
            rating_service: action.payload.rating_service,
          }
          return {
            ...item,
            unread_count: newCount > 0 ? newCount : 0,
            booking: booking,
          }
        } else {
          return item
        }
      })
    },
    remove1UnreadMessageFromChat(state, action) {
      state.list = state.list.map((item) => {
        if (item.id === action.payload.id) {
          const newCount = item.unread_count - 1
          return {
            ...item,
            unread_count: newCount > 0 ? newCount : 0,
          }
        } else {
          return item
        }
      })
    },
    removeItem(state, action) {
      state.list = state.list.filter((item) => item.id !== action.payload.id)
    },
    cleanState() {
      return initialState
    },
  },
})

/**
 * @namespace
 * @property {function} getList
 * @property {function} getListSuccess
 * @property {function} getListError
 * @property {function} addItem
 * @property {function} updateItem
 * @property {function} removeItem
 * @property {function} cleanState
 */

export const chatChatsList = chatChatsListSlice.actions

export default chatChatsListSlice.reducer
