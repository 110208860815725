import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {},

  text: {
    display: 'flex',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    overflow: 'hidden',
  },

  opened: {},

  actions: {
    marginTop: '0.25rem',
  },
}))
