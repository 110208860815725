import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@/components/Elements'
import { BookingTrackingDataLoader } from './BookingTrackingDataLoader'
import { TimeEntryEditContainer } from './TimeEntryEditContainer'

interface IProps {
  id: number
  visible: boolean
  onClose: () => void
  onSuccess: (data: any) => void
}

export const TimeEntryEdit = ({ id, visible, onClose, onSuccess }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal opened={visible} onClose={onClose} centered title={`${t('edit_time')}`}>
        <div style={{ minHeight: '380px' }}>
          <BookingTrackingDataLoader id={id}>
            <TimeEntryEditContainer onClose={onClose} onSuccess={onSuccess} />
          </BookingTrackingDataLoader>
        </div>
      </Modal>
    </>
  )
}
