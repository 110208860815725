import React from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmModal } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { useCandidateDecline } from '@/features/booking-candidate/hooks'
import { MyBookingCandidateType } from '@/features/booking-candidate/types/models'
import { useConfirmPopup } from '@/hooks'

interface IProps {
  candidate: MyBookingCandidateType
  render: (props: { onDecline: (data?: any) => void; loading: boolean }) => React.ReactNode
  onSuccess?: (data: object) => void
}

export const DeclineControl = ({ candidate, onSuccess, render }: IProps) => {
  const { t } = useTranslation()

  const { loading, onSubmit } = useCandidateDecline()

  const {
    appState: { mobileView },
  } = useAppState()
  const onDeclineConfirm = async () => {
    await onSubmit(candidate?.id, onSuccess)
  }

  const { visible, open, close, onConfirm } = useConfirmPopup({ confirm: onDeclineConfirm })

  return (
    <>
      <div className={mobileView ? 'w-100' : ''}>
        {render({
          onDecline: open,
          loading,
        })}
      </div>

      <ConfirmModal visible={visible} onClose={close} onConfirm={onConfirm} />
    </>
  )
}
