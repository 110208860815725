import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { LanguagesFormSection as LanguagesSection } from '@/features/profile/components/FormSections'

export const LanguagesFormSection = () => {
  const { t } = useTranslation()

  return (
    <>
      <Grid>
        <Grid.Col>
          <LanguagesSection other labelMode={'primary'} />
        </Grid.Col>
      </Grid>
    </>
  )
}
