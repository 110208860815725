import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Anchor, Group, Text } from '@mantine/core'
import { AvatarProvider } from '@/components/Elements'
import { BabysitterUser } from '@/features/user'
import { useUsersModel } from '@/features/users/hooks'
import useStyles from './Babysitter.styles'

interface IProps {
  data: BabysitterUser | any
}

export const Babysitter = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { getName } = useUsersModel()

  return (
    <Group spacing={8} noWrap>
      <Anchor component={Link} to={`/babysitters/${data?.id}`}>
        <AvatarProvider size={32} avatar={data?.avatar} />
      </Anchor>

      <Anchor component={Link} to={`/babysitters/${data?.id}`} color={'dark'}>
        <Text weight={600} className={'whitespace-nowrap'}>
          {getName(data)}
        </Text>
      </Anchor>
    </Group>
  )
}
