import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  FieldValues as LanguageValues,
  LanguagesFieldArray,
} from '@/features/profile/components/FormElements'

export type LanguagesFormSectionValues = {
  languages: Array<LanguageValues>
}

interface IProps {
  level?: boolean
  other?: boolean
  labelMode?: any
}

export const LanguagesFormSection = ({
  level = false,
  other = false,
  labelMode,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  const {
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext<LanguagesFormSectionValues>()

  return (
    <>
      <LanguagesFieldArray
        name={'languages'}
        control={control}
        level={level}
        other={other}
        labelMode={labelMode}
        clearErrors={clearErrors}
        meta={{ error: errors?.languages }}
        //limit={2}
        {...props}
      />
    </>
  )
}
