import { createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@/store'

interface IState {
  data: any | null
  loading: boolean
  error: any
}

const initialState: IState = {
  data: null,
  loading: false,
  error: null,
}

const staticSelectsSlice = createSlice({
  name: 'staticSelects',
  initialState,
  reducers: {
    getData(state) {
      state.loading = true
      state.error = null
    },
    getDataSuccess(state, action) {
      state.loading = false
      state.data = action.payload.data
    },
    getDataError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})

export const staticSelects = staticSelectsSlice.actions

export const selectStaticSelectsOptions = createSelector(
  [(state: RootState) => state.static.selects.data, (_, lang) => lang],
  (data, lang) => {
    return data?.[lang] || null
  }
)

export default staticSelectsSlice.reducer
