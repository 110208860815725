import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { bookingTrackerAPI } from '@/features/booking-tracking/api'
import i18n from '@/i18n'
import { bookingTrackingEditData } from './slice'

function* getData({ payload: { id } }: PayloadAction<{ id: number }>) {
  try {
    const response: AxiosResponse = yield call(bookingTrackerAPI.getTracker, id)

    const { data } = response

    yield put(bookingTrackingEditData.getDataSuccess(data))
  } catch (error: any) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(bookingTrackingEditData.getDataError(message))
  }
}

function* watchGetData() {
  yield takeEvery(bookingTrackingEditData.getData, getData)
}

export function* dataSaga() {
  yield all([fork(watchGetData)])
}
