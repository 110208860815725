import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Badge } from '@/components/Elements'
import { BOOKING_STATUS } from '@/features/bookings/consts/booking'
import { bookingHelper } from '@/features/bookings/helpers'

interface IProps {
  value: BOOKING_STATUS
}

export const BookingStatusBadge = ({ value }: IProps) => {
  const { t } = useTranslation()

  const config = useMemo(() => {
    return bookingHelper.status.getStatus(value)
  }, [value])

  return (
    <Badge color={config?.color} variant={'filled'}>
      {config?.label}
    </Badge>
  )
}
