import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { userAPI } from '@/features/users/api'
import {
  USERS_FAVORITE_SUBMIT_FAILED,
  USERS_FAVORITE_SUBMIT_REQUEST,
  USERS_FAVORITE_SUBMIT_SUCCESS,
} from './actionTypes'

type ArgsType = {
  id: number
  status: boolean
}

export const usersFavoriteSubmitPA = createPromiseAction(
  USERS_FAVORITE_SUBMIT_REQUEST,
  USERS_FAVORITE_SUBMIT_SUCCESS,
  USERS_FAVORITE_SUBMIT_FAILED
)<ArgsType, unknown, AxiosError<any>>()

function* submit(action: PromiseAction<string, ArgsType, any>) {
  try {
    const { id, status } = action.payload

    const response: AxiosResponse = status
      ? yield call(userAPI.addFavoriteBabysitter, id)
      : yield call(userAPI.deleteFavoriteBabysitter, id)
    const { data } = response

    yield put(usersFavoriteSubmitPA.success({}))

    resolvePromiseAction(action, { status })
  } catch (err) {
    const error = err as AxiosError<any>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* submitSaga() {
  yield takeEvery(usersFavoriteSubmitPA.request, submit)
}
