import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { ContentCard } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'

export const WalletLayout = () => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <>
      {!mobileView ? (
        <ContentCard title={t('wallet')}>
          <Outlet />
        </ContentCard>
      ) : (
        <div className={'p-4'}>
          <Outlet />
        </div>
      )}
    </>
  )
}
