import React from 'react'
import { BookingTimetableDateTimeType } from '@/features/bookings/types/models'

interface IProps {
  data: Array<BookingTimetableDateTimeType> | null
}

export const OnetimeRanges = ({ data }: IProps) => {
  return (
    <>
      <div className={'flex flex-col'}>
        {data &&
          data.map((item, index) => (
            <div key={index} className={'flex gap-1'}>
              {item.range_date_start === item.range_date_end ? (
                <span className={'whitespace-nowrap'}>
                  {item.range_date_start} {item.range_time_start} {' - '} {item.range_time_end}
                </span>
              ) : (
                <span className={'whitespace-nowrap'}>
                  {item.range_date_start} {item.range_time_start} {' - '} {item.range_date_end}{' '}
                  {item.range_time_end}
                </span>
              )}
            </div>
          ))}
      </div>
    </>
  )
}
