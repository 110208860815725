import React from 'react'
import { useTranslation } from 'react-i18next'
import { walletAPI } from '@/features/wallet/api'
import { EmailForm } from '@/features/wallet/routes/services/wallet-view/email-form'
import { useNotify } from '@/hooks'

interface IProps {
  onCancel: () => void
}

export const WalletEmailsSend = ({ onCancel }: IProps) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const onSubmit = async (values: any) => {
    console.log('Submit send emails: ' + JSON.stringify(values))
    await walletAPI.shareReferral(values)
    showNotification({
      type: 'success',
      message: t('referral.invite_success'),
    })
    onCancel()
  }

  return (
    <>
      <EmailForm onCancel={onCancel} onSubmit={onSubmit} />
    </>
  )
}
