import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MegaTagTitle } from '@/components/Elements'
import { useEvents } from '@/features/app/hooks'
import {
  BookingRegularConfirmedContainer,
  Layout,
} from '@/features/booking/components/BookingRegularConfirmed'
import { checkoutState } from '@/features/booking/store/checkout.slice'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { ageMean } from '../../components/Booking/BookingContainer/BookingWizardForm/helpers'

export const RegularConfirmed = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const events = useEvents()
  const { booking } = useSelector((state: any) => state.booking.checkout)
  const [justCreatedUser, setJustCreatedUser] = useState(false)
  const [type, setType] = useState(BOOKING_TYPE.REGULAR)

  useEffect(() => {
    if (!booking) {
      navigate('/bookings')
    } else {
      const type = booking.type
      const total = (booking.total_amount / 100).toFixed(2)
      const bookingId = booking.id

      try {
        events.actions.careDetails({
          Type: type,
          Value: total,
          Booking: bookingId,
          AgeMean: ageMean(booking.booking_children),
        })
      } catch (e) {
        console.error('Error sending careDetails event')
        console.error(e)
      }

      setType(booking.type)
      setJustCreatedUser(booking.just_created_user)
    }
    dispatch(checkoutState.setBooking(null))
  }, [])

  return (
    <>
      <MegaTagTitle title={'booking_confirmed'} />

      <Layout>
        <BookingRegularConfirmedContainer justCreatedUser={justCreatedUser} type={type} />
      </Layout>
    </>
  )
}
