import React from 'react'
import { FavoriteControl as FavoriteActionControl } from '@/features/babysitter/components/BabysitterActionContols'
import { BabysitterUser } from '@/features/user'

interface IProps {
  babysitter: BabysitterUser
  onSuccess?: (data: object) => void
}

export const FavoriteControl = ({ babysitter, onSuccess }: IProps) => {
  const { id, favorite_babysitter } = babysitter

  const onSubmitSuccess = (data: any) => {
    const { status } = data
    onSuccess && onSuccess({ id, favorite_babysitter: status })
  }

  return (
    <FavoriteActionControl id={id} status={!!favorite_babysitter} onSuccess={onSubmitSuccess} />
  )
}
