import { IconEdit } from '@tabler/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Title, useMantineTheme } from '@mantine/core'
import { NoDataTextMessage, TagBadge } from '@/components/Elements'
import { ModalOrDrawerSwitch } from '@/components/Elements/MobileWebSwitchs'
import { useAppState } from '@/features/app/hooks'
import { BabysitterProfileEdit, EditorType } from '@/features/profile/components/ProfileEdit'
import { SkillType, useUser } from '@/features/user'
import { usePopup } from '@/hooks'

interface IProps {
  editable?: boolean
  data: Array<SkillType>
}

export const SkillsBlock = ({ editable = false, data }: IProps) => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()

  const theme = useMantineTheme()

  const { isBabysitter } = useUser()
  const renderTag = (item: SkillType) => <TagBadge color={'secondary'}>{t(item?.name)}</TagBadge>

  const { visible, open, close } = usePopup()
  return (
    <>
      <div>
        <div className={'flex w-100'}>
          <div className={'w-10/12'}>
            <Title order={mobileView ? 6 : 5}>{t('skills')}</Title>
          </div>
          {isBabysitter() && mobileView && (
            <div className={'flex justify-end w-2/12'}>
              <IconEdit
                color={theme.colors.primary[theme.fn.primaryShade()]}
                stroke={1}
                onClick={open}
              />
            </div>
          )}
        </div>

        <div className={mobileView ? 'my-2' : 'my-3'}>
          {!data.length ? (
            <NoDataTextMessage variant={'fill'} />
          ) : (
            <div className={'flex flex-wrap gap-2.5'}>
              {data.map((item, i) => (
                <React.Fragment key={i}>{renderTag(item)}</React.Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
      {editable && (
        <ModalOrDrawerSwitch
          opened={visible}
          onClose={close}
          leftIcon={'close'}
          headerBorder={true}
          title={t('edit_skills')}
        >
          <BabysitterProfileEdit onCancel={close} editorType={EditorType.SKILLS} />
        </ModalOrDrawerSwitch>
      )}
    </>
  )
}
