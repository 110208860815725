import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  PasswordInput as MantinePasswordInput,
  PasswordInputProps as MantinePasswordInputProps,
} from '@mantine/core'
import { FormUtils } from '@/utils'

export interface PasswordInputProps extends MantinePasswordInputProps {
  fieldRef?: any
  translateParams?: object
  meta?: any
}

export const PasswordInput = ({
  meta,
  fieldRef,
  translateParams,
  ...props
}: PasswordInputProps) => {
  const { t } = useTranslation()
  const { error } = meta

  const showError = Boolean(error)

  const getErrorMessage = () => {
    return FormUtils.getErrorMessage(error, {
      t,
      translateParams: translateParams,
    })
  }

  return <MantinePasswordInput {...props} error={showError ? getErrorMessage() : null} />
}
