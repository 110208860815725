import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { MetaPagination, OverlayDataShower } from '@/components/Elements'
import { GeneralInvoicesTable } from '@/features/booking-invoice/components/Invoices/Tables'
import { BookingInvoiceModel } from '@/features/booking-invoice/types/models'
import { useBookingData } from '@/features/bookings/hooks'
import { bookingsInvoiceList } from '@/features/bookings/store'
import { RootState } from '@/store'

interface IProps {
  list: BookingInvoiceModel[]
  loading: boolean
  error: any
  meta: any
  page: number
}

const BookingInvoicesContainerComponent = ({ ...props }: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { booking } = useBookingData()

  useEffect(() => {
    return () => {
      dispatch(bookingsInvoiceList.cleanState())
    }
  }, [])

  useEffect(() => {
    fetchList()
  }, [props.page])

  const fetchList = () => {
    const params = {
      page: props.page,
    }

    dispatch(bookingsInvoiceList.getList({ id: booking?.id, params }))
  }

  const onPageChange = (page: number) => {
    dispatch(bookingsInvoiceList.setPage(page))
  }

  return (
    <div>
      <OverlayDataShower isLoading={props.loading} isFailed={!!props.error} error={props.error}>
        <GeneralInvoicesTable
          keys={['amount', 'period', 'due-date', 'action']}
          items={props.list}
          loading={props.loading}
        />

        <MetaPagination meta={props.meta} page={props.page} onChange={onPageChange} />
      </OverlayDataShower>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const { list, loading, error, meta, page } = state.bookings.invoice.list
  return {
    list,
    loading,
    error,
    meta,
    page,
  }
}

export const BookingInvoicesContainer = connect(mapStateToProps)(BookingInvoicesContainerComponent)
