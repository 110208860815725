import { createAction } from '@reduxjs/toolkit'

const CHAT_SOCKET_ON_MESSAGE = 'chat/socket-on-message'

export const chatSocketOnMessage = createAction(CHAT_SOCKET_ON_MESSAGE, function prepare(data) {
  return {
    payload: {
      data,
    },
  }
})
