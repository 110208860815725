import moment from 'moment'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DateUtils } from '@/utils'
import useStyles from './Day.styles'

interface IProps {
  date: string
}

export const Day = ({ date }: IProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  const dateFormat = useMemo(() => {
    if (date) {
      const localDate = DateUtils.utcToLocale(date)
      const mDate = moment(localDate)

      if (mDate.isSame(moment(), 'day')) {
        return t('today')
      }

      return DateUtils.formatDate(localDate)
    }

    return null
  }, [date])

  return <div className={classes.root}>{dateFormat}</div>
}
