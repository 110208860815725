import styled from '@emotion/styled'

export const FormControlActionBlock = styled.div`
  margin-top: 28px;
  display: flex;
  height: 50px;
  align-items: center;
  @media (max-width: 992px) {
    margin-top: 0px;
  }
`
