import { createAction } from '@reduxjs/toolkit'

const CHAT_SOCKET_ON_CHAT_SEEN = 'chat/socket-on-chat-seen'

export const chatSocketOnChatSeen = createAction(CHAT_SOCKET_ON_CHAT_SEEN, function prepare(data) {
  return {
    payload: {
      data,
    },
  }
})
