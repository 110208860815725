import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { bookingTrackerAPI } from '@/features/booking-tracking/api'
import {
  BOOKING_TRACKING_EDIT_SUBMIT_FAILED,
  BOOKING_TRACKING_EDIT_SUBMIT_REQUEST,
  BOOKING_TRACKING_EDIT_SUBMIT_SUCCESS,
} from './actionTypes'

type ArgsType = {
  id: number
  params: any
}

export const bookingTrackingEditSubmitPA = createPromiseAction(
  BOOKING_TRACKING_EDIT_SUBMIT_REQUEST,
  BOOKING_TRACKING_EDIT_SUBMIT_SUCCESS,
  BOOKING_TRACKING_EDIT_SUBMIT_FAILED
)<ArgsType, unknown, AxiosError<any>>()

function* submit(action: PromiseAction<string, ArgsType, any>) {
  try {
    const { id, params } = action.payload

    const response: AxiosResponse = yield call(bookingTrackerAPI.updateTracker, id, params)
    const { data } = response

    yield put(bookingTrackingEditSubmitPA.success({}))

    resolvePromiseAction(action, { ...data.data })
  } catch (err) {
    const error = err as AxiosError<any>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* submitSaga() {
  yield takeEvery(bookingTrackingEditSubmitPA.request, submit)
}
