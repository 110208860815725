import { IconCirclePlus } from '@tabler/icons'
import React, { useEffect, useState } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Anchor, Grid, Group, Title } from '@mantine/core'
import { ActionIconButton } from '@/components/Elements'
import {
  FieldError,
  FormControlActionBlock,
  SelectController,
  TextInputController,
} from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'
import { langHelper } from '@/features/profile/helpers'
import { languageAPI, languageLevelsAPI } from '@/features/selects/api'
import { useLang } from '@/hooks'

export type FieldValues = {
  language_id: number | string | null
  name?: string
  level_id?: string | null
}

interface IProps {
  name: string
  control: any
  limit?: number
  other?: boolean
  level?: boolean
  labelMode?: 'default' | 'primary'
  meta?: any
  clearErrors?: (name: any) => void
  gridProps?: any
}

export const LanguagesFieldArray = ({
  name,
  control,
  limit,
  labelMode = 'default',
  other = false,
  level = false,
  meta,
  clearErrors,
  gridProps,
}: IProps) => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()
  const { lang } = useLang()

  const error = meta?.error

  const [languageLevelsList, setLanguageLevelsList] = useState<any[]>([])
  const [languageList, setLanguageList] = useState<any[]>([])

  useEffect(() => {
    fetchDataLanguage()
    fetchDataLanguageLevels()
  }, [])

  const fetchDataLanguage = async () => {
    const { data } = await languageAPI.getLanguages(lang)
    const list = data.languages.map((item: any) => ({
      label: item.name,
      value: item.id.toString(),
    }))

    setLanguageList(list)
  }
  const fetchDataLanguageLevels = async () => {
    const { data } = await languageLevelsAPI.getLevels(lang)
    const list = data.language_levels.map((item: any) => ({
      label: item.name,
      value: item.id.toString(),
    }))

    setLanguageLevelsList(list)
  }

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: name,
    keyName: 'fieldId',
  })

  const watchValue = useWatch({
    control,
    name: name,
  })

  const addRow = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    if (clearErrors) {
      clearErrors(name)
    }
    addRowItem()
  }

  const addRowItem = () => {
    let fields: FieldValues = {
      language_id: null,
    }

    if (level) {
      fields = {
        ...fields,
        level_id: null,
      }
    }

    append(fields)
  }
  const removeRow = (index: number) => {
    remove(index)
  }

  const renderAdd = () => (
    <div className={mobileView ? 'flex flex-col mt-3 self-start' : 'flex flex-col mt-4 self-start'}>
      <Anchor href="#" onClick={addRow} className={'font-bold'}>
        <Group>
          <IconCirclePlus />
          {t('add_language')}
        </Group>
      </Anchor>
      <FieldError error={error} />
    </div>
  )

  const renderTitleSection = (index: number) => {
    const renderPrimaryTitle = () => (index === 0 ? t('primary') : t('secondary'))

    return (
      <Title order={mobileView ? 6 : 5} my={'sm'}>
        {labelMode === 'primary' ? renderPrimaryTitle() : `${t('language')} ${index + 1}`}
      </Title>
    )
  }

  const getOptions = (index: number) => {
    let extra = false
    if (other && index !== 0) {
      extra = true
    }
    if (extra) {
      return [...languageList, ...langHelper.general.getExtraLangOptions()]
    }

    return languageList
  }

  const isLangExtraOption = (index: number) =>
    langHelper.general.isLangExtraOption(watchValue[index]?.language_id)

  const isRemovable = (index: number) => (other ? index !== 0 : true)

  const renderActionBlock = (index: number) =>
    isRemovable(index) ? (
      <FormControlActionBlock className={mobileView ? 'mt-4' : 'mb-4'}>
        <ActionIconButton name={'remove'} onClick={() => removeRow(index)} />
      </FormControlActionBlock>
    ) : null

  useEffect(() => {
    if (fields.length == 0) {
      addRowItem()
    }
  }, [])

  return (
    <div className={mobileView ? 'flex flex-col gap-2' : 'flex flex-col mb-4'}>
      {fields.map((item, index) => (
        <div key={item.fieldId} className={'flex flex-col'}>
          {mobileView ? (
            <>
              <div className={'flex'}>
                <div className={index === 0 ? 'w-100' : 'w-10/12'}>
                  <SelectController
                    control={control}
                    name={`${name}.${index}.language_id`}
                    id={`${name}.${index}.language_id`}
                    label={
                      mobileView ? (index === 0 ? t('primary') : t('secondary')) : t('language')
                    }
                    placeholder={t('language')}
                    size={mobileView ? 'md' : 'lg'}
                    mb={mobileView ? '0' : 'md'}
                    data={getOptions(index)}
                    withinPortal
                  />
                </div>
                {index > 0 && <div className={'w-2/12'}>{renderActionBlock(index)}</div>}
              </div>
              {other && isLangExtraOption(index) && (
                <TextInputController
                  control={control}
                  name={`${name}.${index}.name`}
                  id={`${name}.${index}.name`}
                  label={t('name')}
                  placeholder={t('name')}
                  size={mobileView ? 'md' : 'lg'}
                  mb={mobileView ? '0' : 'md'}
                  defaultValue={''}
                  translateParams={{ count: 3 }}
                />
              )}
              {level && (
                <SelectController
                  control={control}
                  name={`${name}.${index}.level_id`}
                  id={`${name}.${index}.level_id`}
                  label={t('level')}
                  placeholder={t('level')}
                  size={mobileView ? 'md' : 'lg'}
                  mb={mobileView ? '0' : 'md'}
                  data={languageLevelsList}
                  withinPortal
                />
              )}
            </>
          ) : (
            <>
              {renderTitleSection(index)}
              <Grid grow {...gridProps}>
                <Grid.Col span={9} lg={11} xs={10}>
                  <Grid {...gridProps}>
                    <Grid.Col lg={4}>
                      <SelectController
                        control={control}
                        name={`${name}.${index}.language_id`}
                        id={`${name}.${index}.language_id`}
                        label={
                          mobileView ? (index === 0 ? t('primary') : t('secondary')) : t('language')
                        }
                        placeholder={t('language')}
                        size={mobileView ? 'md' : 'lg'}
                        mb={mobileView ? '0' : 'md'}
                        data={getOptions(index)}
                        withinPortal
                      />
                    </Grid.Col>
                    {other && isLangExtraOption(index) && (
                      <Grid.Col lg={4}>
                        <TextInputController
                          control={control}
                          name={`${name}.${index}.name`}
                          id={`${name}.${index}.name`}
                          label={t('name')}
                          placeholder={t('name')}
                          size={mobileView ? 'md' : 'lg'}
                          mb={mobileView ? '0' : 'md'}
                          defaultValue={''}
                          translateParams={{ count: 3 }}
                        />
                      </Grid.Col>
                    )}
                    {level && (
                      <Grid.Col lg={4}>
                        <SelectController
                          control={control}
                          name={`${name}.${index}.level_id`}
                          id={`${name}.${index}.level_id`}
                          label={t('level')}
                          placeholder={t('level')}
                          size={mobileView ? 'md' : 'lg'}
                          mb={mobileView ? '0' : 'md'}
                          data={languageLevelsList}
                          withinPortal
                        />
                      </Grid.Col>
                    )}
                    <Grid.Col lg={4} className={'hidden lg:block'}>
                      {renderActionBlock(index)}
                    </Grid.Col>
                  </Grid>
                </Grid.Col>

                <Grid.Col span={3} lg={1} xs={2} className={'lg:hidden'}>
                  {renderActionBlock(index)}
                </Grid.Col>
              </Grid>
            </>
          )}
        </div>
      ))}
      {limit ? fields.length < limit && renderAdd() : renderAdd()}
    </div>
  )
}
