import React from 'react'
import { useAppState } from '@/features/app/hooks'
import { MyBookingCandidateType } from '@/features/booking-candidate/types/models'
import { BookingService } from '@/features/bookings/types/models'
import { ActionBar } from './ActionBar'
import useStyles from './Card.styles'
import { Footer } from './Footer'
import { InfoActionBarBlock } from './InfoActionBarBlock'
import { InfoBlock } from './InfoBlock'
import { InfoTopBarBlock } from './InfoTopBarBlock'

interface IProps {
  data: BookingService
  onView: (id: number) => void
  onCancelSuccess?: () => void
  actionBar?: boolean
  actionBarSection?: React.ReactNode
  actionBarProps?: any
  footer?: boolean
  footerProps?: any
  candidateProps?: {
    candidate: MyBookingCandidateType
    acceptControlProps: {
      onSuccess?: (data: object) => void
    }
    declineControlProps: {
      onSuccess?: (data: object) => void
    }
  }
  ratingControlProps?: any
}

export const GeneralServiceCard = ({
  data,
  onView,
  onCancelSuccess,
  actionBar = true,
  actionBarSection,
  actionBarProps,
  footer = false,
  footerProps,
  candidateProps,
  ratingControlProps,
}: IProps) => {
  const {
    appState: { mobileView },
  } = useAppState()

  const { classes } = useStyles()

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    if (mobileView) {
      onView(data.id)
    }
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.body}>
          <div className={classes.infoBlock}>
            <div className={classes.infoTopBar} onClick={onClick}>
              <InfoTopBarBlock data={data} />
            </div>
            <div className={classes.infoBlockItem} onClick={onClick}>
              <InfoBlock data={data} />
            </div>
            {!mobileView && (
              <div className={'mt-4'}>
                <InfoActionBarBlock data={data} onView={onView} />
              </div>
            )}
          </div>
          {actionBar &&
            (actionBarSection ||
              actionBarProps.config?.candidateStatusBlock ||
              actionBarProps.config?.chat ||
              actionBarProps.config?.menu) && (
              <div className={classes.actionBar}>
                {actionBarSection ? (
                  actionBarSection
                ) : (
                  <ActionBar
                    bookingService={data}
                    onViewDetailsClick={onClick}
                    {...actionBarProps}
                    candidateProps={candidateProps}
                    onCancelSuccess={onCancelSuccess}
                  />
                )}
              </div>
            )}
        </div>

        {footer && (
          /*isRatingAllow(data) &&*/ <div className={classes.footer}>
            <Footer
              bookingService={data}
              {...footerProps}
              ratingControlProps={ratingControlProps}
            />
          </div>
        )}
      </div>
    </>
  )
}
