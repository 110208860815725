import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  wrapper: {
    paddingRight: '8px', // grid gutters
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      paddingRight: '0px',
    },
  },
  cardWrapper: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      paddingBottom: '32px',
      padding: '24px',
    },
  },
}))
