import { all } from 'redux-saga/effects'
import { avatarSaga } from './avatar/sagas'
import { babysitterSaga } from './babysitter/sagas'
import { clientSaga } from './client/sagas'
import { deactivateSaga } from './deactivate/saga'
import { passwordSaga } from './password/sagas'
import { removeSaga } from './remove/saga'

export function* profileSaga() {
  yield all([
    babysitterSaga(),
    avatarSaga(),
    clientSaga(),
    passwordSaga(),
    deactivateSaga(),
    removeSaga(),
  ])
}
