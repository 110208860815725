import { CITY_TYPE } from '@/const/city'
import { BabysitterRatingModel } from '@/features/rating/types/models'
import { ModelTypes } from '@/types'

export enum ROLE {
  Client = 'client',
  Babysitter = 'babysitter',
}

export type LanguageType = {
  id: number
  language: {
    id: number
    is_default: boolean
    name: string
  }
  level?: {
    id: number
    name: string
  }
}

export type SkillType = {
  id: number
  is_default: boolean
  name: string
}

export type FeedbackType = {
  user_avatar: ModelTypes.AvatarType | null
  user_first_name: string
  feedback_date: string
  babysitter_feedback: string
}

export type CertificationType = {
  school: string
  study: string
  description: string
  start_date: string
  end_date: string
}

export type CityModelType = {
  id: number
  name: string
}

export type BabysitterProfile = {
  bio: string | null
  birthday: string | null
  certifications: Array<CertificationType>
  city: CityModelType | null
  experience_max: number | null
  experience_min: number | null
  have_car: boolean | null
  have_driving_license: boolean | null
  have_pediatric_certification: boolean | null
  have_special_kids: boolean | null
  languages: Array<LanguageType>
  occupation_area: string | null
  skills: Array<SkillType>
  rating: number | null
}

export type ChildType = {
  id: number
  birthday: string
  name: string
}

export type ClientProfile = {
  city: CityModelType | null
  city_description: string | null
  city_type: CITY_TYPE | null
  country: ModelTypes.CountryType | null
  address: string
  //floor: string
  zip: string
  children: Array<ChildType>
  languages: Array<LanguageType>
  about_child: string
  other_info: string
  rating: number | null
}

export type BaseUserType = {
  id: number
  active: boolean
  approved: boolean
  avatar: ModelTypes.AvatarType | null
  email: string
  first_name: string | null
  last_name: string | null
  role: ROLE
  lang: string
  phone: string | null
  unread_messages?: number
  has_bookings: boolean
}

export interface BabysitterUser extends BaseUserType {
  babysitter_profile?: BabysitterProfile | null
  favorite_babysitter?: boolean
  feedbacks: Array<FeedbackType>
  babysitter_rating?: BabysitterRatingModel
}

export interface ClientUser extends BaseUserType {
  client_profile?: ClientProfile | null
}

export interface User extends BabysitterUser, ClientUser {}
