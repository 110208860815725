import React from 'react'
import { OverlayLoading, OverlayLoadingProps } from '../OverlayLoading'

// eslint-disable-next-line
interface IProps extends Omit<OverlayLoadingProps, 'visible'> {
  children: React.ReactNode
  isLoading: boolean
}

export const OverlayBlockLoading = ({ isLoading, children, ...props }: IProps) => {
  return (
    <div className={'relative h-full'}>
      <OverlayLoading {...props} visible={isLoading} />

      {children}
    </div>
  )
}
