import React from 'react'
import { AddSkill } from '../AddSkill'
import { SkillsTags } from '../SkillsTags'
import { SkillsControlOptionType, SkillsControlValueType } from '../types'

interface IProps {
  value: Array<SkillsControlValueType>
  options: Array<SkillsControlOptionType>
  onAdd: (data: string) => void
  onRemove: (data: SkillsControlOptionType, i: number) => void
  onSelect: (data: SkillsControlValueType, checked: boolean) => void
}

export const SkillsContainer = ({
  value = [],
  options = [],
  onAdd,
  onRemove,
  onSelect,
}: IProps) => {
  return (
    <>
      <SkillsTags value={value} options={options} onSelect={onSelect} onRemove={onRemove} />

      {/*<AddSkill onAdd={onAdd} options={options} />*/}
    </>
  )
}
