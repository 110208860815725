import React from 'react'
import { BookingModel } from '@/features/bookings/types/models'
import { RatingSubmittedActionMeta } from '@/features/rating/hooks/useRatingEntryPopupModule'
import { BabysitterRatingModel, ClientRatingModel } from '@/features/rating/types/models'

export type BookingUpdatedActionMeta = {
  action:
    | 'cancel'
    | 'babysitter-cancel'
    | 'candidate-accept'
    | 'candidate-decline'
    | 'request-finish'
    | 'finish'
  payload?: any
}

export interface IProps {
  onBookingUpdated: (booking: BookingModel | null, actionMeta: BookingUpdatedActionMeta) => void
  onRatingSubmitted?: (
    rating: ClientRatingModel | BabysitterRatingModel | null,
    actionMeta: RatingSubmittedActionMeta
  ) => void
}

export const useBookingDrawerModule = ({ onBookingUpdated, onRatingSubmitted }: IProps) => {
  return {
    onBookingUpdated,
    onRatingSubmitted,
  }
}
