import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Anchor, Text } from '@mantine/core'
import { BookingService } from '@/features/bookings/types/models'
import useStyles from './InfoActionBarBlock.styles'

interface IProps {
  data: BookingService
  onView: (id: number) => void
}

export const InfoActionBarBlock = ({ data, onView }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { id } = data

  const handleView = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    onView(id)
  }

  return (
    <div className={'flex'}>
      <Anchor component={Link} to={''} onClick={handleView}>
        <Text size={'lg'} weight={600}>
          {t('show_details')}
        </Text>
      </Anchor>
    </div>
  )
}
