import _ from 'lodash'
import { LANG_TYPE } from '@/features/profile/consts/lang'
import i18n from '@/i18n'

export const getExtraLangOptions = () => {
  return [
    {
      label: i18n.t('others-please-specify'),
      value: LANG_TYPE.OTHER,
    },
  ]
}

export const getExtraLang = (value: string) => {
  const options = getExtraLangOptions()
  return _.find(options, { value })
}

export const isLangExtraOption = (type: string | any) => {
  return [LANG_TYPE.OTHER].includes(type)
}
