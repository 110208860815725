import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  block: {
    ['td']: {
      verticalAlign: 'top',
    },
  },

  labelCell: {
    width: '300px',
  },

  label: {
    fontWeight: 700,
    whiteSpace: 'nowrap',
    lineHeight: '1.75rem',
  },

  groupItem: {
    fontSize: '14px',
    alignItems: 'flex-start',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: '14px',
    },
  },

  groupItemLabel: {
    width: '35%',
    color: theme.colors.gray[7],
    fontWeight: 600,
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: '14px',
      width: '100%',
    },
  },

  groupItemValue: {
    width: '60%',
    fontWeight: 400,
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: '100%',
    },
  },
}))
