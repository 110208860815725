import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useMountedState } from 'react-use'
import { AvatarManagerControl } from '@/features/profile/components/Controls'
import { profileAvatarRemovePA, profileAvatarUploadPA } from '@/features/profile/store'
import { useUser } from '@/features/user'
import { useNotify } from '@/hooks'
import { FormTypes } from '@/types'

export const ProfileAvatarManager = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isMounted = useMountedState()

  const { user } = useUser()
  const { showNotification } = useNotify()

  const [uploading, setUploading] = useState(false)
  const [removing, setRemoving] = useState(false)

  const setUploadingStatus = (status: boolean) => {
    if (isMounted()) {
      setUploading(status)
    }
  }

  const onUpload = async (file: File) => {
    setUploadingStatus(true)

    try {
      const data = {
        avatar: file,
      }
      await dispatch(profileAvatarUploadPA.request(data))
      showNotification({
        type: 'success',
        message: t('saved_successfully'),
      })
      setUploadingStatus(false)
    } catch (err) {
      setUploadingStatus(false)

      const serverError = err as FormTypes.ValidationErrors
      const message = serverError?.message || t('error')

      showNotification({
        type: 'error',
        message: message,
      })
    }
  }

  const setRemovingStatus = (status: boolean) => {
    if (isMounted()) {
      setRemoving(status)
    }
  }

  const onRemove = async () => {
    setRemovingStatus(true)

    try {
      await dispatch(profileAvatarRemovePA.request())
      showNotification({
        type: 'success',
        message: t('deleted_successfully'),
      })
      setRemovingStatus(false)
    } catch (err) {
      setRemovingStatus(false)

      const serverError = err as FormTypes.ValidationErrors
      const message = serverError?.message || t('error')

      showNotification({
        type: 'error',
        message: message,
      })
    }
  }

  return (
    <AvatarManagerControl
      image={user?.avatar?.full}
      onUpload={onUpload}
      onRemove={onRemove}
      uploading={uploading}
      removing={removing}
    />
  )
}
