import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Anchor, Text, Title } from '@mantine/core'
import { Icon } from '@/components/Elements'
import {
  BookingDatePeriodDisplay,
  BookingHoursPerWeekDisplay,
  BookingNewBadge,
  BookingTimePeriodDisplay,
} from '@/features/bookings/components/Elements'
import { BookingDaysPerWeekDisplay } from '@/features/bookings/components/Elements/BookingDaysPerWeekDisplay/BookingDaysPerWeekDisplay'
import { bookingHelper } from '@/features/bookings/helpers/booking'
import { BookingRequest } from '@/features/bookings/types/models'
import useStyles from './Header.styles'

const { bookingModel } = bookingHelper
const getType = bookingHelper.type.getType

interface IProps {
  booking: BookingRequest
  onView: (id: number) => void
}

export const Header: React.FC<IProps> = ({ booking, onView }) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { id, date_start, date_end, type, is_new, hours_per_week, days_per_week } = booking

  const timePeriod = bookingModel.getTimePeriod(booking)

  const renderTimePeriod = () =>
    timePeriod ? (
      <>
        {', '}
        <BookingTimePeriodDisplay timePeriod={timePeriod} />
      </>
    ) : hours_per_week ? (
      <>
        {', '}
        <BookingHoursPerWeekDisplay hoursPerWeek={hours_per_week} />
      </>
    ) : days_per_week ? (
      <>
        {', '}
        <BookingDaysPerWeekDisplay daysPerWeek={days_per_week} />
      </>
    ) : null

  const renderType = () => (
    <>
      <Icon name={getType(type)?.iconName} className={'ml-2'} />
    </>
  )

  const handleView = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    onView(id)
  }

  return (
    <div className={classes.root}>
      <div className={'flex items-center'}>
        <Title order={4}>
          <BookingDatePeriodDisplay startDate={date_start} endDate={date_end} />
          {renderTimePeriod()}
        </Title>
        <div>{renderType()}</div>

        {is_new && (
          <div className={'ml-2'}>
            <BookingNewBadge />
          </div>
        )}
      </div>

      <div className={'self-start'}>
        <Anchor component={Link} to={''} onClick={handleView}>
          <Text size={'lg'} weight={600}>
            {t('view_request')}
          </Text>
        </Anchor>
      </div>
    </div>
  )
}
