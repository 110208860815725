import { PayloadAction } from '@reduxjs/toolkit'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import { chatUpdateOrCreate } from '@/features/chat/store/chats/list/saga'
import { chatConversationData } from '@/features/chat/store/conversation/data/slice'
import { chatConversationMessagesList } from '@/features/chat/store/conversation/messages/list/slice'
import { readChatSubmit } from '@/features/chat/store/conversation/messages/read/saga'
import { userData } from '@/features/user/store'
import { RootState } from '@/store'
import { chatSocketOnMessage } from './actions'

const appWindowFocusState = (state: RootState) => state.app.state.windowFocus
const conversationState = (state: RootState) => state.chat.conversation.data.data

function* onMessage({ payload: { data } }: PayloadAction<{ data: any }>) {
  const { message, unread_count } = data
  const { chat, user, ...messageData } = message
  const conversation: {
    id: number
  } = yield select(conversationState)
  // if message come from current open conversation
  const isCurrentChat = chat.id === conversation.id

  if (isCurrentChat) {
    // add message to feed
    yield put(chatConversationMessagesList.addMessage(messageData))

    const appWindowFocus: boolean = yield select(appWindowFocusState)
    // only if web app is visible (window in focus)
    if (appWindowFocus) {
      // read chat (seen messages)
      yield call(readChatSubmit as any, {
        payload: { chatId: chat.id },
      })
    } else {
      yield put(chatConversationData.setBackgroundUnreadMessages(true))
    }
  } else {
    // update count all of unread messages (- badge in side menu)
    yield put(userData.updateUnreadMessages(unread_count))
  }

  // update chat list
  const chatListPayload = {
    message: {
      ...messageData,
      chat_id: chat.id,
    },
    chat: {
      ...chat,
    },
    isCurrentChat,
  }
  yield call(chatUpdateOrCreate as any, { payload: chatListPayload })
}

function* watchOnMessage() {
  yield takeEvery(chatSocketOnMessage as any, onMessage)
}

export function* messageSaga() {
  yield all([fork(watchOnMessage)])
}
