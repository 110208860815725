import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '@/components/Elements'
import { BOOKING_STATUS, BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { BookingRequest } from '@/features/bookings/types/models'

interface IProps {
  data: BookingRequest
  children?: React.ReactNode
}

export const InfoAlert: React.FC<IProps> = ({ children, data, ...props }) => {
  const { t } = useTranslation()

  const { type, status } = data

  const info = useMemo(() => {
    let message = null
    if (status === BOOKING_STATUS.UNPAID) {
      message = t('booking_candidates.note.status.unpaid')
    } else if (status === BOOKING_STATUS.BABYSITTER_NOT_FOUND) {
      message = t('booking_candidates.note.status.babysitter_not_found')
    } else if (status === BOOKING_STATUS.PAYMENT_LINK_ERROR) {
      message = t('booking_candidates.note.status.payment_link_error')
    }

    return message
  }, [type, status])

  return (
    <>
      {(info || children) && (
        <Alert type={'info'} mt={'md'}>
          {children || info}
        </Alert>
      )}
    </>
  )
}
