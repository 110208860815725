import React from 'react'
import { ScrollArea, Title, Tooltip } from '@mantine/core'
import { Card } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import useStyles from './ContentCard.styles'

interface IProps {
  children: React.ReactNode
  header?: React.ReactNode
  footer?: React.ReactNode
  title?: string
  titleInfo?: string
  headerRight?: React.ReactNode
  fullHeight?: boolean
  className?: string
  scrollable?: boolean
  columnReverse?: boolean
  viewport?: any
}

export const ContentCard: React.FC<IProps> = ({
  children,
  fullHeight = true,
  className,
  header,
  title,
  titleInfo,
  footer,
  headerRight,
  scrollable,
  columnReverse,
  viewport,
  ...props
}) => {
  const { classes, cx } = useStyles()
  const {
    appState: { mobileView },
  } = useAppState()

  const renderInfo = () => {
    return (
      <>
        <div className="flex gap-1 font-bold">
          <span>{titleInfo}</span>
        </div>
      </>
    )
  }

  return (
    <Card
      {...props}
      className={cx(classes.card, className, {
        'h-full': fullHeight || scrollable,
      })}
    >
      {(header || title || headerRight) && (
        <div className={classes.header}>
          {header ? (
            header
          ) : title ? (
            <div className={classes.headerBlock}>
              <div className={classes.headerTitleBlock}>
                <Tooltip
                  label={renderInfo()}
                  position="bottom"
                  //withArrow
                  //arrowSize={6}
                  multiline
                  events={{ hover: true, touch: true, focus: false }}
                  disabled={!titleInfo}
                >
                  <Title order={mobileView ? 3 : 1}>
                    {title}
                    {titleInfo && <i className="pi pi-info-circle ml-2"></i>}
                  </Title>
                </Tooltip>
              </div>
              <div className={classes.headerRightBlock}>{headerRight && headerRight}</div>
            </div>
          ) : null}
        </div>
      )}
      <div
        className={classes.content}
        style={{
          overflow: scrollable ? 'hidden' : 'initial',
          flexDirection: columnReverse ? 'column-reverse' : 'column',
        }}
      >
        {scrollable ? (
          <ScrollArea className={classes.scrollArea} viewportRef={viewport}>
            {children}
          </ScrollArea>
        ) : (
          children
        )}
      </div>
      {footer && <div className={classes.footer}>{footer}</div>}
    </Card>
  )
}
