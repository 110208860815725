import React from 'react'
import { BadgeProps, Badge as MantineBadge } from '@mantine/core'
import useStyles from './Badge.styles'

export interface IProps extends BadgeProps {
  children: React.ReactNode
}

export const Badge = ({ children, ...props }: IProps) => {
  const { classes } = useStyles()

  return (
    <>
      <MantineBadge classNames={classes} {...props}>
        {children}
      </MantineBadge>
    </>
  )
}
