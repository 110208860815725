import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { DataShower } from '@/components/Elements'
import { babysitterData } from '@/features/babysitter/store'
import { useAppSelector } from '@/store'

interface IProps {
  id: number | string | undefined
  children: React.ReactNode
}

export const BabysitterDataLoader = ({ id, children }: IProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(babysitterData.getData({ id }))

    return () => {
      dispatch(babysitterData.cleanState())
    }
  }, [])

  const { data, loading, error } = useAppSelector((state) => state.babysitter.data)

  return (
    <DataShower isLoading={loading} isFetched={!!data} isFailed={!!error} error={error}>
      {children}
    </DataShower>
  )
}
