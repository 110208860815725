import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useEvents } from '@/features/app/hooks'
import { profileBabysitterUpdatePA } from '@/features/profile/store'
import { TAG_MANAGER_EVENT } from '@/features/tag-manager/consts/event'
import { useNotify } from '@/hooks'
import { EditForm } from './EditForm'

interface IProps {
  onCancel: () => void
  editorType?: EditorType
}

export enum EditorType {
  ALL,
  IMAGE,
  GENERAL,
  ABOUT_ME,
  LANGUAGE,
  SKILLS,
  CERTIFICATIONS,
  SETTINGS,
}
export const BabysitterProfileEdit = ({ onCancel, editorType = EditorType.ALL }: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const event = useEvents()
  const { showNotification } = useNotify()
  useEffect(() => event.actions.generic(TAG_MANAGER_EVENT.PROFILE_EDIT), [])

  const onSubmit = async (values: any) => {
    await dispatch(profileBabysitterUpdatePA.request(values))
    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })
    onCancel()
  }

  return (
    <>
      <EditForm editorType={editorType} onCancel={onCancel} onSubmit={onSubmit} />
    </>
  )
}
