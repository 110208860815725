import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.md,
    display: 'flex',
    backgroundColor: theme.white,
    flexDirection: 'column',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      margin: '8px 0px',
    },
  },

  body: {
    display: 'flex',
    gap: theme.spacing.xl,
    padding: '20px',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      flexDirection: 'column',
      gap: theme.spacing.xs,
    },
  },

  dateBlock: {
    width: '140px',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: '100%',
    },
  },

  infoBlock: {
    flexGrow: 1,
  },

  actionBar: {
    display: 'flex',
    alignItems: 'center',
  },

  footer: {
    display: 'flex',
    padding: '20px',
    borderTop: `1px solid ${theme.colors.gray[4]}`,
  },
}))
