import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  block: {},

  groupItem: {
    display: 'block',
    fontSize: '14px',
    alignItems: 'flex-start',
    gap: '2px',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: '12px',
    },
  },

  groupItemLabel: {
    width: '150px',
    color: theme.colors.gray[7],
    fontWeight: 500,
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: '12px',
      width: '45%',
      marginBottom: '2px',
    },
  },

  groupItemValue: {
    fontWeight: 600,
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: '13px',
    },
  },
}))
