import React from 'react'

interface IProps {
  color: string
  size?: number
  viewPort?: number
  className?: string
}

export const MapPin = ({ size = 24, viewPort = size, color, ...props }: IProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${viewPort} ${viewPort}`}
    strokeWidth={2}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 17C18.2091 17 20 15.2091 20 13C20 10.7909 18.2091 9 16 9C13.7909 9 12 10.7909 12 13C12 15.2091 13.7909 17 16 17Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26 13C26 22 16 29 16 29C16 29 6 22 6 13C6 10.3478 7.05357 7.8043 8.92893 5.92893C10.8043 4.05357 13.3478 3 16 3C18.6522 3 21.1957 4.05357 23.0711 5.92893C24.9464 7.8043 26 10.3478 26 13V13Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
