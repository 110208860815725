import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Title } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import { profilePasswordUpdatePA } from '@/features/profile/store'
import { useNotify } from '@/hooks'
import { PasswordForm } from './PasswordForm'

interface IProps {
  onCancel: () => void
}

export const PasswordContainer = ({ onCancel }: IProps) => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()
  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const onSubmit = async (values: any) => {
    await dispatch(profilePasswordUpdatePA.request(values))
    showNotification({
      type: 'success',
      message: t('password_changed_successfully'),
    })
  }

  return (
    <>
      <Title order={mobileView ? 4 : 3} mb={'sm'}>
        {t('password')}
      </Title>

      <PasswordForm onCancel={onCancel} onSubmit={onSubmit} />
    </>
  )
}
