import React from 'react'
import { useTranslation } from 'react-i18next'
import { Anchor, List, Space, Text } from '@mantine/core'
import { Button, Modal } from '@/components/Elements'
import { EXTERNAL_LINK } from '@/const/core'

interface IProps {
  visible: boolean
  onClose: () => void
}

export const AccountNotApprovedInfo = ({ visible, onClose }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal opened={visible} onClose={onClose} centered title={`${t('info')}`}>
        <Text weight={600}>{t('account-not-approved-info.step-title')}</Text>

        <Space h={'sm'} />

        <List size={'lg'} spacing={'sm'}>
          <List.Item>
            <>
              {t('account-not-approved-info.step-1')}
              <br />
              <Anchor component={'a'} href={EXTERNAL_LINK.AIRTABLE} target={'_blank'}>
                {EXTERNAL_LINK.AIRTABLE}
              </Anchor>
            </>
          </List.Item>
        </List>

        <div className={'mt-1 w-full'}>
          <Button mt={'lg'} fullWidth variant="subtle" color="gray" onClick={onClose}>
            {t('cancel')}
          </Button>
        </div>
      </Modal>
    </>
  )
}
