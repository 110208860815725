import React from 'react'
import { useBookingFormTimetableContext } from '@/features/booking/contexts'
import { timetableHelper } from '@/features/booking/helpers'

export const useBookingFormTimetable = () => {
  const { weeks, setWeeks } = useBookingFormTimetableContext()

  const getWeeks = () => weeks

  const getWeekdays = () => timetableHelper.weekdays.getWeekDays()

  const generateWeeks = ({ start, end }: { start: Date; end: Date }) => {
    const weekList = timetableHelper.weeks.getWeekListBetweenDates({ start, end })

    setWeeks(weekList)

    return weekList
  }

  const resetWeeks = () => {
    setWeeks([])
  }

  return {
    getWeeks,
    getWeekdays,
    generateWeeks,
    resetWeeks,
  }
}
