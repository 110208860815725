import React, { useMemo } from 'react'
import { AlertProps, Alert as MantineAlert } from '@mantine/core'

interface IProps extends AlertProps {
  children: React.ReactNode
  className?: string
  type?: 'success' | 'error' | 'warning' | 'info'
  color?: string
}

export const Alert: React.FC<IProps> = ({ type, children, className, ...props }) => {
  const color = useMemo(() => {
    if (props.color) return props.color
    if (type) {
      if (type === 'success') return 'green'
      if (type === 'error') return 'red'
      if (type === 'info') return 'blue'
    }
    return undefined
  }, [props.color, type])

  return (
    <MantineAlert {...props} color={color}>
      {children}
    </MantineAlert>
  )
}
