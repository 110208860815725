import React from 'react'
import { useTranslation } from 'react-i18next'
import { Title } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import { useBabysitterData } from '@/features/babysitter/hooks'
import { RatingStack } from '@/features/rating/components/Elements'
import { BabysitterUser } from '@/features/user'

export const RatingBlock = () => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()

  const { babysitter } = useBabysitterData()
  const { babysitter_profile } = (babysitter as BabysitterUser) || {}

  return (
    <div>
      <Title order={mobileView ? 6 : 5}>{t('rating')}</Title>

      <div className={'my-3'}>
        <div className={'flex'}>
          <RatingStack value={babysitter_profile?.rating as number} />
        </div>
      </div>
    </div>
  )
}
