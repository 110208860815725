import React from 'react'

interface IProps {
  color: string
  size?: number
  viewPort?: number
  className?: string
}

export const Support = ({ size = 24, viewPort = size, color, ...props }: IProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 377 377`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M141.41,147.539c-6.6,0-12.755,3.555-16.065,9.277c-2.489,4.303-7.994,5.773-12.297,3.285s-5.773-7.994-3.285-12.297
	c6.516-11.267,18.643-18.266,31.647-18.266c13.003,0,25.13,6.999,31.647,18.265c2.489,4.303,1.019,9.809-3.284,12.297
	c-1.418,0.821-2.969,1.211-4.498,1.212c-3.108,0-6.131-1.611-7.799-4.495C154.166,151.093,148.009,147.539,141.41,147.539z
	 M188.75,242.974c-6.6,0-12.756-3.555-16.066-9.277c-2.489-4.303-7.995-5.774-12.297-3.285c-4.303,2.488-5.773,7.994-3.285,12.297
	c6.516,11.267,18.643,18.266,31.648,18.266c13.003,0,25.13-6.999,31.647-18.265c2.489-4.303,1.019-9.809-3.284-12.297
	c-4.302-2.489-9.808-1.019-12.297,3.283C201.506,239.419,195.35,242.974,188.75,242.974z M377,155.254v41.042
	c0,15.308-12.045,28.204-27.353,28.204h-16.622c-5.459,17-14.122,33.459-25.788,48.17c-19.169,24.173-45.547,41.731-74.881,50.584
	c-1.319,14.808-13.791,26.247-28.931,26.247h-29.349c-16.017,0-29.048-12.982-29.048-28.999c0-16.021,13.031-29.001,29.048-29.001
	h29.349c10.542,0,19.79,5.802,24.881,14.228c55.474-17.164,93.58-68.237,93.58-127.003c0-57.195-36.252-106.083-86.987-124.889
	c-13.186,16.208-29.765,29.692-48.292,39.227c-21.189,10.903-45.068,16.667-69.054,16.667c-2.783,0-5.617-0.078-8.422-0.232
	c-4.963-0.271-8.767-4.515-8.495-9.478s4.5-8.797,9.478-8.496c2.48,0.136,4.983,0.203,7.44,0.203
	c38.001,0,73.151-15.651,98.335-43.363c-8.427-1.752-17.139-2.711-26.062-2.782c-0.052-0.005-0.139-0.012-0.211-0.012
	c-0.032,0.002-0.07-0.023-0.103-0.023c-0.069-0.002-0.148-0.046-0.217-0.046c-0.034,0-0.065,0-0.1,0c-0.068,0-0.148,0-0.22,0
	c-0.034,0-0.063,0-0.101,0c-0.042,0-0.084,0-0.125,0C115.338,45.5,56,105.314,56,178.725v36.332c0,4.971-4.416,9.443-9.386,9.443
	H27.853C12.545,224.5,0,211.604,0,196.296v-41.042c0-12.095,7.786-22.718,19.323-26.432c4.732-1.527,9.837,1.076,11.36,5.809
	c1.523,4.73-1.1,9.802-5.832,11.325c-4.007,1.29-6.852,5.113-6.852,9.298v41.042c0,5.382,4.47,10.204,9.853,10.204H38v-27.775
	C38,95.388,105.413,27.5,188.75,27.5c0.047,0,0.097,0,0.143,0c0.037,0,0.076,0,0.114,0c0.036,0,0.074,0,0.11,0h0.001
	c0.045,0,0.096,0,0.142,0c0.037,0,0.078,0,0.112,0c0.036,0,0.073,0,0.109,0h0.008c0.048,0,0.091,0,0.139,0c0.04,0,0.075,0,0.114,0
	c0.038,0,0.083,0,0.122,0c0.045,0,0.086,0,0.131,0c0.039,0,0.072,0,0.111,0c0.049,0,0.086,0,0.131,0c0.041,0,0.083,0,0.126,0
	c0.035,0,0.075,0,0.111,0c0.05,0,0.091,0,0.142,0c0.036,0,0.082,0,0.116,0c0.034,0,0.074-0.002,0.108,0c0.046,0,0.099,0,0.146,0
	c0.039,0,0.073,0,0.112,0c0.033,0,0.076,0.056,0.107,0.055c0.047,0.002,0.094,0.054,0.143,0.056c0.036-0.001,0.08-0.001,0.114,0.002
	c0.032,0,0.076-0.001,0.108,0.002c0.047-0.001,0.098,0.002,0.147,0.002c0.038,0.002,0.071,0.001,0.11,0.003
	c0.031,0,0.076,0.001,0.108,0.002c0.045,0.001,0.106,0.004,0.153,0.003c0.037,0.002,0.071,0.002,0.106,0.003
	c0.036,0,0.071,0.002,0.106,0.002c0.046,0.003,0.103,0.002,0.149,0.004c0.036-0.001,0.071,0.002,0.108,0.002
	c0.036,0.003,0.071,0.003,0.107,0.003c0.047,0.002,0.098,0.002,0.146,0.004c0.037,0.002,0.07,0.002,0.108,0.003
	c0.035,0.002,0.074,0.002,0.107,0.002c0.047,0.003,0.084,0.001,0.131,0.003c0.039,0.002,0.079,0,0.119,0
	c0.038,0.002,0.07-0.004,0.11-0.002c0.04,0.001,0.077-0.015,0.117-0.007c0.043,0.002,0.087-0.023,0.131-0.018
	c0.039,0.001,0.069-0.041,0.11-0.042c0.076,0.003,0.153-0.081,0.229-0.081h0.012c0.036,0,0.074,0,0.11,0h0.006
	c13.752,1,27.309,2.961,40.374,7.104c0.183,0.058,0.363,0.268,0.54,0.337c60.779,19.586,104.871,76.798,104.871,144.005
	c0,9.278-0.819,18.554-2.434,27.554h12.195c5.382,0,9.353-4.822,9.353-10.204v-41.042c0-4.185-2.646-8.008-6.653-9.298
	c-4.731-1.523-7.179-6.594-5.656-11.325s6.492-7.33,11.223-5.81C369.452,132.537,377,143.159,377,155.254z M214.472,320.619
	c0-0.745-0.074-1.305-0.215-2.009c-0.02-0.086-0.038-0.251-0.055-0.337c-1.111-4.928-5.521-8.772-10.776-8.772h-29.349
	c-6.092,0-11.048,4.907-11.048,10.998c0,6.096,4.956,11.002,11.048,11.002h29.349C209.516,331.5,214.472,326.711,214.472,320.619z
	 M207.727,160.1c4.302,2.49,9.808,1.019,12.297-3.283c3.311-5.724,9.467-9.278,16.066-9.278s12.756,3.555,16.066,9.278
	c1.668,2.884,4.691,4.495,7.799,4.495c1.529-0.001,3.08-0.391,4.498-1.212c4.303-2.488,5.773-7.994,3.284-12.297
	c-6.517-11.266-18.644-18.265-31.647-18.265c-13.003,0-25.13,6.999-31.647,18.265C201.954,152.106,203.424,157.612,207.727,160.1z"
      fill={color}
    />
  </svg>
)
