import axios from 'axios'

class ApiService {
  constructor() {}

  setToken = (token: string) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }

  clearToken = () => {
    axios.defaults.headers.common['Authorization'] = ''
  }

  setLang = (lang: string) => {
    axios.defaults.headers.common['X-localization'] = lang
  }

  clearLang = () => {
    axios.defaults.headers.common['X-localization'] = ''
  }
}

export const API = new ApiService()
