import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { RequestFinishControl } from '@/features/bookings/components/BookingActionControls'
import { useBookingDrawerModuleContext } from '@/features/bookings/contexts'
import { bookingsData } from '@/features/bookings/store'
import { BookingModel } from '@/features/bookings/types/models'

interface IProps {
  booking: BookingModel
  onSuccess?: (data: object) => void
}

export const RequestFinishBooking = ({ booking }: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const {
    appState: { mobileView },
  } = useAppState()
  const { onBookingUpdated } = useBookingDrawerModuleContext()

  const onSubmitSuccess = (data: any) => {
    dispatch(bookingsData.updateData({ ...data }))

    onBookingUpdated &&
      onBookingUpdated(data, {
        action: 'request-finish',
      })
  }

  return (
    <RequestFinishControl
      booking={booking}
      onSuccess={onSubmitSuccess}
      render={({ onRequestFinish, loading }) => (
        <Button
          onClick={onRequestFinish}
          loading={loading}
          variant={mobileView ? 'filled' : 'outline'}
          fullWidth={mobileView}
        >
          {t('finish_booking')}
        </Button>
      )}
    />
  )
}
