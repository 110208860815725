import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useResizeDetector } from 'react-resize-detector'
import { Anchor } from '@mantine/core'
import useStyles from './Style.styles'

interface IProps {
  children: React.ReactNode
  className?: string
  maxHeight: number
  lineHeight: number
}

export const ReadMoreExpand = ({ children, className, maxHeight, lineHeight }: IProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  const [isExpandMode, setIsExpandMode] = useState(false)
  const [open, setOpen] = useState(false)

  const wrapRef = useRef<any>(null)

  useResizeDetector({
    targetRef: wrapRef,
    onResize: (width, height) => detectExpand(),
  })

  const toggleShow = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    setOpen((prevOpen) => !prevOpen)
  }

  const countLines = () => {
    const height = wrapRef?.current?.scrollHeight
    if ((height - 2) / lineHeight > maxHeight) {
      setIsExpandMode(true)
    }
  }

  const detectExpand = useCallback(() => {
    const height = wrapRef?.current?.scrollHeight
    if (height - 2 > maxHeight) {
      setIsExpandMode(true)
    } else {
      setIsExpandMode(false)
    }
  }, [])

  return (
    <div
      className={cx(classes.root, {
        [`${className}`]: className,
      })}
    >
      <div
        className={cx(classes.text, {
          [`${classes.opened}`]: isExpandMode && open,
        })}
        ref={wrapRef}
        style={{
          maxHeight: isExpandMode && open ? wrapRef?.current?.scrollHeight : maxHeight,
          lineHeight: `${lineHeight}px`,
        }}
      >
        {children}
      </div>

      {isExpandMode && (
        <div className={classes.actions}>
          <Anchor onClick={toggleShow}>{open ? t('collapse') : t('expand')}</Anchor>
        </div>
      )}
    </div>
  )
}
