import { combineReducers } from 'redux'
import bookings from './bookings/reducers'
import data from './data/slice'
import days from './days/reducers'
import invoice from './invoice/reducers'
import tracking from './tracking/reducers'

const bookingsReducer = combineReducers({
  bookings,
  data,
  days,
  tracking,
  invoice,
})

export default bookingsReducer
