import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { BabysitterDataLoader } from './BabysitterDataLoader'
import { BabysitterDetailsContent } from './BabysitterDetailsContent'

export const BookingDetailsContainer = () => {
  const { t } = useTranslation()

  const { id } = useParams()

  return (
    <>
      <BabysitterDataLoader id={id}>
        <BabysitterDetailsContent />
      </BabysitterDataLoader>
    </>
  )
}
