import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CheckboxFieldController } from '@/components/FormElements'

export type AdditionalInfoFormSectionValues = {
  have_car: boolean
  have_driving_license: boolean
  have_pediatric_certification: boolean
  have_special_kids: boolean
}

export const AdditionalInfoFormSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext<AdditionalInfoFormSectionValues>()

  const additionalInfo: Array<{ name: keyof AdditionalInfoFormSectionValues; label: string }> = [
    {
      name: 'have_car',
      label: t('i_have_car'),
    },
    {
      name: 'have_driving_license',
      label: t('i_have_driving_license'),
    },
    {
      name: 'have_pediatric_certification',
      label: t('i_have_pediatric_first_aid_certification'),
    },
    {
      name: 'have_special_kids',
      label: t('i_have_experience_with_kids_with_special_needs'),
    },
  ]

  const renderCheckboxField = ({
    name,
    label,
  }: {
    name: keyof AdditionalInfoFormSectionValues
    label: string
  }) => (
    <CheckboxFieldController
      key={name}
      control={control}
      name={name}
      id={name}
      label={label}
      mb={'md'}
    />
  )

  return <>{additionalInfo.map((item) => renderCheckboxField(item))}</>
}
