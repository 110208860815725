import { createSlice } from '@reduxjs/toolkit'
import { BookingRequest } from '@/features/bookings/types/models'

interface IState {
  list: BookingRequest[]
  loading: boolean
  error: any | null
  page: number
  meta: any | null
}

const initialState: IState = {
  list: [],
  loading: false,
  error: null,
  page: 1,
  meta: null,
}

const bookingsBookingsRequestsListSlice = createSlice({
  name: 'bookingsBookingsRequestsList',
  initialState,
  reducers: {
    getList(state, action) {
      state.loading = true
      state.error = null
    },
    getListSuccess(state, action) {
      state.loading = false
      state.list = action.payload.data
      state.meta = action.payload.meta
    },
    getListError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    setPage(state, action) {
      state.page = action.payload
    },
    updateListItem(state, action) {
      state.list = state.list.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          }
        } else {
          return item
        }
      })
    },
    updateBabysitter(state, action) {
      const bookings = [...state.list]

      state.list = bookings.map((booking) => {
        let candidates = [...booking.booking_candidates]

        candidates = candidates.map((candidate) => {
          if (candidate?.babysitter?.id === action.payload.id) {
            return {
              ...candidate,
              babysitter: {
                ...candidate?.babysitter,
                ...action.payload,
              },
            }
          } else {
            return candidate
          }
        })

        return {
          ...booking,
          booking_candidates: candidates,
        }
      })
    },
    updateCandidate(state, action) {
      const bookings = [...state.list]

      const { bookingId, candidate } = action.payload

      state.list = bookings.map((booking) => {
        if (booking?.id === bookingId) {
          let candidates = [...booking.booking_candidates]

          candidates = candidates.map((item) => {
            if (item?.id === candidate.id) {
              return {
                ...item,
                ...candidate,
              }
            } else {
              return item
            }
          })

          return {
            ...booking,
            booking_candidates: candidates,
          }
        } else {
          return booking
        }
      })
    },
    cleanState() {
      return initialState
    },
  },
})

export const bookingsBookingsRequestsList = bookingsBookingsRequestsListSlice.actions

export default bookingsBookingsRequestsListSlice.reducer
