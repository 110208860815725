import React from 'react'
import {
  NotificationProps,
  showNotification as showMantineNotification,
} from '@mantine/notifications'

type NotifyProps = NotificationProps & {
  type?: 'success' | 'error'
}

export const useNotify = () => {
  const showNotification = (data: NotifyProps) => {
    const color = data?.type === 'success' ? 'green' : data?.type === 'error' ? 'red' : data.color

    showMantineNotification({ ...data, color })
  }

  return {
    showNotification,
  }
}
