import axios from 'axios'
import { ChatModel } from '../types/models'
import { SendMessageToUserDTO } from './dto'

class ChatsUsersAPI {
  // Send message to user
  sendMessageToUser = async (data: SendMessageToUserDTO, activeChat: ChatModel | null) => {
    return axios.post(`api/chats/${activeChat?.id}/message`, data)
  }

  // Get messages from user
  getUserMessages = async (activeChat: ChatModel, params: any) => {
    return axios.get(`api/chats/${activeChat.id}/messages`, {
      params,
    })
  }

  // Make seen massages in chat with user
  seenUserMessages = async (id: number | undefined) => {
    return axios.get(`api/chats/${id}/messages/seen`, {})
  }
}

export const chatsUsersAPI = new ChatsUsersAPI()
