import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FavoriteControl } from '@/features/babysitter/components/BabysitterActionContols'
import { useBabysitterData } from '@/features/babysitter/hooks'
import { babysitterData } from '@/features/babysitter/store'

export const Favorite = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { babysitter } = useBabysitterData()

  const onSuccess = (data: any) => {
    const { status } = data
    dispatch(babysitterData.updateData({ favorite_babysitter: status }))
  }

  return (
    <>
      <FavoriteControl
        id={babysitter?.id}
        status={!!babysitter?.favorite_babysitter}
        onSuccess={onSuccess}
      />
    </>
  )
}
