import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { FavoritesBabysittersContainer } from '@/features/babysitter/components/Babysitters/FavoritesBabysitters'

export const Favorites = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'favorites'} />

      <FavoritesBabysittersContainer />
    </>
  )
}
