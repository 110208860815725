import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  const icon = getRef('icon')
  return {
    link: {
      ...theme.fn.focusStyles(),
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: theme.black,
      padding: `${theme.spacing.sm}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.md,
      fontWeight: 700,

      '&:not(:last-child)': {
        marginBottom: theme.spacing.sm,
      },

      '&:hover': {
        color: theme.colors[theme.primaryColor][theme.fn.primaryShade()],

        // colored svg path with stroke
        [`& .${icon} path`]: {
          stroke: theme.colors[theme.primaryColor][theme.fn.primaryShade()],
        },
      },
    },

    linkIcon: {
      ref: icon,
    },
    text: {
      marginLeft: theme.spacing.sm,
    },
  }
})
