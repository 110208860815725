import React from 'react'
import { useTranslation } from 'react-i18next'
import { Title } from '@mantine/core'
import { InfoStack } from '@/components/Elements'
import { LocationAreaDisplay } from '@/features/location/components/Elements'
import { ClientProfile } from '@/features/user'

//import { cityHelper } from '@/helpers/city'
//import useStyles from './LocationBlock.styles'

//const isCityExtraOption = cityHelper.general.isCityExtraOption

interface IProps {
  data: ClientProfile | null | undefined
}

type InfoItem = {
  label: string
  value: React.ReactNode
}

export const LocationBlock = ({ data }: IProps) => {
  const { t } = useTranslation()

  //const { classes } = useStyles()

  const { city, city_type, city_description, country, address, zip } = data as ClientProfile

  const info: Array<InfoItem> = [
    {
      label: t('location'),
      value: <LocationAreaDisplay data={{ city, city_type, city_description, country }} />,
    },
    {
      label: t('address'),
      value: address,
    },
    {
      label: t('postal_code'),
      value: zip,
    },
  ]

  return (
    <>
      <div>
        <Title order={5}>{t('location')}</Title>

        <InfoStack items={info} className={'my-3'} />
      </div>
    </>
  )
}
