import React from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from '@mantine/core'
import { DateUtils } from '@/utils'
import useStyles from './TimeInput.styles'

interface IProps {
  date: string
}

export const TimeInput = ({ date }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <Input
      size={'xs'}
      value={date ? DateUtils.dateToTime(date) : ''}
      readOnly
      classNames={classes}
    />
  )
}
