import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { TimeInputController } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'

export const TimePeriodFormSection = () => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()
  const { control } = useFormContext()

  return (
    <>
      <Grid>
        <Grid.Col span={mobileView ? 6 : 4}>
          <TimeInputController
            control={control}
            name={`timetable.time_start`}
            id={`timetable.time_start`}
            label={t('from')}
            size={mobileView ? 'md' : 'lg'}
            clearable
            mb={'md'}
            styles={
              mobileView
                ? {
                    label: {
                      fontWeight: 'bold',
                      fontSize: '16px !important',
                    },
                  }
                : {}
            }
          />
        </Grid.Col>

        <Grid.Col span={mobileView ? 6 : 4}>
          <TimeInputController
            control={control}
            name={`timetable.time_end`}
            id={`timetable.time_end`}
            label={t('to')}
            clearable
            size={mobileView ? 'md' : 'lg'}
            mb={'md'}
            className={mobileView ? 'ml-2' : ''}
            styles={
              mobileView
                ? {
                    label: {
                      fontWeight: 'bold',
                      fontSize: '16px !important',
                    },
                  }
                : {}
            }
          />
        </Grid.Col>
      </Grid>
    </>
  )
}
