import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { DataShower } from '@/components/Elements'
import { bookingTrackingEditData } from '@/features/booking-tracking/store'
import { useAppSelector } from '@/store'

interface IProps {
  id: number
  children: React.ReactNode
}

export const BookingTrackingDataLoader = ({ id, children }: IProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(bookingTrackingEditData.getData({ id }))

    return () => {
      dispatch(bookingTrackingEditData.cleanState())
    }
  }, [])

  const { data, loading, error } = useAppSelector((state) => state.bookingTracking.edit.data)

  return (
    <DataShower isLoading={loading} isFetched={!!data} isFailed={!!error} error={error}>
      {children}
    </DataShower>
  )
}
