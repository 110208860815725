import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Center, Text } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { SegmentedControlController } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { useBookingOptions } from '@/features/bookings/hooks'

export const TypeFormSection = () => {
  const { t } = useTranslation()

  const { control, watch } = useFormContext()

  const { getType } = useBookingOptions()
  const {
    appState: { mobileView },
  } = useAppState()

  const options = [
    {
      label: (
        <Center>
          <Icon size={mobileView ? 16 : 20} name={getType(BOOKING_TYPE.ONETIME)?.iconName} />
          <Text size={mobileView ? 16 : 20} ml={mobileView ? 'xs' : 'md'}>
            {getType(BOOKING_TYPE.ONETIME)?.label}
          </Text>
        </Center>
      ),
      value: BOOKING_TYPE.ONETIME,
    },
    {
      label: (
        <Center>
          <Icon size={mobileView ? 16 : 20} name={getType(BOOKING_TYPE.REGULAR)?.iconName} />
          <Text size={mobileView ? 16 : 20} ml={mobileView ? 'xs' : 'md'}>
            {getType(BOOKING_TYPE.REGULAR)?.label}
          </Text>
        </Center>
      ),
      value: BOOKING_TYPE.REGULAR,
    },
  ]

  return (
    <div className="flex flex-col">
      <label className="mantine-InputWrapper-label mantine-Textarea-label phone-widget-title mt-3 mb-1">
        {t('kind_of_care')}
      </label>

      <SegmentedControlController
        label={t('kind_of_care')}
        data={options}
        control={control}
        name={'type'}
        mb={'md'}
        innerTitle={false}
      />
    </div>
  )
}
