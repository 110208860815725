import React from 'react'
import { Calendar as MantineCalendar, CalendarProps as MantineCalendarProps } from '@mantine/dates'

// eslint-disable-next-line
export interface CalendarProps extends MantineCalendarProps<boolean> {}

export const Calendar: React.FC<CalendarProps> = (props) => {
  return (
    <>
      <MantineCalendar {...props} />
    </>
  )
}
