import axios from 'axios'
import { SubmitClientRatingDTO } from './dto'

class ClientRatingAPI {
  updateRating = async (id: number, data: SubmitClientRatingDTO) => {
    return axios.put(`api/client-ratings/${id}`, data)
  }

  deleteRating = async (id: number) => {
    return axios.delete(`api/client-ratings/${id}`)
  }
}

export const clientRatingAPI = new ClientRatingAPI()
