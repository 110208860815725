import React from 'react'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { FieldError } from '@/components/FormElements'
import { WeekDaysPicker, WeekDaysPickerProps } from '@/features/booking/components/Controls'

interface WeekDaysPickerControllerProps<T extends FieldValues> extends WeekDaysPickerProps {
  control: Control<T, any>
  name: FieldPath<T>
  shouldUnregister?: boolean
  defaultValue?: any
  meta?: any
}

export const WeekDaysPickerController = <T extends FieldValues>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  ...rest
}: WeekDaysPickerControllerProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field: { ref, ...field }, fieldState, formState }) => {
        const meta = {
          error: fieldState.error,
        }

        return (
          <>
            <WeekDaysPicker {...field} {...rest} />

            <div className={'my-3'}>
              <FieldError error={meta?.error} size={'lg'} />
            </div>
          </>
        )
      }}
    />
  )
}
