import { useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import { useEvents } from '@/features/app/hooks'
import { useAuth } from '@/features/auth'
import { useUser } from '@/features/user'
import { getRootRoutes } from '@/routes'

export const RootNavigator = () => {
  const { isLoggedIn } = useAuth()
  const event = useEvents()

  const { user } = useUser()

  useEffect(() => {
    event.actions.appLoaded({})
  }, [])

  return useRoutes(getRootRoutes(isLoggedIn, user))
}
