import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CheckboxFieldController } from '@/components/FormElements'

type FormValues = {
  timetable: {
    same_weeks_schedule: false
    same_days_time: false
  }
}

export const ConfigFormSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext<FormValues>()

  return (
    <div className={'my-3'}>
      <CheckboxFieldController
        control={control}
        name={'timetable.same_weeks_schedule'}
        id={'timetable.same_weeks_schedule'}
        label={t('same_schedule_for_all_weeks')}
        mb={'md'}
      />

      <CheckboxFieldController
        control={control}
        name={'timetable.same_days_time'}
        id={'timetable.same_days_time'}
        label={t('same_time_for_all_days')}
        mb={'md'}
      />
    </div>
  )
}
