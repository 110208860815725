import React from 'react'
import { ModalProps as MantineModalProps } from '@mantine/core'
import { GeneralDrawer } from '@/components/Elements'
import { MobileFullModal } from '@/components/Elements/Modals/MobileFullModal'
import { useAppState } from '@/features/app/hooks'

export interface ModalDrawerProps extends MantineModalProps {
  opened: boolean
  onClose: () => void
  headerBorder?: boolean
  header?: React.ReactNode
  leftIcon?: string
  title: string
  children: React.ReactNode
}

export const ModalOrDrawerSwitch = ({
  opened,
  onClose,
  header,
  leftIcon = 'arrow',
  headerBorder = false,
  title,
  children,
  ...props
}: ModalDrawerProps) => {
  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <>
      {mobileView ? (
        <MobileFullModal
          header={header}
          opened={opened}
          onClose={onClose}
          leftIcon={leftIcon}
          headerBorder={headerBorder}
          title={title}
          className={'mt-10'}
        >
          <div className={'p-4'}>{children}</div>
        </MobileFullModal>
      ) : (
        <GeneralDrawer opened={opened} onClose={onClose} title={title}>
          {children}
        </GeneralDrawer>
      )}
    </>
  )
}
