import _ from 'lodash'
import { CITY_TYPE } from '@/const/city'
import i18n from '@/i18n'

type CityOptionType = {
  label: string
  value: CITY_TYPE
}

export const getExtraCityOptions = () => {
  return [
    {
      label: i18n.t('other_areas_in_portugal'),
      value: CITY_TYPE.OTHER,
    },
    {
      label: i18n.t('internacional'),
      value: CITY_TYPE.INTERNACIONAL,
    },
  ]
}

export const getExtraCity = (value: string | null): CityOptionType | any => {
  const options = getExtraCityOptions()
  return _.find(options, { value })
}

export const isDefaultCity = (cityType: string) => cityType && cityType === CITY_TYPE.DEFAULT

export const isCityExtraOption = (cityType: string | any) => {
  return [CITY_TYPE.OTHER, CITY_TYPE.INTERNACIONAL].includes(cityType)
}

export const isInternational = (cityType: string | any) => {
  return cityType === CITY_TYPE.INTERNACIONAL
}
