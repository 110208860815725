import { all, fork } from 'redux-saga/effects'
import { authSaga } from '@/features/auth/store/sagas'
import { babysitterSaga } from '@/features/babysitter/store/sagas'
import { bookingCandidateSaga } from '@/features/booking-candidate/store/sagas'
import { bookingServiceSaga } from '@/features/booking-service/store/sagas'
import { bookingTrackingSaga } from '@/features/booking-tracking/store/sagas'
import { bookingSaga } from '@/features/booking/store/sagas'
import { bookingsSaga } from '@/features/bookings/store/sagas'
import { chatSaga } from '@/features/chat/store/sagas'
import { panelSaga } from '@/features/panel/store/sagas'
import { profileSaga } from '@/features/profile/store/sagas'
import { ratingSaga } from '@/features/rating/store/sagas'
//import { staticSaga } from '@/features/static/store/sagas'
import { userSaga } from '@/features/user/store/sagas'
import { usersSaga } from '@/features/users/store/sagas'

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(userSaga),
    fork(panelSaga),
    //fork(staticSaga),
    fork(profileSaga),
    fork(bookingSaga),
    fork(bookingsSaga),
    fork(babysitterSaga),
    fork(usersSaga),
    fork(bookingCandidateSaga),
    fork(bookingServiceSaga),
    fork(bookingTrackingSaga),
    fork(chatSaga),
    fork(ratingSaga),
  ])
}
