export enum TAG_MANAGER_EVENT {
  REGISTER = 'register',
  BOOKING_WITHOUT_LOGIN = 'booking_without_login',
  NEW_BOOKING = 'newbooking',
  CARE_DETAILS = 'care_details',
  BEFORE_CHECKOUT = 'before_checkout',
  SUBMISSIONS_REGULAR = 'submissions_regular',
  SUBMISSIONS_ENTERTAINMENT = 'submissions_entertainment',
  SUBMISSIONS_NANNY = 'submissions_permanent_nanny',
  PURCHASE = 'purchase',

  PURCHASE_OCASIONAL = 'ocasional',
  PURCHASE_REGULAR = 'regular',

  //
  LOGOUT = 'logout',
  BOOK_NOW = 'book_now',
  BOOK_ONETIME = 'book_onetime',
  BOOK_REGULAR = 'book_regular',
  BOOK_NANNY = 'book_nanny',
  BOOK_PARTY = 'book_party',
  BOOKING_UPCOMING = 'booking_upcoming',
  BOOKING_POTENTIAL = 'booking_potential',
  BOOKING_HISTORY = 'booking_history',
  BOOKING_CALENDAR = 'booking_calendar',
  BOOKING_VIEW_DETAILS = 'booking_view_details',
  BOOKING_VIEW_TRACKER = 'booking_view_tracker',
  BOOKING_VIEW_INVOICES = 'booking_view_invoices',
  BOOKING_VIEW_SCHEDULE = 'booking_view_schedule',
  CHAT_VIEW = 'chat_view',
  CHAT_OPEN = 'chat_open',
  WALLET_VIEW = 'wallet_view',
  WALLET_DETAILS_EDIT = 'wallet_details_edit',
  PROFILE_VIEW = 'profile_view',
  PROFILE_EDIT = 'profile_edit',
  PROFILE_SETTINGS = 'profile_settings',
}
