import classNames from 'classnames'
import React from 'react'
import { useAppState } from '@/features/app/hooks'
import { BookingDay } from '@/features/bookings/types/models'
import { DateUtils } from '@/utils'
import { TimeInput } from './TimeInput'

interface IProps {
  data: BookingDay
}

export const TimePeriod = ({ data }: IProps) => {
  const {
    appState: { mobileView },
  } = useAppState()
  const { date_start, date_end } = data

  return (
    <div
      className={classNames('flex', {
        'w-100': mobileView,
        'justify-end': mobileView,
      })}
    >
      <div
        className={classNames('flex', {
          'items-center': !mobileView,
        })}
      >
        <>
          {' '}
          {mobileView ? (
            <>
              <span>{date_start ? DateUtils.dateToTime(date_start) : ''}</span>
              <span className={'mx-2'}>{' - '}</span>
              <span>{date_end ? DateUtils.dateToTime(date_end) : ''}</span>
            </>
          ) : (
            <>
              <TimeInput date={date_start} />
              <span className={'mx-2'}>{' - '}</span>
              <TimeInput date={date_end} />
            </>
          )}
        </>
      </div>
    </div>
  )
}
