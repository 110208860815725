import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { bookingAPI } from '@/features/bookings/api'
import i18n from '@/i18n'
import { RequestUtils } from '@/utils'
import { bookingsInvoiceList } from './slice'

function* getList({ payload: { id, params } }: PayloadAction<{ id: number; params: any }>) {
  try {
    const requestParams: any = RequestUtils.getMapRequestParams(params)

    const response: AxiosResponse = yield call(bookingAPI.getInvoices, id, requestParams)

    const { data } = response

    const {
      meta: { current_page, last_page },
    } = data

    if (current_page && last_page && current_page > last_page) {
      yield put(bookingsInvoiceList.setPage(last_page))
    } else {
      yield put(bookingsInvoiceList.getListSuccess(data))
    }
  } catch (error: any) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(bookingsInvoiceList.getListError(message))
  }
}

function* watchGetList() {
  yield takeLatest(bookingsInvoiceList.getList, getList)
}

export function* listSaga() {
  yield all([fork(watchGetList)])
}
