import React from 'react'
import { Link } from 'react-router-dom'
import { ButtonProps, Button as MantineButton } from '@mantine/core'

interface IProps extends ButtonProps {
  children: React.ReactNode
  className?: string
  to: string

  [x: string]: any
}

export const ButtonLink: React.FC<IProps> = ({ children, className, to, ...props }) => {
  return (
    <MantineButton component={Link} to={to} {...props}>
      {children}
    </MantineButton>
  )
}
