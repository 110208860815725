import * as React from 'react'
import { Navbar as MantineNavbar } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import { NavbarContent } from './NavbarContent'
import { NavbarDrawer } from './NavbarDrawer'

interface IProps {
  drawerOpened: boolean
  onDrawerClose: () => void
}

export const Navbar = ({ drawerOpened, onDrawerClose }: IProps) => {
  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <>
      <MantineNavbar
        height={'100vh'}
        width={{ base: mobileView ? 0 : 300 }}
        p="xl"
        hiddenBreakpoint={'md'}
        hidden={true}
        styles={(theme) => ({
          root: {
            backgroundColor: theme.colors.mint[0],
            border: 'none',
          },
        })}
      >
        <NavbarContent />
      </MantineNavbar>

      <NavbarDrawer opened={drawerOpened} onClose={onDrawerClose} />
    </>
  )
}
