import React from 'react'
import { useTranslation } from 'react-i18next'
import { bookingCandidateHireSubmitPA } from '@/features/booking-candidate/store'
import { BookingCandidate } from '@/features/booking-candidate/types/models'
import { useAsyncDispatch, useNotify } from '@/hooks'
import { CandidateHireContent } from './CandidateHireContent'

interface IProps {
  candidate: BookingCandidate
  onSuccess?: (data: object) => void
  onCancel: () => void
}

export const CandidateHireContainer = ({ candidate, onSuccess, onCancel }: IProps) => {
  const { id } = candidate

  const { showNotification } = useNotify()
  const { t } = useTranslation()

  const { loading, onAsyncDispatch } = useAsyncDispatch()

  const onSubmit = async () => {
    try {
      const data = await onAsyncDispatch(bookingCandidateHireSubmitPA.request, { id })

      onSuccess && onSuccess({ ...data })

      showNotification({
        type: 'success',
        message: t('booked_successfully'),
      })
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return (
    <CandidateHireContent
      candidate={candidate}
      loading={loading}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  )
}
