import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'
import { AvatarProvider, Button } from '@/components/Elements'
import { BookingCandidate } from '@/features/booking-candidate/types/models'
import { useUsersModel } from '@/features/users/hooks'

interface IProps {
  candidate: BookingCandidate
  loading: boolean
  onSubmit: () => void
  onCancel: () => void
}

export const CandidateHireContent = ({ candidate, loading, onSubmit, onCancel }: IProps) => {
  const { t } = useTranslation()

  const { getName } = useUsersModel()

  const { babysitter } = candidate

  const { avatar } = babysitter

  return (
    <div className={'flex flex-col items-center'}>
      <Text size={'sm'}>{t('please_confirm_hire_babysitter')}</Text>

      <div className={'mt-4 flex flex-col items-center'}>
        <AvatarProvider avatar={avatar} size={32} />

        <Text weight={600} className={'mt-2'}>
          {getName(babysitter)}
        </Text>
      </div>

      <div className={'mt-1 w-full'}>
        <Button mt={'lg'} fullWidth loading={loading} onClick={onSubmit}>
          {t('book_a_babysitter')}
        </Button>

        <Button mt={'lg'} fullWidth variant="subtle" color="gray" onClick={onCancel}>
          {t('cancel')}
        </Button>
      </div>
    </div>
  )
}
