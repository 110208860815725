//import { CITY_TYPE } from '@/const/city'

type MapLocationParams = {
  //city_id?: string
  //city_type?: CITY_TYPE
  //city_description?: null | string
  //country_id?: string | null
  //address?: null | string
  zip?: null | string
  is_hotel?: boolean
  hotel_name?: null | string
  hotel_id?: number | string | null
}

export const useBookingLocation = () => {
  //const { getCity, getCountry } = useSelectOptions()

  const mapLocationToGeocodeParams = ({
    //city_id,
    //city_type,
    //city_description,
    //country_id,
    //address,
    zip,
    is_hotel,
    hotel_id,
    hotel_name,
  }: MapLocationParams) => {
    const addressResult = []
    /*let country = 'PT'

    if (is_hotel && hotel_name) {
      addressResult.push(hotel_name)
    }

    if (address) {
      addressResult.push(address)
    }

    if (zip) {
      addressResult.push(zip)
    }

    if (city_id) {
      const isCustomCity = cityHelper.general.isCityExtraOption(city_id)

      let city
      if (isCustomCity) {
        city = city_description
      } else {
        city = getCity(city_id)?.label || null
      }

      if (city) {
        addressResult.push(city)
      }

      const isInternational = cityHelper.general.isInternational(city_id)
      if (isInternational && country_id) {
        country = getCountry(country_id)?.code || null
      }
    }*/

    return {
      // address: addressResult.join(' '),
      address: '',
      components: {
        // country: country,
        postal_code: zip,
      },
    }
  }

  return {
    mapLocationToGeocodeParams,
  }
}
