import { useBookingPermissions } from '@/features/bookings/hooks'
import { BookingModel, BookingService } from '@/features/bookings/types/models'
import { RatingEntryPopupManagerControl } from '@/features/rating/components/RatingEntryPopupManager'
import { RatingSubmittedActionMeta } from '@/features/rating/hooks/useRatingEntryPopupModule'
import { ClientRatingModel, RatingModel } from '@/features/rating/types/models'

interface IProps {
  showAsTable: boolean
  bookingService: BookingService | BookingModel
  onRatingSubmitted: (rating: any, actionMeta: any) => void
}

export const RatingControl = ({
  showAsTable = false,
  bookingService,
  onRatingSubmitted,
}: IProps) => {
  const { isRatingManageAllow } = useBookingPermissions()

  const { client_rating } = bookingService

  const onHandleRatingSubmitted = (
    rating: ClientRatingModel | RatingModel,
    actionMeta: RatingSubmittedActionMeta
  ) => {
    actionMeta = {
      ...actionMeta,
      payload: {
        bookingServiceId: bookingService?.id,
      },
    }
    onRatingSubmitted(rating, actionMeta)
  }

  return (
    <RatingEntryPopupManagerControl
      showAsTable={showAsTable}
      booking={bookingService}
      value={client_rating?.value}
      disabled={!isRatingManageAllow(bookingService, undefined)}
      ratingControlProps={{ size: 'lg' }}
      onRatingSubmitted={onHandleRatingSubmitted}
    />
  )
}
