import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Input,
  SegmentedControl as MantineSegmentedControl,
  SegmentedControlProps as MantineSegmentedControlProps,
} from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import { FormUtils } from '@/utils'

export interface SegmentedControlProps extends MantineSegmentedControlProps {
  label?: React.ReactNode
  fieldRef?: any
  normalize?: (value: string) => string
  translateParams?: object
  innerTitle?: boolean
  meta?: any
}

export const SegmentedControl = ({
  label,
  normalize,
  meta,
  fieldRef,
  translateParams,
  innerTitle = false,
  ...props
}: SegmentedControlProps) => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()

  const { error } = meta

  const showError = Boolean(error)

  const getErrorMessage = () => {
    return FormUtils.getErrorMessage(error, {
      t,
      translateParams: translateParams,
    })
  }

  return (
    <>
      {innerTitle ? (
        <Input.Wrapper
          label={label}
          error={showError ? getErrorMessage() : null}
          styles={
            mobileView
              ? {
                  label: {
                    fontWeight: 'bold',
                    fontSize: '16px !important',
                  },
                }
              : {}
          }
        >
          <div>
            <MantineSegmentedControl {...props} />
          </div>
        </Input.Wrapper>
      ) : (
        <MantineSegmentedControl {...props} />
      )}
    </>
  )
}
