import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Alert, Button } from '@/components/Elements'
import { useFormSubmit, useNotify } from '@/hooks'
import { DateUtils } from '@/utils'
import { DatePeriodFormSection } from './DatePeriodFormSection'
import { validationSchema } from './validation'

interface IProps {
  onSubmit: (values: object) => Promise<void>
  onCancel: () => void
  initialValues?: any
}

export const TimeEntryForm = ({ onCancel, initialValues = {}, ...props }: IProps) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()
  const defaultValues = {
    date_start: null,
    time_start: null,
    date_end: null,
    time_end: null,
    ...initialValues,
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      const { date_start, time_start, date_end, time_end } = data

      const mapDate = (date: Date, time: Date) => {
        const result = DateUtils.transformDateAndTimeDateToDate(date, time)
        return DateUtils.formatDateToServerDateTimeFormat(result)
      }

      const values = {
        date_start: mapDate(date_start, time_start),
        date_end: mapDate(date_end, time_end),
      }

      await onFormSubmit(values)
    } catch (error) {
      showNotification({
        type: 'error',
        message: t('error_submitting_request'),
      })
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.message || t('error')}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <DatePeriodFormSection />

          <div className={'mt-1 w-full'}>
            <Button mt={'lg'} fullWidth loading={isSubmitting} type={'submit'}>
              {t('save')}
            </Button>

            <Button mt={'lg'} fullWidth variant="subtle" color="gray" onClick={onCancel}>
              {t('cancel')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  )
}
