import React, { useEffect } from 'react'
import { MegaTagTitle } from '@/components/Elements'
import { useEvents } from '@/features/app/hooks'
import { useHeaderContext } from '@/features/header/contexts/HeaderContext'
import {
  BabysitterProfileDetails,
  ClientProfileDetails,
} from '@/features/profile/components/ProfileDetails'
import { TAG_MANAGER_EVENT } from '@/features/tag-manager/consts/event'
import { useUser } from '@/features/user'

export const Profile = () => {
  const { setKey } = useHeaderContext()

  const event = useEvents()
  const { isBabysitter } = useUser()

  useEffect(() => {
    setKey({ base: true, title: 'profile', border: true })
    event.actions.generic(TAG_MANAGER_EVENT.PROFILE_VIEW)
  }, [])
  //dispatch(appState.pageTitle('profile'))

  return (
    <>
      <MegaTagTitle title={'profile'} />

      {isBabysitter() ? <BabysitterProfileDetails /> : <ClientProfileDetails />}
    </>
  )
}
