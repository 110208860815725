import axios from 'axios'

class BookingServicesAPI {
  getBookingServices = async (params: any) => {
    return axios.get(`api/babysitter-bookings`, {
      params,
    })
  }
}

export const bookingServicesAPI = new BookingServicesAPI()
