import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CommonStepper, Icon } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import useStyles from './Stepper.styles'

interface IProps {
  step: number
  setStep: (step: number) => void
}

export const Stepper = ({ step, setStep }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()
  const {
    appState: { mobileView },
  } = useAppState()

  const iconSize = mobileView ? 20 : 32

  const steps = [
    {
      label: t('general_details'),
      icon: <Icon name={'info'} size={iconSize} viewPort={32} />,
    },
    {
      label: t('language_and_skills'),
      icon: <Icon name={'globe-stand'} size={iconSize} viewPort={32} />,
    },
    {
      label: t('experience'),
      icon: <Icon name={'suitcase-simple'} size={iconSize} viewPort={32} />,
    },
    {
      label: t('certifications_and_degrees'),
      icon: <Icon name={'medal'} size={iconSize} viewPort={32} />,
    },
  ]

  return (
    <div className={classes.wrapper}>
      <CommonStepper steps={steps} active={step} setStep={setStep} />
    </div>
  )
}
