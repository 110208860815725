import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useBookingDrawerModuleContext } from '@/features/bookings/contexts'
import { useBookingPermissions } from '@/features/bookings/hooks'
import { bookingsData } from '@/features/bookings/store'
import { BookingModel } from '@/features/bookings/types/models'
import { RatingEntryPopupManagerControl } from '@/features/rating/components/RatingEntryPopupManager'
import { RatingSubmittedActionMeta } from '@/features/rating/hooks/useRatingEntryPopupModule'
import {
  BabysitterRatingModel,
  ClientRatingModel,
  RatingModel,
} from '@/features/rating/types/models'
import { BabysitterUser, useUser } from '@/features/user'

interface IProps {
  booking: BookingModel
  babysitter?: BabysitterUser
}

export const RatingControl = ({ booking, babysitter }: IProps) => {
  const dispatch = useDispatch()

  const { isBabysitter, isClient } = useUser()

  const { isRatingManageAllow } = useBookingPermissions()

  const { client_rating } = booking

  const { onRatingSubmitted } = useBookingDrawerModuleContext()

  const onHandleRatingSubmitted = (
    rating: ClientRatingModel | RatingModel,
    actionMeta: RatingSubmittedActionMeta
  ) => {
    if (isBabysitter()) {
      dispatch(bookingsData.setClientRating(rating))
    } else {
      dispatch(bookingsData.setBabysitterRating(rating))
    }

    actionMeta = {
      ...actionMeta,
      payload: {
        bookingId: booking?.id,
      },
    }

    onRatingSubmitted && onRatingSubmitted(rating, actionMeta)
  }

  const personRating = useMemo(() => {
    if (isClient()) {
      if (booking.rating) {
        const result = booking.rating.babysitter_ratings.filter(
          (item) => item.babysitter.id === babysitter?.id
        ) as BabysitterRatingModel[]
        if (result && result.length > 0) return result[0].person
      }
      return undefined
    } else {
      return client_rating?.value
    }
  }, [booking?.rating?.babysitter_ratings, client_rating, isClient()])

  return (
    <RatingEntryPopupManagerControl
      booking={booking}
      value={personRating}
      disabled={!isRatingManageAllow(booking, babysitter)}
      ratingControlProps={{ size: 'lg' }}
      onRatingSubmitted={onHandleRatingSubmitted}
    />
  )
}
