import React from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import { MegaTagTitle } from '@/components/Elements'
import { Layout } from '@/features/profile/components/CompleteProfile'
import { useUser } from '@/features/user'

export const ProfileComplete = () => {
  const { t } = useTranslation()

  const { isProfileCompleteAllowed } = useUser()

  if (isProfileCompleteAllowed()) {
    return <Navigate to={'/profile'} />
  }

  return (
    <>
      <MegaTagTitle title={'complete_profile'} />

      <Layout />
    </>
  )
}
