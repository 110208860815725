import React from 'react'
import logo from '@/assets/images/logo/logo.png'
import booking from '@/assets/images/logo/logoBooking.png'

type SizeType = 'sm'

type AttrsType = {
  height?: string
}

interface IProps {
  size?: SizeType | undefined
  bookingLogo?: boolean
}

export const Logo = ({ size, bookingLogo = false, ...props }: IProps) => {
  let attrs: AttrsType = {}

  if (size === 'sm') {
    attrs = {
      ...attrs,
      height: '50px',
    }
  }

  return <img src={bookingLogo ? booking : logo} alt={'logo'} {...attrs} {...props} />
}
