import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import {
  CheckboxFieldController,
  PhoneInputController,
  TextAreaController,
} from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'

type FormValues = {
  has_special_needs: boolean
  special_needs: string
  //other_info: string
  care_expectations: string
  phone: string
}

export const InfoFormSection = () => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()

  const { control, watch } = useFormContext<FormValues>()

  const watchHasSpecialNeeds = watch('has_special_needs')

  return (
    <>
      <CheckboxFieldController
        control={control}
        name={'has_special_needs'}
        id={'has_special_needs'}
        label={t('my_kid_has_special_needs')}
        size={mobileView ? 'md' : 'lg'}
        mb={'md'}
      />

      {watchHasSpecialNeeds && (
        <TextAreaController
          control={control}
          name={'special_needs'}
          id={'special_needs'}
          label={t('special_needs')}
          size={mobileView ? 'md' : 'lg'}
          placeholder={t('special_needs-field.placeholder')}
          description={t('special_needs-field.description')}
          mb={'md'}
          styles={
            mobileView
              ? {
                  label: {
                    fontWeight: 'bold',
                    fontSize: '16px !important',
                  },
                }
              : {}
          }
        />
      )}

      {/*<TextAreaController
        control={control}
        name={'other_info'}
        id={'other_info'}
        label={t('other_requirements_info')}
        size={mobileView ? 'md' : 'lg'}
        placeholder={t('booking.other_requirements_info-field.placeholder')}
        mb={'md'}
        styles={
          mobileView
            ? {
                label: {
                  fontWeight: 'bold',
                  fontSize: '16px !important',
                },
              }
            : {}
        }
      />*/}

      <TextAreaController
        control={control}
        name={'care_expectations'}
        id={'care_expectations'}
        label={t('expectations_for_the_care')}
        size={mobileView ? 'md' : 'lg'}
        placeholder={t('booking.expectations_for_the_care-field.placeholder')}
        mb={'md'}
        styles={
          mobileView
            ? {
                label: {
                  fontWeight: 'bold',
                  fontSize: '16px !important',
                },
              }
            : {}
        }
      />
      <Grid>
        <Grid.Col md={6}>
          <PhoneInputController
            control={control}
            //trigger={trigger}
            name={'phone'}
            id={'phone'}
            label={t('phone')}
            size={mobileView ? 'md' : 'lg'}
            placeholder={t('phone')}
            required={true}
            mb={'md'}
          />
        </Grid.Col>
      </Grid>
    </>
  )
}
