import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { FormTypes } from '@/types'
import { authAPI } from '../../api'
import { ResetPasswordDTO } from '../../api/dto'
import {
  AUTH_RESET_PASSWORD_FAILED,
  AUTH_RESET_PASSWORD_REQUEST,
  AUTH_RESET_PASSWORD_SUCCESS,
} from './actionTypes'

export const authResetPasswordPA = createPromiseAction(
  AUTH_RESET_PASSWORD_REQUEST,
  AUTH_RESET_PASSWORD_SUCCESS,
  AUTH_RESET_PASSWORD_FAILED
)<ResetPasswordDTO, { message: string }, AxiosError<FormTypes.ValidationErrors>>()

function* worker(action: PromiseAction<string, ResetPasswordDTO, any>) {
  try {
    const response: AxiosResponse = yield call(authAPI.resetPassword, action.payload)
    const { data } = response

    yield put(authResetPasswordPA.success({ ...data }))
    resolvePromiseAction(action, { ...data })
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* resetPasswordSaga() {
  yield takeEvery(authResetPasswordPA.request, worker)
}
