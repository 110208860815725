import moment from 'moment'
import { Nullable } from 'primereact/ts-helpers'
import { useEffect, useState } from 'react'
import { calendarsAPI } from '@/features/calendar/api'
import { WalletInfo } from '@/features/wallet/components'

export const CheckInView = () => {
  const [events, setEvents] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(10)
  const [sortField, setSortField] = useState<any>(null)
  const [sortOrder, setSortOrder] = useState<any>(null)
  const [loading, setLoading] = useState<any>(false)
  const [invoiceAmount, setInvoiceAmount] = useState<any>(0)
  const [dates, setDates] = useState<Nullable<(Date | null)[]>>([
    moment().startOf('month').toDate(),
    moment().endOf('month').toDate(),
  ])

  const getWalletEvents = async (params?: any) => {
    try {
      setLoading(true)
      const { data } = await calendarsAPI.getCheckins(params)
      setEvents(data.data)
      setTotalRecords(data.total)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getInvoiceAmount = async (params?: any) => {
    try {
      const { data } = await calendarsAPI.getInvoiceAmount({})
      setInvoiceAmount(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getWalletEvents({ sortField, sortOrder, first, rows, dates })
  }, [sortField, sortOrder, first, rows, dates])

  useEffect(() => {
    getInvoiceAmount()
  }, [])

  return (
    <div className="flex flex-col gap-8">
      <WalletInfo />
    </div>
  )
}
