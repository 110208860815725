import React from 'react'
import { Center, Stack, Text } from '@mantine/core'
import { Rating } from '@/components/FormElements'

interface IProps {
  value?: number
  ratingProps?: any
  textProps?: any
}

export const RatingStack = ({ value, ratingProps, textProps }: IProps) => {
  return (
    <>
      <Stack spacing={'xs'} align={'center'}>
        {value ? (
          <Text inline weight={600} size={40} {...textProps}>
            {value}
          </Text>
        ) : null}

        <Rating size={'sm'} {...ratingProps} value={value} readOnly />
      </Stack>
    </>
  )
}
