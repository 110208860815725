import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.md,
    padding: '16px',
    display: 'flex',
    backgroundColor: theme.white,
    gap: 0,

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      padding: '12px 16px',
      marginBottom: '8px',
    },
  },

  iconBlock: {
    marginRight: '16px',
  },

  contentBlock: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '13px',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      marginBottom: '0px',
      fontSize: '12px',
    },
  },

  contentTitle: {
    fontWeight: 500,
    marginBottom: theme.spacing.xs,

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      marginBottom: '4px',
      fontSize: '12px',
    },
  },

  contentDate: {
    fontWeight: 400,
    marginBottom: theme.spacing.xs,

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      marginBottom: '4px',
      fontSize: '12px',
    },
  },

  contentInfo: {
    color: theme.colors.gray[6],
    marginBottom: theme.spacing.xs,

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: '12px',
      marginBottom: '4px',
    },
  },

  description: {
    whiteSpace: 'pre-wrap',
  },

  contentFooter: {
    fontWeight: 700,
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: '12px',
    },
  },
}))
