import React from 'react'
import { INITIAL_STATE, SCREEN } from '../consts'
import { CLEAN_STATE, RESET_STATE, SET_SCREEN, SET_SCREEN_DATA, SET_STATE } from './actionTypes'

export interface State {
  screen: SCREEN | null
  screenData: any
}

interface Action {
  type: string
  payload: any
}

export function reducerInit(initialState: State) {
  return {
    ...INITIAL_STATE,
    ...initialState,
  }
}

export function reducer(state: State, action: Action) {
  switch (action.type) {
    case SET_STATE:
      return {
        ...state,
        screen: action.payload.screen,
        screenData: action.payload.screenData,
      }

    case RESET_STATE:
      return reducerInit(action.payload)

    case CLEAN_STATE:
      return INITIAL_STATE

    case SET_SCREEN:
      return {
        ...state,
        screen: action.payload,
      }

    case SET_SCREEN_DATA:
      return {
        ...state,
        screenData: action.payload,
      }

    default:
      return state
  }
}
