import React from 'react'
import { BabysitterUser } from '@/features/user'
import { useUsersModel } from '@/features/users/hooks'

export const useUsersPermissions = () => {
  const { isUserActive } = useUsersModel()

  const isFavoriteAllow = (user: BabysitterUser) => {
    return !!isUserActive(user)
  }

  return {
    isFavoriteAllow,
  }
}
