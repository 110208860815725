import classNames from 'classnames'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Center, Stack, Text, Title } from '@mantine/core'
import { Button } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { getType } from '@/features/bookings/helpers/booking/type'
import useStyles from './Info.styles'

interface IProps {
  bookingType: BOOKING_TYPE
  showInquiry?: boolean
  onClickAction?: () => void
}
export const InfoEvent = ({ bookingType, showInquiry = false, onClickAction }: IProps) => {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()

  const book = () => {
    if (onClickAction) {
      onClickAction()
    } else {
      navigate('/booking/' + getType(bookingType).path + '/new')
    }
  }

  return (
    <>
      <div className={mobileView ? '' : 'scroll-popup'}>
        <div className={classes.infoBackColor}>
          <Stack>
            <Title order={2}>{getType(bookingType).title}</Title>
            <Text>{ReactHtmlParser(getType(bookingType).service)}</Text>
          </Stack>
        </div>
        <div
          className={classNames(classes.imageBackColor, {
            'mb-4': showInquiry,
          })}
        >
          <Center>
            <img src={getType(bookingType).image} className={classes.imageBorder} alt="image" />
          </Center>
        </div>
      </div>
      {showInquiry && (
        <div
          className={classNames('mt-4', 'w-100', {
            'sticky-footer': mobileView,
          })}
        >
          <div className={classes.footer}>
            <Center>
              <Button size="md" onClick={() => book()}>
                {t('book')}
              </Button>
            </Center>
          </div>
        </div>
      )}
    </>
  )
}
