import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { MetaPagination, OverlayDataShower } from '@/components/Elements'
import { BookingDrawerManager } from '@/features/bookings/components/Booking'
import {
  BookingDrawerManagerProvider,
  BookingDrawerModuleProvider,
} from '@/features/bookings/contexts'
import { useBookingDrawerManager } from '@/features/bookings/hooks'
import {
  BookingUpdatedActionMeta,
  useBookingDrawerModule,
} from '@/features/bookings/hooks/useBookingDrawerModule'
import { bookingsBookingsRequestsList } from '@/features/bookings/store'
import { BookingModel, BookingRequest } from '@/features/bookings/types/models'
import { RootState } from '@/store'
import { EmptyData } from './EmptyData'
import { GridView } from './GridView'

interface IProps {
  list: BookingRequest[]
  loading: boolean
  error: any
  meta: any
  page: number
}

const BookingsRequestsContainerComponent = (props: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(bookingsBookingsRequestsList.cleanState())
    }
  }, [])

  useEffect(() => {
    fetchList()
  }, [props.page])

  const fetchList = () => {
    const params = {
      page: props.page,
      sort: [{ id: 'created_at', desc: true }],
    }

    dispatch(bookingsBookingsRequestsList.getList({ params }))
  }

  const onPageChange = (page: number) => {
    dispatch(bookingsBookingsRequestsList.setPage(page))
  }

  const bookingDrawerManager = useBookingDrawerManager({})

  const onBookingView = (id: number) => {
    bookingDrawerManager.navigation.onScreenView({ id })
  }

  const onBabysitterFavoriteSuccess = (data: any) => {
    dispatch(bookingsBookingsRequestsList.updateBabysitter(data))
  }

  const onCandidateHireSuccess = (bookingId: number, candidate: any) => {
    dispatch(bookingsBookingsRequestsList.updateCandidate({ bookingId, candidate }))
  }

  const onBookingUpdated = (booking: BookingModel | null, actionMeta: BookingUpdatedActionMeta) => {
    fetchList()
  }

  const bookingDrawerModule = useBookingDrawerModule({ onBookingUpdated })

  return (
    <>
      <OverlayDataShower isLoading={props.loading} isFailed={!!props.error} error={props.error}>
        {!!props.list.length && (
          <div className={'flex flex-col justify-between h-full'}>
            <GridView
              items={props.list}
              onView={onBookingView}
              onBabysitterFavoriteSuccess={onBabysitterFavoriteSuccess}
              onCandidateHireSuccess={onCandidateHireSuccess}
            />

            <MetaPagination meta={props.meta} page={props.page} onChange={onPageChange} />
          </div>
        )}

        {!props.list.length && !props.loading && !props.error && <EmptyData />}
      </OverlayDataShower>

      <BookingDrawerManagerProvider {...bookingDrawerManager}>
        <BookingDrawerModuleProvider {...bookingDrawerModule}>
          <BookingDrawerManager />
        </BookingDrawerModuleProvider>
      </BookingDrawerManagerProvider>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  const { list, loading, error, meta, page } = state.bookings.bookings.requests.list
  return {
    list,
    loading,
    error,
    meta,
    page,
  }
}

export const BookingsRequestsContainer = connect(mapStateToProps)(
  BookingsRequestsContainerComponent
)
