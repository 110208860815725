import * as React from 'react'
import { Group, Header as MantineHeader } from '@mantine/core'
import { Text } from '@mantine/core'
import useStyles from './MobileHeader.styles'

interface IProps {
  border: boolean
  background?: string
  title: React.ReactNode
  left: React.ReactNode
  right: React.ReactNode
}

export const MobileHeaderModal = ({ border, title, left, right, background = 'white' }: IProps) => {
  const { classes } = useStyles()

  return (
    <MantineHeader
      height={60}
      p="xs"
      styles={(theme) => ({
        root: {
          border: 'none',
          display: 'flex',
          backgroundColor:
            background == 'white'
              ? theme.white
              : background == 'transparent'
              ? 'transparent'
              : theme.colors.primary[theme.fn.primaryShade()],
        },
      })}
      className={border ? classes.border : classes.noborder}
    >
      <Group position="apart" className={'w-100'}>
        <div className={classes.headerLeft}>{left}</div>
        <Text size={16} weight={600}>
          {title}
        </Text>
        <div className={classes.headerRight}>{right}</div>
      </Group>
    </MantineHeader>
  )
}
