import React from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from '@mantine/core'
import { DatesTags } from './DatesTags'
import useStyles from './ExceptionDates.styles'
import { SelectDates } from './SelectDates'

export interface ExceptionDatesProps {
  value?: any
  onChange?: (dates: Date[]) => void
  period?: Date[]
}

export const ExceptionDates = ({ value = [], onChange, period = [] }: ExceptionDatesProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const handleChange = (dates: Date[]) => {
    onChange && onChange(dates)
  }

  const onRemove = (date: Date) => {
    const result = value.filter((item: Date) => item !== date)

    onChange && onChange(result)
  }

  return (
    <>
      {!!value?.length && (
        <div className={'mb-3'}>
          <Input.Label
            size={'lg'}
            mb={8}
            styles={{
              label: {
                fontWeight: 'bold',
                fontSize: '16px !important',
              },
            }}
          >
            {t('exception_dates')}
          </Input.Label>
          <DatesTags value={value} onRemove={onRemove} />
        </div>
      )}

      <SelectDates value={value} onChange={handleChange} period={period} />
    </>
  )
}
