import React from 'react'

interface IProps {
  color: string
  size?: number
  className?: string
}

export const Clock = ({ size = 24, color, ...props }: IProps) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox={`0 0 24 24`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89136 2.75 2.75 6.89136 2.75 12C2.75 17.1086 6.89136 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89136 17.1086 2.75 12 2.75Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0046 5.25C12.4188 5.25004 12.7545 5.58586 12.7545 6.00008L12.7539 11.6938L16.7739 15.7138C17.0668 16.0067 17.0668 16.4815 16.7739 16.7744C16.481 17.0673 16.0062 17.0673 15.7133 16.7744L11.4736 12.5347C11.3329 12.3941 11.2539 12.2033 11.2539 12.0043L11.2545 5.99992C11.2545 5.58571 11.5904 5.24996 12.0046 5.25Z"
      fill={color}
    />
  </svg>
)
