import React from 'react'
import { useTranslation } from 'react-i18next'
import { Title } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import {
  AdditionalInfoFormSection,
  ExperienceAgeFormSection,
} from '@/features/profile/components/FormSections/BabysitterProfileFormSections'

export const ExperienceForm = () => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <div>
      <div className={'mt-2'}>
        <Title order={mobileView ? 5 : 4} mb={'md'}>
          {t('what_age_group_experienced')}
        </Title>
      </div>
      <div className={'mt-2 mb-4 mr-4'}>
        <ExperienceAgeFormSection />
      </div>

      <div className={'mt-4'}>
        <Title order={mobileView ? 5 : 4} mb={'md'}>
          {t('additional_information')}?
        </Title>

        <AdditionalInfoFormSection />
      </div>
    </div>
  )
}
