import React from 'react'
import { useTranslation } from 'react-i18next'
import { ContentCard } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { Header } from './Header'

interface IProps {
  children: React.ReactNode
}

export const ProfileDetailsLayout = ({ children }: IProps) => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <>
      {!mobileView ? (
        <ContentCard title={t('profile')} headerRight={<Header />}>
          {children}
        </ContentCard>
      ) : (
        <div className={'p-4'}>{children}</div>
      )}
    </>
  )
}
