import React from 'react'
import { Logo } from './Logo'

interface IProps {
  type?: string
  bookinLogo?: boolean

  [x: string]: any
}

export const LogoIcon = ({ type, bookingLogo = false, ...props }: IProps) => {
  switch (type) {
    default:
      return <Logo bookingLogo={bookingLogo} {...props} />
  }
}
