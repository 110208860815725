import React from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmModal } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { useBookingFinish } from '@/features/bookings/hooks'
import { BookingModel } from '@/features/bookings/types/models'
import { useConfirmPopup } from '@/hooks'

interface IProps {
  booking: BookingModel
  render: (props: { onFinish: (data?: any) => void; loading: boolean }) => React.ReactNode
  onSuccess?: (data: object) => void
}

export const FinishControl = ({ booking, onSuccess, render }: IProps) => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()
  const { loading, onSubmit } = useBookingFinish()

  const onFinishConfirm = async () => {
    await onSubmit(booking?.id, onSuccess)
  }

  const { visible, open, close, onConfirm } = useConfirmPopup({ confirm: onFinishConfirm })

  return (
    <>
      <div className={mobileView ? 'w-100' : ''}>
        {render({
          onFinish: open,
          loading,
        })}
      </div>

      <ConfirmModal visible={visible} onClose={close} onConfirm={onConfirm} />
    </>
  )
}
