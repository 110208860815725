import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mantine/core'
import { ModalOrDrawerSwitch } from '@/components/Elements/MobileWebSwitchs'
import { BabysitterProfileEdit, ClientProfileEdit } from '@/features/profile/components/ProfileEdit'
import { useUser } from '@/features/user'
import { usePopup } from '@/hooks'

export const EditControl = () => {
  const { t } = useTranslation()

  const { visible, open, close } = usePopup()

  const { isClient } = useUser()

  return (
    <>
      <Button color={'secondary'} onClick={open}>
        {t('edit_profile')}
      </Button>

      <ModalOrDrawerSwitch opened={visible} onClose={close} title={t('edit_profile')}>
        {isClient() ? (
          <ClientProfileEdit onCancel={close} />
        ) : (
          <BabysitterProfileEdit onCancel={close} />
        )}
      </ModalOrDrawerSwitch>
    </>
  )
}
