import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      padding: '0px',
      paddingTop: '16px',
    },
  },
}))
