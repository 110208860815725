import React from 'react'
import { useGeocodingContext } from '../../contexts'

export const useGeocoding = () => {
  const { client } = useGeocodingContext()

  const getGeocode = async (params: {
    address: string
    language?: string
    components?: object | null
  }) => {
    return client.getGeocode(params)
  }

  return {
    getGeocode,
  }
}
