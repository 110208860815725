import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { bookingTrackerAPI } from '@/features/booking-tracking/api'
import { FormTypes } from '@/types'
import {
  BOOKING_TRACKING_REMOVE_FAILED,
  BOOKING_TRACKING_REMOVE_REQUEST,
  BOOKING_TRACKING_REMOVE_SUCCESS,
} from './actionTypes'

type ArgsType = {
  id: number
}

export const bookingTrackingRemovePA = createPromiseAction(
  BOOKING_TRACKING_REMOVE_REQUEST,
  BOOKING_TRACKING_REMOVE_SUCCESS,
  BOOKING_TRACKING_REMOVE_FAILED
)<ArgsType, unknown, AxiosError<FormTypes.ValidationErrors>>()

function* remove(action: PromiseAction<string, ArgsType, any>) {
  try {
    const { id } = action.payload

    const response: AxiosResponse = yield call(bookingTrackerAPI.deleteTracker, id)
    const { data } = response

    yield put(bookingTrackingRemovePA.success({}))

    resolvePromiseAction(action, {})
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* removeSaga() {
  yield takeEvery(bookingTrackingRemovePA.request, remove)
}
