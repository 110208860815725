import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { NavTabs } from '@/components/Elements'

const TABS_KEYS = {
  AVAILABLE: 'available',
  FAVORITES: 'favorites',
}

export const BabysittersTabsLayout = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const location = useLocation()

  const TABS_CONFIG = {
    [TABS_KEYS.AVAILABLE]: {
      label: t('available'),
      value: 'available',
      path: '/babysitters',
    },
    [TABS_KEYS.FAVORITES]: {
      label: t('favorites'),
      value: 'favorites',
      path: '/babysitters/favorites',
    },
  }

  const tabsConfigValues = Object.values(TABS_CONFIG)

  const tabs = useMemo(() => {
    return tabsConfigValues.map(({ label, value }) => ({
      label,
      value,
    }))
  }, [])

  const onTabChange = (value: string) => {
    const tabConfig = TABS_CONFIG[value]

    navigate(`${tabConfig?.path}`)
  }

  const tabsValue = useMemo(() => {
    const pathName = location.pathname

    for (let i = 0; i < tabsConfigValues.length; i++) {
      const item = tabsConfigValues[i]

      if (pathName === item.path) {
        return item.value
      }
    }

    return ''
  }, [location])

  return (
    <>
      <div className={'mb-4'}>
        <NavTabs value={tabsValue} onChange={onTabChange} tabs={tabs} />
      </div>

      <Outlet />
    </>
  )
}
