import axios from 'axios'
import { GetUserDTO } from './dto'

class UserAPI {
  getUser = async (id: number, params: GetUserDTO) => {
    return axios.get(`api/users/${id}`, {
      params,
    })
  }

  addFavoriteBabysitter = async (id: number) => {
    return axios.post(`api/users/${id}/favorite-babysitter`)
  }

  deleteFavoriteBabysitter = async (id: number) => {
    return axios.delete(`api/users/${id}/favorite-babysitter`)
  }
}

export const userAPI = new UserAPI()
