import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { bookingsAPI } from '@/features/bookings/api'
import { GetBookingsDTO } from '@/features/bookings/api/dto'
import { BOOKING_STATUS } from '@/features/bookings/consts/booking'
import i18n from '@/i18n'
import { RequestUtils } from '@/utils'
import { bookingsBookingsRequestsList } from './slice'

const { UNPAID, WAITING_PAYMENT, WAITING_BABYSITTER, BABYSITTER_NOT_FOUND, PAYMENT_LINK_ERROR } =
  BOOKING_STATUS

function* getList({ payload: { params } }: PayloadAction<{ params: GetBookingsDTO }>) {
  try {
    let requestParams: any = {
      includes: ['bookingCandidates', 'bookingOpenStripeInvoice'],
      filters: {
        statuses: [
          UNPAID,
          WAITING_PAYMENT,
          WAITING_BABYSITTER,
          BABYSITTER_NOT_FOUND,
          PAYMENT_LINK_ERROR,
        ],
      },
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response: AxiosResponse = yield call(bookingsAPI.getBookings, requestParams)

    const { data } = response

    const {
      meta: { current_page, last_page },
    } = data

    if (current_page && last_page && current_page > last_page) {
      yield put(
        bookingsBookingsRequestsList.getList({
          params: {
            ...params,
            page: last_page,
          },
        })
      )
    } else {
      yield put(bookingsBookingsRequestsList.getListSuccess(data))
    }
  } catch (error: any) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(bookingsBookingsRequestsList.getListError(message))
  }
}

function* watchGetList() {
  yield takeLatest(bookingsBookingsRequestsList.getList, getList)
}

export function* listSaga() {
  yield all([fork(watchGetList)])
}
