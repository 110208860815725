import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ExperienceAgeStepRangeSliderController } from '@/features/profile/components/FormElements'

export type ExperienceAgeFormSectionValues = {
  experience: Array<number>
}

export const ExperienceAgeFormSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext<ExperienceAgeFormSectionValues>()

  return (
    <>
      <ExperienceAgeStepRangeSliderController control={control} name={'experience'} />
    </>
  )
}
