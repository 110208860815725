import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useAppState } from '@/features/app/hooks'
import { useChatConversationData } from '@/features/chat/hooks'
import { chatConversationData, chatConversationMessagesReadPA } from '@/features/chat/store'
import { commonHelper } from '@/helpers'

export const useChatBackgroundEffect = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const {
    appState: { windowFocus },
  } = useAppState()
  const { conversation, backgroundUnreadMessages } = useChatConversationData()

  // check app in background and received new messages
  useEffect(() => {
    if (backgroundUnreadMessages) {
      if (!windowFocus) {
        commonHelper.utils.setDocumentTitle(t('new_message'))
      } else {
        commonHelper.utils.setDocumentTitle(t('chats'))

        dispatch(chatConversationData.setBackgroundUnreadMessages(false))

        // send read chat request
        dispatch(
          chatConversationMessagesReadPA.request({
            chatId: conversation?.id,
          })
        )
      }
    }
  }, [windowFocus, backgroundUnreadMessages])
}
