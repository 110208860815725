import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  topBar: {
    display: 'flex',
  },

  avatarBlock: {},

  overviewBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: theme.spacing.sm,
  },
}))
