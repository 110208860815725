import * as Sentry from '@sentry/react'
import 'bootstrap/dist/css/bootstrap.min.css'
import $ from 'jquery'
import { PrimeReactProvider } from 'primereact/api'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from '@/App'
import '@/assets/scss/index.scss'
import '@/i18n'
import '@/libs/axios'
import { ErrorBoundaryProvider, GoogleProvider, MantineProvider, StoreProvider } from '@/providers'
// stay for init service
import reportWebVitals from './reportWebVitals' // Making jQuery available globally

;(window as any).jQuery = $
;(window as any).$ = $

Sentry.init({
  dsn: 'https://6cda742b7627e46ebb7d4409ab647cad@o4505993056616448.ingest.us.sentry.io/4507509865381888',
  environment: process.env.REACT_APP_ENV,
  enabled: process.env.NODE_ENV !== 'development',
  debug: process.env.NODE_ENV === 'development',
  integrations: [
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      //maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/app.babysisters\.pt/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const app = (
  <ErrorBoundaryProvider>
    <StoreProvider>
      <MantineProvider>
        <GoogleProvider>
          <BrowserRouter>
            <Suspense fallback="">
              <PrimeReactProvider>
                <App />
              </PrimeReactProvider>
            </Suspense>
          </BrowserRouter>
        </GoogleProvider>
      </MantineProvider>
    </StoreProvider>
  </ErrorBoundaryProvider>
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(app)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
