import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Pagination as MantinePagination,
  PaginationProps as MantinePaginationProps,
} from '@mantine/core'

// eslint-disable-next-line
export interface PaginationProps extends MantinePaginationProps {}

export const Pagination = ({ radius = 'md', ...props }: PaginationProps) => {
  const { t } = useTranslation()

  return (
    <>
      <MantinePagination radius={radius} {...props} />
    </>
  )
}
