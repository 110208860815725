import _ from 'lodash'
import i18n from '@/i18n'

type OptionType = {
  label: string
  maxAge: number | null
  limit: number
}

export const getChildrenGroups = () => {
  return [
    {
      label: i18n.t('booking-children-limit.label-1', { count: 2 }),
      maxAge: 3,
      limit: 2,
    },
    {
      label: i18n.t('booking-children-limit.label-2', { count: 3 }),
      maxAge: 4,
      limit: 3,
    },
    {
      label: i18n.t('booking-children-limit.label-3', { count: 5 }),
      maxAge: null,
      limit: 5,
    },
  ]
}

export const getChildrenGroupByMaxAge = (maxAge: number): OptionType | any => {
  const options = getChildrenGroups()
  return _.find(options, { maxAge })
}
