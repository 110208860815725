import { IconCheck, IconChecks } from '@tabler/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMantineTheme } from '@mantine/core'
import useStyles from './Read.styles'

interface IProps {
  seen: boolean
}

export const Read = ({ seen }: IProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  const theme = useMantineTheme()

  const config = {
    size: 14,
    color: theme.colors.gray[6],
  }

  return <>{seen ? <IconChecks {...config} /> : <IconCheck {...config} />}</>
}
