import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { bookingsTrackingCreatePA } from '@/features/bookings/store'
import { BookingModel } from '@/features/bookings/types/models'
import { useNotify } from '@/hooks'
import { TimeEntryForm } from '../../TimeEntryForm'

interface IProps {
  booking: BookingModel
  onClose: () => void
  onSuccess: () => void
}

export const TimeEntryAddContainer = ({ booking, onClose, onSuccess }: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const onSubmit = async (values: any) => {
    const params = {
      ...values,
    }
    await dispatch(bookingsTrackingCreatePA.request({ id: booking?.id, params }))
    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })

    onSuccess()
  }

  return (
    <>
      <TimeEntryForm onSubmit={onSubmit} onCancel={onClose} />
    </>
  )
}
