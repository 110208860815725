import { useCallback } from 'react'
import { ScrollArea, Space } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import { ChatModel } from '@/features/chat/types/models'
import useStyles from './ChatsList.styles'
import { Item } from './Item'

interface IProps {
  items: ChatModel[]
  onSelect: (data: ChatModel) => void
  activeChat: ChatModel | null
}

export const ChatsList = ({ items, onSelect, activeChat }: IProps) => {
  const {
    appState: { mobileView },
  } = useAppState()
  const { classes } = useStyles()

  const isActive = useCallback(
    (chat: ChatModel) => {
      if (activeChat) {
        return chat.id === activeChat?.id
      }
      return false
    },
    [activeChat]
  )

  return (
    <div className={mobileView ? '' : 'mr-1'}>
      <ScrollArea className={classes.scrollArea} mx="-xs" px="xs">
        {!mobileView && <Space h={'xs'} />}

        {items.map((item, i) => (
          <Item key={i} data={item} onSelect={onSelect} active={isActive(item)} />
        ))}

        <Space h={'xs'} />
      </ScrollArea>
    </div>
  )
}
