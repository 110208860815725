import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    display: 'flex',
    minWidth: '18px',
    height: '18px',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    paddingLeft: '5px',
    paddingRight: '5px',
    borderRadius: '1000px',
    color: theme.white,
    backgroundColor: theme.colors.secondary[theme.fn.primaryShade()],
    whiteSpace: 'nowrap',
  },
}))
