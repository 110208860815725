import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Title } from '@mantine/core'
import { TextAreaController } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'

export type InfoFormSectionValues = {
  about_child: string
  other_info: string
}

export const InfoFormSection = () => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()
  const { control } = useFormContext<InfoFormSectionValues>()

  return (
    <>
      {mobileView && (
        <Title order={4} mb={'sm'}>
          {t('about_my_children')}
        </Title>
      )}
      <TextAreaController
        control={control}
        name={'about_child'}
        id={'about_child'}
        label={mobileView ? '' : t('about_my_children')}
        placeholder={t('about_child.field.placeholder')}
        mb={'md'}
      />

      {mobileView && (
        <Title order={4} mb={'sm'}>
          {t('other_info')}
        </Title>
      )}
      <TextAreaController
        control={control}
        name={'other_info'}
        id={'other_info'}
        label={mobileView ? '' : t('other_info')}
        placeholder={t('children.other_info.field.placeholder')}
        mb={'md'}
      />
    </>
  )
}
