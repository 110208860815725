import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'
import { ReadMoreExpand } from '@/components/Elements'
import { TagBadge } from '@/components/Elements'
import { BabysitterProfile } from '@/features/user'
import useStyles from './InfoBlock.styles'

interface IProps {
  data: BabysitterProfile | any
}

export const InfoBlock = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { bio, languages, skills } = data as BabysitterProfile

  const renderBadge = (color: string, value: any) => (
    <TagBadge type={'badge'} size={'lg'} color={color}>
      {value}
    </TagBadge>
  )

  const renderLangs = () =>
    languages.map((item, i) => (
      <React.Fragment key={i}>{renderBadge('primary', item?.language?.name)}</React.Fragment>
    ))

  const renderSkills = () =>
    skills.map((item, i) => (
      <React.Fragment key={i}>{renderBadge('secondary', item?.name)}</React.Fragment>
    ))

  return (
    <>
      {bio && (
        <div className={'mt-2'}>
          <Text size={'sm'} color={'gray.7'} className={'whitespace-pre-wrap'}>
            <ReadMoreExpand maxHeight={64} lineHeight={16}>
              {bio}
            </ReadMoreExpand>
          </Text>
        </div>
      )}

      {((languages && !!languages.length) || (skills && !!skills.length)) && (
        <div className={'mt-2 flex flex-wrap gap-2.5'}>
          {renderLangs()}
          {renderSkills()}
        </div>
      )}
    </>
  )
}
