import axios from 'axios'

class TranslationsAPI {
  getTranslations = async (params: any) => {
    return axios.get(`api/translations`, {
      params,
    })
  }

  getTranslationsSingle = async (params: any) => {
    return axios.get(`api/translations/single`, {
      params,
    })
  }
}

export const translationsAPI = new TranslationsAPI()
