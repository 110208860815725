import React from 'react'
import { Outlet } from 'react-router-dom'
import { AppShell } from '@mantine/core'
import { useToggle } from '@mantine/hooks'
import { BottomTabs } from '@/components/Elements/Tabs/BottomTabs'
import { useAppState } from '@/features/app/hooks'
import { HeaderProvider } from '@/features/header/contexts/HeaderContext'
import { Header } from './Header'
import { Navbar } from './Navbar'

interface IProps {
  useOpacity?: boolean
  goToBookNow?: boolean
}

export const PanelLayout = ({ useOpacity = false, goToBookNow = false }: IProps) => {
  const {
    appState: { mobileView },
  } = useAppState()

  const [isNavbarDrawerOpened, toggleNavbarDrawerOpened] = useToggle([false, true])

  return (
    <HeaderProvider>
      <AppShell
        padding={mobileView ? 0 : 'md'}
        navbar={
          <Navbar
            drawerOpened={isNavbarDrawerOpened}
            onDrawerClose={() => toggleNavbarDrawerOpened(false)}
          />
        }
        header={<Header useOpacity={useOpacity} goToBookNow={goToBookNow} />}
        footer={<BottomTabs />}
        styles={(theme) => ({
          main: {
            backgroundColor: mobileView ? 'white' : theme.colors.mint[0],
          },
        })}
      >
        <Outlet />
      </AppShell>
    </HeaderProvider>
  )
}
