import React from 'react'
import { useTranslation } from 'react-i18next'
import { Badge } from '@/components/Elements'
import useStyles from './SplashScreen.styles'

export const SplashScreen = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <Badge color={'secondary'} variant={'filled'} size={'lg'}>
        {t('select_chat_to_start_messaging')}
      </Badge>
    </div>
  )
}
