import React from 'react'
import ScrollBar, { ScrollBarProps } from 'react-perfect-scrollbar'

interface IProps extends ScrollBarProps {
  children: React.ReactNode
}

export const PerfectScrollbar = (props: IProps) => {
  return <ScrollBar {...props}>{props.children}</ScrollBar>
}
