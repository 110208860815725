import { combineReducers } from 'redux'
import data from './data/slice'
import messages from './messages/reducers'

const conversationReducer = combineReducers({
  data,
  messages,
})

export default conversationReducer
