import React from 'react'
import { useEvents } from '@/features/app/hooks'
import i18n from '@/i18n'
import { LOCALE } from '@/services'

export const useLang = () => {
  const event = useEvents()
  const lang = i18n.language

  const changeLang = async (code: string) => {
    if (code !== lang) {
      event.actions.peopleSet({
        $language: code,
      })

      await LOCALE.changeLang(code)
    }
  }

  return {
    lang,
    changeLang,
  }
}
