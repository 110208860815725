import React from 'react'
import { useTranslation } from 'react-i18next'
import { BookingCandidate } from '@/features/booking-candidate/types/models'
import { User } from '@/features/user'
import { ActionBar } from './ActionBar'
import useStyles from './Card.styles'
import { InfoBlock } from './InfoBlock'
import { TopBar } from './TopBar'

interface IProps {
  data: User
  actionBar?: boolean
  actionBarSection?: React.ReactNode
  actionBarProps?: any
  favoriteControlProps?: any
  candidateProps?: {
    candidate: BookingCandidate
    hireControlProps: {
      onSuccess?: (data: object) => void
    }
  }
}

export const GeneralBabysitterCard = ({
  data,
  actionBar = true,
  actionBarSection,
  actionBarProps,
  favoriteControlProps,
  candidateProps,
}: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <>
      <div className={classes.root}>
        <div className={classes.infoBlock}>
          <div className={classes.infoTopBar}>
            <TopBar data={data} />
          </div>
          <div className={classes.infoContentBlock}>
            <InfoBlock data={data?.babysitter_profile} />
          </div>
        </div>
        {actionBar && (
          <div className={classes.actionBar}>
            {actionBarSection ? (
              actionBarSection
            ) : (
              <ActionBar
                babysitter={data}
                config={actionBarProps?.config}
                favoriteControlProps={favoriteControlProps}
                candidateProps={candidateProps}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}
