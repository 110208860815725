import {
  blue,
  cyan,
  fuchsia,
  green,
  indigo,
  lime,
  orange,
  pink,
  red,
  teal,
  violet,
  yellow,
} from 'tailwindcss/colors'
import { MantineThemeColorsOverride } from '@mantine/core'

export const overrideColors: MantineThemeColorsOverride = {
  primary: [
    '#fdf5f3',
    '#fce8e4',
    '#fad6ce',
    '#f6b8ab',
    '#ef8e7a',
    '#e26247',
    '#d04e32',
    '#ae3f27',
    '#903724',
    '#783324',
  ],
  secondary: [
    '#d1ffff',
    '#c7fff8',
    '#bdf5ee',
    '#b3ebe4',
    '#a9e1da',
    '#9fd7d0',
    '#95cdc6',
    '#8bc3bc',
    '#81b9b2',
    '#77afa8',
  ],
  mint: [
    '#f5fbfa',
    '#d6f1ed',
    '#ade2da',
    '#7dcbc4',
    '#52afaa',
    '#389490',
    '#2b7675',
    '#265f5f',
    '#224d4d',
    '#204141',
  ],
  red: [
    red['50'],
    red['100'],
    red['200'],
    red['300'],
    red['400'],
    red['500'],
    red['600'],
    red['700'],
    red['800'],
    red['900'],
  ],
  pink: [
    pink['50'],
    pink['100'],
    pink['200'],
    pink['300'],
    pink['400'],
    pink['500'],
    pink['600'],
    pink['700'],
    pink['800'],
    pink['900'],
  ],
  grape: [
    fuchsia['50'],
    fuchsia['100'],
    fuchsia['200'],
    fuchsia['300'],
    fuchsia['400'],
    fuchsia['500'],
    fuchsia['600'],
    fuchsia['700'],
    fuchsia['800'],
    fuchsia['900'],
  ],
  violet: [
    violet['50'],
    violet['100'],
    violet['200'],
    violet['300'],
    violet['400'],
    violet['500'],
    violet['600'],
    violet['700'],
    violet['800'],
    violet['900'],
  ],
  indigo: [
    indigo['50'],
    indigo['100'],
    indigo['200'],
    indigo['300'],
    indigo['400'],
    indigo['500'],
    indigo['600'],
    indigo['700'],
    indigo['800'],
    indigo['900'],
  ],
  blue: [
    blue['50'],
    blue['100'],
    blue['200'],
    blue['300'],
    blue['400'],
    blue['500'],
    blue['600'],
    blue['700'],
    blue['800'],
    blue['900'],
  ],
  cyan: [
    cyan['50'],
    cyan['100'],
    cyan['200'],
    cyan['300'],
    cyan['400'],
    cyan['500'],
    cyan['600'],
    cyan['700'],
    cyan['800'],
    cyan['900'],
  ],
  teal: [
    teal['50'],
    teal['100'],
    teal['200'],
    teal['300'],
    teal['400'],
    teal['500'],
    teal['600'],
    teal['700'],
    teal['800'],
    teal['900'],
  ],
  green: [
    green['50'],
    green['100'],
    green['200'],
    green['300'],
    green['400'],
    green['500'],
    green['600'],
    green['700'],
    green['800'],
    green['900'],
  ],
  lime: [
    lime['50'],
    lime['100'],
    lime['200'],
    lime['300'],
    lime['400'],
    lime['500'],
    lime['600'],
    lime['700'],
    lime['800'],
    lime['900'],
  ],
  yellow: [
    yellow['50'],
    yellow['100'],
    yellow['200'],
    yellow['300'],
    yellow['400'],
    yellow['500'],
    yellow['600'],
    yellow['700'],
    yellow['800'],
    yellow['900'],
  ],
  orange: [
    orange['50'],
    orange['100'],
    orange['200'],
    orange['300'],
    orange['400'],
    orange['500'],
    orange['600'],
    orange['700'],
    orange['800'],
    orange['900'],
  ],
}
