import { useEffect } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { BackgroundImage, Center, Group, Modal, Space, Stack, Text, Title } from '@mantine/core'
import { MenuDivider } from '@mantine/core/lib/Menu/MenuDivider/MenuDivider'
//import faq2 from '@/assets/images/booking/conhecer_babysitter.png'
import homepage from '@/assets/images/booking/homepage.png'
//import faq5 from '@/assets/images/booking/precos_babysitting.png'
//import faq4 from '@/assets/images/booking/seguro.png'
//import faq3 from '@/assets/images/booking/servico_experimental.png'
//import faq1 from '@/assets/images/booking/verificacao_babysitters.png'
import { Button, ConfirmModal, FinishBookingModal, MegaTagTitle } from '@/components/Elements'
import { ModalOrDialogSwitch } from '@/components/Elements/MobileWebSwitchs/ModalOrDialogSwitch'
import { useAppState, useEvents } from '@/features/app/hooks'
import { SectionReviews } from '@/features/auth/components/SectionReviews'
import { BookEventType } from '@/features/book/components/Book/BookLayout/BookEventType'
import { previousRequestState } from '@/features/booking/store/previousRequest.slice'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { getType } from '@/features/bookings/helpers/booking/type'
import { useHeaderContext } from '@/features/header/contexts/HeaderContext'
import { InfoEvent } from '@/features/header/layout/Info/InfoEvent'
import { TAG_MANAGER_EVENT } from '@/features/tag-manager/consts/event'
import { usePopup } from '@/hooks'
import useStyles from './BookLayout.styles'

export const BookLayout = () => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const event = useEvents()
  const { setKey } = useHeaderContext()

  const {
    appState: { mobileView },
  } = useAppState()

  useEffect(() => {
    setKey({ base: true, title: 'book', border: true })
    event.actions.generic(TAG_MANAGER_EVENT.BOOK_NOW)

    if (localStorage.getItem('working-booking')) {
      openWorkingBooking(true)
    }
  }, [])

  const book = (bookingType: BOOKING_TYPE) => {
    dispatch(previousRequestState.setBooking(null))

    event.actions.newBooking({ Type: getType(bookingType).title })
    if (bookingType == BOOKING_TYPE.PERMANENT_NANNY) {
      open(true)
    } else {
      navigate('/booking/' + getType(bookingType).path + '/new')
    }
  }

  const handleChange = (type: BOOKING_TYPE) => {
    book(type)
  }
  const { visible, open, close } = usePopup()
  const {
    visible: visibleWorkingBooking,
    open: openWorkingBooking,
    close: closeWorkingBooking,
  } = usePopup()

  const onClose = () => {
    close()
  }

  const continueBooking = () => {
    closeWorkingBooking()
    const items = localStorage.getItem('working-booking')
    if (items) {
      const working = JSON.parse(items)

      const type = working.type === BOOKING_TYPE.REGULAR ? BOOKING_TYPE.ONETIME : working.type
      navigate('/booking/' + getType(type).path + '/new')
    }
  }

  const deleteBooking = () => {
    localStorage.removeItem('working-booking')
    closeWorkingBooking()
  }
  /*
  const faq = [
    {
      title: t('faq.1.title'),
      desc: t('faq.1.desc'),
      image: faq1,
    },
    {
      title: t('faq.2.title'),
      desc: t('faq.2.desc'),
      image: faq2,
    },
    {
      title: t('faq.3.title'),
      desc: t('faq.3.desc'),
      image: faq3,
    },
    {
      title: t('faq.4.title'),
      desc: t('faq.4.desc'),
      image: faq4,
    },
    {
      title: t('faq.5.title'),
      desc: t('faq.5.desc'),
      image: faq5,
    },
  ]
*/
  const eventType = [
    /*{
      type: BOOKING_TYPE.ONETIME,
      text: getType(BOOKING_TYPE.ONETIME).title,
      desc: getType(BOOKING_TYPE.ONETIME).desc,
      image: getType(BOOKING_TYPE.ONETIME).image,
    },
    {
      type: BOOKING_TYPE.REGULAR,
      text: getType(BOOKING_TYPE.REGULAR).title,
      desc: getType(BOOKING_TYPE.REGULAR).desc,
      image: getType(BOOKING_TYPE.REGULAR).image,
    },*/
    {
      type: BOOKING_TYPE.PERMANENT_NANNY,
      text: getType(BOOKING_TYPE.PERMANENT_NANNY).title,
      desc: getType(BOOKING_TYPE.PERMANENT_NANNY).desc,
      image: getType(BOOKING_TYPE.PERMANENT_NANNY).image,
    },
    {
      type: BOOKING_TYPE.ANIMATION,
      text: getType(BOOKING_TYPE.ANIMATION).title,
      desc: getType(BOOKING_TYPE.ANIMATION).desc,
      image: getType(BOOKING_TYPE.ANIMATION).image,
    },
  ]

  return (
    <>
      <MegaTagTitle title={'book'} />
      <div className={classes.section}>
        <div id="contentBooking" className={classes.contentSection}>
          <Stack className={classes.stackPosition}>
            <BackgroundImage src={homepage} radius="sm">
              <div className={classes.backgroundHomepage} />
              <div className={classes.textOnImage}>
                <Title order={mobileView ? 2 : 1}>{ReactHtmlParser(t('book.image_message'))}</Title>
              </div>
            </BackgroundImage>

            <div className={classes.whiteSeparator} />
            <div className={classes.button}>
              <Center>
                <div className={classes.buttonBorder}>
                  <Button size="xl" radius={32} onClick={() => book(BOOKING_TYPE.ONETIME)}>
                    {t('book.one_time_sitter')}
                  </Button>
                </div>
              </Center>
            </div>
            <div className={'mx-4 pb-4'}>
              <Stack className={classes.stackNoGap}>
                <Title order={3} className={classes.headText}>
                  {ReactHtmlParser(t('book.explore'))}
                </Title>
                <Space h={25} />
                <Stack>
                  {eventType.map(({ type, text, desc, image }, i) => (
                    <BookEventType
                      key={i}
                      text={text}
                      desc={desc}
                      image={image}
                      event={() => handleChange(type)}
                    />
                  ))}
                </Stack>
                <Space h={50} />
                <SectionReviews showReviews={true} />
                {mobileView && <Space h={'md'} />}
              </Stack>
            </div>
          </Stack>
        </div>
        <ModalOrDialogSwitch
          title={' '}
          opened={visible}
          headerBorder={false}
          onClose={onClose}
          rightIcon={'help'}
          fromBooking={false}
          background={mobileView ? 'primary' : 'white'}
        >
          <InfoEvent bookingType={BOOKING_TYPE.PERMANENT_NANNY} showInquiry={true} />
        </ModalOrDialogSwitch>

        <FinishBookingModal
          visible={visibleWorkingBooking}
          onClose={() => closeWorkingBooking()}
          onConfirm={() => continueBooking()}
          onCancel={() => deleteBooking()}
        />
      </div>
    </>
  )
}
