import React from 'react'
import { Grid, Space } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import {
  AboutBlock,
  CertificationsBlock,
  LanguagesBlock,
  SkillsBlock,
} from '@/features/babysitter/components/Profile/InfoBlocks'
import { FeedbackBlock } from '@/features/babysitter/components/Profile/InfoBlocks/FeedbackBlock'
import { useBabysitterData } from '@/features/babysitter/hooks'
import { BabysitterProfile, BabysitterUser } from '@/features/user'
import { General } from './General'
import { RatingBlock } from './RatingBlock'

export const BabysitterDetailsContent = () => {
  const {
    appState: { mobileView },
  } = useAppState()

  const { babysitter } = useBabysitterData()

  const { babysitter_profile, feedbacks } = babysitter as BabysitterUser

  const { languages, skills, certifications } = babysitter_profile as BabysitterProfile

  return (
    <>
      <General />

      <Space h={'xl'} />

      <Grid>
        <Grid.Col md={6}>
          <div className={mobileView ? 'mb-3' : 'mb-2'}>
            <AboutBlock data={babysitter_profile} />
          </div>

          <div className={mobileView ? 'mb-3' : 'mb-2'}>
            <LanguagesBlock data={languages} />
          </div>

          <SkillsBlock data={skills} />
        </Grid.Col>
        <Grid.Col md={6}>
          <div className={mobileView ? 'mb-3 mt-3' : 'mb-2'}>
            <RatingBlock />
          </div>

          <div className={mobileView ? 'mb-3' : 'mb-2'}>
            <CertificationsBlock data={certifications} />
          </div>

          <FeedbackBlock data={feedbacks} />
        </Grid.Col>
      </Grid>
    </>
  )
}
