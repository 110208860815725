import React from 'react'
import { BasicLayout } from '@/components/Layouts'
import { Confirmed } from '@/features/booking/routes/confirmed'
import { New } from '@/features/booking/routes/new'
import { RegularConfirmed } from '@/features/booking/routes/regularConfirmed'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'

//config
export const getPublicRoutes = () => {
  return [
    {
      path: '/*',
      element: <BasicLayout lang={true} />,
      children: [],
    },
    {
      path: '/booking/onetime/new',
      element: (
        <BasicLayout header={false} loadSelects>
          <New bookingType={BOOKING_TYPE.ONETIME} step={0} />
        </BasicLayout>
      ),
      children: [],
    },
    {
      path: '/booking/regular/new',
      element: (
        <BasicLayout header={false} loadSelects>
          <New bookingType={BOOKING_TYPE.REGULAR} step={0} />
        </BasicLayout>
      ),
      children: [],
    },
    {
      path: '/booking/animation/new',
      element: (
        <BasicLayout header={false} loadSelects>
          <New bookingType={BOOKING_TYPE.ANIMATION} step={0} />
        </BasicLayout>
      ),
      children: [],
    },
    {
      path: '/booking/nanny/new',
      element: (
        <BasicLayout header={false} loadSelects>
          <New bookingType={BOOKING_TYPE.PERMANENT_NANNY} step={0} />
        </BasicLayout>
      ),
      children: [],
    },
    {
      path: '/booking/onetime/checkout',
      element: (
        <BasicLayout header={false} loadSelects>
          <New bookingType={BOOKING_TYPE.ONETIME} step={1} />
        </BasicLayout>
      ),
      children: [],
    },
    {
      path: '/booking/regular/checkout',
      element: (
        <BasicLayout header={false} loadSelects>
          <New bookingType={BOOKING_TYPE.REGULAR} step={1} />
        </BasicLayout>
      ),
      children: [],
    },
    {
      path: '/booking/animation/checkout',
      element: (
        <BasicLayout header={false} loadSelects>
          <New bookingType={BOOKING_TYPE.ANIMATION} step={1} />
        </BasicLayout>
      ),
      children: [],
    },
    {
      path: '/booking/nanny/checkout',
      element: (
        <BasicLayout header={false} loadSelects>
          <New bookingType={BOOKING_TYPE.PERMANENT_NANNY} step={1} />
        </BasicLayout>
      ),
      children: [],
    },
    {
      path: '/booking/onetime/care-details',
      element: (
        <BasicLayout header={false} loadSelects>
          <New bookingType={BOOKING_TYPE.ONETIME} step={2} />
        </BasicLayout>
      ),
      children: [],
    },
    {
      path: '/booking/regular/care-details',
      element: (
        <BasicLayout header={false} loadSelects>
          <New bookingType={BOOKING_TYPE.REGULAR} step={1 /*2*/} />
        </BasicLayout>
      ),
      children: [],
    },
    {
      path: '/booking/animation/care-details',
      element: (
        <BasicLayout header={false} loadSelects>
          <New bookingType={BOOKING_TYPE.ANIMATION} step={1 /*2*/} />
        </BasicLayout>
      ),
      children: [],
    },
    {
      path: '/booking/nanny/care-details',
      element: (
        <BasicLayout header={false} loadSelects>
          <New bookingType={BOOKING_TYPE.PERMANENT_NANNY} step={1 /*2*/} />
        </BasicLayout>
      ),
      children: [],
    },
    {
      path: '/booking/confirmed',
      element: (
        <BasicLayout header={false} loadSelects>
          <Confirmed />
        </BasicLayout>
      ),
      children: [],
    },
    {
      path: '/booking/regular/confirmed',
      element: (
        <BasicLayout header={false} loadSelects>
          <RegularConfirmed />
        </BasicLayout>
      ),
      children: [],
    },
    /*{
      path: '/booking/checkout',
      element: (
        <BasicLayout header={false} loadSelects>
          <Checkout />
        </BasicLayout>
      ),
      children: [],
    },*/
  ]
}
