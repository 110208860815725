import classNames from 'classnames'
import { Arrow } from '@/components/Elements/Icon/Arrow'
import useStyles from './CarouselNextArrow.styles'

interface IProps {
  currentSlide?: any
  slideCount?: any
  cn: any
  handleClick: () => any
}
export const CarouselNextArrow = ({ currentSlide, slideCount, cn, handleClick }: IProps) => {
  const { classes } = useStyles()
  return (
    <span
      className={classNames(
        classes.arrow,
        currentSlide === slideCount - 1 && classes.arrowLast,
        cn
      )}
      onClick={handleClick}
    >
      <Arrow
        stroke={currentSlide === slideCount - 1 ? '#02334b' : 'white'}
        style={{ transform: `scale(${20 / 24})` }}
      />
    </span>
  )
}
