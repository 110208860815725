import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  SkillsControlOptionType,
  SkillsControlValueType,
} from '@/features/profile/components/Controls'
import { SkillsController } from '@/features/profile/components/FormElements'
import { skillsAPI } from '@/features/selects/api'
import { useLang } from '@/hooks'

type SkillValues = {
  id?: number | string | null
  is_default: boolean
  name?: string
}

export type SkillsFormSectionValues = {
  skills: Array<SkillValues>
}

export const SkillsFormSection = () => {
  const { lang } = useLang()
  const { control, getValues, setValue } = useFormContext<SkillsFormSectionValues>()
  const [allSkills, setAllSkills] = useState<any[]>([])
  const [skillsOptions, setSkillsOptions] = useState<Array<any>>(getValues('skills'))

  const fetchDataSkills = async () => {
    const { data } = await skillsAPI.getSkills(lang)
    const defaultOptions = data.skills.map((item: SkillsControlOptionType) => ({
      ...item,
      is_default: true,
    }))

    setAllSkills([...defaultOptions])
  }

  useEffect(() => {
    fetchDataSkills()
  }, [getValues])

  useEffect(() => {
    const selectedOptions = skillsOptions.map((item: SkillsControlValueType) => ({
      ...item,
      is_default: item.is_default || true,
    }))

    setValue('skills', [...selectedOptions])
  }, [skillsOptions])

  /*  const defaultSkillsOptions = useMemo(() => {
    const defaultOptions = allSkills.map((item: SkillsControlOptionType) => ({
      ...item,
      is_default: true,
    }))

    console.log('getvalue skills:' + JSON.stringify(getValues('skills')))
    const customOptions = getValues('skills').filter((item) => item.is_default === false)

    console.log('defaultSkillsOptions default: ' + JSON.stringify(defaultOptions))
    return [...defaultOptions, ...customOptions]
  }, [allSkills])
*/

  const onSkillAdd = (data: SkillsControlValueType) => {
    setSkillsOptions((prevState) => [...prevState, data])
  }

  const onSkillRemove = (index: number) => {
    const items = skillsOptions.filter((_, i) => i !== index)
    setSkillsOptions([...items])
  }
  const onSkillChange = (value: SkillsControlValueType[]) => {
    setSkillsOptions([...value])
  }

  return (
    <>
      <SkillsController
        control={control}
        name={'skills'}
        value={skillsOptions}
        options={allSkills}
        onOptionAdd={onSkillAdd}
        onOptionRemove={onSkillRemove}
        onChange={onSkillChange}
      />
    </>
  )
}
