import React from 'react'

interface IProps {
  color: string
  size?: number
  viewPort?: number
  className?: string
}

export const CreditCard = ({ size = 24, viewPort = size, color, ...props }: IProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${viewPort} ${viewPort}`}
    fill="none"
    strokeWidth={2}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 7H4C3.44772 7 3 7.44772 3 8V24C3 24.5523 3.44772 25 4 25H28C28.5523 25 29 24.5523 29 24V8C29 7.44772 28.5523 7 28 7Z"
      stroke={color}
      color={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M21 21H25" stroke={color} color={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 21H17" stroke={color} color={color} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M3 12.1064H29"
      stroke={color}
      color={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
