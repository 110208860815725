import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import {
  BookingServicesLayout,
  BookingServicesTabsLayout,
} from '@/features/booking-service/components/Layouts'
import { useHeaderContext } from '@/features/header/contexts/HeaderContext'
import { History, Potential, Upcoming } from '../services'

export const BookingServicesRoot = () => {
  const { setKey } = useHeaderContext()
  const navigate = useNavigate()

  const calendarClickHandler = (e: React.MouseEvent<SVGElement>) => {
    e.preventDefault()

    navigate('/calendar')
  }
  useEffect(() => {
    setKey({
      base: false,
      title: 'services',
      border: false,
      rightIcon: 'calendar',
      rightClick: calendarClickHandler,
    })
  }, [])

  return (
    <Routes>
      <Route element={<BookingServicesLayout />}>
        <Route element={<BookingServicesTabsLayout />}>
          <Route index element={<Potential />} />
          <Route path={'upcoming'} element={<Upcoming />} />
          <Route path={'history'} element={<History />} />
        </Route>
      </Route>

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
