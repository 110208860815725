import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useUser } from '@/features/user'
import { WalletLayout } from '@/features/wallet/components/Layouts'
import { WalletViewBabysitter, WalletViewClient } from '@/features/wallet/routes/services'

export const WalletRoot = () => {
  const { isClient } = useUser()
  return (
    <Routes>
      <Route element={<WalletLayout />}>
        <Route index element={isClient() ? <WalletViewClient /> : <WalletViewBabysitter />} />
      </Route>

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
