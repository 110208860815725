import { IconX } from '@tabler/icons'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionIcon } from '@mantine/core'
import { ChipTagBadge, TagBadge } from '@/components/Elements'
import { SkillsControlOptionType, SkillsControlValueType } from '../types'

interface IProps {
  value: Array<SkillsControlValueType>
  options: Array<SkillsControlOptionType>
  onRemove: (data: SkillsControlOptionType, i: number) => void
  onSelect: (data: SkillsControlValueType, checked: boolean) => void
}

export const SkillsTags = ({ value = [], options = [], onRemove, onSelect }: IProps) => {
  const { t } = useTranslation()
  const renderRemoveButton = (data: SkillsControlOptionType, index: number) => (
    <ActionIcon
      size={'sm'}
      color="dark"
      radius="xl"
      variant="transparent"
      onClick={() => onRemove(data, index)}
    >
      <IconX size={16} />
    </ActionIcon>
  )

  const renderTag = (item: SkillsControlOptionType, index: number) => (
    <TagBadge key={index} rightSection={renderRemoveButton(item, index)} color={'secondary'}>
      {item.name}
    </TagBadge>
  )

  const renderChip = (item: SkillsControlOptionType, index: number) => {
    const checked = !!_.find(value, { id: item.id })

    return (
      <ChipTagBadge
        key={index}
        checked={checked}
        color={'secondary'}
        id={item.id}
        onChange={(checkedValue) => onSelect(item, checkedValue)}
      >
        {t(item.name)}

        {checked && <IconX size={16} />}
      </ChipTagBadge>
    )
  }

  return (
    <>
      <div className={'flex flex-wrap gap-2.5'}>
        {options.map((item, index) =>
          item.is_default ? renderChip(item, index) : renderTag(item, index)
        )}
      </div>
    </>
  )
}
