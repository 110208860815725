import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group, Stack, Text, Title } from '@mantine/core'
import { NoDataTextMessage } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { LanguageType } from '@/features/user'
import useStyles from './LanguagesBlock.styles'

interface IProps {
  data: Array<LanguageType>
}

export const LanguagesBlock = ({ data = [] }: IProps) => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()
  const { classes } = useStyles()

  return (
    <>
      <div>
        <Title order={5}>{t('languages')}</Title>

        <div className={'my-3'}>
          {!data.length ? (
            <NoDataTextMessage variant={'info'} />
          ) : (
            <Stack className={'my-3'}>
              {data.map((item, i) => (
                <React.Fragment key={i}>
                  {mobileView ? (
                    <Group className={'mobile-info-view-group'}>
                      <Text className={'mobile-info-view-title'}>
                        {i === 0 ? t('primary') : t('secondary')}
                      </Text>
                      <Text className={'mobile-info-view-content'}>{item?.language?.name}</Text>
                    </Group>
                  ) : (
                    <Group className={classes.groupItem}>
                      <Text className={classes.groupItemLabel}>
                        {i === 0 ? t('primary') : t('secondary')}
                      </Text>
                      <Text className={classes.groupItemValue}>{item?.language?.name}</Text>
                    </Group>
                  )}
                </React.Fragment>
              ))}
            </Stack>
          )}
        </div>
      </div>
    </>
  )
}
