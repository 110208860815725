import * as Yup from 'yup'

export const detailsValidation = {
  birthday: Yup.date().nullable(true).required('field.error.required'),
  city_id: Yup.mixed().nullable().required('field.error.required'),
  occupation_area: Yup.string().required('field.error.required'),
  bio: Yup.string().required('field.error.required'),
}

export const languagesValidation = {
  languages: Yup.array()
    .of(
      Yup.object().shape({
        language_id: Yup.mixed().nullable().required('field.error.required'),
        level_id: Yup.mixed().nullable().required('field.error.required'),
      })
    )
    .min(1, 'field.error.required'),
}

export const skillsValidation = {
  skills: Yup.array().min(1, 'field.error.required'),
}

export const certificationsValidation = {
  certifications: Yup.array().of(
    Yup.object().shape({
      school: Yup.string().required('field.error.required'),
      study: Yup.string().required('field.error.required'),
      description: Yup.string().required('field.error.required'),
      start_date: Yup.date().nullable().required('field.error.required'),
      end_date: Yup.date().nullable().required('field.error.required'),
    })
  ),
}
