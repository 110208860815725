import CSS from 'csstype'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
import { Nav, NavItem } from 'reactstrap'
import { Footer as MantineFooter, MediaQuery, Text, useMantineTheme } from '@mantine/core'
import { Icon, IconNameType } from '@/components/Elements'
import { BottomTabsBookNow } from '@/components/Elements/Tabs/BottomTabs/BottomTabsBookNow'
import { ChatUnreadBadge } from '@/components/Layouts/PanelLayout/Navbar/NavbarContent/Badges'
import { useAppState } from '@/features/app/hooks'
import { useUser } from '@/features/user'
import useStyles from './BottomTabs.styles'

const navbar: CSS.Properties = {
  background: 'white',
}
const bottomTabLabel: CSS.Properties = {
  fontSize: '10px',
  fontWeight: 'bold',
  color: 'black',
  textAlign: 'center',
}

const bottomNavLink: CSS.Properties = {
  borderTop: 'solid 2px #EBEBEB',
  color: 'black',
}
const iconPadding: CSS.Properties = {
  paddingTop: '4px',
  paddingBottom: '4px',
  paddingLeft: '0px',
  paddingRight: '0px',
}

export const BottomTabs = () => {
  const theme = useMantineTheme()
  const { classes } = useStyles()
  const { isBabysitter } = useUser()
  const {
    appState: { mobileView },
  } = useAppState()
  const { t } = useTranslation()

  const location = useLocation()

  const tabsClient: Array<NavLinkType> = [
    {
      route: '/book-now',
      icon: 'book',
      label: t('book'),
      activeRoutes: ['/book-now', '/booking/'],
    },
    {
      route: '/bookings',
      icon: 'calendar-search',
      label: t('bookings'),
      activeRoutes: ['/bookings'],
    },
    {
      route: '/chat',
      icon: 'chat',
      label: t('chats'),
      activeRoutes: ['/chat'],
      renderRightSection: ({ isActive }: { isActive: boolean }) => (
        <ChatUnreadBadge isActive={isActive} />
      ),
    },
    {
      route: '/wallet',
      icon: 'wallet',
      label: t('wallet'),
      activeRoutes: ['/wallet'],
    },
    {
      route: '/profile',
      icon: 'profile',
      label: t('profile'),
      activeRoutes: ['/profile'],
    },
  ]

  type NavLinkType = {
    route: string
    label: string
    icon: IconNameType
    activeRoutes: string[]
    renderRightSection?: (data: any) => React.ReactNode
  }

  const tabsBabysitting: Array<NavLinkType> = [
    {
      route: '/services',
      icon: 'calendar-search',
      label: t('services'),
      activeRoutes: ['/services', '/calendar'],
    },
    {
      route: '/chat',
      icon: 'chat',
      label: t('chats'),
      activeRoutes: ['/chat'],
      renderRightSection: ({ isActive }: { isActive: boolean }) => (
        <ChatUnreadBadge isActive={isActive} />
      ),
    },
    {
      route: '/wallet',
      icon: 'wallet',
      label: t('wallet'),
      activeRoutes: ['/wallet'],
    },
    {
      route: '/profile',
      icon: 'profile',
      label: t('profile'),
      activeRoutes: ['/profile'],
    },
  ]

  const tabs = isBabysitter() ? tabsBabysitting : tabsClient

  const bottomNavLinkActive: CSS.Properties = {
    borderTop: '2px solid ' + theme.colors.primary[theme.fn.primaryShade()],
  }

  const bottomTabActiveLabel: CSS.Properties = {
    fontSize: '10px',
    fontWeight: 'bold',
    color: theme.colors.primary[theme.fn.primaryShade()],
    textAlign: 'center',
  }

  const startWith = (str: string, prefix: string) => {
    return str.slice(0, prefix.length) === prefix
  }
  const isActive = (item: string[] | undefined) => {
    if (item === undefined) return false
    return item.filter((key) => startWith(location.pathname, key)).length > 0
  }

  return (
    <MediaQuery largerThan="md" styles={{ display: 'none', height: 0 }}>
      <MantineFooter height={mobileView ? 62 : 0}>
        <nav
          className="navbar fixed-bottom navbar-light d-block d-lg-none bottom-tab-nav"
          style={navbar}
          role="navigation"
        >
          <Nav className="w-100">
            {isActive(['/book-now']) && <BottomTabsBookNow />}
            <div className=" d-flex flex-row w-100">
              {tabs.map((tab, index) => (
                <NavItem key={`tab-${index}`} className="w-100">
                  <NavLink
                    to={tab.route}
                    className="nav-link bottom-nav-link"
                    style={isActive(tab.activeRoutes) ? bottomNavLinkActive : bottomNavLink}
                  >
                    <div className="row d-flex flex-column justify-content-center align-items-center">
                      <Icon
                        name={tab.icon}
                        size={20}
                        viewPort={24}
                        color={
                          isActive(tab.activeRoutes)
                            ? theme.colors.primary[theme.fn.primaryShade()]
                            : 'black'
                        }
                      />
                      <div
                        className={classes.tabLabel}
                        style={isActive(tab.activeRoutes) ? bottomTabActiveLabel : bottomTabLabel}
                      >
                        {tab.label}
                      </div>
                      {tab.renderRightSection && (
                        <div className={classes.rightSection}>
                          {tab.renderRightSection(isActive(tab.activeRoutes))}
                        </div>
                      )}
                    </div>
                  </NavLink>
                </NavItem>
              ))}
            </div>
          </Nav>
        </nav>
      </MantineFooter>
    </MediaQuery>
  )
}
