import React from 'react'
import useStyles from './Selector.styles'

interface IProps {
  children: React.ReactNode
  checked: boolean
  className?: string
  onChange: (checked: boolean) => void
}

export const Selector = ({ children, className, checked, onChange, ...props }: IProps) => {
  const { classes, cx } = useStyles()

  return (
    <label {...props} className={cx(className, classes.label)}>
      <input
        type={'checkbox'}
        className={'hidden'}
        checked={checked}
        onChange={() => onChange(!checked)}
      />
      {children}
    </label>
  )
}
