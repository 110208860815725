import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  drawer: {
    overflow: 'auto',
  },

  header: {
    padding: '40px 40px 0 40px',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      padding: '24px 24px 0 24px',
    },
  },

  drawerBody: {
    padding: '0px 40px 40px 40px',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      padding: '0px 24px 24px 24px',
    },
  },
}))
