import React from 'react'
import { CandidateStatusBadge } from '@/features/booking-candidate/components/Elements'
import { BOOKING_CANDIDATE_STATUS } from '@/features/booking-candidate/consts/candidate'
import { useCandidatePermissions } from '@/features/booking-candidate/hooks'
import { BookingCandidate } from '@/features/booking-candidate/types/models'
import { CandidateHireControl } from '../../Controls'

interface IProps {
  candidate: BookingCandidate
  hireControlProps: {
    onSuccess?: (data: object) => void
  }
}

export const CandidateStatusBlock = ({ candidate, hireControlProps }: IProps) => {
  const { status } = candidate

  const { isHireAllow } = useCandidatePermissions()

  // return (
  //   <div>
  //     {isHireAllow(candidate) ? (
  //       <CandidateHireControl candidate={candidate} {...hireControlProps} />
  //     ) : (
  //       <CandidateStatusBadge value={status} />
  //     )}
  //   </div>
  // )

  return (
    <div>
      {status === BOOKING_CANDIDATE_STATUS.WAITING_HIRING ? (
        <>
          {isHireAllow(candidate) ? (
            <CandidateHireControl candidate={candidate} {...hireControlProps} />
          ) : null}
        </>
      ) : (
        <>
          <CandidateStatusBadge value={status} />
        </>
      )}
    </div>
  )
}
