import React, { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'
import { Accordion } from '@mantine/core'
import { WeekDaysPickerController } from '@/features/booking/components/FormElements'
import { timetableHelper } from '@/features/booking/helpers'
import { DateUtils } from '@/utils'
import { WeekDayTimePeriodFormSection } from '../../WeekDayTimePeriodFormSection'

const weekDays = timetableHelper.weekdays.getWeekDays()

interface IProps {
  week: {
    startDate: Date
    endDate: Date
  }
  weekIndex: number
  isDayDisable: (item: any) => boolean
  weekNumberLabel: boolean
}

export const WeekDays = ({ week, weekIndex, isDayDisable, weekNumberLabel }: IProps) => {
  const { t } = useTranslation()

  const { startDate, endDate } = week

  const { control, watch } = useFormContext()

  const watchWeeksSchedule = watch('timetable.weeks_schedule')

  const watchSameDaysTime = watch('timetable.same_days_time')

  const renderWeekLabel = useCallback(() => {
    const weekLabel = weekNumberLabel ? `${t('week')} ${weekIndex + 1}` : t('week')
    const startDateLabel = startDate ? DateUtils.format(startDate, 'D MMM YYYY') : null
    const endDateLabel = endDate ? DateUtils.format(endDate, 'D MMM YYYY') : null

    return (
      <Text weight={700}>
        {weekLabel}{' '}
        <Text
          component="span"
          color={'gray.6'}
          weight={500}
        >{`(${startDateLabel} - ${endDateLabel})`}</Text>
      </Text>
    )
  }, [startDate, endDate, weekNumberLabel])

  return (
    <Accordion.Item value={weekIndex.toString()}>
      <Accordion.Control>
        <div>{renderWeekLabel()}</div>
      </Accordion.Control>

      <Accordion.Panel>
        <div>
          <WeekDaysPickerController
            control={control}
            name={`timetable.weeks_schedule.${weekIndex}`}
            isDayDisabled={isDayDisable}
          />
        </div>

        {!watchSameDaysTime &&
          weekDays.map(({ key }) =>
            watchWeeksSchedule?.[weekIndex]?.[key]?.enabled ? (
              <div className={'mt-3'} key={key}>
                <WeekDayTimePeriodFormSection
                  namePrefix={`timetable.weeks_schedule.${weekIndex}`}
                  weekDayKey={key}
                />
              </div>
            ) : null
          )}
      </Accordion.Panel>
    </Accordion.Item>
  )
}
