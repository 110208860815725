import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    gap: '20px',

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      flexDirection: 'column',
    },
  },

  dateBlock: {
    flexGrow: 1,
  },

  divider: {
    height: '90%',
    backgroundColor: theme.colors.gray[4],
    width: '1px',
    display: 'flex',
    alignSelf: 'center',

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      height: '1px',
      width: '90%',
    },
  },
}))
