import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    margin: '8px',
    minHeight: '56px',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      margin: '0px',
      marginTop: '8px',
      minHeight: 'unset',
      padding: '0px 16px',
    },
  },
}))
