import * as React from 'react'
import { Stepper as MantineStepper } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import useStyles from './CommonStepper.styles'

interface IProps {
  steps: Array<any>
  active: number

  [x: string]: any
}

export const CommonStepper = ({ steps, active, ...props }: IProps) => {
  const { classes } = useStyles()
  const {
    appState: { mobileView },
  } = useAppState()

  const handleStepChange = (nextStep: number) => {}

  return (
    <MantineStepper
      active={active}
      onStepClick={handleStepChange}
      color={'secondary'}
      size="xl"
      iconSize={mobileView ? 36 : 60}
      orientation={mobileView ? 'horizontal' : 'vertical'}
      completedIcon={<Icon name={'check'} size={mobileView ? 20 : 32} viewPort={32} />}
      classNames={classes}
      {...props}
    >
      {steps.map(({ label, icon }, i) => (
        <MantineStepper.Step key={i} label={mobileView ? '' : label} icon={icon} />
      ))}
    </MantineStepper>
  )
}
