import React from 'react'
import { useTranslation } from 'react-i18next'
import { Center, ScrollArea, Space, Text, Title } from '@mantine/core'
import { ModalOrDialogSwitch } from '@/components/Elements/MobileWebSwitchs'
import { useAppState } from '@/features/app/hooks'
import { useUser } from '@/features/user'

interface IProps {
  children: React.ReactNode
  visible: boolean
  onClose: () => void
  title?: string
}

export const RatingEntryModal = ({ children, visible, onClose, title }: IProps) => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()

  const { isBabysitter } = useUser()

  title = title ? title : isBabysitter() ? t('rate_client') : t('rate_service')

  return (
    <ModalOrDialogSwitch
      opened={visible}
      onClose={onClose}
      headerBorder={true}
      centered
      title={title || ''}
    >
      <div className={mobileView ? 'p-4' : ''}>
        <Center>
          <Title order={4} className={'text-center'}>
            {t('rating-info-message')}
          </Title>
        </Center>

        <Space h={mobileView ? 'lg' : 'lg'} />

        {mobileView ? (
          children
        ) : (
          <ScrollArea w={'100%'} h={400}>
            {children}
          </ScrollArea>
        )}
      </div>
    </ModalOrDialogSwitch>
  )
}
