import { IconCirclePlus } from '@tabler/icons'
import React, { useEffect } from 'react'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Anchor, Group, ScrollArea } from '@mantine/core'
import { ActionIconButton } from '@/components/Elements'
import { FieldError, FormControlActionBlock, TextInputController } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'
import useStyles from './Email.styles'

export type FieldValues = {
  email?: string
}

interface IProps {
  name: string
  control: any
  limit?: number
  meta?: any
  clearErrors?: (name: any) => void
  gridProps?: any
}

export const EmailsFieldArray = ({
  name,
  control,
  limit,
  meta,
  clearErrors,
  gridProps,
}: IProps) => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()

  const { classes } = useStyles()
  const error = meta?.error

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: name,
    keyName: 'fieldId',
  })

  const addRow = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    if (clearErrors) {
      clearErrors(name)
    }
    addRowItem()
  }

  const addRowItem = () => {
    const fields: FieldValues = {
      email: undefined,
    }

    append(fields)
  }
  const removeRow = (index: number) => {
    remove(index)
  }

  const renderAdd = () => (
    <div className={mobileView ? 'flex flex-col mt-3 self-start' : 'flex flex-col mt-4 self-start'}>
      <Anchor href="#" onClick={addRow} className={'font-bold'}>
        <Group>
          <IconCirclePlus />
          {t('add_email')}
        </Group>
      </Anchor>
      <FieldError error={error} />
    </div>
  )

  const renderTitleSection = (index: number) => {
    return t('email') + ` ${index + 1}`
  }

  const isRemovable = (index: number) => index !== 0

  const renderActionBlock = (index: number) =>
    isRemovable(index) ? (
      <FormControlActionBlock className={mobileView ? 'mt-4' : 'mb-4'}>
        <ActionIconButton name={'remove'} onClick={() => removeRow(index)} />
      </FormControlActionBlock>
    ) : null

  useEffect(() => {
    if (fields.length == 0) {
      addRowItem()
    }
  }, [])

  return (
    <div className={classes.content}>
      <ScrollArea className={classes.scrollArea}>
        {fields.map((item, index) => (
          <div key={item.fieldId} className={'flex flex-col'}>
            <div className={'flex'}>
              <div className={index === 0 ? 'w-100' : 'w-10/12'}>
                <TextInputController
                  control={control}
                  name={`${name}.${index}.email`}
                  id={`${name}.${index}.email`}
                  label={renderTitleSection(index)}
                  placeholder={t('email')}
                  size={mobileView ? 'md' : 'lg'}
                  mb={mobileView ? 'sm' : 'md'}
                  defaultValue={''}
                  translateParams={{ count: 10 }}
                />
              </div>
              {index > 0 && <div className={'w-2/12'}>{renderActionBlock(index)}</div>}
            </div>
          </div>
        ))}
        {limit ? fields.length < limit && renderAdd() : renderAdd()}
      </ScrollArea>
    </div>
  )
}
;('')
