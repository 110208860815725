import { useEffect } from 'react'
import { MegaTagTitle } from '@/components/Elements'
import { useEvents } from '@/features/app/hooks'
import { BookingLayout } from '@/features/booking/components/Booking'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { TAG_MANAGER_EVENT } from '@/features/tag-manager/consts/event'

interface IProps {
  bookingType: BOOKING_TYPE
  step: number
}
export const New = ({ bookingType, step }: IProps) => {
  const event = useEvents()

  useEffect(() => {
    sendMixPanelEvent()
  }, [])

  const sendMixPanelEvent = () => {
    if (step == 0)
      event.actions.generic(
        bookingType == BOOKING_TYPE.ONETIME
          ? TAG_MANAGER_EVENT.BOOK_ONETIME
          : bookingType == BOOKING_TYPE.REGULAR
          ? TAG_MANAGER_EVENT.BOOK_REGULAR
          : bookingType == BOOKING_TYPE.PERMANENT_NANNY
          ? TAG_MANAGER_EVENT.BOOK_NANNY
          : TAG_MANAGER_EVENT.BOOK_PARTY
      )
  }

  return (
    <>
      <MegaTagTitle title={step == 0 ? 'new_booking' : step == 1 ? 'checkout' : 'care_details'} />

      <BookingLayout bookingType={bookingType} step={step} />
    </>
  )
}
