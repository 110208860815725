import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionIconButton } from '@/components/Elements'
import { BookingInvoiceModel } from '@/features/booking-invoice/types/models'
import { FileUtils } from '@/utils'

interface IProps {
  bookingInvoice: BookingInvoiceModel
}

export const DownloadControl = ({ bookingInvoice }: IProps) => {
  const { t } = useTranslation()

  const { pdf_url } = bookingInvoice

  const onDownload = () => {
    if (pdf_url) {
      FileUtils.downloadFileByLink(pdf_url)
    }
  }

  return <ActionIconButton name={'download'} onClick={onDownload} />
}
