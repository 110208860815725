import React from 'react'
import { Group } from '@mantine/core'
import { AvatarProvider } from '@/components/Elements'
import { Participant } from '@/features/chat/types/models'
import useStyles from './ActionBar.styles'
import { ViewProfile } from './Controls'

const defaultConfig = {
  viewProfile: false,
  viewBabysitters: false,
  otherBabysitters: undefined,
}

interface IProps {
  config: {
    viewProfile?: boolean
    viewBabysitters?: boolean
    otherBabysitters?: Participant[]
  }
}

export const ActionBar = ({ config }: IProps) => {
  const { classes } = useStyles()

  config = {
    ...defaultConfig,
    ...config,
  }

  return (
    <div className={'flex flex-wrap gap-4'}>
      {config?.viewProfile && <ViewProfile />}
      {config?.viewBabysitters && (
        <Group className={'gap-1'}>
          {config?.otherBabysitters?.map((babysitter, index) => (
            <AvatarProvider
              size={32}
              avatar={babysitter.avatar}
              key={'group-babysitter-' + index}
            />
          ))}
        </Group>
      )}
    </div>
  )
}
