import React from 'react'
import { Title } from '@mantine/core'
import { AvatarProvider } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { Favorite } from '@/features/babysitter/components/Babysitter/BabysitterDetails/Layout/Header/Actions'
import { useBabysitterData } from '@/features/babysitter/hooks'
import { BabysitterUser } from '@/features/user'
import { useUsersModel } from '@/features/users/hooks'
import useStyles from './General.styles'

export const General = () => {
  const {
    appState: { mobileView },
  } = useAppState()

  const { classes } = useStyles()

  const { babysitter } = useBabysitterData()
  const { getName } = useUsersModel()

  const { avatar } = babysitter as BabysitterUser

  return (
    <>
      <div className={classes.block}>
        <div className={classes.avatarWrap}>
          <AvatarProvider size={mobileView ? 70 : 80} radius={50} avatar={avatar} />
        </div>

        <div className={'h-full w-full flex items-center'}>
          {getName(babysitter) && <Title order={2}>{getName(babysitter)}</Title>}
        </div>

        <>
          {mobileView && (
            <div>
              <Favorite />
            </div>
          )}
        </>
      </div>
    </>
  )
}
