import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  block: {},

  groupItem: {
    display: 'block',
    fontSize: '14px',
    alignItems: 'flex-start',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: '12px',
      marginBottom: '8px',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  groupItemLabel: {
    width: '150px',
    color: theme.colors.gray[7],
    fontWeight: 700,
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: '13px',
      width: '90%',
      marginBottom: '4px',
    },
  },

  groupItemValue: {
    fontWeight: 500,
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: '13px',
      marginBottom: '4px',
    },
  },

  badge: {
    fontSize: '10px',
    fontWeight: 400,
  },

  contentTitle: {
    position: 'relative',
    zIndex: 1,
    flexDirection: 'column',
    maxWidth: '550px',
    marginTop: '16px',
  },

  content: {
    position: 'relative',
    zIndex: 1,
    flexDirection: 'column',
    maxWidth: '450px',
    backgroundColor: '#fce8e4',
    borderRadius: '8px',
    padding: '24px',
    gap: '16px',
    marginTop: '24px',
  },

  info: {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    margin: '0 16px',
  },

  code: {
    textAlign: 'center',
    border: '1px solid #d04e32',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '16px 24px',
  },
  imgWrap: {
    position: 'fixed',
    right: '0',
    bottom: '0',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      display: 'none',
    },
  },
}))
