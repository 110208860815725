import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/Elements'

interface IProps {
  loading: boolean
  onSubmit: () => void
  onCancel: () => void
}

export const BookingCancelContent = ({ loading, onSubmit, onCancel }: IProps) => {
  const { t } = useTranslation()

  return (
    <div className={'flex flex-col items-center'}>
      <div className={'mt-3 w-full'}>
        <Button mt={'lg'} fullWidth loading={loading} onClick={onSubmit}>
          {t('yes_cancel_booking')}
        </Button>

        <Button mt={'lg'} fullWidth variant="subtle" color="gray" onClick={onCancel}>
          {t('no_go_back')}
        </Button>
      </div>
    </div>
  )
}
