import { IconArrowLeft, IconCalendarEvent, IconListDetails, IconPlus } from '@tabler/icons'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { Text, useMantineTheme } from '@mantine/core'

const HeaderContext = createContext({
  base: false,
  title: '',
  border: false,
  left: <Text></Text>,
  right: <Text></Text>,
  setKey: (key: KeyProps) => {},
})

export interface KeyProps {
  base: boolean
  title: string
  border: boolean
  leftIcon?: string
  leftClick?: (e: any) => void
  rightIcon?: string
  rightClick?: (e: any) => void
}

export interface IProviderProps {
  children: React.ReactNode
}
const HeaderProvider = ({ children }: IProviderProps) => {
  const theme = useMantineTheme()
  const [base, setBase] = useState(false)
  const [key, setKey] = useState<KeyProps>({ base: false, title: '', border: false })
  const [border, setBorder] = useState(false)
  const [title, setTitle] = useState('')
  const [left, setLeft] = useState(<Text></Text>)
  const [right, setRight] = useState(<Text></Text>)

  const findIcon = (name?: string, click?: any) => {
    if (!name) {
      return <Text></Text>
    }
    switch (name) {
      case 'plus':
        return (
          <IconPlus
            size={20}
            color={theme.colors.primary[theme.fn.primaryShade()]}
            onClick={click}
          />
        )
      case 'arrow_left':
        return (
          <IconArrowLeft
            size={20}
            color={theme.colors.primary[theme.fn.primaryShade()]}
            onClick={click}
          />
        )
      case 'calendar':
        return (
          <IconCalendarEvent
            size={20}
            color={theme.colors.primary[theme.fn.primaryShade()]}
            onClick={click}
          />
        )
      case 'list_details':
        return (
          <IconListDetails
            size={20}
            color={theme.colors.primary[theme.fn.primaryShade()]}
            onClick={click}
          />
        )
      default:
        return <Text></Text>
    }
  }

  useEffect(() => {
    setBase(key.base)
    setTitle(key.title)
    setBorder(key.border)
    setLeft(findIcon(key.leftIcon, key.leftClick))
    setRight(findIcon(key.rightIcon, key.rightClick))
  }, [key])

  return (
    <HeaderContext.Provider value={{ base, border, title, left, right, setKey }}>
      {children}
    </HeaderContext.Provider>
  )
}

const useHeaderContext = () => useContext(HeaderContext)

export { HeaderProvider, useHeaderContext }
