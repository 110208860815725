import React from 'react'

interface IProps {
  color: string
  size?: number
  viewPort?: number
  className?: string
}

export const Info = ({ size = 24, viewPort = size, color, ...props }: IProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${viewPort} ${viewPort}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15 15H16V22H17" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M16 11.5C16.5523 11.5 17 11.0523 17 10.5C17 9.94772 16.5523 9.5 16 9.5C15.4477 9.5 15 9.94772 15 10.5C15 11.0523 15.4477 11.5 16 11.5Z"
      fill={color}
    />
  </svg>
)
