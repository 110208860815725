import { IconChevronLeft } from '@tabler/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { useBookingData } from '@/features/bookings/hooks'
import { useBookingPermissions } from '@/features/bookings/hooks'
import { useUser } from '@/features/user'
import { BookingPay, CancelBooking, FinishBooking, RequestFinishBooking } from './Actions'
import { CandidateStatusBlock } from './Blocks'

interface IProps {
  onDrawerClose: () => void
}

export const Footer = ({ onDrawerClose }: IProps) => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()

  const { booking } = useBookingData()

  const { isCancelAllow, isRequestFinishAllow, isFinishAllow, isPayAllow } = useBookingPermissions()

  const { isBabysitter } = useUser()

  return (
    <div className={mobileView ? 'flex flex-wrap gap-3 p-3 pb-0' : 'flex flex-wrap gap-3 p-3'}>
      {!mobileView && (
        <div className={'grow'}>
          <Button
            variant="default"
            color="dark"
            leftIcon={<IconChevronLeft />}
            onClick={onDrawerClose}
          >
            {t('back')}
          </Button>
        </div>
      )}

      {booking && (
        <>
          {mobileView ? (
            <div className={'flex flex-wrap w-100 gap-3'}>
              {isBabysitter() && <CandidateStatusBlock />}

              {isRequestFinishAllow(booking?.status) && <RequestFinishBooking booking={booking} />}

              {isFinishAllow(booking?.status) && <FinishBooking booking={booking} />}

              {isPayAllow(booking) && <BookingPay booking={booking} />}

              {isCancelAllow(booking?.status) && <CancelBooking />}
            </div>
          ) : (
            <div className={'flex flex-wrap w-100 gap-3'}>
              {isCancelAllow(booking?.status) && <CancelBooking />}

              {isBabysitter() && <CandidateStatusBlock />}

              {isRequestFinishAllow(booking?.status) && <RequestFinishBooking booking={booking} />}

              {isFinishAllow(booking?.status) && <FinishBooking booking={booking} />}

              {isPayAllow(booking) && <BookingPay booking={booking} />}
            </div>
          )}
        </>
      )}
    </div>
  )
}
