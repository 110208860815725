import React, { createContext, useContext, useState } from 'react'
import { Geocoding } from '../../services'

/* eslint-disable */
const GeocodingContext = createContext({
  client: {
    getGeocode: async (params: any) => {},
  },
  setClient: (client: any) => {},
})

/* eslint-enable */

interface IProps {
  children: React.ReactNode

  [x: string]: any
}

const GeocodingProvider = ({ children }: IProps) => {
  const [client, setClient] = useState<any>(Geocoding)

  return (
    <GeocodingContext.Provider value={{ client, setClient }}>{children}</GeocodingContext.Provider>
  )
}

const useGeocodingContext = () => useContext(GeocodingContext)

export { GeocodingProvider, useGeocodingContext }
