import React from 'react'
import { MyBookingCandidateType } from '@/features/booking-candidate/types/models'
import { CandidateAcceptControl, CandidateDeclineControl } from '../../Controls'

interface IProps {
  candidate: MyBookingCandidateType
  acceptControlProps: {
    onSuccess?: (data: object) => void
  }
  declineControlProps: {
    onSuccess?: (data: object) => void
  }
}

export const CandidateStatusBlock = ({
  candidate,
  acceptControlProps,
  declineControlProps,
}: IProps) => {
  return (
    <>
      <CandidateDeclineControl candidate={candidate} {...declineControlProps} />

      <CandidateAcceptControl candidate={candidate} {...acceptControlProps} />
    </>
  )
}
