import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/Elements'
import useStyles from './LoadMore.styles'

interface IProps {
  onLoad: () => void
}

export const LoadMore = ({ onLoad }: IProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  return (
    <div className={cx('mt-2 mb-4', classes.root)}>
      <Button onClick={onLoad} size={'xs'}>
        {t('load_more')}
      </Button>
    </div>
  )
}
