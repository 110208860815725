import React, { useEffect, useMemo } from 'react'
import { connect, useDispatch } from 'react-redux'
import { MetaPagination, OverlayDataShower } from '@/components/Elements'
import { useEvents } from '@/features/app/hooks'
import { BookingDrawerManager } from '@/features/bookings/components/Booking'
import { SPECIFIC_BOOKING_ALIAS } from '@/features/bookings/consts/specific-bookings'
import {
  BookingDrawerManagerProvider,
  BookingDrawerModuleProvider,
} from '@/features/bookings/contexts'
import { useBookingDrawerManager } from '@/features/bookings/hooks'
import {
  BookingUpdatedActionMeta,
  useBookingDrawerModule,
} from '@/features/bookings/hooks/useBookingDrawerModule'
import { bookingsBookingsSpecificList } from '@/features/bookings/store'
import { BookingModel, BookingService } from '@/features/bookings/types/models'
import { RatingSubmittedActionMeta } from '@/features/rating/hooks'
import { TAG_MANAGER_EVENT } from '@/features/tag-manager/consts/event'
import { RootState } from '@/store'
import { EmptyData } from './EmptyData'
import { GridView } from './GridView'
import useStyles from './SpecificBookingsContainer.styles'
import { getSpecificFetchParams } from './helpers'

interface IProps {
  alias: SPECIFIC_BOOKING_ALIAS

  list: BookingModel[]
  loading: boolean
  error: any
  meta: any
  page: number
}

const SpecificBookingsContainerComponent = ({ alias, ...props }: IProps) => {
  const { classes } = useStyles()

  const dispatch = useDispatch()
  const event = useEvents()

  useEffect(() => {
    event.actions.generic(
      alias === SPECIFIC_BOOKING_ALIAS.UPCOMING
        ? TAG_MANAGER_EVENT.BOOKING_UPCOMING
        : TAG_MANAGER_EVENT.BOOKING_HISTORY
    )

    return () => {
      dispatch(bookingsBookingsSpecificList.cleanState())
    }
  }, [])

  useEffect(() => {
    fetchList()
  }, [props.page])

  const fetchList = () => {
    const params = {
      page: props.page,
      sort: [{ id: 'date_start', desc: alias !== SPECIFIC_BOOKING_ALIAS.UPCOMING }],
      ...getSpecificFetchParams(alias),
    }

    dispatch(bookingsBookingsSpecificList.getList({ params }))
  }

  const onPageChange = (page: number) => {
    dispatch(bookingsBookingsSpecificList.setPage(page))
  }

  const bookingDrawerManager = useBookingDrawerManager({})

  const onBookingView = (id: number) => {
    bookingDrawerManager.navigation.onScreenView({ id })
  }

  const onBookingUpdated = (
    booking: BookingModel | BookingService | null,
    actionMeta: BookingUpdatedActionMeta
  ) => {
    fetchList()

    const { action } = actionMeta
    if (action && ['finish'].includes(action)) {
      bookingDrawerManager.navigation.onScreenClose()
    }
  }

  const onCancelSuccess = () => {
    fetchList()
  }

  const onBabysitterFavoriteSuccess = (data: any) => {
    dispatch(bookingsBookingsSpecificList.updateBabysitter(data))
  }

  const onRatingSubmitted = (rating: any | null, actionMeta: RatingSubmittedActionMeta) => {
    const { payload } = actionMeta
    const { bookingId } = payload

    dispatch(
      bookingsBookingsSpecificList.setBookingBabysitterRating({
        id: bookingId,
        rating,
      })
    )
  }

  const bookingDrawerModule = useBookingDrawerModule({ onBookingUpdated, onRatingSubmitted })

  const bookingActionBarProps = useMemo(() => {
    return {
      config: {
        menu: alias === SPECIFIC_BOOKING_ALIAS.UPCOMING,
        favorite: alias === SPECIFIC_BOOKING_ALIAS.HISTORY,
        chat: alias === SPECIFIC_BOOKING_ALIAS.UPCOMING,
        rating: alias === SPECIFIC_BOOKING_ALIAS.HISTORY,
        upcomingPanel: alias === SPECIFIC_BOOKING_ALIAS.UPCOMING,
      },
    }
  }, [alias])

  const bookingFooterProps = useMemo(() => {
    return {
      config: {
        rating: alias === SPECIFIC_BOOKING_ALIAS.HISTORY,
      },
    }
  }, [alias])

  return (
    <div className={classes.root}>
      <OverlayDataShower isLoading={props.loading} isFailed={!!props.error} error={props.error}>
        {!!props.list.length && (
          <div className={'flex flex-col justify-between h-full'}>
            <GridView
              items={props.list}
              onView={onBookingView}
              actionBarProps={{ ...bookingActionBarProps }}
              onCancelSuccess={onCancelSuccess}
              onBabysitterFavoriteSuccess={onBabysitterFavoriteSuccess}
              footerProps={{ ...bookingFooterProps }}
              onRatingSubmitted={onRatingSubmitted}
            />

            <MetaPagination meta={props.meta} page={props.page} onChange={onPageChange} />
          </div>
        )}

        {!props.list.length && !props.loading && !props.error && <EmptyData />}
      </OverlayDataShower>

      <BookingDrawerManagerProvider {...bookingDrawerManager}>
        <BookingDrawerModuleProvider {...bookingDrawerModule}>
          <BookingDrawerManager />
        </BookingDrawerModuleProvider>
      </BookingDrawerManagerProvider>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const { list, loading, error, meta, page } = state.bookings.bookings.specific.list
  return {
    list,
    loading,
    error,
    meta,
    page,
  }
}

export const SpecificBookingsContainer = connect(mapStateToProps)(
  SpecificBookingsContainerComponent
)
