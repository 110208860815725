import React from 'react'

interface IProps {
  color: string
  size?: number
  viewPort?: number
  className?: string
}

export const Chat = ({ size = 24, viewPort = size, color, ...props }: IProps) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox={`0 0 ${viewPort} ${viewPort}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.75C6.89136 2.75 2.75 6.89136 2.75 12V21.25H12C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89136 17.1086 2.75 12 2.75ZM2 22L1.25 21.9999V12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75H2.00008L2 22ZM2 22L2.00008 22.75C1.58587 22.75 1.25 22.4141 1.25 21.9999L2 22Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 9C6.25 8.58579 6.58579 8.25 7 8.25H16C16.4142 8.25 16.75 8.58579 16.75 9C16.75 9.41421 16.4142 9.75 16 9.75H7C6.58579 9.75 6.25 9.41421 6.25 9Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 13C6.25 12.5858 6.58579 12.25 7 12.25H16C16.4142 12.25 16.75 12.5858 16.75 13C16.75 13.4142 16.4142 13.75 16 13.75H7C6.58579 13.75 6.25 13.4142 6.25 13Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 17C6.25 16.5858 6.58579 16.25 7 16.25H12C12.4142 16.25 12.75 16.5858 12.75 17C12.75 17.4142 12.4142 17.75 12 17.75H7C6.58579 17.75 6.25 17.4142 6.25 17Z"
      fill={color}
    />
  </svg>
)
