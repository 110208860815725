import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { userAPI } from '@/features/users/api'
import { GetUserDTO } from '@/features/users/api/dto'
import i18n from '@/i18n'
import { RequestUtils } from '@/utils'
import { babysitterData } from '../data/slice'

function* getData({
  payload: { id, params = {} },
}: PayloadAction<{ id: number | string; params: GetUserDTO }>) {
  try {
    let requestParams: any = {
      includes: ['avatar', 'babysitterProfile', 'myFavoriteBabysitter'],
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response: AxiosResponse = yield call(userAPI.getUser, +id, requestParams)

    const { data } = response

    yield put(babysitterData.getDataSuccess(data))
  } catch (error: any) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(babysitterData.getDataError(message))
  }
}

function* watchGetData() {
  yield takeEvery(babysitterData.getData, getData)
}

export function* dataSaga() {
  yield all([fork(watchGetData)])
}
