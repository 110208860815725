import { IconDownload, IconEdit, IconTrash } from '@tabler/icons'
import React from 'react'
import { ActionIconProps, ActionIcon as MantineActionIcon } from '@mantine/core'

interface IProps extends ActionIconProps {
  className?: string
  name: 'remove' | 'edit' | 'download'
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const ActionIconButton: React.FC<IProps> = ({ name, children, className, ...props }) => {
  const config = () => {
    let data: any = {
      icon: '',
      color: '',
      bgColor: '',
    }
    switch (name) {
      case 'remove':
        data = {
          icon: <IconTrash />,
          color: 'primary',
        }
        break

      case 'edit':
        data = {
          icon: <IconEdit />,
          color: 'primary',
        }
        break

      case 'download':
        data = {
          icon: <IconDownload />,
          color: 'primary',
        }
        break

      default:
        data = { ...data }
        break
    }
    return data
  }

  return (
    <MantineActionIcon variant="transparent" color={config().color} {...props}>
      {config().icon}
    </MantineActionIcon>
  )
}
