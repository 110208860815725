import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { RegisterContainer } from '@/features/auth/components/Register'
import { ROLE } from '@/features/user'

interface IProps {
  role: ROLE
}

export const Register = ({ role }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={role === ROLE.Client ? 'client_sign_up' : 'babysitter_sign_up'} />

      <RegisterContainer role={role} />
    </>
  )
}
