import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    minHeight: '90px',
    flexWrap: 'wrap',
    padding: '40px 0 10px',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      padding: '16px 0',
    },
  },
  headerRight: {
    display: 'flex',
    alignItems: 'end',
  },
}))
