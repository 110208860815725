import axios from 'axios'

class ChatsAPI {
  getChat = async (id: number | string) => {
    return axios.get(`api/chats/${id}`)
  }
  getChats = async () => {
    return axios.get(`api/chats`)
  }
  uploadImage = async (params: any) => {
    return axios.post(`api/chats/chat-image-upload`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
  uploadKidsImage = async (params: any) => {
    return axios.post(`api/chats/kids-image-upload`, params)
  }
}

export const chatsAPI = new ChatsAPI()
