import React from 'react'
import { useAppSelector } from '@/store'

export const useAppState = () => {
  const appState = useAppSelector((state) => state.app.state)

  return {
    appState,
  }
}
