import React from 'react'
import useStyles from './Card.styles'

interface IProps {
  children: React.ReactNode
  className?: string
}

export const Card: React.FC<IProps> = ({ children, className, ...props }) => {
  const { classes, cx } = useStyles()
  return (
    <div className={cx(classes.card, className)} {...props}>
      {children}
    </div>
  )
}
