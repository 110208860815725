import React from 'react'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { CheckboxField, CheckboxFieldProps } from '../../CheckboxField'

interface CheckboxFieldControllerProps<T extends FieldValues> extends CheckboxFieldProps {
  control: Control<T, any>
  name: FieldPath<T>
  shouldUnregister?: boolean
  defaultValue?: any
  meta?: any
}

export const CheckboxFieldController = <T extends FieldValues>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  ...rest
}: CheckboxFieldControllerProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field: { ref, ...field }, fieldState, formState }) => {
        const meta = {
          error: fieldState.error,
        }

        return <CheckboxField {...field} fieldRef={ref} {...rest} />
      }}
    />
  )
}
