import React from 'react'
import { useTranslation } from 'react-i18next'
import { Calendar, CalendarProps } from '@/components/Elements'
import useStyles from './DatePickerCalendar.styles'

// eslint-disable-next-line
export interface DatePickerCalendarProps extends CalendarProps {}

export const DatePickerCalendar: React.FC<DatePickerCalendarProps> = (props) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <>
      <Calendar {...props} />
    </>
  )
}
