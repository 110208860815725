import { timetableHelper } from '@/features/booking/helpers'

export const getWeekDaysDefaultValues = () => {
  const weekdays = timetableHelper.weekdays.getWeekDays()
  return Object.assign(
    {},
    ...weekdays.map(({ key }) => ({
      [key]: {
        enabled: false,
        time_start: null,
        time_end: null,
      },
    }))
  )
}

export const getDaysScheduleDefaultValues = () => getWeekDaysDefaultValues()
