import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    display: 'flex',
    minWidth: '30px',
    height: '30px',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '15px',
    fontWeight: 700,
    paddingLeft: '5px',
    paddingRight: '5px',
    borderRadius: '1000px',
    color: theme.white,
    backgroundColor: theme.colors.primary[theme.fn.primaryShade()],
    whiteSpace: 'nowrap',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: '12px',
      minWidth: '16px',
      height: '16px',
    },
  },

  active: {
    color: theme.colors.primary[theme.fn.primaryShade()],
    backgroundColor: theme.white,
  },
}))
