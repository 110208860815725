import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { bookingsAPI } from '@/features/bookings/api'
import { SubmitBookingDTO } from '@/features/bookings/api/dto'
import { BookingModel } from '@/features/bookings/types/models'
import { FormTypes } from '@/types'
import {
  BOOKING_CREATE_FAILED,
  BOOKING_CREATE_REQUEST,
  BOOKING_CREATE_SUCCESS,
} from './actionTypes'

export const bookingCreatePA = createPromiseAction(
  BOOKING_CREATE_REQUEST,
  BOOKING_CREATE_SUCCESS,
  BOOKING_CREATE_FAILED
)<SubmitBookingDTO, BookingModel, AxiosError<FormTypes.ValidationErrors>>()

function* create(action: PromiseAction<string, SubmitBookingDTO, any>) {
  try {
    const response: AxiosResponse = yield call(bookingsAPI.createBooking, action.payload)
    const { data } = response

    yield put(bookingCreatePA.success({ ...data }))

    resolvePromiseAction(action, { ...data })
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* createSaga() {
  yield takeEvery(bookingCreatePA.request, create)
}
