import { IconArrowRight } from '@tabler/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Anchor, Group, Text } from '@mantine/core'

interface IProps {
  id: number
  onView: (id: number) => void
}

export const BookingDetails = ({ id, onView }: IProps) => {
  const { t } = useTranslation()

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    onView(id)
  }

  return (
    <Anchor component={Link} to={``} color={'dark'} onClick={onClick}>
      <Group spacing={'xs'} noWrap>
        <Text size={15} weight={700}>
          {t('more_details')}
        </Text>

        <IconArrowRight size={20} />
      </Group>
    </Anchor>
  )
}
