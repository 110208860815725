import React from 'react'
import { Badge, BadgeProps } from '@mantine/core'
import useStyles from './TagBadge.styles'

export interface TagBadgeProps extends BadgeProps {
  children: React.ReactNode
  component?: any
  type?: 'tag' | 'badge'

  [x: string]: any
}

export const TagBadge = ({ children, type = 'tag', ...props }: TagBadgeProps) => {
  const { classes, cx } = useStyles()

  return (
    <>
      <Badge
        color={'gray'}
        classNames={classes}
        className={cx(classes, {
          [classes.tagMode]: type === 'tag',
        })}
        styles={(theme, _params) => {
          const { color } = _params
          return {
            root: {
              backgroundColor:
                color === 'secondary'
                  ? theme.fn.rgba(theme.colors.secondary[theme.fn.primaryShade()], 0.4)
                  : color === 'gray'
                  ? theme.colors.gray[1]
                  : theme.colors[color][0],
            },
          }
        }}
        {...props}
      >
        {children}
      </Badge>
    </>
  )
}
