import React from 'react'
import { useChatConversationData } from '@/features/chat/hooks'
import { ROLE, useUser } from '@/features/user'
import { ActionBar } from './ActionBar'
import useStyles from './Header.styles'
import { Info } from './Info'

export const Header = () => {
  const { user, isBabysitter, isClient } = useUser()
  const { classes, cx } = useStyles()

  const { getParticipantsBabysitters, getParticipantsClient, getParticipantsBabysittersNotMe } =
    useChatConversationData()

  const participants = isBabysitter() ? getParticipantsClient() : getParticipantsBabysitters()

  const otherBabysitters = isBabysitter() ? getParticipantsBabysittersNotMe(user.id) : undefined

  const viewProfile = participants && isClient() && participants[0].role === ROLE.Babysitter
  const viewBabysitters = otherBabysitters && isBabysitter()

  return (
    <div className={cx(classes.root, 'flex flex-wrap gap-4 items-center')}>
      <div className={'flex grow'}>
        <Info />
      </div>

      {viewProfile && participants && participants.length == 1 && (
        <div className={'self-end'}>
          <ActionBar config={{ viewProfile }} />
        </div>
      )}
      {viewBabysitters && (
        <div className={'self-end'}>
          <ActionBar config={{ viewBabysitters, otherBabysitters: otherBabysitters }} />
        </div>
      )}
    </div>
  )
}
