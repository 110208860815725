import React, { createContext, useContext, useState } from 'react'
import { EchoClient } from '@/services'

/* eslint-disable */
const EchoContext = createContext({
  client: {
    create: () => {},
    destroy: () => {},
    privateChannelAddListener: (channel: string, event: string, action: any) => {},
    privateChannelNotification: (channel: string, action: any) => {},
    privateChannelAddListeners: (channel: string, events: object) => {},
    channelLeaveAll: () => {},
    privateChannelStopListeners: (channel: string, events: string[]) => {},
  },
  setClient: (client: any) => {},

  init: false,
  setInit: (status: boolean) => {},
})

/* eslint-enable */

interface IProps {
  children: React.ReactNode

  [x: string]: any
}

const EchoProvider = ({ children }: IProps) => {
  const [client, setClient] = useState<any>(EchoClient)
  const [init, setInit] = useState<boolean>(false)

  return (
    <EchoContext.Provider value={{ client, setClient, init, setInit }}>
      {children}
    </EchoContext.Provider>
  )
}

const useEchoContext = () => useContext(EchoContext)

export { EchoProvider, useEchoContext }
