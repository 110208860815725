export enum SCREEN {
  VIEW = 'view',
  CREATE = 'create',
  EDIT = 'edit',
}

export const INITIAL_STATE = {
  screen: null,
  screenData: null,
}
