import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  container: {
    width: '100%',
    margin: '0',
    height: '350px',
    position: 'relative',
    backgroundImage: 'url("/images/commons/large-pill-desktop.png")',
    backgroundSize: '100% 100%',
    overflow: 'hidden',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      height: '300px',
    },
  },
  content: {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    //z-index: 1;
    padding: '24px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    textAlign: 'left',
  },
  stars: {
    display: 'flex',
  },
  starsSvgPath: {
    /* fill: var(--Orange); */
    fill: '#fbbc04',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    fontFamily: 'Recoleta',
    fontWeight: 'bold',
    color: '#000000',
    fontSize: '20px',
    lineHeight: '30px',
    marginBottom: '0',
  },
  text: {
    color: '#505a5f',
    fontFamily: 'Circular Std Book',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '20px',
  },
  role: {
    color: '#505a5f',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '20px',
  },
}))
