import React from 'react'

interface IProps {
  size?: number
  className?: string
  style?: any
  stroke?: string
}

export const Arrow = ({ size = 24, stroke = '#292D32', ...props }: IProps) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox={`0 0 24 24`}
    fill={'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0699 14.43L11.9999 20.5L5.92993 14.43"
      stroke={stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 3.5V20.33"
      stroke={stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
