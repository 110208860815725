import { useMantineTheme } from '@mantine/core'
import { ArrowsSpin } from './ArrowsSpin'
import { BabyFace } from './BabyFace'
import { Book } from './Book'
import { CalendarSearch } from './CalendarSearch'
import { CareDetails } from './CareDetails'
import { Chat } from './Chat'
import { Check } from './Check'
import { Clock } from './Clock'
import { CreditCard } from './CreditCard'
import { Dots } from './Dots'
import { GlobeStand } from './GlobeStand'
import { Heart } from './Heart'
import { HeartOutline } from './HeartOutline'
import { Info } from './Info'
import { Logout } from './Logout'
import { MapPin } from './MapPin'
import { Medal } from './Medal'
import { Profile } from './Profile'
import { SuitcaseSimple } from './SuitcaseSimple'
import { Support } from './Support'
import { Wallet } from './Wallet'

export type IconNameType =
  | 'calendar-search'
  | 'baby-face'
  | 'chat'
  | 'wallet'
  | 'logout'
  | 'check'
  | 'info'
  | 'globe-stand'
  | 'suitcase-simple'
  | 'medal'
  | 'map-pin'
  | 'credit-card'
  | 'clock'
  | 'dots'
  | 'arrows-spin'
  | 'camera'
  | 'heart'
  | 'heart-outline'
  | 'profile'
  | 'book'
  | 'support'
  | 'care-details'

interface IProps {
  name: IconNameType
  size?: number
  color?: string
  className?: string

  [x: string]: any
}

export const Icon = ({ name, ...props }: IProps) => {
  const theme = useMantineTheme()

  const color = props?.color || theme.black

  switch (name) {
    case 'calendar-search':
      return <CalendarSearch color={color} {...props} />

    case 'baby-face':
      return <BabyFace color={color} {...props} />

    case 'chat':
      return <Chat color={color} {...props} />

    case 'wallet':
      return <Wallet color={color} {...props} />

    case 'logout':
      return <Logout color={color} {...props} />

    case 'book':
      return <Book color={color} {...props} />

    case 'check':
      return <Check color={color} {...props} />

    case 'info':
      return <Info color={color} {...props} />

    case 'globe-stand':
      return <GlobeStand color={color} {...props} />

    case 'suitcase-simple':
      return <SuitcaseSimple color={color} {...props} />

    case 'medal':
      return <Medal color={color} {...props} />

    case 'map-pin':
      return <MapPin color={color} {...props} />

    case 'credit-card':
      return <CreditCard color={color} {...props} />

    case 'clock':
      return <Clock color={color} {...props} />

    case 'dots':
      return <Dots color={color} {...props} />

    case 'arrows-spin':
      return <ArrowsSpin color={color} {...props} />

    case 'heart':
      return <Heart color={color} {...props} />

    case 'heart-outline':
      return <HeartOutline color={color} {...props} />

    case 'profile':
      return <Profile color={color} {...props} />

    case 'camera':
      return <Profile color={color} {...props} />

    case 'support':
      return <Support color={color} {...props} />

    case 'care-details':
      return <CareDetails color={color} {...props} />

    default:
      return null
  }
}
