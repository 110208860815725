import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  wrapper: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },

  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },

  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: theme.fn.linearGradient(
      180,
      'rgba(28, 27, 30, 0) 25%',
      'rgba(28, 27, 30, 0.49) 68.83%'
    ),
  },

  bgImage: {
    height: '100%',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center right',
  },

  contentBlock: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: '100px 30px 100px 110px',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: theme.white,
    textAlign: 'center',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      padding: '100px 30px 100px 30px',
    },

    a: {
      color: theme.black,
    },
  },

  content: {
    color: theme.white,
  },
}))
