import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useEvents } from '@/features/app/hooks'
import { profileClientUpdatePA } from '@/features/profile/store'
import { TAG_MANAGER_EVENT } from '@/features/tag-manager/consts/event'
import { useNotify } from '@/hooks'
import { EditForm } from './EditForm'

interface IProps {
  onCancel: () => void
}

export const ClientProfileEdit = ({ onCancel }: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const event = useEvents()
  const { showNotification } = useNotify()

  useEffect(() => event.actions.generic(TAG_MANAGER_EVENT.PROFILE_EDIT), [])

  const onSubmit = async (values: any) => {
    await dispatch(profileClientUpdatePA.request(values))
    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })
    onCancel()
  }

  return (
    <>
      <EditForm onCancel={onCancel} onSubmit={onSubmit} />
    </>
  )
}
