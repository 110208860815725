import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AvatarProvider } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'

export const BookingCheckoutDetails = () => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()
  const { booking } = useSelector((state: any) => state.booking.checkout)
  return (
    booking && (
      <div className="flex bg-white rounded-lg shadow-sm mb-8 p-3 gap-3">
        <AvatarProvider
          checkout={true}
          size={mobileView ? 60 : 90}
          radius={mobileView ? 30 : 45}
          avatar={null}
        />
        <div>
          <div className="text-md">{t('checkout.babysitter_title')}</div>
          <div className="text-sm text-bold mt-1">{t('checkout.babysitter_title_name')}</div>
        </div>
      </div>
    )
  )
}
