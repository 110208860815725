import * as React from 'react'
import { Group } from '@mantine/core'
import { AppLangPicker, LogoIcon } from '@/components/Elements'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { Info } from '@/features/header/layout/Info'
import { Support } from '@/features/header/layout/Support'
import useStyles from './Header.styles'

interface IProps {
  bookingType?: BOOKING_TYPE
}
export const BookingDesktopHeader = ({ bookingType = undefined }: IProps) => {
  const { classes } = useStyles()

  return (
    <div className={classes.header}>
      <LogoIcon size={'lg'} />
      <div className="mr-3">
        <Group>
          {bookingType && <Info bookingType={bookingType} />}
          <Support />
          <AppLangPicker />
        </Group>
      </div>
    </div>
  )
}
