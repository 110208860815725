import React, { createContext, useContext } from 'react'

const RatingEntryPopupManagerContext = createContext({
  state: {
    isCreateScreen: false,
    isEditScreen: false,
    screenData: null as any,
  },
  actions: {},
  navigation: {
    onScreenClose: () => null,
    onScreenDataClean: () => null,
    onScreenCreate: () => null,
    onScreenEdit: () => null,
  },
})

interface IProps {
  children: React.ReactNode
  state: any
  actions: any
  navigation: any

  [x: string]: any
}

const RatingEntryPopupManagerProvider = ({ children, ...props }: IProps) => {
  return (
    <RatingEntryPopupManagerContext.Provider value={{ ...props }}>
      {children}
    </RatingEntryPopupManagerContext.Provider>
  )
}

const useRatingEntryPopupManagerContext = () => useContext(RatingEntryPopupManagerContext)

export { RatingEntryPopupManagerProvider, useRatingEntryPopupManagerContext }
