import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { NavTabs } from '@/components/Elements'
import { SPECIFIC_BOOKING_ALIAS } from '@/features/bookings/consts/specific-bookings'

const { UPCOMING, HISTORY } = SPECIFIC_BOOKING_ALIAS

export const BookingsTabsLayout = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const location = useLocation()

  const TABS_CONFIG = {
    [UPCOMING]: {
      label: t('upcoming'),
      value: 'upcoming',
      path: '/bookings',
    },
    [HISTORY]: {
      label: t('history'),
      value: 'history',
      path: '/bookings/history',
    },
  }

  const tabsConfigValues = Object.values(TABS_CONFIG)

  const tabs = useMemo(() => {
    return tabsConfigValues.map(({ label, value }) => ({
      label,
      value,
    }))
  }, [])

  const onTabChange = (value: SPECIFIC_BOOKING_ALIAS) => {
    const tabConfig = TABS_CONFIG[value]

    navigate(`${tabConfig?.path}`)
  }

  const tabsValue = useMemo(() => {
    const pathName = location.pathname

    for (let i = 0; i < tabsConfigValues.length; i++) {
      const item = tabsConfigValues[i]

      if (pathName === item.path) {
        return item.value
      }
    }

    return ''
  }, [location])

  return (
    <>
      <div className={'mb-4'}>
        <NavTabs value={tabsValue} onChange={onTabChange} tabs={tabs} />
      </div>

      <Outlet />
    </>
  )
}
