import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { BookingDaysDataLoader } from '@/features/bookings/components/Booking/BookingDetails'
import { useBookingData } from '@/features/bookings/hooks'
import { useAppSelector } from '@/store'
import { DaysSchedule } from './DaysSchedule'
import { ScheduleCalendar } from './ScheduleCalendar'

export const BookingScheduleContainer = () => {
  const { t } = useTranslation()

  const { booking } = useBookingData()

  const { list: days } = useAppSelector((state) => state.bookings.days.list)

  return (
    <BookingDaysDataLoader id={booking?.id}>
      <Grid className={'lg:h-full'}>
        <Grid.Col lg={6} order={2} orderLg={1} className={'lg:h-full'}>
          <DaysSchedule days={days} />
        </Grid.Col>
        <Grid.Col lg={6} order={1} orderLg={2} className={'lg:h-full'}>
          <ScheduleCalendar days={days} />
        </Grid.Col>
      </Grid>
    </BookingDaysDataLoader>
  )
}
