import React from 'react'
import { LoadingOverlay, LoadingOverlayProps } from '@mantine/core'

// eslint-disable-next-line
export interface OverlayLoadingProps extends LoadingOverlayProps {}

export const OverlayLoading = ({ ...props }: OverlayLoadingProps) => {
  return (
    <>
      <LoadingOverlay {...props} />
    </>
  )
}
