import { all } from 'redux-saga/effects'
import { babysitterCancelSaga } from './babysitter-cancel/sagas'
import { bookingsSaga as listsSaga } from './bookings/sagas'
import { cancelSaga } from './cancel/sagas'
import { dataSaga } from './data/saga'
import { daysSaga } from './days/sagas'
import { finishSaga } from './finish/sagas'
import { invoiceSaga } from './invoice/sagas'
import { ratingSaga } from './rating/sagas'
import { requestFinishSaga } from './request-finish/sagas'
import { trackingSaga } from './tracking/sagas'

export function* bookingsSaga() {
  yield all([
    listsSaga(),
    dataSaga(),
    daysSaga(),
    cancelSaga(),
    babysitterCancelSaga(),
    requestFinishSaga(),
    finishSaga(),
    trackingSaga(),
    ratingSaga(),
    invoiceSaga(),
  ])
}
