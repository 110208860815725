import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  wrap: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      height: '100%',
      padding: '0px 32px',
    },
  },
  back: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      padding: '0px 32px',
    },
  },
}))
