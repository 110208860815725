import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { ContentCard } from '@/components/Elements'
import { BottomTabsBookNow } from '@/components/Elements/Tabs/BottomTabs/BottomTabsBookNow'
import { useAppState } from '@/features/app/hooks'

export const BookGeneralLayout = () => {
  const { t } = useTranslation()
  const viewport = useRef<HTMLDivElement>()
  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <>
      {!mobileView ? (
        <ContentCard
          title={t('book')}
          footer={<BottomTabsBookNow />}
          scrollable={true}
          viewport={viewport}
        >
          <Outlet />
        </ContentCard>
      ) : (
        <div>
          <Outlet />
        </div>
      )}
    </>
  )
}
