import React from 'react'
import { Navigate } from 'react-router-dom'
import { ROLE } from '@/features/user'
import { useUser } from '@/features/user'
import { useFromPath } from '@/hooks'
import { GUARD, profileCompleteGuard } from '../../guards'

interface IProps {
  children: React.ReactNode
  roles?: Array<string>
  guards?: Array<GUARD>

  [x: string]: any
}

export const RouteGuard: React.FC<IProps> = ({ children, roles, guards = [], ...props }) => {
  const from = useFromPath()

  const { user } = useUser()

  const withGuard = (type: GUARD) => {
    return guards.some((guard) => guard === type)
  }

  if (roles && user && !roles.includes(user.role)) {
    const path = user.role === ROLE.Client ? '/bookings' : '/services/upcoming'
    return <Navigate to={path} state={{ from }} />
  } else if (withGuard(GUARD.PROFILE_COMPLETE) && !profileCompleteGuard.condition(user)) {
    return <Navigate to={profileCompleteGuard.redirectPath} state={{ from }} />
  }

  return <>{children}</>
}
