import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {},
  scrollArea: {
    height: 'calc(100vh - 88px)',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      height: '100%',
    },
  },
}))
