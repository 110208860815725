import { Global } from '@mantine/core'
import circularStdBold from '@/assets/fonts/CircularStd/CircularStd-Bold.ttf'
import circularStdBook from '@/assets/fonts/CircularStd/CircularStd-Book.ttf'
import circularStdMedium from '@/assets/fonts/CircularStd/CircularStd-Medium.ttf'
import bold from '@/assets/fonts/Quicksand/Quicksand-Bold.ttf'
import light from '@/assets/fonts/Quicksand/Quicksand-Light.ttf'
import medium from '@/assets/fonts/Quicksand/Quicksand-Medium.ttf'
import regular from '@/assets/fonts/Quicksand/Quicksand-Regular.ttf'
import semiBold from '@/assets/fonts/Quicksand/Quicksand-SemiBold.ttf'
import recoletaRegular from '@/assets/fonts/Recoleta/Recoleta-Regular.ttf'
import recoletaSemiBold from '@/assets/fonts/Recoleta/Recoleta-SemiBold.ttf'

export function Fonts() {
  return (
    <Global
      styles={[
        {
          '@font-face': {
            fontFamily: 'Quicksand',
            src: `url('${light}')`,
            fontWeight: 300,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Quicksand',
            src: `url('${regular}')`,
            fontWeight: 400,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Quicksand',
            src: `url('${medium}')`,
            fontWeight: 500,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Quicksand',
            src: `url('${semiBold}')`,
            fontWeight: 600,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Quicksand',
            src: `url('${bold}')`,
            fontWeight: 700,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Recoleta',
            src: `url('${recoletaSemiBold}')`,
            fontWeight: 600,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Recoleta',
            src: `url('${recoletaRegular}')`,
            fontWeight: 'normal',
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Recoleta',
            src: `url('${recoletaSemiBold}')`,
            fontWeight: 'bold',
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Circular Std',
            src: `url('${circularStdMedium}')`,
            fontWeight: 'normal',
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Circular Std',
            src: `url('${circularStdBold}')`,
            fontWeight: 'bold',
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Circular Std Book',
            src: `url('${circularStdBook}')`,
            fontWeight: 'normal',
            fontStyle: 'normal',
          },
        },
      ]}
    />
  )
}

/*
@font-face {
  font-family: 'Recoleta Alt';
  src: url('../public/fonts/recoleta/RecoletaAlt-Thin.eot');
  src: local('Recoleta Alt Thin'), local('RecoletaAlt-Thin'),
    url('../public/fonts/recoleta/RecoletaAlt-Thin.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/recoleta/RecoletaAlt-Thin.woff2') format('woff2'),
    url('../public/fonts/recoleta/RecoletaAlt-Thin.woff') format('woff'),
    url('../public/fonts/recoleta/RecoletaAlt-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta';
  src: url('../public/fonts/recoleta/Recoleta-Medium.eot');
  src: local('Recoleta Medium'), local('Recoleta-Medium'),
    url('../public/fonts/recoleta/Recoleta-Medium.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/recoleta/Recoleta-Medium.woff2') format('woff2'),
    url('../public/fonts/recoleta/Recoleta-Medium.woff') format('woff'),
    url('../public/fonts/recoleta/Recoleta-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta Alt';
  src: url('../public/fonts/recoleta/RecoletaAlt-SemiBold.eot');
  src: local('Recoleta Alt SemiBold'), local('RecoletaAlt-SemiBold'),
    url('../public/fonts/recoleta/RecoletaAlt-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/recoleta/RecoletaAlt-SemiBold.woff2') format('woff2'),
    url('../public/fonts/recoleta/RecoletaAlt-SemiBold.woff') format('woff'),
    url('../public/fonts/recoleta/RecoletaAlt-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta Alt';
  src: url('../public/fonts/recoleta/RecoletaAlt-Medium.eot');
  src: local('Recoleta Alt Medium'), local('RecoletaAlt-Medium'),
    url('../public/fonts/recoleta/RecoletaAlt-Medium.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/recoleta/RecoletaAlt-Medium.woff2') format('woff2'),
    url('../public/fonts/recoleta/RecoletaAlt-Medium.woff') format('woff'),
    url('../public/fonts/recoleta/RecoletaAlt-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta Alt';
  src: url('../public/fonts/recoleta/RecoletaAlt-Regular.eot');
  src: local('Recoleta Alt Regular'), local('RecoletaAlt-Regular'),
    url('../public/fonts/recoleta/RecoletaAlt-Regular.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/recoleta/RecoletaAlt-Regular.woff2') format('woff2'),
    url('../public/fonts/recoleta/RecoletaAlt-Regular.woff') format('woff'),
    url('../public/fonts/recoleta/RecoletaAlt-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta';
  src: url('../public/fonts/recoleta/Recoleta-Thin.eot');
  src: local('Recoleta Thin'), local('Recoleta-Thin'),
    url('../public/fonts/recoleta/Recoleta-Thin.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/recoleta/Recoleta-Thin.woff2') format('woff2'),
    url('../public/fonts/recoleta/Recoleta-Thin.woff') format('woff'),
    url('../public/fonts/recoleta/Recoleta-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta Alt';
  src: url('../public/fonts/recoleta/RecoletaAlt-Bold.eot');
  src: local('Recoleta Alt Bold'), local('RecoletaAlt-Bold'),
    url('../public/fonts/recoleta/RecoletaAlt-Bold.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/recoleta/RecoletaAlt-Bold.woff2') format('woff2'),
    url('../public/fonts/recoleta/RecoletaAlt-Bold.woff') format('woff'),
    url('../public/fonts/recoleta/RecoletaAlt-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta Alt';
  src: url('../public/fonts/recoleta/RecoletaAlt-Black.eot');
  src: local('Recoleta Alt Black'), local('RecoletaAlt-Black'),
    url('../public/fonts/recoleta/RecoletaAlt-Black.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/recoleta/RecoletaAlt-Black.woff2') format('woff2'),
    url('../public/fonts/recoleta/RecoletaAlt-Black.woff') format('woff'),
    url('../public/fonts/recoleta/RecoletaAlt-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta Alt';
  src: url('../public/fonts/recoleta/RecoletaAlt-Light.eot');
  src: local('Recoleta Alt Light'), local('RecoletaAlt-Light'),
    url('../public/fonts/recoleta/RecoletaAlt-Light.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/recoleta/RecoletaAlt-Light.woff2') format('woff2'),
    url('../public/fonts/recoleta/RecoletaAlt-Light.woff') format('woff'),
    url('../public/fonts/recoleta/RecoletaAlt-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta';
  src: url('../public/fonts/recoleta/Recoleta-Black.eot');
  src: local('Recoleta Black'), local('Recoleta-Black'),
    url('../public/fonts/recoleta/Recoleta-Black.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/recoleta/Recoleta-Black.woff2') format('woff2'),
    url('../public/fonts/recoleta/Recoleta-Black.woff') format('woff'),
    url('../public/fonts/recoleta/Recoleta-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta';
  src: url('../public/fonts/recoleta/Recoleta-Light.eot');
  src: local('Recoleta Light'), local('Recoleta-Light'),
    url('../public/fonts/recoleta/Recoleta-Light.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/recoleta/Recoleta-Light.woff2') format('woff2'),
    url('../public/fonts/recoleta/Recoleta-Light.woff') format('woff'),
    url('../public/fonts/recoleta/Recoleta-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../public/fonts/circular_std/CircularStd-MediumItalic.eot');
  src: local('Circular Std Medium Italic'), local('CircularStd-MediumItalic'),
    url('../public/fonts/circular_std/CircularStd-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/circular_std/CircularStd-MediumItalic.woff2') format('woff2'),
    url('../public/fonts/circular_std/CircularStd-MediumItalic.woff') format('woff'),
    url('../public/fonts/circular_std/CircularStd-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../public/fonts/circular_std/CircularStd-Black.eot');
  src: local('Circular Std Black'), local('CircularStd-Black'),
    url('../public/fonts/circular_std/CircularStd-Black.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/circular_std/CircularStd-Black.woff2') format('woff2'),
    url('../public/fonts/circular_std/CircularStd-Black.woff') format('woff'),
    url('../public/fonts/circular_std/CircularStd-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../public/fonts/circular_std/CircularStd-BlackItalic.eot');
  src: local('Circular Std Black Italic'), local('CircularStd-BlackItalic'),
    url('../public/fonts/circular_std/CircularStd-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/circular_std/CircularStd-BlackItalic.woff2') format('woff2'),
    url('../public/fonts/circular_std/CircularStd-BlackItalic.woff') format('woff'),
    url('../public/fonts/circular_std/CircularStd-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Circular Std Book';
  src: url('../public/fonts/circular_std/CircularStd-BookItalic.eot');
  src: local('Circular Std Book Italic'), local('CircularStd-BookItalic'),
    url('../public/fonts/circular_std/CircularStd-BookItalic.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/circular_std/CircularStd-BookItalic.woff2') format('woff2'),
    url('../public/fonts/circular_std/CircularStd-BookItalic.woff') format('woff'),
    url('../public/fonts/circular_std/CircularStd-BookItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../public/fonts/circular_std/CircularStd-BoldItalic.eot');
  src: local('Circular Std Bold Italic'), local('CircularStd-BoldItalic'),
    url('../public/fonts/circular_std/CircularStd-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/circular_std/CircularStd-BoldItalic.woff2') format('woff2'),
    url('../public/fonts/circular_std/CircularStd-BoldItalic.woff') format('woff'),
    url('../public/fonts/circular_std/CircularStd-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Circular Std Book';
  src: url('../public/fonts/circular_std/CircularStd-Book.eot');
  src: local('Circular Std Book'), local('CircularStd-Book'),
    url('../public/fonts/circular_std/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/circular_std/CircularStd-Book.woff2') format('woff2'),
    url('../public/fonts/circular_std/CircularStd-Book.woff') format('woff'),
    url('../public/fonts/circular_std/CircularStd-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}*/
