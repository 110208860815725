import { combineReducers } from 'redux'
import requests from './requests/reducers'
import specific from './specific/reducers'

const bookingsReducer = combineReducers({
  requests,
  specific,
})

export default bookingsReducer
