import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  card: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      height: '97vh',
    },
  },

  animationBlock: {
    position: 'relative',
    width: '240px',
    height: '240px',
    marginTop: '-40px',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: '240px',
      height: '240px',
      marginTop: '10px',
    },
  },

  content: {
    marginTop: '-40px',
    position: 'relative',
    zIndex: 1,

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      marginTop: '-10px',
    },
  },

  info: {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
  },
}))
