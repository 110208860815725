import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollArea } from '@mantine/core'
import { BookingDaysCalendar } from '@/features/bookings/components/Elements'
import { BookingDay } from '@/features/bookings/types/models'

interface IProps {
  days: BookingDay[]
}

export const ScheduleCalendar = ({ days = [] }: IProps) => {
  const { t } = useTranslation()

  return (
    <ScrollArea className={'h-full'}>
      <div className={'flex justify-center'}>
        <BookingDaysCalendar days={days} />
      </div>
    </ScrollArea>
  )
}
