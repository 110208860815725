import React from 'react'
import useStyles from './NavBadge.styles'

interface IProps {
  children: React.ReactNode
  isActive: boolean
  className?: string
}

export const NavBadge = ({ children, isActive, className }: IProps) => {
  const { classes, cx } = useStyles()

  return (
    <div
      className={cx(classes.root, {
        [`${classes.active}`]: isActive,
        [`${className}`]: className,
      })}
    >
      {children}
    </div>
  )
}
