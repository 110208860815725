import { IconSearch } from '@tabler/icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { Input } from '@mantine/core'
import { DataShower } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { chatsUsersAPI } from '@/features/chat/api'
import { chatChatsList, chatConversationData, selectChatsSortList } from '@/features/chat/store'
import { ChatModel } from '@/features/chat/types/models'
import { useUser } from '@/features/user'
import { userLoader } from '@/features/user/store'
import { getData } from '@/features/user/store/loader/saga'
import { RootState } from '@/store'
import useStyles from './ChatsContainer.styles'
import { ChatsList } from './ChatsList'

interface IProps {
  list: ChatModel[]
  loading: boolean
  error: any
  activeChat: ChatModel | null
  openRoom?: () => void
}

const ChatsContainerComponent = ({ list, loading, error, activeChat, openRoom }: IProps) => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()
  const { user } = useUser()
  const { classes } = useStyles()
  const [search, setSearch] = useState('')
  const [chats, setChats] = useState<ChatModel[]>(list)

  const dispatch = useDispatch()

  useEffect(() => {
    fetchData()

    return () => {
      dispatch(chatChatsList.cleanState())
    }
  }, [])

  const includesName = (item: ChatModel) => {
    let names = ''
    item?.participants?.map(
      (p) => (names = names + (p.id != user.id ? ' ' + p.first_name + ' ' + p.last_name : ''))
    )

    return names.includes(search)
  }

  useEffect(() => {
    setChats(list.filter(includesName))
  }, [search, list])

  const fetchData = (disableLoading?: boolean) => {
    dispatch(chatChatsList.getList({ disableLoading }))
  }

  const onChatSelect = async (item: any) => {
    dispatch(chatConversationData.initChat(item))
    await chatsUsersAPI.seenUserMessages(item.id)
    fetchData(true)
    dispatch(userLoader.getData(getData))

    if (openRoom) {
      dispatch(chatConversationData.setShowRating(false))
      openRoom()
    }
  }

  const handelChangeSearch = function (event: any) {
    setSearch(event.target.value)
  }

  return (
    <>
      <DataShower
        isLoading={loading}
        isFetched={!loading && !error}
        isFailed={!!error}
        error={error}
        loaderWrapClass={'h-full flex justify-center items-center'}
      >
        {!!list.length && (
          <div className={'h-100'}>
            <div className={classes.chatSearch}>
              <Input
                variant="filled"
                placeholder={t('search')}
                icon={<IconSearch size={16} />}
                className={classes.inputSearch}
                radius="lg"
                onChange={handelChangeSearch}
              />
            </div>
            <ChatsList items={chats} activeChat={activeChat} onSelect={onChatSelect} />
          </div>
        )}

        {!list.length && !loading && !error && (
          <div className={'h-full flex justify-center items-center'}>{t('no_chats')}</div>
        )}
      </DataShower>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  const { loading, error } = state.chat.chats.list
  const { activeChat } = state.chat.conversation.data

  return {
    list: selectChatsSortList(state),
    loading,
    error,
    activeChat,
  }
}

export const ChatsContainer = connect(mapStateToProps)(ChatsContainerComponent)
