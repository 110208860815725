import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { userData } from '@/features/user/store'
import i18n from '@/i18n'
import { LOCALE } from '@/services'
import { FormTypes } from '@/types'
import { profileAPI } from '../../../api'
import { UpdateClientProfileDTO } from '../../../api/dto'
import {
  PROFILE_CLIENT_UPDATE_FAILED,
  PROFILE_CLIENT_UPDATE_REQUEST,
  PROFILE_CLIENT_UPDATE_SUCCESS,
} from './actionTypes'

export const profileClientUpdatePA = createPromiseAction(
  PROFILE_CLIENT_UPDATE_REQUEST,
  PROFILE_CLIENT_UPDATE_SUCCESS,
  PROFILE_CLIENT_UPDATE_FAILED
)<UpdateClientProfileDTO, unknown, AxiosError<FormTypes.ValidationErrors>>()

function* update(action: PromiseAction<string, UpdateClientProfileDTO, any>) {
  try {
    const response: AxiosResponse = yield call(profileAPI.updateClientProfile, action.payload)
    const { data } = response

    yield put(profileClientUpdatePA.success({}))

    yield put(userData.updateData(data?.data))

    const { lang } = data.data

    if (i18n.language !== lang) {
      LOCALE.changeLang(lang)
    }

    resolvePromiseAction(action, {})
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* updateSaga() {
  yield takeEvery(profileClientUpdatePA.request, update)
}
