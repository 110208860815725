import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Space } from '@mantine/core'
import { ModalOrDrawerSwitch } from '@/components/Elements/MobileWebSwitchs'
import { useEvents } from '@/features/app/hooks'
import { TAG_MANAGER_EVENT } from '@/features/tag-manager/consts/event'
import { useUser } from '@/features/user'
import { PasswordContainer } from './PasswordContainer'
import { ProfileDeactivation } from './ProfileDeactivation'
import { ProfileDelete } from './ProfileDelete'

interface IProps {
  visible: boolean
  onClose: () => void
}

export const ProfileSettings = ({ visible, onClose }: IProps) => {
  const { t } = useTranslation()

  const event = useEvents()
  const { isBabysitter, isClient } = useUser()

  useEffect(() => event.actions.generic(TAG_MANAGER_EVENT.PROFILE_SETTINGS), [])

  return (
    <>
      <ModalOrDrawerSwitch opened={visible} onClose={onClose} title={t('settings')}>
        <PasswordContainer onCancel={onClose} />

        <Space h={'xl'} />

        {isBabysitter() && <ProfileDeactivation />}

        {isClient() && <ProfileDelete />}
      </ModalOrDrawerSwitch>
    </>
  )
}
