import React from 'react'
import { useTranslation } from 'react-i18next'
import icon from '@/assets/images/illustrations/certification-icon.svg'
import { CertificationType } from '@/features/user'
import { DateUtils } from '@/utils'
import useStyles from './CertificationCard.styles'

interface IProps {
  data: CertificationType
}

export const CertificationCard = ({ data }: IProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { school, study, description, start_date, end_date } = data

  return (
    <>
      <div className={classes.root}>
        <div className={classes.iconBlock}>
          <img src={icon} alt={'icon'} />
        </div>
        <div className={classes.contentBlock}>
          <div className={classes.contentTitle}>{school}</div>
          <div className={classes.contentInfo}>
            <span>{study}</span>
            <br />
            <span className={classes.description}>{description}</span>
          </div>
          <div className={classes.contentFooter}>
            {`${DateUtils.formatDate(start_date)} - ${DateUtils.formatDate(end_date)}`}
          </div>
        </div>
      </div>
    </>
  )
}
