import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Title } from '@mantine/core'
import { NoDataTextMessage } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { FeedbackCard } from '@/features/babysitter/components/Profile/FeedbackCard'
import { FeedbackType } from '@/features/user'

interface IProps {
  data: Array<FeedbackType>
}

export const FeedbackBlock = ({ data }: IProps) => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <>
      <div>
        <div className={'flex w-100'}>
          <div className={'w-10/12'}>
            <Title order={mobileView ? 6 : 5}>{t('rating.feedbacks')}</Title>
          </div>
        </div>

        <div className={'my-3'}>
          {!data.length ? (
            <NoDataTextMessage variant={'data'} />
          ) : (
            <Grid>
              {data.map((item, i) => (
                <Grid.Col sm={6} md={12} lg={6} key={i}>
                  {<FeedbackCard data={item} />}
                </Grid.Col>
              ))}
            </Grid>
          )}
        </div>
      </div>
    </>
  )
}
