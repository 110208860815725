import React from 'react'
import { ButtonProps, Button as MantineButton } from '@mantine/core'

// import { Button } from '@/components/Elements'

interface IProps extends ButtonProps {
  children: React.ReactNode
  className?: string

  [x: string]: any
}

export const ExternalLinkButton: React.FC<IProps> = ({ children, className, ...props }) => {
  return (
    <MantineButton component="a" target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </MantineButton>
  )
}
