import React from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox as MantineCheckbox, CheckboxProps as MantineCheckboxProps } from '@mantine/core'
import { NormalizeUtils } from '@/utils'

export interface CheckboxFieldProps extends MantineCheckboxProps {
  fieldRef?: any
  normalize?: (value: any) => any
}

export const CheckboxField = ({
  value,
  onChange,
  normalize,
  fieldRef,
  ...props
}: CheckboxFieldProps) => {
  const { t } = useTranslation()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = normalize ? normalize(event.target.checked) : event.target.checked

    if (onChange) {
      onChange(checked)
    }
  }

  return (
    <MantineCheckbox
      {...props}
      data-hj-allow
      checked={NormalizeUtils.stringBoolToBoolean(value)}
      onChange={handleChange}
    />
  )
}
