import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  chatSearch: {
    width: '100%',
    padding: '9px 16px 10px 16px',
    borderBottom: '1px solid lightgrey',
  },
  inputSearch: {
    backgroundColor: theme.colors.gray[4],
    borderRadius: '16px',
    color: 'black',
  },
}))
