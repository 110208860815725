import React from 'react'
import { Group, Text, UnstyledButton } from '@mantine/core'
import { Rating } from '@/components/FormElements'

interface IProps {
  value?: number
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  disabled?: boolean
  ratingProps?: any
  withValue?: boolean
  textProps?: any
}

export const RatingButtonControl = ({
  value,
  onClick,
  disabled = false,
  ratingProps,
  withValue = true,
  textProps,
}: IProps) => {
  return (
    <>
      <UnstyledButton onClick={onClick} disabled={disabled}>
        <div className={'pointer-events-none'}>
          <Group spacing={'xs'} className={'flex-nowrap'}>
            <Rating {...ratingProps} value={value} readOnly />

            {withValue && value ? (
              <Text weight={700} {...textProps}>
                {value}
              </Text>
            ) : null}
          </Group>
        </div>
      </UnstyledButton>
    </>
  )
}
