import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {},

  tabsList: {
    borderColor: theme.colors.gray[2],
    flexWrap: 'nowrap',
  },

  tab: {
    fontWeight: 600,
    fontSize: '18px',

    '&[data-active]': {
      color: theme.colors.primary[theme.fn.primaryShade()],
    },
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: '14px',
    },
  },
}))
