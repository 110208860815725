import React from 'react'

interface IProps {
  color?: string
  size?: number
  viewPort?: number
  className?: string
}

export const Star = ({ size = 24, viewPort = size, color = 'gold', ...props }: IProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${viewPort} ${viewPort}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3901 5.15653L14.8002 7.99563C14.9902 8.38827 15.5002 8.76078 15.9303 8.84132L18.4804 9.26416C20.1106 9.53599 20.4906 10.724 19.3205 11.912L17.3304 13.9155C17.0003 14.2477 16.8103 14.9021 16.9203 15.3753L17.4904 17.8519C17.9404 19.8051 16.9003 20.5702 15.1902 19.5433L12.8001 18.1137C12.37 17.8519 11.65 17.8519 11.2199 18.1137L8.82978 19.5433C7.11967 20.5602 6.0796 19.8051 6.52963 17.8519L7.09967 15.3753C7.18967 14.892 6.99966 14.2376 6.66964 13.9054L4.6795 11.9019C3.50942 10.724 3.88945 9.53599 5.51956 9.2541L8.06973 8.83125C8.49976 8.76078 9.0098 8.37821 9.19981 7.98556L10.6099 5.14647C11.38 3.61617 12.62 3.61617 13.3901 5.15653Z"
      fill={color}
    />
  </svg>
)
