import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Center } from '@mantine/core'
import { Button } from '@/components/Elements'
import { DatePickerCalendarController } from '@/components/FormElements'

type FormValues = {
  dates: Date[]
}

interface IProps {
  onApply: (dates: Date[]) => void
  period: Date[]
  initialValues?: object
  onCancel: () => void
}

export const SelectDatesForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const [minDate, maxDate] = props.period

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      dates: [],
      ...props.initialValues,
    },
  })

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const { dates } = data

    dates.sort((a, b) => a.getTime() - b.getTime())

    props.onApply(dates)
  }

  return (
    <>
      <form
        onSubmit={(event) => {
          // this part is for stopping parent forms to trigger their submit
          if (event) {
            // sometimes not true, e.g. React Native
            if (typeof event.preventDefault === 'function') {
              event.preventDefault()
            }
            if (typeof event.stopPropagation === 'function') {
              // prevent any outer forms from receiving the event too
              event.stopPropagation()
            }
          }
          return handleSubmit(onSubmit)(event)
        }}
      >
        <Center>
          <DatePickerCalendarController
            control={control}
            name={'dates'}
            multiple={true}
            minDate={minDate}
            maxDate={maxDate}
            initialMonth={minDate}
          />
        </Center>

        <Button mt={'lg'} fullWidth color={'secondary'} type={'submit'}>
          {t('apply')}
        </Button>

        <Button mt={'lg'} fullWidth variant="subtle" color="gray" onClick={props.onCancel}>
          {t('cancel')}
        </Button>
      </form>
    </>
  )
}
