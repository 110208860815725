import * as React from 'react'
import { Drawer as MantineDrawer, DrawerProps as MantineDrawerProps } from '@mantine/core'

export interface DrawerProps extends MantineDrawerProps {
  children: React.ReactNode
  opened: boolean
  onClose: () => void
}

export const Drawer = ({ children, opened, onClose, ...props }: DrawerProps) => {
  return (
    <MantineDrawer
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      padding={'xl'}
      {...props}
    >
      {children}
    </MantineDrawer>
  )
}
