import * as React from 'react'
import { useAppState } from '@/features/app/hooks'
import { ROLE } from '@/features/user/types'
import useStyles from './AuthLayout.styles'
import { ContentSection } from './ContentSection'
import { InfoSection } from './InfoSection'

interface IProps {
  role?: ROLE
}

export const AuthLayout = ({ role }: IProps) => {
  const { classes } = useStyles()
  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <div className={classes.wrap}>
      <div className={classes.contentSection}>
        <ContentSection />
      </div>
      {!mobileView && (
        <div className={classes.infoSection}>
          <InfoSection role={role} />
        </div>
      )}
    </div>
  )
}
