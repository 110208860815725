import _ from 'lodash'
import { CURRENCY, GENERAL_CURRENCY } from '@/const/currency'

type CurrencyOptionType = {
  label: string
  value: CURRENCY
  symbol: symbol
}

export const getCurrencies = () => {
  return [
    {
      label: CURRENCY.EUR,
      value: CURRENCY.EUR,
      symbol: '€',
    },
  ]
}

export const getCurrency = (value: string | null): CurrencyOptionType | any => {
  const options = getCurrencies()
  return _.find(options, { value })
}

export const getGeneralCurrency = () => {
  return getCurrency(GENERAL_CURRENCY)
}
