import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { FormTypes } from '@/types'
import { profileAPI } from '../../../api'
import {
  PROFILE_PASSWORD_UPDATE_FAILED,
  PROFILE_PASSWORD_UPDATE_REQUEST,
  PROFILE_PASSWORD_UPDATE_SUCCESS,
} from './actionTypes'

export const profilePasswordUpdatePA = createPromiseAction(
  PROFILE_PASSWORD_UPDATE_REQUEST,
  PROFILE_PASSWORD_UPDATE_SUCCESS,
  PROFILE_PASSWORD_UPDATE_FAILED
)<unknown, unknown, AxiosError<FormTypes.ValidationErrors>>()

function* update(action: PromiseAction<string, any, any>) {
  try {
    const response: AxiosResponse = yield call(profileAPI.updatePassword, action.payload)
    const { data } = response

    yield put(profilePasswordUpdatePA.success({}))

    resolvePromiseAction(action, {})
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* updateSaga() {
  yield takeEvery(profilePasswordUpdatePA.request, update)
}
