import React from 'react'
import { useTranslation } from 'react-i18next'
import { BookingTrackingModel } from '@/features/booking-tracking/types/models'
import { EditControl, RemoveControl } from './Controls'

interface IProps {
  config?: {
    edit: boolean
    remove: boolean
  }
  bookingTracking: BookingTrackingModel
  onEditSuccess?: (data: any) => void
  onRemoveSuccess?: () => void
}

const defaultConfig = {
  edit: true,
  remove: true,
}

export const Action = ({ bookingTracking, config, onEditSuccess, onRemoveSuccess }: IProps) => {
  const { t } = useTranslation()

  config = {
    ...defaultConfig,
    ...config,
  }

  return (
    <div className={'flex gap-4 items-center'}>
      {config?.edit && <EditControl bookingTracking={bookingTracking} onSuccess={onEditSuccess} />}

      {config?.remove && (
        <RemoveControl bookingTracking={bookingTracking} onSuccess={onRemoveSuccess} />
      )}
    </div>
  )
}
