import { AvatarProvider } from '@/components/Elements'
import { isCanceledBabysitter } from '@/features/bookings/helpers/booking/status'
import { BookingModel } from '@/features/bookings/types/models'
import { MessageType } from '@/features/chat/types/models'
import { RatingSubmittedActionMeta } from '@/features/rating/hooks'
import { ClientRatingModel, RatingModel } from '@/features/rating/types/models'
import { useUser } from '@/features/user'
import { Message } from '../Message'
import { Day } from './Day'
import useStyles from './MessageItem.styles'
import { Unread } from './Unread'

interface IProps {
  item: MessageType
  ratingRequest?: boolean
  config: any
  forwardRef: any
  booking?: BookingModel
  onRatingSubmitted?: (rating: any, actionMeta: any) => void
}

export const MessageItem = ({
  item,
  booking,
  onRatingSubmitted,
  config,
  ratingRequest = false,
  forwardRef,
}: IProps) => {
  const { isClient, isBabysitter } = useUser()
  const { classes, cx } = useStyles()

  const onHandleRatingSubmitted = (
    rating: ClientRatingModel | RatingModel,
    actionMeta: RatingSubmittedActionMeta
  ) => {
    actionMeta = {
      ...actionMeta,
      payload: {
        bookingId: booking?.id,
      },
    }
    onRatingSubmitted && onRatingSubmitted(rating, actionMeta)
  }

  return (
    <div
      className={cx(classes.root, {
        [`${classes.right}`]: item.is_my && !ratingRequest,
        [`${classes.ratingRequestBabysitter}`]: ratingRequest && isBabysitter(),
      })}
      ref={forwardRef}
    >
      <div className={'w-full'}>
        {config.showDate && <Day date={item.created_at} />}

        {config.showUnread && <Unread />}

        <div
          className={cx(classes.messageBlock, {
            [`${classes.right}`]: item.is_my && !ratingRequest,
            [`${classes.ratingRequestClient}`]: ratingRequest && isClient(),
            [`${classes.ratingRequestBabysitter}`]: ratingRequest && isBabysitter(),
          })}
        >
          {!item.is_my && (!ratingRequest || isClient()) && (
            <>
              {config.showAvatar ? (
                <div className={classes.avatarGap}>
                  <AvatarProvider
                    size={32}
                    avatar={ratingRequest ? null : item?.user?.avatar}
                    ratingRequest={ratingRequest}
                  />
                </div>
              ) : (
                <div className={classes.avatarHidden} />
              )}
            </>
          )}
          <Message
            item={item}
            ratingRequest={ratingRequest}
            showName={config.showName}
            booking={booking}
            onRatingSubmitted={onHandleRatingSubmitted}
          />
        </div>
      </div>
    </div>
  )
}
