import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  wrapper: {
    height: '100vh', // need fix: need 100vh - header height + paddings (but layout can be without header)
    padding: '0 16px',

    // minHeight: '100vh',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      padding: '0',
    },
  },

  content: {
    height: '100%',
    padding: '30px 0',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      padding: '0',
    },
  },
}))
