import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ChildrenFieldArray } from '@/features/profile/components/FormElements'
import { ChildType } from '@/features/user'

export type ChildrenFormSectionValues = {
  children: Array<ChildType>
}

export const ChildrenFormSection = () => {
  const { t } = useTranslation()

  const {
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext<ChildrenFormSectionValues>()

  return (
    <>
      <ChildrenFieldArray
        name={'children'}
        control={control}
        clearErrors={clearErrors}
        meta={{ error: errors?.children }}
      />
    </>
  )
}
