import { IconCalendar } from '@tabler/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  DatePicker as MantineDatePicker,
  DatePickerProps as MantineDatePickerProps,
} from '@mantine/dates'
import { useAppState } from '@/features/app/hooks'
import { useLang } from '@/hooks'
import { DateUtils, FormUtils } from '@/utils'

export interface DatePickerProps extends MantineDatePickerProps {
  fieldRef?: any
  normalize?: (value: string) => string
  translateParams?: object
  meta?: any
}

export const DatePicker = ({
  onChange,
  normalize,
  meta,
  fieldRef,
  translateParams,
  ...props
}: DatePickerProps) => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()

  const { lang } = useLang()

  const { error } = meta

  const showError = Boolean(error)

  const getErrorMessage = () => {
    return FormUtils.getErrorMessage(error, {
      t,
      translateParams: translateParams,
    })
  }

  return (
    <MantineDatePicker
      {...props}
      locale={lang}
      data-hj-allow
      value={props.value ? DateUtils.toDate(props.value) : props.value}
      onChange={onChange}
      error={showError ? getErrorMessage() : null}
      icon={mobileView ? <IconCalendar /> : <></>}
    />
  )
}
