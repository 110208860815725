import { User, guardHelper } from '@/features/user'
import { GUARD } from './types'

export type GuardType = {
  type: GUARD
  condition: (arg: any) => boolean
  redirectPath: string
}

export const profileCompleteGuard: GuardType = {
  type: GUARD.PROFILE_COMPLETE,
  condition: (user: User) => guardHelper.isProfileCompleteAllowed(user),
  redirectPath: `/profile/complete`,
}
