import React from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmModal } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { useCandidateAccept } from '@/features/booking-candidate/hooks'
import { MyBookingCandidateType } from '@/features/booking-candidate/types/models'
import { useConfirmPopup } from '@/hooks'

interface IProps {
  candidate: MyBookingCandidateType
  render: (props: { onAccept: (data?: any) => void; loading: boolean }) => React.ReactNode
  onSuccess?: (data: object) => void
}

export const AcceptControl = ({ candidate, onSuccess, render }: IProps) => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()

  const { loading, onSubmit } = useCandidateAccept()

  const onAcceptConfirm = async () => {
    await onSubmit(candidate?.id, onSuccess)
  }

  const { visible, open, close, onConfirm } = useConfirmPopup({ confirm: onAcceptConfirm })

  return (
    <>
      <div className={mobileView ? 'w-100' : ''}>
        {render({
          onAccept: open,
          loading,
        })}
      </div>

      <ConfirmModal visible={visible} onClose={close} onConfirm={onConfirm} />
    </>
  )
}
