import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'
import { DateUtils } from '@/utils'
import useStyles from './Date.styles'

interface IProps {
  date: string
}

export const Date = ({ date }: IProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  const dateFormat = useMemo(() => {
    if (date) {
      const localDate = DateUtils.utcToLocale(date)
      return DateUtils.format(localDate, 'HH:mm')
    }

    return null
  }, [date])

  return (
    <Text size={'xs'} color={'gray.6'}>
      {dateFormat}
    </Text>
  )
}
