export enum BOOKING_STATUS {
  UNPAID = 'unpaid',
  WAITING_PAYMENT = 'waiting_payment',
  CANCELED_CLIENT = 'canceled_client',
  CANCELED_BABYSITTER = 'canceled_babysitter',
  FINISHED = 'finished',
  WAITING_BABYSITTER = 'waiting_babysitter',
  BABYSITTER_NOT_FOUND = 'babysitter_not_found',
  UPCOMING = 'upcoming',
  REQUEST_FINISH = 'request_finish',
  PAYMENT_LINK_ERROR = 'payment_link_error',
  CANCELED_LATE_PAYMENT = 'canceled_late_payment',
}
