import React from 'react'

interface IProps {
  color: string
  size?: number
  viewPort?: number
  className?: string
}

export const GlobeStand = ({ size = 24, viewPort = size, color, ...props }: IProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${viewPort} ${viewPort}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 20.999C20.9706 20.999 25 16.9696 25 11.999C25 7.02846 20.9706 2.99902 16 2.99902C11.0294 2.99902 7 7.02846 7 11.999C7 16.9696 11.0294 20.999 16 20.999Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.1924 21.1914C22.7544 23.6294 19.4478 24.999 16 24.999C12.5522 24.999 9.24559 23.6294 6.80761 21.1914C4.36964 18.7534 3 15.4468 3 11.999C3 8.55121 4.36964 5.24461 6.80761 2.80664"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12 28.999H20" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16 24.999V28.999" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
