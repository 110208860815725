import React from 'react'

interface IProps {
  color: string
  size?: number
  viewPort?: number
  className?: string
}

export const Wallet = ({ size = 24, viewPort = size, color, ...props }: IProps) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox={`0 0 ${viewPort} ${viewPort}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5186 1.35061C15.8773 1.1435 16.336 1.26641 16.5431 1.62512L18.8493 5.61958C18.9833 5.85175 18.9832 6.13782 18.8491 6.36993C18.7149 6.60203 18.467 6.74487 18.1989 6.74458L8.99144 6.73457C8.65214 6.73421 8.35535 6.50608 8.26771 6.1783C8.18007 5.85051 8.32341 5.50471 8.61726 5.33506L15.5186 1.35061ZM11.786 5.23761L16.8999 5.24316L15.619 3.02464L11.786 5.23761Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 6.75C2.86192 6.75 2.75 6.86192 2.75 7V21C2.75 21.1381 2.86192 21.25 3 21.25H21C21.1381 21.25 21.25 21.1381 21.25 21V7C21.25 6.86191 21.1381 6.75 21 6.75H3ZM1.25 7C1.25 6.03348 2.03351 5.25 3 5.25H21C21.9665 5.25 22.75 6.03349 22.75 7V21C22.75 21.9665 21.9665 22.75 21 22.75H3C2.03351 22.75 1.25 21.9665 1.25 21V7Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.25 14C14.25 12.171 15.7959 10.75 17.625 10.75H22C22.4142 10.75 22.75 11.0858 22.75 11.5V16.5C22.75 16.9142 22.4142 17.25 22 17.25H17.625C15.7959 17.25 14.25 15.829 14.25 14ZM17.625 12.25C16.5546 12.25 15.75 13.0676 15.75 14C15.75 14.9324 16.5546 15.75 17.625 15.75H21.25V12.25H17.625Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 7.5C22.4142 7.5 22.75 7.83579 22.75 8.25V20.25C22.75 20.6642 22.4142 21 22 21C21.5858 21 21.25 20.6642 21.25 20.25V8.25C21.25 7.83579 21.5858 7.5 22 7.5Z"
      fill={color}
    />
  </svg>
)
