import axios from 'axios'

class BookingCandidateAPI {
  hireCandidate = async (id: number) => {
    return axios.post(`api/booking-candidates/${id}/hire`)
  }

  acceptCandidate = async (id: number) => {
    return axios.post(`api/booking-candidates/${id}/accept`)
  }

  declineCandidate = async (id: number) => {
    return axios.post(`api/booking-candidates/${id}/decline`)
  }
}

export const bookingCandidateAPI = new BookingCandidateAPI()
