import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button as MantineButton } from '@mantine/core'
import { TextInputController } from '@/components/FormElements'
import { useAppState, useEvents } from '@/features/app/hooks'
import { TAG_MANAGER_EVENT } from '@/features/tag-manager/consts/event'

interface IProps {
  setVisible: (value: boolean) => void
  control: any
  submit: () => {}
}

export const EditDetailsLayout = ({ setVisible, control, submit }: IProps) => {
  const { t } = useTranslation()
  const event = useEvents()
  const {
    appState: { mobileView },
  } = useAppState()

  useEffect(() => {
    event.actions.generic(TAG_MANAGER_EVENT.WALLET_DETAILS_EDIT)
  }, [])

  return (
    <form className="flex flex-col gap-4 m-0 p-4" onSubmit={submit}>
      <TextInputController
        control={control}
        name={'account_number'}
        id={'account_number'}
        label={t('bank_account_number')}
        placeholder={t('bank_account_number')}
        size={mobileView ? 'md' : 'lg'}
        mb={mobileView ? '0' : 'md'}
      />

      <TextInputController
        control={control}
        name={'swift'}
        id={'swift'}
        label={t('swift')}
        placeholder={t('swift')}
        size={mobileView ? 'md' : 'lg'}
        mb={mobileView ? '0' : 'md'}
      />

      <TextInputController
        control={control}
        name={'bank'}
        id={'bank'}
        label={t('bank_name')}
        placeholder={t('bank_name')}
        size={mobileView ? 'md' : 'lg'}
        mb={mobileView ? '0' : 'md'}
      />

      {mobileView ? (
        <div className="flex w-100">
          <MantineButton className={'w-100'} type="submit">
            {t('save')}
          </MantineButton>
        </div>
      ) : (
        <div className="flex justify-end gap-4">
          <MantineButton
            variant="default"
            color="dark"
            type="button"
            onClick={() => setVisible(false)}
          >
            {t('cancel')}
          </MantineButton>
          <MantineButton type="submit">{t('save')}</MantineButton>
        </div>
      )}
    </form>
  )
}
