import React from 'react'
import { useTranslation } from 'react-i18next'
import { BookingInvoiceModel } from '@/features/booking-invoice/types/models'
import { DownloadControl } from './Controls'

interface IProps {
  config?: {
    download: boolean
  }
  bookingInvoice: BookingInvoiceModel
}

const defaultConfig = {
  download: true,
}

export const Action = ({ bookingInvoice, config }: IProps) => {
  const { t } = useTranslation()

  config = {
    ...defaultConfig,
    ...config,
  }

  return (
    <div className={'flex gap-4 items-center'}>
      <DownloadControl bookingInvoice={bookingInvoice} />
    </div>
  )
}
