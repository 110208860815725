import React, { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useFirstMountState } from 'react-use'
import { useBookingFormTimetable } from '@/features/booking/hooks'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { ValidationsUtils } from '@/utils'
import { getWeekDaysDefaultValues } from '../helpers'

export const BookingTimetableFormConnector = () => {
  const isFirstMount = useFirstMountState()

  const { watch, setValue } = useFormContext()

  const { generateWeeks } = useBookingFormTimetable()

  const watchType = watch('type')
  const watchDateStart = watch('date_start')
  const watchDateEnd = watch('date_end')
  const watchSameWeeks = watch('timetable.same_weeks_schedule')

  const isNotOcasional = watchType !== BOOKING_TYPE.ONETIME && watchType !== BOOKING_TYPE.ANIMATION

  const isTimetableActive = useMemo(() => {
    if (isNotOcasional && watchDateStart && watchDateEnd) {
      const isStartValid = watchDateStart
      const isEndValid = ValidationsUtils.dateEqualOrAfter(watchDateEnd, watchDateStart)

      return isStartValid && isEndValid
    }

    return false
  }, [isNotOcasional, watchDateStart, watchDateEnd])

  // weeks schedule
  useEffect(() => {
    if (!isFirstMount) {
      if (isTimetableActive && !watchSameWeeks) {
        const weeks = generateWeeks({ start: watchDateStart, end: watchDateEnd })

        let weeksSchedule: any[] = []
        weeks.forEach(() => {
          const result = getWeekDaysDefaultValues()
          weeksSchedule = [...weeksSchedule, result]
        })

        setValue('timetable.weeks_schedule', weeksSchedule)
      }
    }
  }, [isTimetableActive, watchDateStart, watchDateEnd, watchSameWeeks])

  // // days schedule
  // useEffect(() => {
  //   if (!isFirstMount) {
  //     if (isTimetableActive && watchSameWeeks) {
  //       const daysSchedule = weekDaysValue()
  //       setValue('timetable.days_schedule', daysSchedule)
  //     } else {
  //     }
  //   }
  // }, [isTimetableActive, watchSameWeeks])

  // // reset values if turn job type to onetime type
  // useEffect(() => {
  //   return () => {
  //     if (!isFirstMount && isNotOcasional) {
  //       setValue('timetable.weeks_schedule', [])
  //       setValue('timetable.days_schedule', [])
  //     }
  //   }
  // }, [isNotOcasional])

  return null
}
