import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TableView } from '@/components/Elements'
import { DateUtils } from '@/utils'
import { Action } from './Action'

type Key = 'booking' | 'start-date' | 'end-date' | 'action'

interface IProps {
  keys: Key[]
  items: any[]
  loading: boolean
  actionProps?: any
  onEditSuccess?: (data: any) => void
  onRemoveSuccess?: () => void
}

export const GeneralTimeEntriesTable = ({
  keys = [],
  items,
  loading,
  actionProps,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  const [columns, setColumns] = useState<any[]>([])

  const columnConfig: any = {
    booking: {
      accessorKey: 'booking',
      id: 'booking',
      header: t('booking'),
      cell: (info: any) => null,
    },
    'start-date': {
      accessorKey: 'date_start',
      header: t('start_date'),
      cell: (info: any) => DateUtils.formatDateTime(info.getValue()) || '-',
    },
    'end-date': {
      accessorKey: 'date_end',
      header: t('end_date'),
      cell: (info: any) => DateUtils.formatDateTime(info.getValue()) || '-',
    },
    action: {
      accessorKey: 'action',
      header: t('action'),
      cell: (info: any) => (
        <Action
          {...actionProps}
          bookingTracking={info.row.original}
          onEditSuccess={props.onEditSuccess}
          onRemoveSuccess={props.onRemoveSuccess}
        />
      ),
    },
  }

  useEffect(() => {
    const config = []

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (columnConfig[key]) {
        config.push(columnConfig[key])
      }
    }

    setColumns(config)
  }, [])

  return (
    <>
      <TableView data={items} columns={columns} loading={loading} />
    </>
  )
}
