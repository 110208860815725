import { createSlice } from '@reduxjs/toolkit'
import { BookingInvoiceModel } from '@/features/booking-invoice/types/models'

interface IState {
  list: BookingInvoiceModel[]
  loading: boolean
  error: any | null
  page: number
  meta: any | null
}

const initialState: IState = {
  list: [],
  loading: false,
  error: null,
  page: 1,
  meta: null,
}

const bookingsInvoiceListSlice = createSlice({
  name: 'bookingsInvoiceList',
  initialState,
  reducers: {
    getList(state, action) {
      state.loading = true
      state.error = null
    },
    getListSuccess(state, action) {
      state.loading = false
      state.list = action.payload.data
      state.meta = action.payload.meta
    },
    getListError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    setPage(state, action) {
      state.page = action.payload
    },
    updateListItem(state, action) {
      state.list = state.list.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          }
        } else {
          return item
        }
      })
    },
    cleanState() {
      return initialState
    },
  },
})

export const bookingsInvoiceList = bookingsInvoiceListSlice.actions

export default bookingsInvoiceListSlice.reducer
