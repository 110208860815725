import React from 'react'
import adminPhotoZoom from '@/assets/images/placeholders/admin_image_zoom.png'
import adminPhoto from '@/assets/images/placeholders/rating_admin_image.jpg'
import { ModelTypes } from '@/types'
import { Avatar, AvatarProps } from '../Avatar'

interface IProps extends AvatarProps {
  avatar: ModelTypes.AvatarType | undefined | null
  multiUser?: boolean
  ratingRequest?: boolean
  checkout?: boolean
  type?: 'full' | 'thumb'
  children?: React.ReactNode
}

export const AvatarProvider: React.FC<IProps> = ({
  avatar,
  multiUser = false,
  ratingRequest = false,
  checkout = false,
  type = 'thumb',
  children,
  ...props
}) => {
  const src = ratingRequest
    ? adminPhoto
    : checkout
    ? adminPhotoZoom
    : avatar
    ? type === 'thumb'
      ? avatar?.thumb
      : type === 'full'
      ? avatar?.full
      : ''
    : ''

  return (
    <Avatar defaultMulti={multiUser} src={src} {...props}>
      {children}
    </Avatar>
  )
}
