import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.md,
    padding: '20px',
    display: 'flex',
    backgroundColor: theme.white,
    gap: theme.spacing.md,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      flexDirection: 'column',
    },
  },

  infoBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  actionBar: {},

  infoTopBar: {
    display: 'flex',
  },

  infoContentBlock: {
    marginLeft: '48px',
    paddingLeft: theme.spacing.sm,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      marginLeft: 0,
      paddingLeft: 0,
    },
  },

  avatarBlock: {},

  overviewBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.spacing.sm,
  },
}))
