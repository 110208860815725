import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { FormTypes } from '@/types'
import { profileAPI } from '../../api'
import {
  PROFILE_REMOVE_FAILED,
  PROFILE_REMOVE_REQUEST,
  PROFILE_REMOVE_SUCCESS,
} from './actionTypes'

export const profileRemovePA = createPromiseAction(
  PROFILE_REMOVE_REQUEST,
  PROFILE_REMOVE_SUCCESS,
  PROFILE_REMOVE_FAILED
)<undefined, unknown, AxiosError<FormTypes.ValidationErrors>>()

function* remove(action: PromiseAction<string, any, any>) {
  try {
    const response: AxiosResponse = yield call(profileAPI.deleteProfile)
    const { data } = response

    yield put(profileRemovePA.success({}))

    resolvePromiseAction(action, {})
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* removeSaga() {
  yield takeEvery(profileRemovePA.request, remove)
}
