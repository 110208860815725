import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  content: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    flexDirection: 'column',
  },

  scrollArea: {
    width: '100%',
    //    height: 'calc(100vh - 250px)',
  },

  footer: {
    paddingTop: '16px',
  },
}))
