import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { DataShower } from '@/components/Elements'
import { bookingsDaysList } from '@/features/bookings/store'
import { useAppSelector } from '@/store'

interface IProps {
  id: number | undefined
  children: React.ReactNode
}

export const BookingDaysDataLoader = ({ id, children }: IProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(bookingsDaysList.getList({ id }))

    return () => {
      dispatch(bookingsDaysList.cleanState())
    }
  }, [])

  const { list, loading, error } = useAppSelector((state) => state.bookings.days.list)

  return (
    <DataShower isLoading={loading} isFetched={!loading && !error} isFailed={!!error} error={error}>
      {children}
    </DataShower>
  )
}
