import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  CertificationFieldValues,
  CertificationsFieldArray,
} from '@/features/profile/components/FormElements'

export type CertificationsFormSectionValues = {
  certifications: Array<CertificationFieldValues>
}

export const CertificationsFormSection = () => {
  const { t } = useTranslation()

  const {
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext<CertificationsFormSectionValues>()

  return (
    <>
      <CertificationsFieldArray
        name={'certifications'}
        control={control}
        clearErrors={clearErrors}
        meta={{ error: errors?.certifications }}
      />
    </>
  )
}
