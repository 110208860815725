import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { BookingModel, BookingService } from '@/features/bookings/types/models'
import { useRatingEntryPopupModuleContext } from '@/features/rating/contexts'
import { ratingBabysitterEditSubmitPA, ratingClientEditSubmitPA } from '@/features/rating/store'
import { useUser } from '@/features/user'
import { useNotify } from '@/hooks'
import { RatingEntryForm } from '../../RatingEntryForm'

interface IProps {
  booking: BookingModel | BookingService
  onClose: () => void
  onDestroy: () => void
}

export const RatingEntryEditContainer = ({ booking, onClose, onDestroy }: IProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => onDestroy()
  }, [])

  const { onRatingSubmitted } = useRatingEntryPopupModuleContext()

  const { user, isBabysitter } = useUser()

  const onSuccess = (data: any) => {
    onRatingSubmitted && onRatingSubmitted(data, { action: 'edit', entity: user?.role })
  }

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const onSubmit = async (values: any) => {
    const params = {
      ...values,
    }

    const action = isBabysitter() ? ratingClientEditSubmitPA : ratingBabysitterEditSubmitPA

    const data = await dispatch(
      action.request({ id: isBabysitter() ? booking.client_rating?.id || 0 : booking.id, params })
    )
    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })

    onSuccess(data)
  }

  return (
    <RatingEntryForm
      initialValues={
        isBabysitter()
          ? {
              value: booking.client_rating?.value,
            }
          : {
              babysitter_ratings: booking.rating?.babysitter_ratings || [],
              value: booking.rating?.value || 0,
              platform: booking.rating?.platform || 0,
              other_feedback: booking.rating?.other_feedback || '',
            }
      }
      onSubmit={onSubmit}
      onCancel={onClose}
      isEdit
      booking={booking}
    />
  )
}
