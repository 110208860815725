import { createSlice } from '@reduxjs/toolkit'
import { BookingModel } from '@/features/bookings/types/models'
import { BabysitterUser } from '@/features/user'

interface State {
  data: BookingModel | null
  loading: boolean
  error: any
}

const initialState: State = {
  data: null,
  loading: false,
  error: null,
}

const bookingsDataSlice = createSlice({
  name: 'bookingsData',
  initialState,
  reducers: {
    getData(state, action) {
      state.loading = true
      state.error = null
    },
    getDataSuccess(state, action) {
      state.loading = false
      state.data = action.payload.data
    },
    getDataError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    updateData(state, action) {
      state.data = { ...state.data, ...action.payload }
    },
    setClientRating(state, action) {
      const data = state.data as BookingModel
      state.data = {
        ...data,
        client_rating: action.payload,
      }
    },
    setBabysitterRating(state, action) {
      const data = state.data as BookingModel
      console.log('previous rating: ' + JSON.stringify(data.rating))
      state.data = {
        ...data,
        rating: action.payload,
      }
      console.log('previous rating: ' + JSON.stringify(state.data.rating))
    },
    cleanState() {
      return initialState
    },
  },
})

export const bookingsData = bookingsDataSlice.actions

export default bookingsDataSlice.reducer
