import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { chatsUsersAPI } from '@/features/chat/api'
import { chatConversationData } from '@/features/chat/store'
import { chatsAPI } from '../../api/chats'
import { Loading } from '../../components/Chat/Chat/Room/MessagesContainer/MessagesList/Loading'

interface IProps {
  showRating: boolean
}

export const ChatDirectLink = ({ showRating }: IProps) => {
  const { id } = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()

  useEffect(() => {
    if (id) {
      loadChat(id)
    } else onChatSelect(null)
  }, [])

  const loadChat = async (id: any) => {
    try {
      const response = await chatsAPI.getChat(id)
      onChatSelect(response.data.data)
    } catch (error) {
      onChatSelect(null)
    }
  }

  const onChatSelect = async (item: any) => {
    if (item) {
      dispatch(chatConversationData.initChat(item))
      dispatch(chatConversationData.setShowRating(showRating))
      await chatsUsersAPI.seenUserMessages(item.id)
    } else {
      dispatch(chatConversationData.setShowRating(false))
    }
    navigate(`/chat`)
  }

  return <Loading />
}
