import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { MegaTagTitle } from '@/components/Elements'
import { useEvents } from '@/features/app/hooks'
import { Chat as ChatComponent, ChatSocketMiddleware } from '@/features/chat/components/Chat'
import { chatConversationData } from '@/features/chat/store'
import { Participant } from '@/features/chat/types/models'
import { useHeaderContext } from '@/features/header/contexts/HeaderContext'
import { TAG_MANAGER_EVENT } from '@/features/tag-manager/consts/event'

export const Chat = () => {
  const { setKey } = useHeaderContext()

  const event = useEvents()
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    setKey({ base: true, title: 'chats', border: true })

    event.actions.generic(TAG_MANAGER_EVENT.CHAT_VIEW)
  }, [])
  //dispatch(appState.pageTitle('chats'))

  useEffect(() => {
    const { participant } = (location.state as { participant?: Participant }) || {}

    console.log('Chat #1', JSON.stringify(participant))

    if (participant) {
      dispatch(chatConversationData.initParticipants([participant]))
    }
  }, [location.state])

  return (
    <>
      <MegaTagTitle title={'chats'} />

      <ChatSocketMiddleware>
        <ChatComponent />
      </ChatSocketMiddleware>
    </>
  )
}
