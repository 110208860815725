import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/Elements'
import { DeclineControl } from '@/features/booking-candidate/components/CandidateActionControls'
import { MyBookingCandidateType } from '@/features/booking-candidate/types/models'

interface IProps {
  candidate: MyBookingCandidateType
  onSuccess?: (data: object) => void
}

export const CandidateDeclineControl = ({ candidate, onSuccess }: IProps) => {
  const { t } = useTranslation()

  const onSubmitSuccess = (data: any) => {
    onSuccess && onSuccess(data)
  }

  return (
    <DeclineControl
      candidate={candidate}
      onSuccess={onSubmitSuccess}
      render={({ onDecline, loading }) => (
        <Button onClick={onDecline} loading={loading} size={'sm'} variant={'outline'}>
          {t('decline')}
        </Button>
      )}
    />
  )
}
