import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
//import { getData as getSelects } from '@/features/static/store/selects/saga'
import { User } from '@/features/user'
import { getData } from '@/features/user/store/loader/saga'
import i18n from '@/i18n'
import { panelLoader } from './slice'

function* loadData() {
  try {
    const [user, selects]: [User, object] = yield all([call(getData) /*, call(getSelects)*/])

    yield put(panelLoader.loadDataSuccess({}))
  } catch (error: any) {
    const message = error?.response?.data?.message || i18n.t('error')
    yield put(panelLoader.loadDataError(message))
  }
}

export function* watchLoadData() {
  yield takeLatest(panelLoader.loadData, loadData)
}

export function* loaderSaga() {
  yield all([fork(watchLoadData)])
}
