import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FacebookIcon, FacebookShareButton, WhatsappIcon } from 'react-share'
import { Button, Center, Text, Title } from '@mantine/core'
import cat from '@/assets/images/illustrations/cat.png'
import { CurrencyFormat } from '@/components/Elements'
import { ModalOrDrawerSwitch } from '@/components/Elements/MobileWebSwitchs'
import { useAppState, useEvents } from '@/features/app/hooks'
import { useHeaderContext } from '@/features/header/contexts/HeaderContext'
import { TAG_MANAGER_EVENT } from '@/features/tag-manager/consts/event'
import { walletAPI } from '@/features/wallet/api'
import { WalletEmailsSend } from '@/features/wallet/routes/services'
import { usePopup } from '@/hooks'
import useStyles from './WalletView.styles'

export const WalletViewClient = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { setKey } = useHeaderContext()
  const [wallet, setWallet] = useState({
    amount: '0',
    referral_code: '',
  })
  const event = useEvents()
  const {
    appState: { mobileView },
  } = useAppState()

  useEffect(() => {
    event.actions.generic(TAG_MANAGER_EVENT.WALLET_VIEW)
    setKey({ base: true, title: 'wallet', border: true })
    fetchData()
  }, [])

  const fetchData = async () => {
    const { data } = await walletAPI.getWalletInfo()
    setWallet(data.data)
  }

  const shareEvent = async (social: string) => {
    event.actions.shareSocialMedia(social)
  }

  const { visible, open, close } = usePopup()

  const messageContentWhatsapp = () => {
    const message =
      'https://api.whatsapp.com/send?text=' +
      encodeURIComponent(
        t('referral.social_media_message')
          .replace('%CODE%', wallet?.referral_code)
          .replace(
            '%URL%',
            (process.env.REACT_APP_WEBSITE_URL || '') +
              'register/client?ref=' +
              wallet?.referral_code +
              '&share=whatsapp'
          )
      )
    shareEvent('whatsapp')
    window.open(message, '_blank', 'noreferrer')
  }

  return (
    <div className="flex flex-col gap-8">
      <Center className="flex flex-col">
        <Center className={classes.contentTitle}>
          <Title order={mobileView ? 3 : 2} className="text-center">
            {' '}
            {t('referral.amount')}{' '}
          </Title>

          <Title order={mobileView ? 2 : 1}>
            <CurrencyFormat value={wallet?.amount} />
          </Title>
        </Center>
        <Center className={classes.content}>
          <Title order={mobileView ? 2 : 1} className="text-center">
            {t('referral.promo_code')}
          </Title>

          <Text className={classes.info} weight={'600'} size={'md'}>
            {t('referral.invitation')}
          </Text>
          <Title order={mobileView ? 3 : 2}>{t('referral.your_code')}</Title>
          <Title order={mobileView ? 4 : 3} className={classes.code}>
            {wallet?.referral_code}
          </Title>
          <Button onClick={open} size="xl">
            {t('referral.invite_friends')}
          </Button>

          <ModalOrDrawerSwitch
            opened={visible}
            leftIcon={'close'}
            onClose={close}
            headerBorder={true}
            title={t('referral.invite_friends')}
          >
            <WalletEmailsSend onCancel={close} />
          </ModalOrDrawerSwitch>
        </Center>
        <div className="flex flex-col mt-2 gap-2">
          <Text className={classes.info} size={'md'}>
            {t('referral.share')}
          </Text>
          <div className={'flex gap-2 justify-center'}>
            <FacebookShareButton
              url={
                (process.env.REACT_APP_WEBSITE_URL || '') +
                'register/client?ref=' +
                wallet?.referral_code +
                '&share=facebook'
              }
              hashtag="#babysisters"
              onShareWindowClose={() => shareEvent('facebook')}
            >
              <FacebookIcon size={36} round />
            </FacebookShareButton>

            {/*<WhatsappShareButton
              url={
                (process.env.REACT_APP_WEBSITE_URL || '') +
                'register/client?ref=' +
                wallet?.referral_code +
                '&share=whatsapp'
              }
              title={t('referral.social_media_message')
                .replace('%CODE%', wallet?.referral_code)
                .replace(
                  '%URL%',
                  (process.env.REACT_APP_WEBSITE_URL || '') +
                    'register/client?ref=' +
                    wallet?.referral_code +
                    '&share=whatsapp'
                )}
              separator=" "
              onShareWindowClose={() => shareEvent('whatsapp')}
            >
              <WhatsappIcon size={36} round />
            </WhatsappShareButton>*/}
            <div className={classes.pointer} onClick={() => messageContentWhatsapp()}>
              <WhatsappIcon size={36} round />
            </div>
          </div>
        </div>
      </Center>
      <div className={classes.imgWrap}>
        <img src={cat} alt={'cat'} />
      </div>
    </div>
  )
}
