import React from 'react'
import { useBookingData } from '@/features/bookings/hooks'
import { Footer } from './Footer'
import { Header } from './Header'
import { TabsLayoutContent } from './TabsLayoutContent'

interface IProps {
  onDrawerClose: () => void
  ratingProps?: any
}

export const BookingLayout = ({ onDrawerClose, ratingProps }: IProps) => {
  const { booking } = useBookingData()

  return (
    <div className={'flex flex-col h-full'}>
      {booking && <Header />}

      <TabsLayoutContent ratingProps={ratingProps} />

      <div className={'py-1'}>
        <Footer onDrawerClose={onDrawerClose} />
      </div>
    </div>
  )
}
