import React from 'react'
import { useEchoContext } from '@/features/socket/contexts'

export const useEcho = () => {
  const { client, init, setInit } = useEchoContext()

  const create = () => {
    client.create()
    setInit(true)
  }

  const destroy = () => {
    client.destroy()
    setInit(false)
  }

  const privateChannelAddListener = (channel: string, event: string, action: any) => {
    client.privateChannelAddListener(channel, event, action)
  }

  const privateChannelNotification = (channel: string, action: any) => {
    client.privateChannelNotification(channel, action)
  }

  const privateChannelAddListeners = (channel: string, events: object) => {
    client.privateChannelAddListeners(channel, events)
  }

  const channelLeaveAll = () => {
    client.channelLeaveAll()
  }

  const privateChannelStopListeners = (channel: string, events: string[]) => {
    client.privateChannelStopListeners(channel, events)
  }

  return {
    init,
    create,
    destroy,
    channelLeaveAll,
    privateChannelAddListener,
    privateChannelAddListeners,
    privateChannelNotification,
    privateChannelStopListeners,
  }
}
