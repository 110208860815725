import { IconTrash } from '@tabler/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button, Modal } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { BookingCancelProvider } from '@/features/bookings/components/BookingCancel'
import { useBookingDrawerModuleContext } from '@/features/bookings/contexts'
import { useBookingData } from '@/features/bookings/hooks'
import { bookingsData } from '@/features/bookings/store'
import { useUser } from '@/features/user'
import { usePopup } from '@/hooks'

export const CancelBooking = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { isBabysitter } = useUser()

  const { booking } = useBookingData()

  const { visible, open, close } = usePopup()

  const { onBookingUpdated } = useBookingDrawerModuleContext()

  const {
    appState: { mobileView },
  } = useAppState()

  const onSuccess = (data: any) => {
    close()

    dispatch(bookingsData.updateData({ ...data }))

    onBookingUpdated &&
      onBookingUpdated(data, { action: isBabysitter() ? 'babysitter-cancel' : 'cancel' })
  }

  return (
    <>
      <div className={mobileView ? 'w-100' : ''}>
        <Button variant="subtle" leftIcon={<IconTrash />} onClick={open} fullWidth={mobileView}>
          {t('cancel_booking')}
        </Button>
      </div>

      <Modal opened={visible} onClose={close} centered title={`${t('cancel_booking')}?`}>
        <BookingCancelProvider id={booking?.id as number} onSuccess={onSuccess} onCancel={close} />
      </Modal>
    </>
  )
}
