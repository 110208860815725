import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextInputController } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'

export type GeneralFormNoLoginValues = {
  first_name: string
  last_name: string
  email: string
  referral_code?: string
}

export const GeneralFormNoLogin = () => {
  const { t } = useTranslation()
  const { control, watch } = useFormContext<GeneralFormNoLoginValues>()
  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <>
      <TextInputController
        control={control}
        name={'first_name'}
        id={'first_name'}
        size={mobileView ? 'md' : 'lg'}
        label={t('first_name')}
        placeholder={t('first_name')}
        mb={'md'}
        styles={
          mobileView
            ? {
                label: {
                  fontWeight: 'bold',
                  fontSize: '16px !important',
                },
              }
            : {}
        }
      />

      <TextInputController
        control={control}
        name={'last_name'}
        id={'last_name'}
        size={mobileView ? 'md' : 'lg'}
        label={t('last_name')}
        placeholder={t('last_name')}
        mb={'md'}
        styles={
          mobileView
            ? {
                label: {
                  fontWeight: 'bold',
                  fontSize: '16px !important',
                },
              }
            : {}
        }
      />

      <TextInputController
        control={control}
        name={'email'}
        id={'email'}
        size={mobileView ? 'md' : 'lg'}
        label={t('email')}
        placeholder={t('email')}
        mb={'md'}
        styles={
          mobileView
            ? {
                label: {
                  fontWeight: 'bold',
                  fontSize: '16px !important',
                },
              }
            : {}
        }
      />

      <TextInputController
        control={control}
        name={'referral_code'}
        id={'referral_code'}
        size={mobileView ? 'md' : 'lg'}
        label={t('referral_code') + ' (' + t('optional').toLowerCase() + ')'}
        placeholder={t('referral_code')}
        mb={'md'}
        styles={
          mobileView
            ? {
                label: {
                  fontWeight: 'bold',
                  fontSize: '16px !important',
                },
              }
            : {}
        }
      />
    </>
  )
}
