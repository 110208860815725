import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { FinishControl } from '@/features/bookings/components/BookingActionControls'
import { useBookingDrawerModuleContext } from '@/features/bookings/contexts'
import { bookingsData } from '@/features/bookings/store'
import { BookingModel } from '@/features/bookings/types/models'

interface IProps {
  booking: BookingModel
  onSuccess?: (data: object) => void
}

export const FinishBooking = ({ booking }: IProps) => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()
  const dispatch = useDispatch()

  const { onBookingUpdated } = useBookingDrawerModuleContext()

  const onSubmitSuccess = (data: any) => {
    dispatch(bookingsData.updateData({ ...data }))

    onBookingUpdated &&
      onBookingUpdated(data, {
        action: 'finish',
      })
  }

  return (
    <FinishControl
      booking={booking}
      onSuccess={onSubmitSuccess}
      render={({ onFinish, loading }) => (
        <Button onClick={onFinish} loading={loading} fullWidth={mobileView}>
          {t('accept_finish')}
        </Button>
      )}
    />
  )
}
