import { IconTrash } from '@tabler/icons'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/Elements'
import { BookingModel, BookingService } from '@/features/bookings/types/models'
import { useRatingEntryPopupModuleContext } from '@/features/rating/contexts'
import { useRatingRemove } from '@/features/rating/hooks'
import { useUser } from '@/features/user'

interface IProps {
  booking: BookingModel | BookingService
}

export const RatingEntryRemoveButton = ({ booking }: IProps) => {
  const { t } = useTranslation()
  const { isBabysitter } = useUser()

  const { onRatingSubmitted } = useRatingEntryPopupModuleContext()

  const { loading, onSubmit } = useRatingRemove()

  const { user } = useUser()

  const onSuccess = () => {
    onRatingSubmitted && onRatingSubmitted(null, { action: 'remove', entity: user?.role })
  }

  const onRemove = async () => {
    await onSubmit(isBabysitter() ? (booking.client_rating?.id as number) : booking.id, onSuccess)
  }

  return (
    <Button
      mt={'lg'}
      fullWidth
      variant={'subtle'}
      onClick={onRemove}
      leftIcon={<IconTrash />}
      loading={loading}
    >
      {t('remove_feedback')}
    </Button>
  )
}
