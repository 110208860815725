import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group, Text } from '@mantine/core'
import { AvatarProvider } from '@/components/Elements'
import { CheckboxFieldController, FieldError, TextAreaController } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'
import { BabysitterRatingModel } from '@/features/rating/types/models'
import { BabysitterUser } from '@/features/user'
import { useUsersModel } from '@/features/users/hooks'
import { RatingField } from '../../FormElements'

export type ChildFieldValues = {
  person: number
  same_babysitter: boolean
  babysitter_feedback: null
  babysitter: BabysitterUser
}

interface IProps {
  name: string
  control: any
  meta?: any
  clearErrors?: (name: any) => void
}

export const BabysitterRatingFieldArray = ({ name, control, meta, clearErrors }: IProps) => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()

  const { getName } = useUsersModel()
  const error = meta?.error

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: name,
    keyName: 'fieldId',
  })

  const renderTitleSection = (item: BabysitterUser) => (
    <>
      <div style={{ borderTop: '1px solid #ced4da' }}></div>

      <Group noWrap className={'mb-2 mt-3'}>
        <AvatarProvider size={mobileView ? 30 : 32} avatar={item?.avatar} />

        <Text size={18} weight={800}>
          {getName(item)}
        </Text>
      </Group>
    </>
  )

  const fieldsUnknown = fields as unknown
  const fieldsArray = fieldsUnknown as BabysitterRatingModel[]

  return (
    <div className={mobileView ? 'flex flex-col gap-0' : 'flex flex-col mb-4'}>
      {fields.map((item, index) => (
        <div key={item.fieldId} className={'flex flex-col'}>
          <>
            {renderTitleSection(fieldsArray[index].babysitter)}
            <RatingField
              control={control}
              name={`${name}.${index}.person`}
              label={t('baby_sister_as_a_person')}
              mb={'md'}
            />
            <CheckboxFieldController
              control={control}
              name={`${name}.${index}.same_babysitter`}
              id={`${name}.${index}.same_babysitter`}
              label={t('rating.same_babysitter')}
              size={'md'}
              mb={'md'}
              styles={{
                label: {
                  fontWeight: 'bold',
                  fontSize: '16px !important',
                },
              }}
            />
            <TextAreaController
              control={control}
              name={`${name}.${index}.babysitter_feedback`}
              id={`${name}.${index}.babysitter_feedback`}
              label={t('rating.babysitter_feedback')}
              size={mobileView ? 'md' : 'lg'}
              placeholder={t('rating.other_feedback.placeholder')}
              mb={'md'}
              styles={{
                label: {
                  fontWeight: 'bold',
                  fontSize: '16px !important',
                },
              }}
            />
          </>
        </div>
      ))}

      <FieldError error={error} />
    </div>
  )
}
