import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { FormTypes } from '@/types'
import { authAPI } from '../../api'
import { ForgotPasswordDTO } from '../../api/dto'
import {
  AUTH_FORGOT_PASSWORD_FAILED,
  AUTH_FORGOT_PASSWORD_REQUEST,
  AUTH_FORGOT_PASSWORD_SUCCESS,
} from './actionTypes'

export const authForgotPasswordPA = createPromiseAction(
  AUTH_FORGOT_PASSWORD_REQUEST,
  AUTH_FORGOT_PASSWORD_SUCCESS,
  AUTH_FORGOT_PASSWORD_FAILED
)<ForgotPasswordDTO, { message: string }, AxiosError<FormTypes.ValidationErrors>>()

function* forgot(action: PromiseAction<string, ForgotPasswordDTO, any>) {
  try {
    const response: AxiosResponse = yield call(authAPI.forgotPassword, action.payload)
    const { data } = response

    yield put(authForgotPasswordPA.success({ ...data }))
    resolvePromiseAction(action, { ...data })
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* forgotPasswordSaga() {
  yield takeEvery(authForgotPasswordPA.request, forgot)
}
