import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid, Input } from '@mantine/core'
import { TimeInputController } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'
import { timetableHelper } from '@/features/booking/helpers'

const getWeekDay = timetableHelper.weekdays.getWeekDay

interface IProps {
  namePrefix: string
  weekDayKey: string
}

export const WeekDayTimePeriodFormSection = ({ namePrefix = '', weekDayKey }: IProps) => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()
  const { control } = useFormContext()

  return (
    <>
      <Input.Label
        mb={8}
        styles={{
          label: {
            fontWeight: 'bold',
            fontSize: '16px !important',
          },
        }}
      >
        {getWeekDay(weekDayKey, t)?.label}
      </Input.Label>

      <Grid>
        <Grid.Col span={6}>
          <TimeInputController
            control={control}
            name={`${namePrefix}.${weekDayKey}.time_start`}
            id={`${namePrefix}.${weekDayKey}.time_start`}
            size={mobileView ? 'md' : 'lg'}
            clearable
            mb={'md'}
            styles={
              mobileView
                ? {
                    label: {
                      fontWeight: 'bold',
                      fontSize: '16px',
                    },
                  }
                : {}
            }
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <TimeInputController
            control={control}
            name={`${namePrefix}.${weekDayKey}.time_end`}
            id={`${namePrefix}.${weekDayKey}.time_end`}
            size={mobileView ? 'md' : 'lg'}
            clearable
            mb={'md'}
            ml={'xs'}
            styles={
              mobileView
                ? {
                    label: {
                      fontWeight: 'bold',
                      fontSize: '16px',
                    },
                  }
                : {}
            }
          />
        </Grid.Col>
      </Grid>
    </>
  )
}
