import { IconMail, IconPhone, IconSettings } from '@tabler/icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Group, Stack, Text, Title } from '@mantine/core'
import { Button } from '@/components/Elements'
import { ModalOrDrawerSwitch } from '@/components/Elements/MobileWebSwitchs'
import { useAppState } from '@/features/app/hooks'
import { ClientProfileEdit } from '@/features/profile/components/ProfileEdit'
import { ProfileSettings } from '@/features/profile/components/Settings'
import { useUser } from '@/features/user'
import { usePopup } from '@/hooks'
import { FormatUtils } from '@/utils'
import useStyles from './General.styles'

export const General = () => {
  const [popupType, setPopupType] = useState(1)
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()

  const { classes } = useStyles()

  const { user, getName } = useUser()

  const { email, phone } = user

  const { visible, open, close } = usePopup()

  const openPopup = (type: number) => {
    setPopupType(type)
    open(true)
  }
  return (
    <>
      <div className={classes.block}>
        {mobileView ? (
          <div className={'h-full w-full mt-4'}>
            <Stack spacing="xs" justify="flex-end">
              {getName() && (
                <Title order={3} className={'text-center'}>
                  {getName()}
                </Title>
              )}

              <div className={'flex w-100 my-2 gap-2'}>
                <div className={'w-50'}>
                  <Button
                    fullWidth
                    color={'secondary'}
                    size={'md'}
                    variant={'outline'}
                    onClick={() => openPopup(2)}
                    leftIcon={<IconSettings />}
                    styles={(theme) => ({
                      root: {
                        fontSize: '14px',
                      },
                    })}
                  >
                    {t('settings')}
                  </Button>
                </div>
                <div className={'w-50'}>
                  <Button
                    fullWidth
                    color={'primary'}
                    size={'md'}
                    onClick={() => openPopup(1)}
                    styles={(theme) => ({
                      root: {
                        fontSize: '14px',
                      },
                    })}
                  >
                    {t('edit_profile')}
                  </Button>
                </div>
              </div>

              <Title order={5} className={'my-2'}>
                {t('contact_information')}
              </Title>

              {phone && (
                <Group className={'mobile-info-view-group'}>
                  <Text className={'mobile-info-view-title'}>{t('phone')}</Text>
                  <Text className={'mobile-info-view-content'}>
                    {FormatUtils.formatPhone(phone)}
                  </Text>
                </Group>
              )}

              {email && (
                <Group className={'mobile-info-view-group'}>
                  <Text className={'mobile-info-view-title'}>{t('email')}</Text>
                  <Text className={'mobile-info-view-content'}>{email}</Text>
                </Group>
              )}
            </Stack>
          </div>
        ) : (
          <div className={'h-full w-full'}>
            <Stack spacing="xs" justify="flex-end">
              {getName() && <Title order={3}>{getName()}</Title>}

              {phone && (
                <div className={classes.infoItem}>
                  <div>
                    <IconPhone size={20} />
                  </div>
                  <Text size={'sm'} className={'ml-2 truncate'}>
                    {FormatUtils.formatPhone(phone)}
                  </Text>
                </div>
              )}

              {email && (
                <div className={classes.infoItem}>
                  <div>
                    <IconMail size={20} />
                  </div>
                  <Text size={'sm'} className={'ml-2 truncate'}>
                    {email}
                  </Text>
                </div>
              )}
            </Stack>
          </div>
        )}
      </div>
      {popupType == 1 && (
        <ModalOrDrawerSwitch
          opened={visible}
          onClose={close}
          leftIcon={'close'}
          headerBorder={true}
          title={t('edit_profile')}
        >
          <ClientProfileEdit onCancel={close} />
        </ModalOrDrawerSwitch>
      )}
      {popupType == 2 && <ProfileSettings visible={visible} onClose={close} />}
    </>
  )
}
