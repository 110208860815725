import * as Yup from 'yup'
import { DateUtils, ValidationsUtils } from '@/utils'

export const validationSchema = Yup.object().shape({
  date_start: Yup.date().nullable().required('field.error.required'),
  time_start: Yup.date().nullable().required('field.error.required'),
  date_end: Yup.date()
    .nullable()
    .required('field.error.required')
    .test({
      name: 'dateEqualOrGrater',
      exclusive: false,
      params: {},
      message: 'field.error.date.after-or-equal',
      test: function (value) {
        return ValidationsUtils.dateEqualOrAfter(value, this.parent.date_start)
      },
    }),
  time_end: Yup.date()
    .nullable()
    .required('field.error.required')
    .test({
      name: 'dateGrater',
      exclusive: false,
      params: {},
      message: 'field.error.time.after',
      test: function (value) {
        const { date_start, time_start, date_end } = this.parent
        if (date_start && time_start && date_end && value) {
          const startDate = DateUtils.transformDateAndTimeDateToDate(date_start, time_start)
          const endDate = DateUtils.transformDateAndTimeDateToDate(date_end, value)

          return ValidationsUtils.dateAfter(endDate, startDate)
        }

        return true
      },
    }),
})
