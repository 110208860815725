import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionIconButton } from '@/components/Elements'
import { ConfirmModal } from '@/components/Elements'
import { useBookingTrackingRemove } from '@/features/booking-tracking/hooks'
import { BookingTrackingModel } from '@/features/booking-tracking/types/models'
import { useConfirmPopup } from '@/hooks'

interface IProps {
  bookingTracking: BookingTrackingModel
  onSuccess?: (data: any) => void
}

export const RemoveControl = ({ bookingTracking, onSuccess }: IProps) => {
  const { t } = useTranslation()

  const { loading, onSubmit } = useBookingTrackingRemove()

  const onRemoveConfirm = async () => {
    await onSubmit(bookingTracking?.id, onSuccess)
  }

  const { visible, open, close, onConfirm } = useConfirmPopup({ confirm: onRemoveConfirm })

  return (
    <>
      <div>
        <ActionIconButton name={'remove'} onClick={open} disabled={loading} />
      </div>

      <ConfirmModal visible={visible} onClose={close} onConfirm={onConfirm} />
    </>
  )
}
