import React from 'react'
import { ROLE, User, guardHelper } from '@/features/user'
import { selectUserData } from '@/features/user/store'
import { useUsersModel } from '@/features/users/hooks'
import { useAppSelector } from '@/store'

export const useUser = () => {
  const user = useAppSelector(selectUserData) as User

  const usersModel = useUsersModel()

  const isClient = () => {
    return user?.role === ROLE.Client
  }

  const isBabysitter = () => {
    return user?.role === ROLE.Babysitter
  }

  const getName = () => {
    return usersModel.getName(user)
  }

  const isProfileCompleteAllowed = () => {
    return guardHelper.isProfileCompleteAllowed(user)
  }

  return {
    user,
    isClient,
    isBabysitter,
    getName,
    isProfileCompleteAllowed,
  }
}
