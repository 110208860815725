import React from 'react'
import { useTranslation } from 'react-i18next'
import { Badge } from '@/components/Elements'

export const BookingNewBadge = () => {
  const { t } = useTranslation()

  return (
    <Badge color={'secondary'} variant={'filled'} fullWidth>
      {t('new')}
    </Badge>
  )
}
