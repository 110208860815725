import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { SelectController, TextInputController } from '@/components/FormElements'
import { CITY_TYPE } from '@/const/city'
import { useAppState } from '@/features/app/hooks'
import { cityAPI, countryAPI } from '@/features/selects/api'
import { cityHelper } from '@/helpers'
import { useLang } from '@/hooks'

const isCityExtraOption = cityHelper.general.isCityExtraOption
const isInternational = cityHelper.general.isInternational

const { DEFAULT } = CITY_TYPE

type ExtendedCityFormSectionValues = {
  city_id: number | string | null
  city_type: string
  city_description: string
  country_id: number | string | null
}

export const ExtendedCityFormSection = ({
  boldTitle = false,
  cityProps = {},
  disabled = false,
}) => {
  const { t } = useTranslation()
  const { lang } = useLang()

  const {
    appState: { mobileView },
  } = useAppState()

  const { control, watch, setValue } = useFormContext<ExtendedCityFormSectionValues>()

  const [cityList, setCityList] = useState<any[]>([])
  const [countryList, setCountryList] = useState<any[]>([])

  useEffect(() => {
    fetchDataCities()
    fetchDataCountries()
  }, [])

  const fetchDataCities = async () => {
    const { data } = await cityAPI.getCities(lang)
    const list = data.cities.map((item: any) => ({
      label: item.name,
      value: item.id.toString(),
    }))

    setCityList([...list, ...cityHelper.general.getExtraCityOptions()])
  }
  const fetchDataCountries = async () => {
    const { data } = await countryAPI.getCountries(lang)
    const list = data.countries.map((item: any) => ({
      label: item.name,
      value: item.id.toString(),
    }))
    setCountryList(list)
  }

  const watchCityId = watch('city_id') as string

  const onCityChange = (value: string) => {
    const city_type = isCityExtraOption(value) ? value : DEFAULT
    setValue('city_type', city_type)

    setValue('city_id', value)
  }

  return (
    <>
      <Grid>
        <Grid.Col md={6}>
          <SelectController
            control={control}
            name={'city_id'}
            id={'city_id'}
            label={t('location')}
            size={mobileView ? 'md' : 'lg'}
            placeholder={t('location')}
            mb={'md'}
            data={cityList}
            onChange={onCityChange}
            disabled={disabled}
            {...cityProps}
            styles={
              mobileView && boldTitle
                ? {
                    label: {
                      fontWeight: 'bold',
                      fontSize: '16px !important',
                    },
                  }
                : {}
            }
          />
        </Grid.Col>

        {watchCityId && isInternational(watchCityId) && (
          <Grid.Col md={6}>
            <SelectController
              control={control}
              name={'country_id'}
              id={'country_id'}
              label={t('country')}
              placeholder={t('country')}
              mb={'md'}
              data={countryList}
              disabled={disabled}
              searchable
              clearable
              styles={
                mobileView
                  ? {
                      label: {
                        fontWeight: 'bold',
                        fontSize: '16px !important',
                      },
                    }
                  : {}
              }
            />
          </Grid.Col>
        )}

        {watchCityId && isCityExtraOption(watchCityId) && (
          <Grid.Col md={6}>
            <TextInputController
              control={control}
              name={'city_description'}
              id={'city_description'}
              label={t('name')}
              placeholder={t('name')}
              size={mobileView ? 'md' : 'lg'}
              mb={'md'}
              disabled={disabled}
              styles={
                mobileView
                  ? {
                      label: {
                        fontWeight: 'bold',
                        fontSize: '16px !important',
                      },
                    }
                  : {}
              }
            />
          </Grid.Col>
        )}
      </Grid>
    </>
  )
}
