import { IconChevronLeft } from '@tabler/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Anchor, Group, Title } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import { ROLE } from '@/features/user'
import { useAppDispatch } from '@/store'
import { authLoginPA, authVerifyEmailPA } from '../../../store'
import useStyles from './LoginContainer.styles'
import { FormValues, LoginForm } from './LoginForm'

interface IProps {
  role: ROLE
  onApprovedInfo?: () => void
  hideForm?: boolean
  backLink?: string
}

export const LoginContainer = ({ role, onApprovedInfo, hideForm, backLink }: IProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { classes } = useStyles()
  const { i18n } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()

  const handleSubmit = async (values: FormValues) => {
    await dispatch(authLoginPA.request({ ...values, language: i18n.language }))
  }

  const onVerifyEmail = async (email: string) => {
    await dispatch(authVerifyEmailPA.request({ email }))
  }

  const goToAuth = () => {
    navigate(role === ROLE.Client ? '/' : '/babysitter')
  }

  const goToRegister = () => {
    navigate(role === ROLE.Client ? '/register/client' : '/register/babysitter')
  }
  return (
    <div>
      {!hideForm && (
        <>
          <div className={'mb-2 flex'}>
            {role == ROLE.Client && (
              <Title order={5} className={classes.back}>
                <Anchor onClick={goToAuth}>
                  <Group>
                    <IconChevronLeft />
                    {t('back')}
                  </Group>
                </Anchor>
              </Title>
            )}
          </div>
          <div className={classes.wrap}>
            <Title>{`${t('hi')}, ${t('welcome_back')}`}</Title>

            <div className={'mt-4 mb-4 flex'}>
              <Title order={mobileView ? 5 : 6}>
                {t('dont_have_account')}? <Anchor onClick={goToRegister}>{t('sign_up')}</Anchor>{' '}
              </Title>
            </div>
          </div>
        </>
      )}

      <LoginForm
        onSubmit={handleSubmit}
        onVerifyEmail={onVerifyEmail}
        onApprovedInfo={onApprovedInfo}
        hideForm={hideForm}
      />
    </div>
  )
}
