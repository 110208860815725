import React from 'react'
import { Group, Stack, Text } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import useStyles from './InfoStack.styles'

type InfoItem = {
  label: string
  value: React.ReactNode
}

interface IProps {
  items: Array<InfoItem>
  className: string
}

export const InfoStack = ({ items, className = '' }: IProps) => {
  const {
    appState: { mobileView },
  } = useAppState()

  const { classes } = useStyles()

  const renderGroup = ({ label, value }: InfoItem) => (
    <Group className={classes.groupItem}>
      <Text className={classes.groupItemLabel}>{label}</Text>
      <Text className={classes.groupItemValue}>{value ? value : '-'}</Text>
    </Group>
  )
  const renderGroupMobile = ({ label, value }: InfoItem) => (
    <Group className={'mobile-info-view-group'}>
      <Text className={'mobile-info-view-title'}>{label}</Text>
      <Text className={'mobile-info-view-content'}>{value ? value : '-'}</Text>
    </Group>
  )

  return (
    <>
      <Stack className={className}>
        {items.map((item, i) => (
          <React.Fragment key={i}>
            {mobileView ? renderGroupMobile(item) : renderGroup(item)}
          </React.Fragment>
        ))}
      </Stack>
    </>
  )
}
