import React from 'react'
import { useTranslation } from 'react-i18next'
import { Input, InputErrorProps } from '@mantine/core'
import { FormUtils } from '@/utils'

interface FieldErrorProps extends InputErrorProps {
  translateParams?: object
  error?: any
}

export const FieldError = ({ translateParams, error, ...props }: FieldErrorProps) => {
  const { t } = useTranslation()

  const showError = Boolean(error)

  const getErrorMessage = () => {
    return FormUtils.getErrorMessage(error, {
      t,
      translateParams: translateParams,
    })
  }

  return <Input.Error {...props}>{showError ? getErrorMessage() : null}</Input.Error>
}
