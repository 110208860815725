import React from 'react'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import { TextInputProps } from '../../TextInput'

interface PhoneInputControllerProps<T extends FieldValues> extends TextInputProps {
  control: Control<T, any>
  name: FieldPath<T>
  shouldUnregister?: boolean
  label?: string
  defaultValue?: any
  meta?: any
  required: boolean
  placeholder: string
}

export const PhoneInputController = <T extends FieldValues>({
  name,
  label,
  control,
  required,
  placeholder,
  shouldUnregister,
}: PhoneInputControllerProps<T>) => {
  const { t } = useTranslation()
  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      render={({ field: { ref, ...field }, fieldState }) => {
        return (
          <div className="react-tel-input">
            {label && (
              <label className="mantine-InputWrapper-label mantine-Textarea-label phone-widget-title">
                {label}
              </label>
            )}
            <PhoneInput
              onChange={(e) => {
                field.onChange(e)
              }}
              inputProps={{
                id: name,
                name,
                required,
                autoComplete: 'none',
                'data-testid': 'input-id',
              }}
              placeholder={placeholder}
              country={'pt'}
              value={field.value}
              isValid={() => {
                return !fieldState.error
              }}
              specialLabel=""
            />
            {fieldState.error && (
              <label className="mantine-Text-root mantine-InputWrapper-error phone-widget-error">
                {t(fieldState.error.message || '')}
              </label>
            )}
          </div>
        )
      }}
    />
  )
}
