import React from 'react'
import { ConfirmModal } from '@/components/Elements'
import { useConfirmPopup } from '@/hooks'

interface IProps {
  render: (props: { onOpen: (data: any) => void }) => React.ReactNode
  onConfirm: () => void
}

export const ConfirmationControl = ({ onConfirm, render }: IProps) => {
  const {
    visible,
    open,
    close,
    onConfirm: onPopupConfirm,
  } = useConfirmPopup({ confirm: onConfirm })

  return (
    <>
      {render({
        onOpen: open,
      })}

      <ConfirmModal visible={visible} onClose={close} onConfirm={onPopupConfirm} />
    </>
  )
}
