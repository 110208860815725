import moment from 'moment'
import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid, Title } from '@mantine/core'
import { DatePickerController, TimeInputController } from '@/components/FormElements'
import { DateUtils, ValidationsUtils } from '@/utils'

export const DatePeriodFormSection = () => {
  const { t } = useTranslation()

  const { control, watch } = useFormContext()

  const watchDates = watch(['date_start', 'time_start', 'date_end', 'time_end'])

  const duration = useMemo(() => {
    const [date_start, time_start, date_end, time_end] = watchDates

    if (date_start && time_start && date_end && time_end) {
      const startDate = DateUtils.transformDateAndTimeDateToDate(date_start, time_start)
      const endDate = DateUtils.transformDateAndTimeDateToDate(date_end, time_end)

      if (ValidationsUtils.dateAfter(endDate, startDate)) {
        const start = moment(startDate)
        const end = moment(endDate)
        const diff = end.diff(start)

        if (diff > 0) {
          const duration = moment.duration(diff)

          return moment.duration(duration).format('HH:mm', { trim: false, useGrouping: false })
        }
      }
    }

    return null
  }, [watchDates])

  return (
    <>
      <Grid>
        <Grid.Col sm={6}>
          <DatePickerController
            control={control}
            name={'date_start'}
            id={'date_start'}
            label={t('start_date')}
            placeholder={t('start_date')}
            withinPortal
            clearable={false}
            mb={'md'}
          />
        </Grid.Col>

        <Grid.Col sm={6}>
          <TimeInputController
            control={control}
            name={'time_start'}
            id={'time_start'}
            label={t('from')}
            mb={'md'}
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col sm={6}>
          <DatePickerController
            control={control}
            name={'date_end'}
            id={'date_end'}
            label={t('end_date')}
            placeholder={t('end_date')}
            mb={'md'}
            withinPortal
            clearable={false}
            translateParams={{ date: t('start_date') }}
          />
        </Grid.Col>

        <Grid.Col sm={6}>
          <TimeInputController
            control={control}
            name={'time_end'}
            id={'time_end'}
            label={t('to')}
            mb={'md'}
            translateParams={{ date: t('start_date') }}
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col>
          <Title order={3}>
            <>
              {`${t('duration')}: `}
              {duration || '-'}
            </>
          </Title>
        </Grid.Col>
      </Grid>
    </>
  )
}
