import React from 'react'
import { MegaTagTitle } from '@/components/Elements'
import { RegisterPasswordContainer } from '@/features/auth/components/RegisterPassword'

export const RegisterPassword = () => {
  return (
    <>
      <MegaTagTitle title={'register_password'} />

      <RegisterPasswordContainer />
    </>
  )
}
