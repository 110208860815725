import { IconTrash } from '@tabler/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { DeclineControl } from '@/features/booking-candidate/components/CandidateActionControls'
import { MyBookingCandidateType } from '@/features/booking-candidate/types/models'
import { useBookingDrawerModuleContext } from '@/features/bookings/contexts'
import { bookingsData } from '@/features/bookings/store'

interface IProps {
  candidate: MyBookingCandidateType
  onSuccess?: (data: object) => void
}

export const CandidateDecline = ({ candidate }: IProps) => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()

  const dispatch = useDispatch()

  const { onBookingUpdated } = useBookingDrawerModuleContext()

  const onSubmitSuccess = (data: any) => {
    dispatch(bookingsData.updateData({ my_booking_candidate: { ...data } }))

    onBookingUpdated &&
      onBookingUpdated(null, {
        action: 'candidate-decline',
        payload: { my_booking_candidate: data },
      })
  }

  return (
    <DeclineControl
      candidate={candidate}
      onSuccess={onSubmitSuccess}
      render={({ onDecline, loading }) => (
        <Button
          onClick={onDecline}
          loading={loading}
          variant="subtle"
          leftIcon={<IconTrash />}
          fullWidth={mobileView}
        >
          {t('decline_job')}
        </Button>
      )}
    />
  )
}
