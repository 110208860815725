import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Space } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { TextAreaController } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'
import { BookingModel, BookingService } from '@/features/bookings/types/models'
import { RatingField } from '@/features/rating/components/FormElements'
import { useUser } from '@/features/user'
import { useFormSubmit, useNotify } from '@/hooks'
import { BabysitterRatingFieldArray } from './BabysitterRatingFieldArray'
import { RatingEntryRemoveButton } from './RatingEntryRemoveButton'
import { getValidationSchema } from './validation'

interface IProps {
  onSubmit: (values: object) => Promise<void>
  onCancel: () => void
  initialValues?: any
  isEdit?: boolean
  booking: BookingModel | BookingService
}

export const RatingEntryForm = ({
  onCancel,
  initialValues = {},
  isEdit,
  booking,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()
  const { isBabysitter } = useUser()

  const {
    appState: { mobileView },
  } = useAppState()

  const defaultValues = isBabysitter()
    ? {
        value: null,
        ...initialValues,
      }
    : {
        ...initialValues,
      }

  const currentValidationSchema = getValidationSchema({ babysitter: isBabysitter() })

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(currentValidationSchema),
    mode: 'onChange',
  })

  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting, isValid },
    control,
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      await onFormSubmit(data)
      // eslint-disable-next-line
    } catch (error) {
      showNotification({
        type: 'error',
        message: t('error_submitting_request'),
      })
    }
  }

  return (
    <FormProvider {...methods}>
      {submitError && (
        <Alert type={'error'} mb={'sm'}>
          {submitError?.message || t('error')}
        </Alert>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <RatingField
          control={control}
          name={'value'}
          label={t('client_as_a_person')}
          mb={mobileView ? 'md' : ''}
        />

        {!isBabysitter() && (
          <>
            <RatingField
              control={control}
              name={'platform'}
              label={t('rating.platform_evaluation')}
              mb={'md'}
            />
            <TextAreaController
              control={control}
              name={'other_feedback'}
              id={'other_feedback'}
              label={t('rating.other_feedback')}
              size={mobileView ? 'md' : 'lg'}
              placeholder={t('rating.other_feedback.placeholder')}
              mb={'md'}
              styles={{
                label: {
                  fontWeight: 'bold',
                  fontSize: '16px !important',
                },
              }}
            />

            <BabysitterRatingFieldArray
              name={'babysitter_ratings'}
              control={control}
              clearErrors={clearErrors}
              meta={{ error: errors?.booking_children }}
            />
          </>
        )}
        <Space h={'sm'} />

        <div className={'mt-1 w-full'}>
          {isEdit && <RatingEntryRemoveButton booking={booking} />}

          <Button
            mt={'lg'}
            fullWidth
            loading={isSubmitting}
            color="secondary"
            type={'submit'}
            disabled={!isValid}
          >
            {t('submit_feedback')}
          </Button>

          <Button mt={'lg'} fullWidth variant="subtle" color="gray" onClick={onCancel}>
            {t('cancel')}
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}
