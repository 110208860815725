import React from 'react'
import { useAppSelector } from '@/store'

export const useBabysitterData = () => {
  const { data: babysitter } = useAppSelector((state) => state.babysitter.data)

  return {
    babysitter,
  }
}
