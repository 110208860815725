import * as React from 'react'
import { Outlet } from 'react-router-dom'
import { Space } from '@mantine/core'
import { Card } from '@/components/Elements'
import useStyles from './ContentSection.styles'
import { Header } from './Header'

export const ContentSection = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.wrapper}>
      <Card className={classes.card}>
        <Header />
        <Space h="md" />

        <Outlet />
      </Card>
    </div>
  )
}
