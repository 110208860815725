import { useTranslation } from 'react-i18next'
import { ButtonLink } from '@/components/Elements'
import { useChatConversationData } from '@/features/chat/hooks'
import { useUser } from '@/features/user'

export const ViewProfile = () => {
  const { t } = useTranslation()
  const { isBabysitter, isClient } = useUser()

  const { getParticipantsBabysitters, getParticipantsClient } = useChatConversationData()

  const participants = isBabysitter() ? getParticipantsClient() : getParticipantsBabysitters()

  return participants && participants.length == 1 ? (
    <ButtonLink to={`/babysitters/${participants[0]?.id}`} variant={'outline'} size={'sm'}>
      {t('view_profile')}
    </ButtonLink>
  ) : null
}
