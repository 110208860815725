import { useTranslation } from 'react-i18next'
import { Checkout } from '@/features/booking/routes/checkout'
import { RegularConfirmed } from '@/features/booking/routes/regularConfirmed'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { getType } from '@/features/bookings/helpers/booking/type'
import { CareDetailsForm, GeneralForm } from './Forms'

interface IProps {
  step: number
  bookingType: BOOKING_TYPE
  initialValuesBooking: { general: object; timetable: object }
  initialValuesDetails: { general: object }
  previousRequest: any
  loadedWorkingBooking: any
}

export const BookingWizardForm = ({
  step,
  bookingType,
  initialValuesBooking,
  initialValuesDetails,
  previousRequest,
  loadedWorkingBooking,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  const stepsOcasional = [
    {
      title: t('book_baby_sister').replace('%BOOKING_TYPE%', getType(bookingType)?.title),
      content: (
        <GeneralForm
          initialValuesBooking={initialValuesBooking}
          initialValuesDetails={initialValuesDetails}
          previousRequest={previousRequest}
          loadedWorkingBooking={loadedWorkingBooking}
          bookingType={bookingType}
        />
      ),
    },
    {
      title: t('payment_method'),
      content: <Checkout />,
    },
    {
      title: t('care_details'),
      content: (
        <CareDetailsForm
          initialValuesDetails={initialValuesDetails}
          initialValuesBooking={initialValuesBooking}
        />
      ),
    },
  ]

  const stepsRegular = [
    {
      title: t('book_baby_sister').replace('%BOOKING_TYPE%', getType(bookingType)?.title),
      content: (
        <GeneralForm
          initialValuesBooking={initialValuesBooking}
          initialValuesDetails={initialValuesDetails}
          previousRequest={previousRequest}
          loadedWorkingBooking={loadedWorkingBooking}
          bookingType={bookingType}
        />
      ),
    },
    {
      title: t('care_details'),
      content: (
        <CareDetailsForm
          initialValuesDetails={initialValuesDetails}
          initialValuesBooking={initialValuesBooking}
        />
      ),
    },
    {
      title: t('booking_submitted'),
      content: <RegularConfirmed />,
    },
  ]

  function getStepContent(step: number) {
    return bookingType == BOOKING_TYPE.ONETIME ? stepsOcasional[step] : stepsRegular[step]
  }

  return <>{getStepContent(step)?.content}</>
}
