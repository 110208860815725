import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  header: {},

  title: {},

  // hotfix
  body: {
    height: 'calc(100% - 44.2px - 16px - 40px)', // header height - header marginBottom - header paddingTop

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      height: 'calc(100% - 44.2px - 16px - 24px)', // header height - header marginBottom - header paddingTop
    },
  },
}))
