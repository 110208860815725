import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@/components/Elements'
import { BookingModel } from '@/features/bookings/types/models'
import { TimeEntryAddContainer } from './TimeEntryAddContainer'

interface IProps {
  booking: BookingModel
  visible: boolean
  onClose: () => void
  onSuccess: () => void
}

export const TimeEntryAdd = ({ booking, visible, onClose, onSuccess }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal opened={visible} onClose={onClose} centered title={`${t('add_time')}`}>
        <TimeEntryAddContainer booking={booking} onClose={onClose} onSuccess={onSuccess} />
      </Modal>
    </>
  )
}
