import React from 'react'
import { TagBadge, TagBadgeProps } from '../TagBadge'
import useStyles from './ChipTagBadge.styles'
import { Selector } from './Selector'

interface IProps extends TagBadgeProps {
  children: React.ReactNode
  checked: boolean
  color: string
  id: string | number | undefined
  onChange: (checked: boolean) => void
}

export const ChipTagBadge = ({ children, checked, id, color, onChange, ...props }: IProps) => {
  const { classes } = useStyles()

  return (
    <>
      <TagBadge
        component={Selector}
        checked={checked}
        id={id}
        color={checked ? color : 'red'}
        onChange={onChange}
      >
        {children}
      </TagBadge>
    </>
  )
}
