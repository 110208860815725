import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { ContentCard } from '@/components/Elements'

export const BabysittersLayout = () => {
  const { t } = useTranslation()

  return (
    <>
      <ContentCard title={t('baby-sisters')}>
        <Outlet />
      </ContentCard>
    </>
  )
}
