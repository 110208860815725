import moment from 'moment'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { bookingTrackingEditSubmitPA } from '@/features/booking-tracking/store'
import { useNotify } from '@/hooks'
import { useAppSelector } from '@/store'
import { DateUtils } from '@/utils'
import { TimeEntryForm } from '../../TimeEntryForm'

interface IProps {
  onClose: () => void
  onSuccess: (data: any) => void
}

export const TimeEntryEditContainer = ({ onClose, onSuccess }: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const { data: bookingTracking } = useAppSelector((state) => state.bookingTracking.edit.data)

  const initialValues = useMemo(() => {
    let result = {}

    if (bookingTracking) {
      const { date_start, date_end } = bookingTracking

      result = {
        date_start: date_start ? moment(date_start).startOf('day').toDate() : null,
        time_start: date_start
          ? DateUtils.transformDateAndTimeDateToDate(new Date(), date_start)
          : null,
        date_end: date_end ? moment(date_end).startOf('day').toDate() : null,
        time_end: date_end ? DateUtils.transformDateAndTimeDateToDate(new Date(), date_end) : null,
      }
    }

    return result
  }, [bookingTracking])

  const onSubmit = async (values: any) => {
    const params = {
      ...values,
    }
    const data = await dispatch(
      bookingTrackingEditSubmitPA.request({ id: bookingTracking?.id as number, params })
    )
    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })

    onSuccess(data)
  }

  return (
    <>
      <TimeEntryForm initialValues={initialValues} onSubmit={onSubmit} onCancel={onClose} />
    </>
  )
}
