import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLogout } from '@/features/auth/hooks'
import { profileDeactivatePA } from '@/features/profile/store'
import { useAsyncDispatch, useNotify } from '@/hooks'

export const useProfileDeactivate = () => {
  const { t } = useTranslation()

  const { loading, onAsyncDispatch } = useAsyncDispatch()

  const { showNotification } = useNotify()

  const { loading: logoutLoading, logout } = useLogout()

  const deactivate = async () => {
    try {
      await onAsyncDispatch(profileDeactivatePA.request)

      showNotification({ type: 'success', message: t('account_deactivated_successfully') })

      await logout()
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return {
    loading: loading || logoutLoading,
    deactivate,
  }
}
