import React from 'react'
import { Participant } from '@/features/chat/types/models'
import { BabysitterUser, ClientUser, ROLE, User } from '@/features/user'

export const useUsersModel = () => {
  const getName = (user: User | BabysitterUser | ClientUser | Participant | null | undefined) => {
    if (user) {
      let result = user?.first_name

      if (user?.last_name) {
        result = result ? `${result} ${user?.last_name}` : user?.last_name
      }

      return result
    }
    return null
  }

  const getAllNames = (
    participants: User[] | BabysitterUser[] | ClientUser[] | Participant[] | undefined,
    except: number
  ) => {
    let fullNames = ''

    if (participants)
      participants.forEach((element) => {
        if (element.id != except) {
          if (fullNames != '') fullNames = fullNames + ', '
          fullNames = fullNames + getName(element)
        }
      })

    return fullNames
  }

  const getAllParticipantsRole = (
    participants: User[] | BabysitterUser[] | ClientUser[] | Participant[] | undefined,
    role: ROLE
  ) => {
    const result = [] as any[]
    if (participants)
      participants.forEach((element) => {
        if (element.role === role) result.push(element)
      })

    return result
  }

  const isUserActive = (user?: User | BabysitterUser | ClientUser) => {
    if (user) {
      return user?.active && user?.approved
    }

    return false
  }

  return {
    getName,
    getAllNames,
    isUserActive,
    getAllParticipantsRole,
  }
}
