import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    flexGrow: 1,
    width: 0,
  },

  topBar: {
    display: 'flex',
    alignItems: 'center',
  },

  subBar: {
    display: 'flex',
    alignItems: 'center',
  },
}))
