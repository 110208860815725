import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Badge } from '@/components/Elements'
import { BOOKING_CANDIDATE_STATUS } from '@/features/booking-candidate/consts/candidate'
import { candidateHelper } from '@/features/booking-candidate/helpers'

interface IProps {
  value: BOOKING_CANDIDATE_STATUS
}

export const CandidateStatusBadge = ({ value }: IProps) => {
  const { t } = useTranslation()

  const config = useMemo(() => {
    return candidateHelper.status.getStatus(value)
  }, [value])

  return (
    <Badge color={config?.color} variant={'filled'}>
      {config?.label}
    </Badge>
  )
}
