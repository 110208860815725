import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  section: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  container: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  sectionContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    fontFamily: 'Recoleta',
    fontWeight: 'bold',
    color: '#000000',
    fontSize: '32px',
    lineHeight: '44px',
    marginBottom: '0px',
  },
  titleColored: {
    fontFamily: 'Recoleta',
    fontWeight: 'bold',
    color: '#fdbcb5',
    fontSize: '32px',
    lineHeight: '44px',
    marginBottom: '0px',
  },
  titleSpan: {
    color: '#fdbcb5',
  },
  text: {
    color: '#505a5f',
    fontFamily: 'Circular Std Book, sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '20px',
    marginBottom: '0',
  },
  reviewHeader: {
    color: '#505a5f',
    fontFamily: 'Circular Std Book, sans-serif',
    fontWeight: 'bold',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '20px',
    marginBottom: '0',
  },
  reviewText: {
    color: '#505a5f',
    fontFamily: 'Circular Std Book, sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '20px',
  },
  cta: {
    marginBottom: '32px',
  },
  carousel: {
    alignSelf: 'stretch',
  },
  slide: {
    display: 'flex !important',
    justifyContent: 'center',
  },
  arrow: {
    position: 'absolute',
    zIndex: 1,
    bottom: '-14px',
  },
  arrowPrev: {
    left: '-20px',
  },
  arrowNext: {
    right: '-18px',
  },
  centerButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '50px',
  },
  underline: {
    height: '0',
    zIndex: 2,
    position: 'relative',
    top: '-12px',
  },
}))
