import TagManager from 'react-gtm-module'

export const useTagManager = () => {
  const actions = {
    simpleEvent: (event: string) => {
      TagManager.dataLayer({
        dataLayer: {
          event: event,
        },
      })
    },
    simpleEventWithValue: (event: string, value: any) => {
      TagManager.dataLayer({
        dataLayer: {
          event: event,
          value: value,
        },
      })
    },
    simpleEventWithEcommerce: (event: string, value: any, ecommerce: any) => {
      TagManager.dataLayer({
        dataLayer: {
          event: event,
          fbTotal: value,
          ecommerce: ecommerce,
        },
      })
    },
  }
  return {
    actions,
  }
}
