import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/features/auth'
import { useUser } from '@/features/user'
import { ChatUnreadBadge } from '../Badges'
import { NavLink, NavLinkType } from '../NavLink'

interface IProps {
  onLinkClick: () => void
}

export const GeneralLinks = ({ onLinkClick }: IProps) => {
  const { t } = useTranslation()

  const { isLoggedIn } = useAuth()
  const { isClient, isBabysitter } = useUser()

  const links: Array<NavLinkType> = [
    {
      path: '/book-now',
      label: t('book'),
      icon: 'book',
      allow: isClient(),
    },
    {
      path: '/bookings',
      label: t('bookings'),
      icon: 'calendar-search',
      allow: isClient() && isLoggedIn,
    },
    // {
    //   path: '/babysitters',
    //   label: t('baby-sisters'),
    //   icon: 'baby-face',
    //   allow: isClient(),
    // },
    {
      path: '/services/upcoming',
      label: t('services'),
      icon: 'calendar-search',
      allow: isBabysitter(),
    },
    {
      path: '/calendar',
      label: t('calendar'),
      icon: 'calendar-search',
      allow: isBabysitter(),
    },
    {
      path: '/chat',
      label: t('chats'),
      icon: 'chat',
      allow: isLoggedIn,
      renderRightSection: ({ isActive }: { isActive: boolean }) => (
        <ChatUnreadBadge isActive={isActive} />
      ),
    },
    {
      path: '/wallet',
      label: t('wallet'),
      icon: 'wallet',
      allow: isLoggedIn,
    },
    // {
    //   path: '/check-ins',
    //   label: t('Check In'),
    //   icon: 'wallet',
    //   allow: isBabysitter(),
    // },
  ]

  return (
    <>
      {links
        .filter((item) => item.allow)
        .map((item) => {
          return <NavLink key={item.path} onClick={onLinkClick} {...item} />
        })}
    </>
  )
}
