import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },

  card: {
    width: '100%',
    maxWidth: '658px',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      maxWidth: `${theme.breakpoints.md}px`,
      backgroundColor: theme.colors.mint[0],
      padding: '0px',
    },
  },
}))
