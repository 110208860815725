import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { BookLayout } from '@/features/book/components/Book/BookLayout'
import { BookGeneralLayout } from '@/features/book/components/Book/BookLayout/BookGeneralLayout'

export const BookRoot = () => {
  return (
    <Routes>
      <Route element={<BookGeneralLayout />}>
        <Route index element={<BookLayout />} />
      </Route>

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
