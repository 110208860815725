import * as React from 'react'
import useStyles from './Layout.styles'

interface IProps {
  children: React.ReactNode
}

export const Layout = ({ children }: IProps) => {
  const { classes } = useStyles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.block}>{children}</div>
    </div>
  )
}
