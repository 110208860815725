import * as Sentry from '@sentry/react'
import { IconCirclePlus } from '@tabler/icons'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Anchor, Grid, Group, SelectItem, Title } from '@mantine/core'
import { ActionIconButton } from '@/components/Elements'
import { FormControlActionBlock, SelectController } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'
import { languageAPI } from '@/features/selects/api'
import { useLang } from '@/hooks'

interface IProps {
  extraOptions?: Array<{ label: string; value: string }>
  initialVisible: boolean
  boldTitle?: boolean
}

export const LanguagesGroupFields = ({
  extraOptions = [],
  initialVisible,
  boldTitle = false,
}: IProps) => {
  const { t } = useTranslation()
  const { lang } = useLang()
  const {
    appState: { mobileView },
  } = useAppState()

  const { control, watch, setValue } = useFormContext()
  const [visible, setVisible] = useState(initialVisible)
  const [languagePrimaryList, setLanguagePrimaryList] = useState<any[]>([])
  const [languageSecondaryList, setLanguageSecondaryList] = useState<any[]>([])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const { data } = await languageAPI.getLanguages(lang)
    const list = data.languages.map((item: any) => ({
      label: item.name,
      value: item.id.toString(),
    }))
    setLanguagePrimaryList(list)
    setLanguageSecondaryList(list)
  }

  const watchPrimary = watch('primary_language_id')

  const watchSecondary = watch('secondary_language_id')

  //const { getLanguages } = useSelectOptions()

  const showSecondary = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    setVisible(true)
  }

  const hideSecondary = () => {
    setValue('secondary_language_id', '')
    setVisible(false)
  }

  useEffect(() => {
    setVisible(watchSecondary && watchSecondary != '')
  }, [watchSecondary])

  const renderAdd = () => (
    <div className={mobileView ? 'flex flex-col self-start' : 'flex flex-col mt-2 self-start'}>
      <Anchor href="#" onClick={showSecondary} className={'font-bold add-new-item'}>
        <Group className={'gap-2'}>
          <IconCirclePlus size={18} />
          {t('add_language')}
        </Group>
      </Anchor>
    </div>
  )

  const renderActionBlock = () => (
    <FormControlActionBlock className={mobileView ? 'flex h-100 align-bottom mb-3 mt-3' : 'mb-4'}>
      <ActionIconButton name={'remove'} onClick={() => hideSecondary()} />
    </FormControlActionBlock>
  )

  return (
    <div className={mobileView ? 'flex flex-col mt-0' : 'flex flex-col mb-4'}>
      <div className={'flex flex-col'}>
        <Grid grow className={'mt-0'}>
          <Grid.Col span={mobileView ? 12 : 9} xs={10}>
            <Grid>
              <Grid.Col lg={4}>
                <SelectController
                  control={control}
                  name={`primary_language_id`}
                  id={`primary_language_id`}
                  label={t('language_spoken')}
                  placeholder={t(mobileView ? 'primary' : 'language')}
                  mb={'md'}
                  size={mobileView ? 'md' : 'lg'}
                  data={languagePrimaryList}
                  withinPortal
                  styles={
                    mobileView && boldTitle
                      ? {
                          label: {
                            fontWeight: 'bold',
                            fontSize: '16px !important',
                          },
                        }
                      : {}
                  }
                />
              </Grid.Col>
              <Grid.Col lg={4} className={'hidden lg:block'} />
            </Grid>
          </Grid.Col>
          <Grid.Col span={3} xs={2} className={'lg:hidden'} />
        </Grid>
      </div>

      {visible && (
        <div className={'flex flex-col'}>
          <Grid grow>
            <Grid.Col span={9} lg={11} xs={10}>
              <Grid>
                <Grid.Col lg={4}>
                  <SelectController
                    control={control}
                    name={`secondary_language_id`}
                    id={`secondary_language_id`}
                    label={t('secondary')}
                    placeholder={t('secondary')}
                    size={mobileView ? 'md' : 'lg'}
                    mb={'md'}
                    data={languageSecondaryList}
                    withinPortal
                    styles={
                      mobileView && boldTitle
                        ? {
                            label: {
                              fontWeight: 'bold',
                              fontSize: '16px !important',
                              marginTop: '12px',
                            },
                          }
                        : {}
                    }
                  />
                </Grid.Col>
                <Grid.Col lg={4} className={'hidden lg:block'}>
                  {renderActionBlock()}
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col span={3} lg={1} xs={2} className={'lg:hidden'}>
              {renderActionBlock()}
            </Grid.Col>
          </Grid>
        </div>
      )}

      {!visible && renderAdd()}
    </div>
  )
}
