import { IconChevronLeft } from '@tabler/icons'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, ContentCard, MegaTagTitle } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { BookingDetailsContainer } from '@/features/babysitter/components/Babysitter/BabysitterDetails'
import { Header } from '@/features/babysitter/components/Babysitter/BabysitterDetails/Layout'
import { useBabysitterData } from '@/features/babysitter/hooks'
import { useHeaderContext } from '@/features/header/contexts/HeaderContext'

export const Babysitter = () => {
  const { setKey } = useHeaderContext()
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()

  const navigate = useNavigate()

  const { babysitter } = useBabysitterData()

  const navigateBack = () => navigate(-1)

  useEffect(() => {
    setKey({
      base: false,
      title: '',
      border: false,
      leftIcon: 'arrow_left',
      leftClick: navigateBack,
    })
  }, [])

  return (
    <>
      <MegaTagTitle title={'baby_sister'} />

      {!mobileView ? (
        <ContentCard
          header={
            <div className={'flex flex-wrap gap-2'}>
              <div className={'grow'}>
                <Button
                  variant="default"
                  color="dark"
                  leftIcon={<IconChevronLeft />}
                  onClick={() => navigate(-1)}
                >
                  {t('back')}
                </Button>
              </div>
              {babysitter && (
                <div className={'flex items-center'}>
                  <Header />
                </div>
              )}
            </div>
          }
        >
          <BookingDetailsContainer />
        </ContentCard>
      ) : (
        <div className={'p-4'}>
          <BookingDetailsContainer />
        </div>
      )}
    </>
  )
}
