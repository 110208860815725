import React from 'react'
import { GeocodingProvider } from '@/packages/google-maps/geocoding/contexts'

type Props = {
  children: React.ReactNode
}

export const GoogleProvider: React.FC<Props> = ({ children }) => {
  return <GeocodingProvider>{children}</GeocodingProvider>
}
