import { createSlice } from '@reduxjs/toolkit'
import { BabysitterUser } from '@/features/user'

interface State {
  data: BabysitterUser | null
  loading: boolean
  error: any
}

const initialState: State = {
  data: null,
  loading: false,
  error: null,
}

const babysitterDataSlice = createSlice({
  name: 'babysitterData',
  initialState,
  reducers: {
    getData(state, action) {
      state.loading = true
      state.error = null
    },
    getDataSuccess(state, action) {
      state.loading = false
      state.data = action.payload.data
    },
    getDataError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    updateData(state, action) {
      state.data = { ...state.data, ...action.payload }
    },
    cleanState() {
      return initialState
    },
  },
})

export const babysitterData = babysitterDataSlice.actions

export default babysitterDataSlice.reducer
