import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  block: {
    display: 'flex',
    alignItems: 'center',
  },

  avatarWrap: {
    marginRight: '1.5rem',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}))
