import { useTranslation } from 'react-i18next'

interface IProps {
  daysPerWeek: string
  className?: string
}

export const BookingDaysPerWeekDisplay = ({ daysPerWeek, className }: IProps) => {
  const { t } = useTranslation()
  return (
    <span className={className}>
      {daysPerWeek} {t('daysPerWeek')}
    </span>
  )
}
