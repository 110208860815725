import React, { createContext, useContext } from 'react'

const BookingDrawerManagerContext = createContext({
  state: {
    isViewScreen: false,
    screenData: null as any,
  },
  actions: {},
  navigation: {
    onScreenClose: () => null,
    onScreenDataClean: () => null,
    onScreenView: () => null,
  },
})

interface IProps {
  children: React.ReactNode
  state: any
  actions: any
  navigation: any

  [x: string]: any
}

const BookingDrawerManagerProvider = ({ children, ...props }: IProps) => {
  return (
    <BookingDrawerManagerContext.Provider value={{ ...props }}>
      {children}
    </BookingDrawerManagerContext.Provider>
  )
}

const useBookingDrawerManagerContext = () => useContext(BookingDrawerManagerContext)

export { BookingDrawerManagerProvider, useBookingDrawerManagerContext }
