import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
  },

  header: {
    paddingBottom: '16px',
  },

  headerBlock: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  headerTitleBlock: {
    flexGrow: 1,
  },

  headerRightBlock: {},

  content: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
  },

  scrollArea: {
    width: '100%',
  },

  footer: {
    paddingTop: '16px',
  },
}))
