import { BookingModel, BookingService } from '@/features/bookings/types/models'
import { RatingEntryModal } from '../RatingEntryModal'
import { RatingEntryEditContainer } from './RatingEntryEditContainer'

interface IProps {
  booking: BookingModel | BookingService
  visible: boolean
  onClose: () => void
  onDestroy: () => void
}

export const RatingEntryEdit = ({ booking, visible, onClose, onDestroy }: IProps) => {
  return (
    <RatingEntryModal visible={visible} onClose={onClose}>
      <RatingEntryEditContainer booking={booking} onClose={onClose} onDestroy={onDestroy} />
    </RatingEntryModal>
  )
}
