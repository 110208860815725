import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { MetaPagination, OverlayDataShower } from '@/components/Elements'
import { useEvents } from '@/features/app/hooks'
import { SPECIFIC_SERVICE_ALIAS } from '@/features/booking-service/consts/specific-services'
import { bookingServiceServicesSpecificList } from '@/features/booking-service/store'
import { BookingDrawerManager } from '@/features/bookings/components/Booking'
import {
  BookingDrawerManagerProvider,
  BookingDrawerModuleProvider,
} from '@/features/bookings/contexts'
import { useBookingDrawerManager } from '@/features/bookings/hooks'
import {
  BookingUpdatedActionMeta,
  useBookingDrawerModule,
} from '@/features/bookings/hooks/useBookingDrawerModule'
import { BookingModel, BookingService } from '@/features/bookings/types/models'
import { RatingSubmittedActionMeta } from '@/features/rating/hooks'
import { TAG_MANAGER_EVENT } from '@/features/tag-manager/consts/event'
import { RootState } from '@/store'
import { GridView } from './GridView'
import useStyles from './SpecificServicesContainer.styles'
import { getSpecificFetchParams } from './helpers'

interface IProps {
  alias: SPECIFIC_SERVICE_ALIAS

  list: BookingService[]
  loading: boolean
  error: any
  meta: any
  page: number
}

const SpecificServicesContainerComponent = ({ alias, ...props }: IProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const dispatch = useDispatch()
  const event = useEvents()

  useEffect(() => {
    event.actions.generic(
      alias === SPECIFIC_SERVICE_ALIAS.UPCOMING
        ? TAG_MANAGER_EVENT.BOOKING_UPCOMING
        : alias === SPECIFIC_SERVICE_ALIAS.HISTORY
        ? TAG_MANAGER_EVENT.BOOKING_HISTORY
        : TAG_MANAGER_EVENT.BOOKING_POTENTIAL
    )

    return () => {
      dispatch(bookingServiceServicesSpecificList.cleanState())
    }
  }, [])

  useEffect(() => {
    fetchList()
  }, [props.page])

  const fetchList = () => {
    const params = {
      page: props.page,
      ...getSpecificFetchParams(alias),
    }

    dispatch(bookingServiceServicesSpecificList.getList({ params }))
  }

  const onPageChange = (page: number) => {
    dispatch(bookingServiceServicesSpecificList.setPage(page))
  }

  const bookingDrawerManager = useBookingDrawerManager({})

  const onBookingView = (id: number) => {
    bookingDrawerManager.navigation.onScreenView({ id })
  }

  const onBookingUpdated = (booking: any | null, actionMeta: BookingUpdatedActionMeta) => {
    fetchList()

    const { action } = actionMeta
    if (action && ['candidate-decline', 'request-finish'].includes(action)) {
      bookingDrawerManager.navigation.onScreenClose()
    }
  }

  const onCandidateAcceptSuccess = () => {
    fetchList()
  }

  const onCandidateDeclineSuccess = () => {
    fetchList()
  }

  const onCancelSuccess = () => {
    fetchList()
  }

  const onRatingSubmitted = (rating: any | null, actionMeta: RatingSubmittedActionMeta) => {
    const { payload } = actionMeta
    const { bookingServiceId, bookingId } = payload

    const id = bookingServiceId || bookingId

    dispatch(bookingServiceServicesSpecificList.setServiceClientRating({ id, rating }))
  }

  const bookingDrawerModule = useBookingDrawerModule({ onBookingUpdated, onRatingSubmitted })

  const serviceActionBarProps = useMemo(() => {
    return {
      config: {
        candidateStatusBlock: alias === SPECIFIC_SERVICE_ALIAS.POTENTIAL,
        menu: alias === SPECIFIC_SERVICE_ALIAS.UPCOMING,
        chat: alias === SPECIFIC_SERVICE_ALIAS.UPCOMING,
      },
    }
  }, [alias])

  const serviceFooterProps = useMemo(() => {
    return {
      config: {
        rating: alias === SPECIFIC_SERVICE_ALIAS.HISTORY,
      },
    }
  }, [alias])

  const serviceFooterStatus = useMemo(() => {
    return alias === SPECIFIC_SERVICE_ALIAS.HISTORY
  }, [alias])

  useEffect(() => {
    console.log('ratingProps specific container:' + serviceFooterProps?.config.rating)
  }, [])

  return (
    <div className={classes.root}>
      <OverlayDataShower isLoading={props.loading} isFailed={!!props.error} error={props.error}>
        {!!props.list.length && (
          <div className={'flex flex-col justify-between h-full'}>
            <GridView
              items={props.list}
              onView={onBookingView}
              actionBarProps={{ ...serviceActionBarProps }}
              onCandidateAcceptSuccess={onCandidateAcceptSuccess}
              onCandidateDeclineSuccess={onCandidateDeclineSuccess}
              onCancelSuccess={onCancelSuccess}
              footer={serviceFooterStatus}
              footerProps={{ ...serviceFooterProps }}
              onRatingSubmitted={onRatingSubmitted}
            />

            <MetaPagination meta={props.meta} page={props.page} onChange={onPageChange} />
          </div>
        )}

        {!props.list.length && !props.loading && !props.error && t('no_services_yet')}
      </OverlayDataShower>

      <BookingDrawerManagerProvider {...bookingDrawerManager}>
        <BookingDrawerModuleProvider {...bookingDrawerModule}>
          <BookingDrawerManager ratingProps={{ ...serviceFooterProps }} />
        </BookingDrawerModuleProvider>
      </BookingDrawerManagerProvider>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const { list, loading, error, meta, page } = state.bookingService.services.specific.list
  return {
    list,
    loading,
    error,
    meta,
    page,
  }
}

export const SpecificServicesContainer = connect(mapStateToProps)(
  SpecificServicesContainerComponent
)
