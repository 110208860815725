import React from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  timePeriod: {
    time_start: string
    time_end: string
  }
  className?: string
}

export const BookingTimePeriodDisplay = ({ timePeriod, className }: IProps) => {
  const { t } = useTranslation()

  return (
    <span className={className}>
      {timePeriod.time_start}
      {' - '}
      {timePeriod.time_end}
    </span>
  )
}
