import { HOTEL_TYPE } from '@/const/hotel'
import i18n from '@/i18n'

type HotelOptionType = {
  label: string
  value: HOTEL_TYPE | string
}

export const getOtherHotelOptions = () => {
  return [
    {
      label: i18n.t('other_hotel'),
      value: HOTEL_TYPE.OTHER,
    },
  ]
}
