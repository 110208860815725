import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import {
  DatePickerController,
  SelectController,
  TextAreaController,
  TextInputController,
} from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'
import { cityAPI } from '@/features/selects/api'
import { useLang } from '@/hooks'

export type DetailsFormSectionValues = {
  birthday: Date
  city_id: number | string | null
  occupation_area: string
  bio: string
}

export const DetailsFormSection = () => {
  const { t } = useTranslation()
  const { lang } = useLang()
  const {
    appState: { mobileView },
  } = useAppState()

  const { control } = useFormContext<DetailsFormSectionValues>()

  const [cityList, setCityList] = useState<any[]>([])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const { data } = await cityAPI.getCities(lang)
    const list = data.cities.map((item: any) => ({
      label: item.name,
      value: item.id.toString(),
    }))

    setCityList(list)
  }

  return (
    <>
      <Grid>
        <Grid.Col md={4} sm={6}>
          <DatePickerController
            control={control}
            name={'birthday'}
            id={'birthday'}
            label={t('date_of_birth')}
            placeholder={t('date_of_birth')}
            size={mobileView ? 'md' : 'lg'}
            mb={mobileView ? '0' : 'md'}
          />
        </Grid.Col>

        <Grid.Col>
          <SelectController
            control={control}
            name={'city_id'}
            id={'city_id'}
            label={t('location')}
            placeholder={t('location')}
            size={mobileView ? 'md' : 'lg'}
            mb={mobileView ? '0' : 'md'}
            data={cityList}
            withinPortal
          />
        </Grid.Col>

        <Grid.Col>
          <TextInputController
            control={control}
            name={'occupation_area'}
            id={'occupation_area'}
            label={t('occupation')}
            placeholder={t('occupation-field-placeholder')}
            size={mobileView ? 'md' : 'lg'}
            mb={mobileView ? '0' : 'md'}
          />
        </Grid.Col>

        <Grid.Col>
          <TextAreaController
            control={control}
            name={'bio'}
            id={'bio'}
            label={t('short_bio')}
            placeholder={t('bio-field-placeholder')}
            size={mobileView ? 'md' : 'lg'}
            mb={mobileView ? '0' : 'md'}
          />
        </Grid.Col>
      </Grid>
    </>
  )
}
