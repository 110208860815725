import { IconEdit } from '@tabler/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { BooleanTextMessage } from '@/components/Elements'
import { ModalOrDrawerSwitch } from '@/components/Elements/MobileWebSwitchs'
import { useAppState } from '@/features/app/hooks'
import { BabysitterProfileEdit, EditorType } from '@/features/profile/components/ProfileEdit'
import { experienceHelper } from '@/features/profile/helpers'
import { BabysitterProfile, useUser } from '@/features/user'
import { usePopup } from '@/hooks'
import { DateUtils } from '@/utils'
import useStyles from './AboutBlock.styles'

interface IProps {
  editable?: boolean
  data: BabysitterProfile | null | undefined
}

type InfoItem = {
  label: string
  value: React.ReactNode
}

export const AboutBlock = ({ editable = false, data }: IProps) => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()

  const { classes } = useStyles()
  const theme = useMantineTheme()

  const { isBabysitter } = useUser()

  const {
    bio,
    birthday,
    city,
    occupation_area,
    experience_max,
    experience_min,
    have_car,
    have_driving_license,
    have_pediatric_certification,
    have_special_kids,
  } = data as BabysitterProfile

  const renderGroup = ({ label, value }: InfoItem) => (
    <Group className={classes.groupItem}>
      <Text className={classes.groupItemLabel}>{label}</Text>
      <Text className={classes.groupItemValue}>{value ? value : '-'}</Text>
    </Group>
  )

  const renderExperience = () => {
    if (experience_min !== null && experience_max !== null) {
      return `${experienceHelper.age.getFormattedAge(experience_min).text} - ${
        experienceHelper.age.getFormattedAge(experience_max).text
      }`
    }
    return '-'
  }

  const { visible, open, close } = usePopup()

  const info: Array<InfoItem> = [
    {
      label: t('birthday'),
      value: birthday ? DateUtils.format(birthday, 'D MMM YYYY') : null,
    },
    {
      label: t('location'),
      value: city?.name,
    },
    {
      label: t('occupation'),
      value: occupation_area,
    },
    {
      label: t('preferred_age_group_to_work'),
      value: renderExperience(),
    },
    {
      label: t('car'),
      value: <BooleanTextMessage value={have_car} />,
    },
    {
      label: t('driving_license'),
      value: <BooleanTextMessage value={have_driving_license} />,
    },
    {
      label: t('pediatric_first_aid_certification'),
      value: <BooleanTextMessage value={have_pediatric_certification} />,
    },
    {
      label: t('experience_with_kids_with_special_needs'),
      value: <BooleanTextMessage value={have_special_kids} />,
    },
  ]

  return (
    <>
      <div className={classes.block}>
        <div className={'flex w-100'}>
          <div className={'w-10/12'}>
            <Title order={mobileView ? 6 : 5}>{t('about_baby_sister')}</Title>
          </div>
          {isBabysitter() && mobileView && (
            <div className={'flex justify-end w-2/12'}>
              <IconEdit
                color={theme.colors.primary[theme.fn.primaryShade()]}
                stroke={1}
                onClick={open}
              />
            </div>
          )}
        </div>
        <div>
          <Text className={classes.bio}>{bio}</Text>
        </div>

        <Stack className={mobileView ? 'my-3 gap-2.5' : 'my-3'}>
          {info.map((item, i) => (
            <React.Fragment key={i}>{renderGroup(item)}</React.Fragment>
          ))}
        </Stack>
      </div>
      {editable && (
        <ModalOrDrawerSwitch
          opened={visible}
          leftIcon={'close'}
          onClose={close}
          headerBorder={true}
          title={t('edit_general_details')}
        >
          <BabysitterProfileEdit onCancel={close} editorType={EditorType.ABOUT_ME} />
        </ModalOrDrawerSwitch>
      )}
    </>
  )
}
