const convertStepToMonth = (step: number) => {
  if (step >= 12) {
    return (step - 11) * 12
  }
  return step
}

const convertMonthToStep = (month: number) => {
  if (month >= 12) {
    return month / 12 + 11
  }
  return month
}

export const experienceAgeStepRangeSliderHelper = {
  convertStepToMonth,
  convertMonthToStep,
}
