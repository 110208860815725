import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Chat, ChatDirectLink } from '../chat'

export const ChatRoot = () => {
  return (
    <Routes>
      <Route index element={<Chat />} />
      <Route path={':id'} element={<ChatDirectLink showRating={false} />} />
      <Route path={':id/rate'} element={<ChatDirectLink showRating={true} />} />
      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
