import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Babysitter } from '../babysitter'

export const BabysitterRoot = () => {
  return (
    <Routes>
      <Route index element={<Babysitter />} />

      <Route path={'*'} element={<Navigate to={'..'} replace={true} />} />
    </Routes>
  )
}
