import classNames from 'classnames'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Group, Header as MantineHeader } from '@mantine/core'
import { LogoIcon } from '@/components/Elements'
import { MobileBackLink } from '@/features/header/layout/BackLink/MobileBackLink'
import { Support } from '@/features/header/layout/Support'
import useStyles from './MobileHeader.styles'

interface IProps {
  border: boolean
  useOpacity?: boolean
  showBackLink?: boolean
  goToBookNow?: boolean
}

export const MobileHeader = ({
  border,
  useOpacity = false,
  showBackLink = false,
  goToBookNow = false,
}: IProps) => {
  const [opacity, setOpacity] = useState(0)
  const { classes } = useStyles()

  const handleNavigation = () => {
    if (!useOpacity || window.scrollY > 60) {
      setOpacity(1)
    } else {
      setOpacity(window.scrollY / 60)
    }
  }

  useEffect(() => {
    handleNavigation()
    if (useOpacity) {
      window.addEventListener('scroll', (e) => handleNavigation())
      return () => {
        // return a cleanup function to unregister our function since it will run multiple times
        window.removeEventListener('scroll', (e) => handleNavigation())
      }
    }
  }, [])

  return (
    <MantineHeader
      height={60}
      p="xs"
      styles={(theme) => ({
        root: {
          position: 'fixed',
          border: 'none',
          display: 'flex',
          backgroundColor: `rgba(255,255,255, ${opacity})`,
        },
      })}
      className={border && opacity >= 1 ? classes.border : classes.noborder}
    >
      <Group position="apart" className={'w-100 gap-0'}>
        <div className={showBackLink ? classes.third : classes.half}>
          {showBackLink ? (
            <MobileBackLink goToBookNow={goToBookNow} />
          ) : (
            <LogoIcon size={'sm'} bookingLogo={useOpacity} />
          )}
        </div>
        {showBackLink && (
          <div className={classNames('text-center', classes.third)}>
            <LogoIcon size={'sm'} />
          </div>
        )}
        <div
          className={classNames(showBackLink ? classes.third : classes.half, classes.headerRight)}
        >
          <div className={classes.marginSupport}>
            <Support />
          </div>
        </div>
      </Group>
    </MantineHeader>
  )
}
