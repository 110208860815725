import React, { createContext, useContext } from 'react'
import { BookingModel } from '@/features/bookings/types/models'

type BookingDrawerModuleContextValues = {
  onBookingUpdated: (booking: BookingModel | null, actionMeta: any) => void
  onRatingSubmitted?: (rating: any, actionMeta: any) => void
}

const BookingDrawerModuleContext = createContext<BookingDrawerModuleContextValues>({
  onBookingUpdated: (booking: BookingModel | null, actionMeta: any) => {},
  // onRatingSubmitted: (rating: any, actionMeta: any) => {},
})

interface IProps {
  children: React.ReactNode
  onBookingUpdated: (booking: BookingModel | null, actionMeta: any) => void
  onRatingSubmitted?: (rating: any, actionMeta: any) => void

  [x: string]: any
}

const BookingDrawerModuleProvider = ({ children, ...props }: IProps) => {
  return (
    <BookingDrawerModuleContext.Provider value={{ ...props }}>
      {children}
    </BookingDrawerModuleContext.Provider>
  )
}

const useBookingDrawerModuleContext = () => useContext(BookingDrawerModuleContext)

export { BookingDrawerModuleProvider, useBookingDrawerModuleContext }
