interface IProps {
  color: string
  size?: number
  viewPort?: number
  className?: string
}

export const CareDetails = ({ size = 24, viewPort = size, color, ...props }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    width={size}
    height={size}
    viewBox="0 0 100 110"
  >
    <path
      stroke={color}
      color={color}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M86,18.77a10,10,0,0,0-10-10H24a10,10,0,0,0-10,10V81.23a10,10,0,0,0,10,10H76a10,10,0,0,0,10-10ZM82,81.23a6,6,0,0,1-6,6H24a6,6,0,0,1-6-6V18.77a6,6,0,0,1,6-6H76a6,6,0,0,1,6,6Z"
    />
    <path
      stroke={color}
      color={color}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M31.35,48.5H27.4a2,2,0,0,0,0,4h3.95a2,2,0,0,0,0-4Z"
    />
    <path
      stroke={color}
      color={color}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M72.6,48.5H40.29a2,2,0,0,0,0,4H72.6a2,2,0,0,0,0-4Z"
    />
    <path
      stroke={color}
      color={color}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M31.35,30.5H27.4a2,2,0,0,0,0,4h3.95a2,2,0,1,0,0-4Z"
    />
    <path
      stroke={color}
      color={color}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M72.6,30.5H40.29a2,2,0,1,0,0,4H72.6a2,2,0,0,0,0-4Z"
    />
    <path
      stroke={color}
      color={color}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M31.35,66.5H27.4a2,2,0,1,0,0,4h3.95a2,2,0,0,0,0-4Z"
    />
    <path
      stroke={color}
      color={color}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M72.6,66.5H40.29a2,2,0,0,0,0,4H72.6a2,2,0,0,0,0-4Z"
    />
  </svg>
)
