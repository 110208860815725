import { combineReducers } from 'redux'
import app from '@/features/app/store/reducers'
import auth from '@/features/auth/store/reducers'
import babysitter from '@/features/babysitter/store/reducers'
import bookingService from '@/features/booking-service/store/reducers'
import bookingTracking from '@/features/booking-tracking/store/reducers'
import booking from '@/features/booking/store/reducers'
import bookings from '@/features/bookings/store/reducers'
import chat from '@/features/chat/store/reducers'
import panel from '@/features/panel/store/reducers'
// import profile from '@/features/profile/store/reducers'
import staticReducer from '@/features/static/store/reducers'
import user from '@/features/user/store/reducers'

const rootReducer = combineReducers({
  app,
  auth,
  user,
  panel,
  static: staticReducer,
  bookings,
  babysitter,
  bookingService,
  bookingTracking,
  chat,
  booking,
})

export default rootReducer
