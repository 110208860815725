import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollArea } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import { BookingTableDetails } from '@/features/bookings/components/Booking/BookingDetails'
import { useBookingData, useBookingPermissions } from '@/features/bookings/hooks'
import { BookingModel } from '@/features/bookings/types/models'
import { useUser } from '@/features/user'

interface IProps {
  ratingProps?: any
}
export const BookingDetailsContent = ({ ratingProps }: IProps) => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()
  let { booking } = useBookingData()

  booking = (booking as BookingModel) || {}

  const { isClient, isBabysitter } = useUser()

  const { isStatusShowAllow, isNextPaymentShowAllow } = useBookingPermissions()

  return (
    <div className={'w-full'}>
      {mobileView ? (
        <BookingTableDetails
          data={booking as BookingModel}
          ratingProps={ratingProps}
          config={{
            babysitter: isClient(),
            client: isBabysitter(),
            status: isStatusShowAllow(booking?.status),
            nextPayment: isNextPaymentShowAllow(booking),
            totalAmount: isClient(),
            babysitterAmount: isBabysitter(),
          }}
        />
      ) : (
        <ScrollArea>
          <BookingTableDetails
            data={booking as BookingModel}
            config={{
              babysitter: isClient(),
              client: isBabysitter(),
              status: isStatusShowAllow(booking?.status),
              nextPayment: isNextPaymentShowAllow(booking),
              totalAmount: isClient(),
              babysitterAmount: isBabysitter(),
            }}
          />
        </ScrollArea>
      )}
    </div>
  )
}
