import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionIconButton } from '@/components/Elements'
import { TimeEntryEdit } from '@/features/booking-tracking/components/TimeEntry'
import { BookingTrackingModel } from '@/features/booking-tracking/types/models'
import { usePopup } from '@/hooks'

interface IProps {
  bookingTracking: BookingTrackingModel
  onSuccess?: (data: any) => void
}

export const EditControl = ({ bookingTracking, onSuccess }: IProps) => {
  const { t } = useTranslation()

  const { visible, open, close } = usePopup()

  const onEditSuccess = (data: any) => {
    onSuccess && onSuccess(data)

    close()
  }

  return (
    <>
      <div>
        <ActionIconButton name={'edit'} onClick={open} />
      </div>

      <TimeEntryEdit
        id={bookingTracking?.id}
        visible={visible}
        onClose={close}
        onSuccess={onEditSuccess}
      />
    </>
  )
}
