import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  groupItem: {
    fontSize: '14px',
    alignItems: 'flex-start',
  },

  groupItemLabel: {
    width: '150px',
    color: theme.colors.gray[7],
    fontWeight: 500,
  },

  groupItemValue: {
    fontWeight: 600,
  },
}))
