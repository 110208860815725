import { IconEdit } from '@tabler/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Title, useMantineTheme } from '@mantine/core'
import { NoDataTextMessage } from '@/components/Elements'
import { ModalOrDrawerSwitch } from '@/components/Elements/MobileWebSwitchs'
import { useAppState } from '@/features/app/hooks'
import { BabysitterProfileEdit, EditorType } from '@/features/profile/components/ProfileEdit'
import { CertificationType, useUser } from '@/features/user'
import { usePopup } from '@/hooks'
import { CertificationCard } from '../../CertificationCard'

interface IProps {
  editable?: boolean
  data: Array<CertificationType>
}

export const CertificationsBlock = ({ editable = false, data }: IProps) => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()

  const theme = useMantineTheme()

  const { isBabysitter } = useUser()
  const { visible, open, close } = usePopup()
  return (
    <>
      <div>
        <div className={'flex w-100'}>
          <div className={'w-10/12'}>
            <Title order={mobileView ? 6 : 5}>{t('certifications')}</Title>
          </div>
          {isBabysitter() && mobileView && (
            <div className={'flex justify-end w-2/12'}>
              <IconEdit
                color={theme.colors.primary[theme.fn.primaryShade()]}
                stroke={1}
                onClick={open}
              />
            </div>
          )}
        </div>

        <div className={'my-3'}>
          {!data.length ? (
            <NoDataTextMessage variant={'fill'} />
          ) : (
            <Grid>
              {data.map((item, i) => (
                <Grid.Col sm={6} md={12} lg={6} key={i}>
                  {<CertificationCard data={item} />}
                </Grid.Col>
              ))}
            </Grid>
          )}
        </div>
      </div>

      {editable && (
        <ModalOrDrawerSwitch
          opened={visible}
          onClose={close}
          leftIcon="close"
          headerBorder={true}
          title={t('edit_certification')}
        >
          <BabysitterProfileEdit onCancel={close} editorType={EditorType.CERTIFICATIONS} />
        </ModalOrDrawerSwitch>
      )}
    </>
  )
}
