import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { babysitterRatingAPI } from '@/features/rating/api'
import { FormTypes } from '@/types'
import {
  RATING_BABYSITTER_REMOVE_FAILED,
  RATING_BABYSITTER_REMOVE_REQUEST,
  RATING_BABYSITTER_REMOVE_SUCCESS,
} from './actionTypes'

type ArgsType = {
  id: number
}

export const ratingBabysitterRemovePA = createPromiseAction(
  RATING_BABYSITTER_REMOVE_REQUEST,
  RATING_BABYSITTER_REMOVE_SUCCESS,
  RATING_BABYSITTER_REMOVE_FAILED
)<ArgsType, unknown, AxiosError<FormTypes.ValidationErrors>>()

function* remove(action: PromiseAction<string, ArgsType, any>) {
  try {
    const { id } = action.payload

    const response: AxiosResponse = yield call(babysitterRatingAPI.deleteRating, id)
    const { data } = response

    yield put(ratingBabysitterRemovePA.success({}))

    resolvePromiseAction(action, {})
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* removeSaga() {
  yield takeEvery(ratingBabysitterRemovePA.request, remove)
}
