import React from 'react'
import { HireControl } from '@/features/booking-candidate/components/CandidateActionControls'
import { BookingCandidate } from '@/features/booking-candidate/types/models'

interface IProps {
  candidate: BookingCandidate
  onSuccess?: (data: object) => void
}

export const CandidateHireControl = ({ candidate, onSuccess }: IProps) => {
  const { id } = candidate

  const onSubmitSuccess = (data: any) => {
    onSuccess && onSuccess(data)
  }

  return <HireControl candidate={candidate} onSuccess={onSubmitSuccess} />
}
