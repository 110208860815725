import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { bookingCandidateAPI } from '@/features/booking-candidate/api'
import {
  BOOKING_CANDIDATE_HIRE_SUBMIT_FAILED,
  BOOKING_CANDIDATE_HIRE_SUBMIT_REQUEST,
  BOOKING_CANDIDATE_HIRE_SUBMIT_SUCCESS,
} from './actionTypes'

type ArgsType = {
  id: number
}

export const bookingCandidateHireSubmitPA = createPromiseAction(
  BOOKING_CANDIDATE_HIRE_SUBMIT_REQUEST,
  BOOKING_CANDIDATE_HIRE_SUBMIT_SUCCESS,
  BOOKING_CANDIDATE_HIRE_SUBMIT_FAILED
)<ArgsType, unknown, AxiosError<any>>()

function* submit(action: PromiseAction<string, ArgsType, any>) {
  try {
    const { id } = action.payload

    const response: AxiosResponse = yield call(bookingCandidateAPI.hireCandidate, id)
    const { data } = response

    yield put(bookingCandidateHireSubmitPA.success({}))

    resolvePromiseAction(action, { ...data.data })
  } catch (err) {
    const error = err as AxiosError<any>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* submitSaga() {
  yield takeEvery(bookingCandidateHireSubmitPA.request, submit)
}
