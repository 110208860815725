import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Anchor, Text } from '@mantine/core'
import { AvatarProvider } from '@/components/Elements'
import { RatingLabel } from '@/features/rating/components/Elements'
import { User } from '@/features/user'
import { useUsersModel } from '@/features/users/hooks'
import useStyles from './TopBar.styles'

interface IProps {
  data: User
}

export const TopBar = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { id, avatar, babysitter_profile } = data

  const { rating } = babysitter_profile || {}

  const { getName } = useUsersModel()

  return (
    <div className={classes.topBar}>
      <div className={classes.avatarBlock}>
        <Anchor component={Link} to={`/babysitters/${id}`}>
          <AvatarProvider avatar={avatar} />
        </Anchor>
      </div>
      <div className={classes.overviewBlock}>
        <Anchor component={Link} to={`/babysitters/${id}`} color={'dark'}>
          <Text size={'lg'} weight={700}>
            {getName(data)}
          </Text>
        </Anchor>

        <div>
          <RatingLabel value={rating as number} />
        </div>
      </div>
    </div>
  )
}
