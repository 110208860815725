import React from 'react'
import { Group, Input } from '@mantine/core'
import { RatingController } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'

interface IProps {
  control: any
  label: any
  name: string
  mb?: string
}

export const RatingField = ({ control, label, name, mb = '' }: IProps) => {
  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <>
      <Group className={mobileView ? 'block' : ''}>
        <div className={'grow'}>
          <Input.Label
            styles={{
              label: {
                fontWeight: 'bold',
                fontSize: '16px !important',
              },
            }}
            mb={mb}
          >
            {label}
          </Input.Label>
        </div>

        <div>
          <RatingController control={control} name={name} size={'xl'} mb={mb} />
        </div>
      </Group>
    </>
  )
}
