import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  wrapper: {
    width: '98px',
  },

  input: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 500,
  },
}))
