import { useTranslation } from 'react-i18next'

interface IProps {
  hoursPerWeek: string
  className?: string
}

export const BookingHoursPerWeekDisplay = ({ hoursPerWeek, className }: IProps) => {
  const { t } = useTranslation()
  return (
    <span className={className}>
      {hoursPerWeek} {t('hours_per_week')}
    </span>
  )
}
