import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

interface IProps {
  isLoggedIn: boolean

  [x: string]: any
}

export const AuthGuard: React.FC<IProps> = ({ children, ...props }) => {
  const location = useLocation()

  const { isLoggedIn } = props

  if (isLoggedIn) {
    return <Navigate to={'/'} state={{ from: location }} />
  }

  return <Outlet />
}
