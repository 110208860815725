import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group, Title } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { EXPERIENCE_AGE_STEP_RANGE } from '@/features/profile/components/FormElements'
import { experienceAgeStepRangeSliderHelper } from '@/features/profile/components/FormElements'
import {
  GeneralFormSection,
  LanguagesFormSection,
} from '@/features/profile/components/FormSections'
import {
  AdditionalInfoFormSection,
  CertificationsFormSection,
  DetailsFormSection,
  ExperienceAgeFormSection,
  SkillsFormSection,
} from '@/features/profile/components/FormSections/BabysitterProfileFormSections'
import { ProfileAvatarManager } from '@/features/profile/components/ProfileAvatarManager'
import { EditorType } from '@/features/profile/components/ProfileEdit'
import { BabysitterProfile, LanguageType, useUser } from '@/features/user'
import { useFormSubmit, useNotify } from '@/hooks'
import { DateUtils, FormatUtils } from '@/utils'
import { validationSchema } from './validation'

interface IProps {
  onSubmit: (values: object) => Promise<void>
  onCancel: () => void
  editorType: EditorType
}

export const EditForm = ({ editorType, onCancel, ...props }: IProps) => {
  const { t } = useTranslation()

  const { user } = useUser()
  const { showNotification } = useNotify()

  const { babysitter_profile, phone, ...restUserData } = user

  const { city, languages, experience_min, experience_max, ...restProfileData } =
    babysitter_profile as BabysitterProfile

  const {
    appState: { mobileView },
  } = useAppState()
  const selectDefaultValue = (field: any) => field?.id || null

  const langsDefaultValue = (langs: Array<LanguageType>) =>
    langs
      ? langs.map(({ language, level }) => ({
          language_id: language?.id,
          level_id: level?.id,
        }))
      : []

  const experienceDefaultValue = () => {
    let min = EXPERIENCE_AGE_STEP_RANGE.MIN
    let max = EXPERIENCE_AGE_STEP_RANGE.MAX

    if (experience_min !== null && experience_max !== null) {
      min = experienceAgeStepRangeSliderHelper.convertMonthToStep(experience_min)
      max = experienceAgeStepRangeSliderHelper.convertMonthToStep(experience_max)
    }

    return [min, max]
  }

  const defaultValues = {
    ...restUserData,
    phone: FormatUtils.formatPhone(phone) || '',
    city_id: selectDefaultValue(city),
    languages: langsDefaultValue(languages),
    experience: experienceDefaultValue(),
    ...restProfileData,
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      const { birthday, phone, skills, certifications, experience, ...resetValues } = data

      const skillsValue = skills.map(({ id, name }: { id: number; name: string }) => {
        if (id) {
          return { skill_id: id }
        } else {
          return { name }
        }
      })

      const certificationsValue = certifications.map(
        ({ start_date, end_date, ...restItem }: { start_date: Date; end_date: Date }) => ({
          ...restItem,
          start_date: DateUtils.formatDateToServerFormat(start_date),
          end_date: DateUtils.formatDateToServerFormat(end_date),
        })
      )

      const values = {
        ...resetValues,
        phone: phone ? phone.replace(/[^0-9]/g, '') : '',
        birthday: DateUtils.formatDateToServerFormat(birthday),
        skills: skillsValue,
        certifications: certificationsValue,
        experience_min: experienceAgeStepRangeSliderHelper.convertStepToMonth(experience[0]),
        experience_max: experienceAgeStepRangeSliderHelper.convertStepToMonth(experience[1]),
      }

      await onFormSubmit(values)
    } catch (error) {
      showNotification({
        type: 'error',
        message: t('error_submitting_request'),
      })
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {(editorType === EditorType.ALL || editorType === EditorType.IMAGE) && (
            <ProfileAvatarManager />
          )}

          {(editorType === EditorType.ALL || editorType === EditorType.GENERAL) && (
            <div className={editorType === EditorType.GENERAL ? '' : 'mt-4'}>
              <GeneralFormSection />
            </div>
          )}

          {(editorType === EditorType.ALL || editorType === EditorType.ABOUT_ME) && (
            <div className={editorType === EditorType.ABOUT_ME ? '' : 'mt-4'}>
              {editorType !== EditorType.ABOUT_ME && (
                <Title order={2} align={'center'}>
                  {t('details')}
                </Title>
              )}
              <DetailsFormSection />
            </div>
          )}

          {(editorType === EditorType.ALL || editorType === EditorType.LANGUAGE) && (
            <div className={editorType === EditorType.LANGUAGE ? '' : 'mt-4 pt-4'}>
              {editorType !== EditorType.LANGUAGE && (
                <Title order={2} align={'center'}>
                  {t('languages')}
                </Title>
              )}
              <LanguagesFormSection level />
            </div>
          )}

          {(editorType === EditorType.ALL || editorType === EditorType.SKILLS) && (
            <div className={editorType === EditorType.SKILLS ? '' : 'mt-4 pt-4'}>
              {editorType !== EditorType.SKILLS && (
                <Title order={2} align={'center'}>
                  {t('skills')}
                </Title>
              )}
              <div className={'mt-4'}>
                <SkillsFormSection />
              </div>
            </div>
          )}

          {(editorType === EditorType.ALL || editorType === EditorType.ABOUT_ME) && (
            <div className={mobileView ? 'mt-4' : 'mt-4 pt-4'}>
              {editorType === EditorType.ALL && (
                <Title order={2} align={'center'} mb={'md'}>
                  {t('experience')}
                </Title>
              )}
              <Title order={mobileView ? 6 : 4} mb={'md'}>
                {t('preferred_age_group_to_work')}
              </Title>
              <div className={'mt-4'}>
                <ExperienceAgeFormSection />
              </div>
            </div>
          )}

          {(editorType === EditorType.ALL || editorType === EditorType.ABOUT_ME) && (
            <div className={mobileView ? 'mt-4' : 'mt-4 pt-4'}>
              <Title order={mobileView ? 6 : 2} align={mobileView ? 'left' : 'center'} mb={'md'}>
                {t('additional_information')}
              </Title>
              <AdditionalInfoFormSection />
            </div>
          )}

          {(editorType === EditorType.ALL || editorType === EditorType.CERTIFICATIONS) && (
            <div className={editorType === EditorType.CERTIFICATIONS ? '' : 'mt-4 pt-4'}>
              {editorType !== EditorType.CERTIFICATIONS && (
                <Title order={2} align={'center'}>
                  {t('certifications_and_degrees')}
                </Title>
              )}
              <CertificationsFormSection />
            </div>
          )}

          {submitError && (
            <Alert type={'error'} mb={'sm'}>
              {submitError?.message || t('error')}
            </Alert>
          )}

          {mobileView ? (
            <div className={'flex w-100 mt-4'}>
              <Button disabled={isSubmitting} loading={isSubmitting} fullWidth type={'submit'}>
                {t('save')}
              </Button>
            </div>
          ) : (
            <div className={'flex justify-end'}>
              <Group>
                <Button variant="default" color="dark" onClick={onCancel}>
                  {t('cancel')}
                </Button>
                <Button disabled={isSubmitting} loading={isSubmitting} type={'submit'}>
                  {t('save')}
                </Button>
              </Group>
            </div>
          )}
        </form>
      </FormProvider>
    </>
  )
}
