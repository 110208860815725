import React from 'react'
import useStyles from './DayButton.styles'

interface DayButton {
  children: React.ReactNode
  enabled: boolean
  onClick: () => void
  disabled: boolean
}

export const DayButton = ({ children, enabled, onClick, disabled = false }: DayButton) => {
  const { classes, cx } = useStyles()

  const onDayClick = () => {
    onClick && onClick()
  }

  return (
    <button
      type={'button'}
      className={cx(classes.root, {
        [classes.enabled]: enabled,
        [classes.disabled]: disabled,
      })}
      disabled={disabled}
      onClick={() => onDayClick()}
    >
      {children}
    </button>
  )
}
