import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import { useAppState } from '@/features/app/hooks'

interface IProps {
  data: any[]
  columns: any[]
}

export const Table = ({ data = [], columns = [] }: IProps) => {
  const {
    appState: { mobileView },
  } = useAppState()
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      <div className="overflow-x-auto relative">
        <table className={'border-spacing-2 w-full'}>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className={mobileView ? 'pb-2' : ''}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
