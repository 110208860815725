import React from 'react'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { TextInput, TextInputProps } from '../../TextInput'

interface TextInputControllerProps<T extends FieldValues> extends TextInputProps {
  control: Control<T, any>
  name: FieldPath<T>
  shouldUnregister?: boolean
  defaultValue?: any
  meta?: any
}

export const TextInputController = <T extends FieldValues>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  ...rest
}: TextInputControllerProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field: { ref, ...field }, fieldState, formState }) => {
        const meta = {
          error: fieldState.error,
        }

        return <TextInput description={' '} {...field} fieldRef={ref} meta={meta} {...rest} />
      }}
    />
  )
}
