import * as React from 'react'
import { useState } from 'react'
import { Group, useMantineTheme } from '@mantine/core'
import { Icon } from '@/components/Elements'
import useStyles from './Support.styles'

interface IProps {
  size?: number
}
export const Support = ({ size }: IProps) => {
  const theme = useMantineTheme()

  const [scrolled, setScrolled] = useState(false)
  const { classes, cx } = useStyles()

  const openSupport = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.zE('messenger', 'show')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.zE('messenger', 'open')
  }
  return (
    <div className={'flex'}>
      <Group>
        <div
          id="icons"
          className={scrolled ? classes.iconColor : classes.iconWhite}
          onClick={openSupport}
        >
          <Icon
            name={'support'}
            stroke={1}
            size={size}
            className={classes.linkIcon}
            color={theme.colors[theme.primaryColor][theme.fn.primaryShade()]}
          />
        </div>
      </Group>
    </div>
  )
}
