import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CheckInLayout } from '@/features/check-ins/components/Layouts'
import { CheckInView } from '../services'

export const CheckInRoot = () => {
  return (
    <Routes>
      <Route element={<CheckInLayout />}>
        <Route index element={<CheckInView />} />
      </Route>

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
