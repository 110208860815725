import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useLogout } from '@/features/auth'
import { useUser } from '@/features/user'
import { NavLink } from '../NavLink'

export const LogoutLink = () => {
  const { t } = useTranslation()
  const { logout } = useLogout()
  const { isBabysitter } = useUser()

  const onClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    await logout()
  }

  return (
    <NavLink
      path={isBabysitter() ? '/babysitter' : '/'}
      label={t('logout')}
      icon={'logout'}
      allow
      onClick={onClick}
      className={'link_icon_stroke'}
    />
  )
}
