import React from 'react'
import { useTranslation } from 'react-i18next'
import { bookingHelper } from '@/features/bookings/helpers'

export const useBookingOptions = () => {
  const { t } = useTranslation()

  const getTypes = () => bookingHelper.type.getTypes()

  const getType = (value: string) => bookingHelper.type.getType(value)

  return {
    getTypes,
    getType,
  }
}
