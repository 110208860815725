import React from 'react'
import { useTranslation } from 'react-i18next'
import { useBookingDrawerManagerContext } from '@/features/bookings/contexts'
import { BookingDetailsContainer } from './BookingDetails'
import { BookingDrawer } from './BookingDrawer'

interface IProps {
  ratingProps?: any
}

export const BookingDrawerManager = ({ ratingProps }: IProps) => {
  const { t } = useTranslation()

  const {
    state: { isViewScreen, screenData },
    navigation: { onScreenClose, onScreenDataClean },
  } = useBookingDrawerManagerContext()

  return (
    <>
      <BookingDrawer title={t('booking_details')} opened={isViewScreen} onClose={onScreenClose}>
        <BookingDetailsContainer
          id={screenData?.id}
          onDestroy={onScreenDataClean}
          onDrawerClose={onScreenClose}
          ratingProps={ratingProps}
        />
      </BookingDrawer>
    </>
  )
}
