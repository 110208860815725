import { ScrollArea } from '@mantine/core'
import { MobileHeaderBooking } from '@/components/Elements/Header/MobileHeader'
import { useAppState } from '@/features/app/hooks'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { BackLink } from '@/features/header/layout/BackLink'
import { BookingDesktopHeader } from '@/features/header/layout/Header'
import { BookingContainer } from '../BookingContainer'
import useStyles from './BookingLayout.styles'
import { Stepper } from './Stepper'

interface IProps {
  bookingType: BOOKING_TYPE
  step: number
}
export const BookingLayout = ({ bookingType, step }: IProps) => {
  const { classes } = useStyles()
  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <div className={classes.section}>
      <div className={classes.infoSection}>
        {!mobileView && <BookingDesktopHeader bookingType={bookingType} />}
        {mobileView && <MobileHeaderBooking bookingType={bookingType} step={step} />}
        <ScrollArea className={classes.stepperWap}>
          <Stepper step={step} bookingType={bookingType} />
        </ScrollArea>
        {!mobileView && step == 0 && <BackLink goToBookNow={true} />}
      </div>
      <div id="contentBooking" className={classes.contentSection}>
        <BookingContainer step={step} bookingType={bookingType} />
      </div>
    </div>
  )
}
