import i18n from '@/i18n'

export const getFormattedAge = (months: number) => {
  let value
  let postfix

  if (months < 12) {
    value = months
    postfix = i18n.t('month_old.abbreviation')
  } else {
    value = Math.trunc(months / 12)
    postfix = i18n.t('year_old.abbreviation')
  }

  const text = `${value} ${postfix}`

  return {
    value,
    postfix,
    text,
  }
}
