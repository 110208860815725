import { Navigate } from 'react-router-dom'
import { AuthBasicLayout, AuthLayout } from '@/components/Layouts'
import {
  Auth,
  ForgotPassword,
  Login,
  Register,
  RegisterPassword,
  ResetPassword,
} from '@/features/auth/routes'
import { ROLE } from '@/features/user'
import { AuthGuard } from '@/routes/components'

//config
export const getAuthRoutes = (isLoggedIn: boolean) => {
  return [
    {
      path: '/*',
      element: <AuthGuard isLoggedIn={isLoggedIn} />,
      children: [
        {
          element: <AuthLayout />,
          children: [
            {
              index: !isLoggedIn,
              element: <Auth role={ROLE.Client} />,
              //element: <Home />,
            },
            {
              path: `login/client`,
              element: <Login role={ROLE.Client} />,
            },
            {
              path: `auth/client`,
              element: <Navigate to="/" replace />,
            },
            {
              path: `s`,
              element: <Navigate to="/" replace />,
            },
            {
              path: `register/client`,
              element: <Register role={ROLE.Client} />,
            },
          ],
        },
        {
          element: <AuthLayout role={ROLE.Babysitter} />,
          children: [
            {
              path: `babysitter`,
              element: <Login role={ROLE.Babysitter} />,
            },
            {
              path: `login/babysitter`,
              element: <Navigate to="/babysitter" replace />,
            },
            {
              path: `register/babysitter`,
              element: <Register role={ROLE.Babysitter} />,
            },
          ],
        },
        {
          element: <AuthBasicLayout />,
          children: [
            {
              path: 'forgot-password',
              element: <ForgotPassword />,
            },
            {
              path: 'reset-password/:token',
              element: <ResetPassword />,
            },
            {
              path: 'client/register-password/:token',
              element: <RegisterPassword />,
            },
          ],
        },
      ],
    },
  ]
}
