import React from 'react'
import { useTranslation } from 'react-i18next'
import InputMask from 'react-input-mask'
import {
  TextInput as MantineTextInput,
  TextInputProps as MantineTextInputProps,
} from '@mantine/core'
import { FormUtils } from '@/utils'

export interface ZipInputProps extends MantineTextInputProps {
  fieldRef?: any
  normalize?: (value: string) => string
  translateParams?: object
  meta?: any
  mask?: string
}

export const ZipInput = ({
  normalize,
  meta,
  fieldRef,
  translateParams,
  value,
  onChange,
  mask = '9999-999',
  disabled,
  onBlur,
  ...props
}: ZipInputProps) => {
  const { t } = useTranslation()

  const { error } = meta

  const showError = Boolean(error)

  const getErrorMessage = () => {
    return FormUtils.getErrorMessage(error, {
      t,
      translateParams: translateParams,
    })
  }

  return (
    <InputMask mask={mask} value={value} onChange={onChange} disabled={disabled} onBlur={onBlur}>
      <MantineTextInput
        {...props}
        data-hj-allow
        ref={fieldRef}
        error={showError ? getErrorMessage() : null}
      />
    </InputMask>
  )
}
