import React from 'react'
import { Stack, Text } from '@mantine/core'
import { Participant } from '@/features/chat/types/models'
import { ROLE, useUser } from '@/features/user'
import { useUsersModel } from '@/features/users/hooks'
import useStyles from './Name.styles'

interface IProps {
  participants?: Participant[]
}

export const Name = ({ participants }: IProps) => {
  const { classes, cx } = useStyles()
  const { user, isClient } = useUser()

  const { getAllNames, getAllParticipantsRole } = useUsersModel()

  return (
    <>
      {isClient() ? (
        <Text weight={600} className={cx(classes.name, 'truncate')}>
          {getAllNames(participants, user.id)}
        </Text>
      ) : (
        <Stack className={'gap-0'}>
          <Text weight={600} className={cx(classes.name, 'truncate')}>
            {getAllNames(getAllParticipantsRole(participants, ROLE.Client), user.id)}
          </Text>
          {getAllParticipantsRole(participants, ROLE.Babysitter).length > 0 && (
            <Text weight={400} size={14} className={cx(classes.name, 'mb-1', 'truncate')}>
              {getAllNames(getAllParticipantsRole(participants, ROLE.Babysitter), user.id)}
            </Text>
          )}
        </Stack>
      )}
    </>
  )
}
