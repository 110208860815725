import { Group, Text } from '@mantine/core'
import { AvatarProvider } from '@/components/Elements'
import { useChatConversationData } from '@/features/chat/hooks'
import { useUser } from '@/features/user'
import { useUsersModel } from '@/features/users/hooks'

export const MobileHeaderRightBabysitters = () => {
  const { isClient, isBabysitter, user } = useUser()
  const { getName } = useUsersModel()
  const { getParticipants, getParticipantsBabysittersNotMe, getParticipantsClient } =
    useChatConversationData()
  const participants = isBabysitter() ? getParticipantsBabysittersNotMe(user.id) : undefined

  return (
    <>
      {isBabysitter() && (
        <>
          <div className={'flex'}>
            <Group className={'gap-1'}>
              <>
                {participants?.map((item, index) => (
                  <AvatarProvider size={32} avatar={item.avatar} key={'avatar-' + index} />
                ))}
              </>
            </Group>
          </div>
        </>
      )}
      {isClient() && <Text></Text>}
    </>
  )
}
