import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

export const getWeekListBetweenDates = ({ start, end }: { start: Date; end: Date }) => {
  let startDate = moment(start).isoWeekday(1)
  const endDate = moment(end).isoWeekday(7)

  const weeks = (moment(endDate).diff(startDate, 'days') + 1) / 7

  const weekList = []
  for (let i = 1; i <= weeks; i++) {
    weekList.push({
      id: uuidv4(), // need for map weeks in ui where key will be 'id'
      startDate: moment(startDate).toDate(),
      endDate: moment(startDate).add(6, 'days').toDate(),
    })
    startDate = moment(startDate).add(7, 'days')
  }

  return weekList
}
