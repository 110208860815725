import React from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import { experienceHelper } from '@/features/profile/helpers'
import { experienceAgeStepRangeSliderHelper } from '../helpers'
import useStyles from './InputToolbar.styles'

const { convertStepToMonth } = experienceAgeStepRangeSliderHelper

interface IProps {
  value: Array<number>
}

export const InputToolbar = ({ value }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const formatValue = (step: number) => {
    const month = convertStepToMonth(step)
    const { text } = experienceHelper.age.getFormattedAge(month)
    return text
  }

  const renderInput = (index: number) => (
    <Input
      className={classes.wrapper}
      size={'xs'}
      value={formatValue(value[index])}
      readOnly
      classNames={classes}
    />
  )

  return (
    <div className={'flex justify-between'}>
      <div>{renderInput(0)}</div>
      <div>{renderInput(1)}</div>
    </div>
  )
}
