import React from 'react'
import { Favorite } from './Actions'

export const Header = () => {
  return (
    <>
      <div className={'flex flex-wrap gap-4'}>
        <Favorite />
      </div>
    </>
  )
}
