import * as React from 'react'
import { ROLE } from '@/features/user/types'
import { Carousel } from './Carousel'

interface IProps {
  role?: ROLE
}

export const InfoSection = ({ role }: IProps) => {
  return <Carousel role={role} />
}
