import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  section: {
    height: '100%',
    display: 'flex',
    background: 'white',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      flexDirection: 'column',
    },
  },
  fitImage: {
    objectFit: 'cover',
  },
  imageTypeSelect: {
    objectFit: 'cover',
    maxWidth: '100%',
    width: '100%',
    height: '200px',
  },
  stackPosition: {
    position: 'relative',
    gap: 0,
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      top: '-60px',
    },
  },
  stackNoGap: {
    gap: 0,
    width: '100%',
  },
  whiteSeparator: {
    border: '3px solid white',
  },
  button: {
    width: '100%',
    position: 'relative',
    top: '-36px',
  },
  buttonBorder: {
    border: '3px solid white',
    borderRadius: '34px',
  },
  option: {
    border: '1px solid',
    borderColor: theme.colors[theme.primaryColor][theme.fn.primaryShade()],
    borderRadius: '8px',
    display: 'flex',
    cursor: 'pointer',
    boxShadow: `0 4px 8px 0 ${theme.fn.rgba(
      theme.colors[theme.primaryColor][theme.fn.primaryShade()],
      0.2
    )}, 0 6px 20px 0 ${theme.fn.rgba(
      theme.colors[theme.primaryColor][theme.fn.primaryShade()],
      0.19
    )}`,
  },
  optionDisabled: {
    border: '1px solid',
    borderColor: 'grey',
    borderRadius: '8px',
    margin: '8px',
    display: 'flex',
    cursor: 'default',
    opacity: 0.5,
  },
  optionContent: {
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    flexOrientation: 'column',
    width: 'calc(100% - 136px)',
  },
  optionContentFull: {
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    flexOrientation: 'column',
  },
  infoSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '40%',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: '100%',
      padding: '0 16px',
      marginBottom: '24px',
    },
  },
  scrollArea: {
    width: '100%',
  },
  contentSection: {
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '36px',
  },
  footerSection: {
    width: '60%',
    height: '100%',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: '100%',
    },
  },
  headText: {
    color: 'black', //theme.colors.primary[theme.fn.primaryShade()],
  },
  textOnImage: {
    height: 0,
    position: 'relative',
    top: '-130px',
    paddingLeft: '24px',
    color: 'white',
  },
  carouselMaxWidth: {
    maxWidth: '100%',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      maxWidth: 'calc(100vw - 69px)',
    },
  },
  backgroundHomepage: {
    height: '300px',
    width: '100%',
    backgroundColor: 'black',
    opacity: '0.4',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      height: '250px',
    },
  },
  backgroundImageFAQ: {
    backgroundColor: 'white',
    width: '100%',
    opacity: '0.4',
    cursor: 'pointer',
    height: '256px',
  },
  faqBackground: {
    height: 0,
    position: 'relative',
    top: '-256px',
    cursor: 'pointer',
  },
  faqContent: {
    padding: '16px',
    color: 'black',
  },

  infoBackColor: {
    paddingTop: '16px',
    paddingBottom: '64px',
  },
}))
