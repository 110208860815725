import React from 'react'

interface IProps {
  color: string
  size?: number
  className?: string
}

export const ArrowsSpin = ({ size = 24, color, ...props }: IProps) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox={`0 0 24 24`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25 17.5C3.25 17.0858 3.58579 16.75 4 16.75H7C7.41421 16.75 7.75 17.0858 7.75 17.5C7.75 17.9142 7.41421 18.25 7 18.25H4.75V20.5C4.75 20.9142 4.41421 21.25 4 21.25C3.58579 21.25 3.25 20.9142 3.25 20.5V17.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 16.75C18.4142 16.75 18.75 17.0858 18.75 17.5V19.75H21C21.4142 19.75 21.75 20.0858 21.75 20.5C21.75 20.9142 21.4142 21.25 21 21.25H18C17.5858 21.25 17.25 20.9142 17.25 20.5V17.5C17.25 17.0858 17.5858 16.75 18 16.75Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 2.75C21.4142 2.75 21.75 3.08579 21.75 3.5V6.5C21.75 6.91421 21.4142 7.25 21 7.25H18C17.5858 7.25 17.25 6.91421 17.25 6.5C17.25 6.08579 17.5858 5.75 18 5.75H20.25V3.5C20.25 3.08579 20.5858 2.75 21 2.75Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25 3.5C3.25 3.08579 3.58579 2.75 4 2.75H7C7.41421 2.75 7.75 3.08579 7.75 3.5V6.5C7.75 6.91421 7.41421 7.25 7 7.25C6.58579 7.25 6.25 6.91421 6.25 6.5V4.25H4C3.58579 4.25 3.25 3.91421 3.25 3.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.62602 3.23353C7.85413 3.57928 7.75878 4.04448 7.41303 4.27259C4.90335 5.92842 3.25 8.77106 3.25 11.9995C3.25 12.4719 3.28534 12.9355 3.35341 13.388C3.41502 13.7976 3.13291 14.1796 2.72331 14.2412C2.3137 14.3028 1.93171 14.0207 1.87009 13.6111C1.79096 13.085 1.75 12.5468 1.75 11.9995C1.75 8.24592 3.67443 4.94217 6.58697 3.02055C6.93271 2.79243 7.39791 2.88779 7.62602 3.23353Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.73549 16.8741C4.08123 16.6459 4.54643 16.7413 4.77454 17.087C6.43037 19.5967 9.27302 21.2501 12.5015 21.2501C12.9738 21.2501 13.4374 21.2147 13.8899 21.1467C14.2995 21.0851 14.6815 21.3672 14.7432 21.7768C14.8048 22.1864 14.5227 22.5684 14.1131 22.63C13.587 22.7091 13.0488 22.7501 12.5015 22.7501C8.74788 22.7501 5.44412 20.8256 3.5225 17.9131C3.29439 17.5674 3.38974 17.1022 3.73549 16.8741Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.2768 9.75845C22.6864 9.69683 23.0684 9.97894 23.13 10.3885C23.2091 10.9146 23.2501 11.4528 23.2501 12.0001C23.2501 15.7537 21.3256 19.0575 18.4131 20.9791C18.0674 21.2072 17.6022 21.1119 17.3741 20.7661C17.1459 20.4204 17.2413 19.9552 17.587 19.7271C20.0967 18.0712 21.7501 15.2286 21.7501 12.0001C21.7501 11.5278 21.7147 11.0642 21.6467 10.6117C21.5851 10.2021 21.8672 9.82006 22.2768 9.75845Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5001 2.75C12.0278 2.75 11.5642 2.78534 11.1117 2.85341C10.7021 2.91502 10.3201 2.63291 10.2584 2.22331C10.1968 1.8137 10.4789 1.43171 10.8885 1.37009C11.4146 1.29096 11.9528 1.25 12.5001 1.25C16.2537 1.25 19.5575 3.17443 21.4791 6.08696C21.7072 6.4327 21.6119 6.8979 21.2661 7.12602C20.9204 7.35413 20.4552 7.25878 20.2271 6.91304C18.5712 4.40335 15.7286 2.75 12.5001 2.75Z"
      fill={color}
    />
  </svg>
)
