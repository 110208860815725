import React from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  value: boolean | number | null | undefined
  options?: {
    positive: string
    negative: string
  }
}

export const BooleanTextMessage = ({ value, options }: IProps) => {
  const { t } = useTranslation()

  const positive = options?.positive || t('yes')

  const negative = options?.negative || t('no')

  return <React.Fragment>{value ? positive : negative}</React.Fragment>
}
