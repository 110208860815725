import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { BookingsRequestsContainer } from '@/features/bookings/components/Bookings/BookingsRequests'

export const Available = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'available'} />

      <BookingsRequestsContainer />
    </>
  )
}
