import React from 'react'
import { Group, Stack } from '@mantine/core'
import { AvatarProvider } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { FeedbackType } from '@/features/user'
import useStyles from './FeedbackCard.styles'

interface IProps {
  data: FeedbackType
}

export const FeedbackCard = ({ data }: IProps) => {
  const { classes } = useStyles()

  const { user_avatar, user_first_name, feedback_date, babysitter_feedback } = data
  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <>
      <Stack className={classes.root}>
        <Group>
          <AvatarProvider size={mobileView ? 30 : 35} radius={50} avatar={user_avatar} />
          <div className={classes.contentTitle}>{user_first_name}</div>
        </Group>
        <div className={classes.contentDate}>{feedback_date}</div>
        <div className={classes.contentInfo}>
          <span>{babysitter_feedback}</span>
        </div>
      </Stack>
    </>
  )
}
