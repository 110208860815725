import axios from 'axios'
import { UpdateBabysitterProfileDTO, UpdateClientProfileDTO } from './dto'

class ProfileAPI {
  getProfile = async () => {
    return axios.get('api/profile')
  }

  updateBabysitterProfile = async (data: UpdateBabysitterProfileDTO) => {
    return axios.post('api/profile/babysitter', data)
  }

  updateClientProfile = async (data: UpdateClientProfileDTO) => {
    return axios.post('api/profile/client', data)
  }

  updatePassword = async (data: any) => {
    return axios.post('api/profile/password', data)
  }

  deactivateProfile = async () => {
    return axios.post('api/profile/deactivate')
  }

  deleteProfile = async () => {
    return axios.post('api/profile/delete')
  }
}

export const profileAPI = new ProfileAPI()
