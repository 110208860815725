import { createSlice } from '@reduxjs/toolkit'
import { User } from '@/features/user'
import { RootState } from '@/store'

interface UserDataState {
  data: User | null
}

const initialState: UserDataState = {
  data: null,
}

const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setData(state, action) {
      // console.log('userDataSlice setData action.payload', action.payload)
      state.data = action.payload
    },
    updateData(state, action) {
      // console.log('userDataSlice updateData action.payload', action.payload)
      state.data = {
        ...state.data,
        ...action.payload,
      }
    },
    updateUnreadMessages(state, action) {
      const data = state.data as User
      state.data = {
        ...data,
        unread_messages: action.payload >= 0 ? action.payload : 0,
      }
    },
    remove1UnreadMessages(state) {
      const data = state.data as User
      const newValue = (data.unread_messages || 0) - 1
      state.data = {
        ...data,
        unread_messages: newValue >= 0 ? newValue : 0,
      }
    },
    cleanData(state) {
      state.data = null
      return initialState
    },
  },
})

export const userData = userDataSlice.actions

export const selectUserData = (state: RootState) => state.user.data.data

export default userDataSlice.reducer
