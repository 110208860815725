import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'
import {
  FieldValues as EmailValues,
  EmailsFieldArray,
} from '@/features/wallet/routes/services/wallet-view/email-form/EmailsFieldArray'

export type EmailsFormSectionValues = {
  emails: Array<EmailValues>
}

export const EmailsFormSection = () => {
  const { t } = useTranslation()

  const {
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext<EmailsFormSectionValues>()

  return (
    <>
      <Text mb={'md'}>{t('referral.invitation_intro')}</Text>
      <EmailsFieldArray
        name={'emails'}
        control={control}
        clearErrors={clearErrors}
        meta={{ error: errors?.emails }}
        limit={10}
      />
    </>
  )
}
