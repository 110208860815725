import { createSlice } from '@reduxjs/toolkit'

interface IState {
  windowFocus: boolean
  mobileView: boolean
}

const initialState: IState = {
  windowFocus: true,
  mobileView: false,
}

const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    windowFocus(state, action) {
      state.windowFocus = action.payload
    },
    mobileView(state, action) {
      console.log('set mobile view:' + action.payload)
      state.mobileView = action.payload
    },
  },
})

/**
 * @namespace
 * @property {function} windowFocus
 */

export const appState = appStateSlice.actions

export default appStateSlice.reducer
