import * as React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import { Center, Space, Text, Title } from '@mantine/core'
import { ButtonLink, Card } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { useUser } from '@/features/user'
import { Animation } from './Animation'
import useStyles from './Component.styles'

export const BookingRegularConfirmedContainer = ({ justCreatedUser, type }: any) => {
  const { t } = useTranslation()
  const { user } = useUser()

  const { classes } = useStyles()

  const {
    appState: { mobileView },
  } = useAppState()

  const isLoggedIn = !!user?.id

  return (
    <Card className={classes.card}>
      <Center>
        <div className={classes.animationBlock}>
          <Animation />
        </div>
      </Center>

      <div className={classes.content}>
        <Center>
          <Title order={mobileView ? 3 : 1} className="text-center">
            {t('booking_submitted_success')}
          </Title>
        </Center>

        <Space h={'md'} />

        <div>
          <Text className={classes.info} weight={'600'} size={'sm'}>
            {ReactHtmlParser(
              t(
                type == BOOKING_TYPE.ANIMATION
                  ? 'regular_booking_confirmed_party.info'
                  : 'regular_booking_confirmed.info'
              )
            )}
          </Text>
        </div>

        <Space h={40} />

        {isLoggedIn ? (
          <ButtonLink fullWidth to={'/bookings'}>
            {t(justCreatedUser ? 'access_my_account' : 'see_my_bookings')}
          </ButtonLink>
        ) : (
          <ButtonLink fullWidth to={'/login/client'}>
            {t('access_my_account')}
          </ButtonLink>
        )}

        <Space h={'md'} />
      </div>
    </Card>
  )
}
