import theme from 'tailwindcss/defaultTheme'
import { MantineThemeOverride } from '@mantine/core'
import i18n from '@/i18n'
import { overrideColors } from './colors'

export const globalTheme: MantineThemeOverride = {
  //Setup global theme for util, components
  //https://github.com/mantinedev/mantine/blob/master/src/mantine-styles/src/theme/default-theme.ts
  colors: overrideColors,
  primaryShade: { light: 5, dark: 5 },
  primaryColor: 'primary',
  fontFamily: 'Quicksand',
  fontFamilyMonospace: 'Quicksand',
  headings: {
    fontFamily: 'Quicksand',
  },
  defaultRadius: 'md',
  components: {
    Container: {
      defaultProps: {
        sizes: {
          xs: 576,
          sm: 768,
          md: 992,
          lg: 1200,
          xl: 1400,
        },
      },
    },
    Button: {
      defaultProps: {
        size: 'lg',
      },
    },
    Input: {
      // styles: (theme) => ({
      //   input: {
      //     '&:focus': {
      //       borderColor: theme.colors.secondary[theme.fn.primaryShade()],
      //     },
      //     '&:focus-within': {
      //       borderColor: theme.colors.secondary[theme.fn.primaryShade()],
      //     },
      //   },
      // }),
    },
    InputWrapper: {
      defaultProps: {
        size: 'lg',
      },
    },
    TextInput: {
      defaultProps: {
        size: 'lg',
      },
    },
    PasswordInput: {
      defaultProps: {
        size: 'lg',
      },
    },
    Checkbox: {
      defaultProps: {
        radius: 'sm',
        color: 'secondary',
      },
      styles: (theme) => ({
        label: {
          fontWeight: 600,
        },
      }),
    },
    Select: {
      defaultProps: {
        size: 'lg',
      },
    },
    Textarea: {
      defaultProps: {
        size: 'lg',
      },
    },
    DatePicker: {
      defaultProps: {
        size: 'lg',
      },
      styles: (theme, params) => ({
        day: {
          '&[data-selected]': {
            backgroundColor: theme.colors['secondary'][theme.fn.primaryShade()],
            borderRadius: 100,
            position: 'relative',
          },
        },
      }),
    },
    Calendar: {
      defaultProps: {
        size: 'md',
      },
      styles: (theme, params) => ({
        day: {
          '&[data-selected]': {
            backgroundColor: theme.colors['secondary'][theme.fn.primaryShade()],
            borderRadius: 100,
            position: 'relative',
          },
        },
      }),
    },
    Anchor: {
      defaultProps: {
        color: 'primary',
      },
    },
    Stepper: {
      styles: (theme) => ({
        stepIcon: {
          borderColor: 'transparent',
          borderWidth: 0,
          backgroundColor: theme.fn.rgba(theme.colors.secondary[theme.fn.primaryShade()], 0.3),

          '&[data-progress]': {
            borderWidth: 0,
            borderColor: 'transparent',
            backgroundColor: theme.colors[theme.primaryColor][theme.fn.primaryShade()],

            '& svg path': {
              stroke: theme.white,
            },
          },

          '&[data-completed]': {
            borderWidth: 0,
            borderColor: 'transparent',

            '& svg path': {
              stroke: theme.white,
            },
          },
        },
      }),
    },
    Modal: {
      defaultProps: {
        size: 'lg',
        padding: '40px',
        radius: 'xl',
      },
    },
    Avatar: {
      defaultProps: {
        size: 48,
        radius: 'xl',
      },
    },
    SegmentedControl: {
      defaultProps: {
        size: 'lg',
        radius: 'md',
      },
    },
    TimeInput: {
      defaultProps: {
        size: 'lg',
      },
    },
    Menu: {
      defaultProps: {
        shadow: 'md',
      },
      styles: (theme) => ({
        itemLabel: {
          fontWeight: 600,
        },
      }),
    },
  },
  dateFormat: 'DD/MM/YYYY',
  datesLocale: i18n.language,
}
