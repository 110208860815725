import React from 'react'
import { Loader } from '@mantine/core'
import useStyles from './Loading.styles'

export const Loading = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <Loader />
    </div>
  )
}
