import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Title } from '@mantine/core'
import { ChildrenFieldArray } from '@/features/profile/components/FormElements'
import { ChildType } from '@/features/user'

export type ChildrenFormSectionValues = {
  booking_children: Array<ChildType>
}

interface IProps {
  boldTitle?: boolean
  animation: boolean
}

export const ChildrenFormSection = ({ boldTitle = false, animation = false }: IProps) => {
  const { t } = useTranslation()

  const {
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext<ChildrenFormSectionValues>()

  return (
    <>
      <Title order={4}>
        <div className="flex flex-col">
          {t('who_needs_care')}
          <small className="text-xs">{t('use_for_tailor')}</small>
        </div>
      </Title>

      <ChildrenFieldArray
        name={'booking_children'}
        control={control}
        clearErrors={clearErrors}
        meta={{ error: errors?.booking_children }}
        limit={animation ? 30 : 5}
        translateParams={{ count: animation ? 30 : 5 }}
        boldTitle={boldTitle}
        animation={animation}
      />
    </>
  )
}
