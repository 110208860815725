import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from '@mantine/core'
import { useLang } from '@/hooks'
import { LangPicker } from '../LangPicker'

interface IProps {
  className?: string
  btnProps?: ButtonProps
}

export const AppLangPicker: React.FC<IProps> = ({ className, btnProps, ...props }) => {
  const { changeLang } = useLang()
  const { i18n } = useTranslation()

  const currentLang = i18n.language

  const onChange = async (code: string) => {
    await changeLang(code)
  }

  return <LangPicker value={currentLang} onChange={onChange} {...props} />
}
