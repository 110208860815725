import React from 'react'
import { useBookingCancel } from '@/features/bookings/hooks'
import { BookingCancelContent } from './BookingCancelContent'

interface IProps {
  id: number
  onSuccess?: (data: object) => void
  onCancel: () => void
}

export const BookingCancelContainer = ({ id, onSuccess, onCancel }: IProps) => {
  const { loading, onSubmit } = useBookingCancel()

  const onCancelSubmit = async () => {
    await onSubmit(id, onSuccess)
  }

  return <BookingCancelContent loading={loading} onSubmit={onCancelSubmit} onCancel={onCancel} />
}
