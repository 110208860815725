import { IconChevronDown, IconTrash } from '@tabler/icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Menu, Text, useMantineTheme } from '@mantine/core'
import { Modal } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { BookingCancelProvider } from '@/features/bookings/components/BookingCancel'
import { BookingService } from '@/features/bookings/types/models'
import { usePopup } from '@/hooks'

interface IProps {
  config: {
    cancel: boolean
  }
  bookingService: BookingService
  onCancelSuccess?: () => void
}

export enum SERVICE_OPTIONS_MENU {
  BOOKING_OPTIONS = 'options',
  BOOKING_CANCEL = 'cancel',
}

const defaultConfig = {
  cancel: true,
}

export const ActionMenu = ({ config, bookingService, onCancelSuccess }: IProps) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const [type, setType] = useState(SERVICE_OPTIONS_MENU.BOOKING_OPTIONS)
  const {
    appState: { mobileView },
  } = useAppState()

  config = { ...defaultConfig, ...config }

  // Cancel
  const { visible, open, close } = usePopup()

  const onBookingOptionsClick = (e: any) => {
    e.stopPropagation()
    console.log('onBookingOptionsClick')
    setType(SERVICE_OPTIONS_MENU.BOOKING_CANCEL)
    open(false)
  }

  const onBookingCancelClick = (e: any) => {
    console.log('onBookingCancelClick')
    e.stopPropagation()
    setType(SERVICE_OPTIONS_MENU.BOOKING_OPTIONS)
    open(false)
  }

  const onBookingCancelSuccess = () => {
    close()

    onCancelSuccess && onCancelSuccess()
  }
  // ==== //

  return (
    <>
      {!mobileView && (
        <>
          <Menu width={150}>
            <Menu.Target>
              <Button size={'sm'} rightIcon={<IconChevronDown size={16} />}>
                {t('modify')}
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              {config?.cancel && (
                <Menu.Item
                  icon={
                    <IconTrash size={20} color={theme.colors.primary[theme.fn.primaryShade()]} />
                  }
                  onClick={open}
                >
                  <Text color={'primary'}>{t('cancel')}</Text>
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu>

          <Modal opened={visible} onClose={close} centered title={`${t('cancel_booking')}?`}>
            <BookingCancelProvider
              id={bookingService?.id}
              onSuccess={onBookingCancelSuccess}
              onCancel={close}
            />
          </Modal>
        </>
      )}
      {mobileView && (
        <Button className={'w-100'} size={'sm'} onClick={onBookingOptionsClick}>
          {t('modify')}
        </Button>
      )}

      <Modal opened={visible} onClose={close} centered title={`${t('cancel_booking')}?`}>
        {type == SERVICE_OPTIONS_MENU.BOOKING_OPTIONS && (
          <BookingCancelProvider
            id={bookingService?.id}
            onSuccess={onBookingCancelSuccess}
            onCancel={close}
          />
        )}
        {type == SERVICE_OPTIONS_MENU.BOOKING_CANCEL && (
          <div className={'flex flex-col items-center'}>
            <div className={'w-full'}>
              <div className={'flex w-100 pointer'} onClick={onBookingCancelClick}>
                <IconTrash size={24} color={theme.colors.primary[theme.fn.primaryShade()]} />
                <Text className={'pl-3'} size={18} color={'primary'}>
                  {t('cancel')}
                </Text>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}
