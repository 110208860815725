import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  cardWrapper: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      paddingBottom: '32px',
      borderRadiusBottom: '0',
    },
  },
}))
