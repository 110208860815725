import React, { useReducer } from 'react'
import { INITIAL_STATE, SCREEN } from './consts'
import { actions, reducers } from './store'

export const useBookingDrawerManager = ({ initialState = INITIAL_STATE }) => {
  const [state, dispatch] = useReducer(reducers.reducer, initialState, reducers.reducerInit)

  const { screen, screenData } = state

  const setState = (screen: SCREEN | null, screenData: any = null) => {
    dispatch(
      actions.setState({
        screen,
        screenData,
      })
    )
  }

  const navigate = (screen: SCREEN | null, screenData: any = null) => {
    setState(screen, screenData)
  }

  const onScreenClose = () => {
    dispatch(actions.setScreen(null))
  }

  const onScreenDataClean = () => {
    dispatch(actions.setScreenData(null))
  }

  const onScreenView = (screenData: any = null) => {
    navigate(SCREEN.VIEW, screenData)
  }

  return {
    state: {
      isViewScreen: screen === SCREEN.VIEW,
      screenData,
    },
    actions: {},
    navigation: {
      // navigate,
      onScreenClose,
      onScreenDataClean,
      onScreenView,
    },
  }
}
