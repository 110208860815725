import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  block: {
    display: 'flex',
    alignItems: 'center',

    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      flexDirection: 'column',
    },
  },

  infoItem: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.gray[7],
    fontWeight: 500,

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))
