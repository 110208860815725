import React from 'react'

interface IProps {
  color: string
  size?: number
  viewPort?: number
  className?: string
}

export const Book = ({ size = 24, viewPort = size, color, ...props }: IProps) => (
  <svg
    {...props}
    fill={color}
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 437.178 437.178"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.339,106.001c0.084,0,0.167-0.005,0.25-0.006c0.083,0.001,0.166,0.006,0.25,0.006 c12.246,0,22.174-9.928,22.174-22.174c0-6.46-2.765-12.274-7.174-16.327V15c0-8.284-6.716-15-15-15 c-0.084,0-0.166,0.011-0.25,0.013C138.505,0.012,138.423,0,138.339,0c-8.284,0-15,6.716-15,15v52.5 c-4.409,4.053-7.174,9.866-7.174,16.327C116.165,96.073,126.093,106.001,138.339,106.001z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M218.839,106.001c12.246,0,22.174-9.928,22.174-22.174c0-6.46-2.765-12.274-7.174-16.327V15c0-8.284-6.716-15-15-15 s-15,6.716-15,15v52.5c-4.409,4.053-7.174,9.866-7.174,16.327C196.665,96.073,206.593,106.001,218.839,106.001z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M298.839,106.001c12.246,0,22.174-9.928,22.174-22.174c0-6.46-2.765-12.274-7.174-16.327V15c0-8.284-6.716-15-15-15 s-15,6.716-15,15v52.5c-4.409,4.053-7.174,9.866-7.174,16.327C276.665,96.073,286.593,106.001,298.839,106.001z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M353.579,36.823v30c24.812,0,45,20.187,45,45v250.355c0,24.813-20.188,45-45,45H83.599c-24.813,0-45-20.187-45-45V111.823 c0-24.813,20.187-45,45-45v-30c-41.355,0-75,33.645-75,75v250.355c0,41.355,33.645,75,75,75h269.98c41.354,0,75-33.645,75-75 V111.823C428.579,70.468,394.934,36.823,353.579,36.823z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M233.421,172.001H79.089c-4.971,0-9,4.029-9,9c0,4.971,4.029,9,9,9H217.8 C221.677,182.946,227.021,176.808,233.421,172.001z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M358.089,172.001h-57.253c6.4,4.807,11.744,10.945,15.62,18h41.633c4.971,0,9-4.029,9-9 C367.089,176.03,363.06,172.001,358.089,172.001z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.089,270.001h41.633c3.876-7.056,9.22-13.193,15.62-18H79.089c-4.971,0-9,4.028-9,9 C70.089,265.972,74.118,270.001,79.089,270.001z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M358.089,252.001h-46.977c-6.49,8.138-15.18,14.442-25.167,18h72.144c4.971,0,9-4.029,9-9 C367.089,256.029,363.06,252.001,358.089,252.001z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M219.378,270.001h28.934c-9.988-3.558-18.678-9.862-25.166-18h-19.388C210.158,256.808,215.501,262.945,219.378,270.001z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.089,350.001h72.145c-9.989-3.558-18.678-9.862-25.167-18H79.089c-4.971,0-9,4.028-9,9 C70.089,345.972,74.118,350.001,79.089,350.001z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M367.089,341.001c0-4.972-4.029-9-9-9H214.034c-6.49,8.138-15.179,14.442-25.167,18h169.222 C363.06,350.001,367.089,345.972,367.089,341.001z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M225.88,217.001c0,22.78,18.467,41.248,41.248,41.248s41.248-18.468,41.248-41.248c0-22.781-18.467-41.248-41.248-41.248 S225.88,194.22,225.88,217.001z M251.376,208.611l10.105,10.105l21.398-21.399c3.307-3.308,8.67-3.308,11.979,0 c3.308,3.308,3.308,8.671,0,11.979l-27.39,27.39c-1.653,1.653-3.819,2.479-5.989,2.479c-2.168,0-4.334-0.826-5.988-2.479 l-16.096-16.096c-3.307-3.307-3.307-8.671,0-11.979C242.706,205.304,248.067,205.304,251.376,208.611z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M211.298,297.001c0-22.781-18.467-41.248-41.248-41.248c-22.781,0-41.248,18.467-41.248,41.248 c0,22.78,18.467,41.248,41.248,41.248C192.831,338.249,211.298,319.781,211.298,297.001z M158.414,316.686l-16.096-16.096 c-3.307-3.307-3.307-8.671,0-11.979c3.31-3.309,8.672-3.309,11.979,0l10.105,10.104l21.399-21.399 c3.307-3.308,8.67-3.308,11.979,0c3.307,3.309,3.307,8.672,0,11.979l-27.39,27.39c-1.654,1.653-3.82,2.479-5.989,2.479 S160.068,318.339,158.414,316.686z"
      fill={color}
    />
  </svg>
)
