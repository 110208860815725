import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { ContentCard } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'

export const CalendarLayout = () => {
  const {
    appState: { mobileView },
  } = useAppState()
  const { t } = useTranslation()

  return (
    <div className="h-full">
      {!mobileView ? (
        <ContentCard title={t('bookings')}>
          <Outlet />
        </ContentCard>
      ) : (
        <Outlet />
      )}
    </div>
  )
}
