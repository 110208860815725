import React from 'react'
import { SkillsContainer } from './SkillsContainer'
import { SkillsControlOptionType, SkillsControlValueType } from './types'

export interface SkillsControlProps {
  value?: Array<SkillsControlValueType>
  onChange?: (value: Array<SkillsControlValueType>) => void
  options: Array<SkillsControlOptionType>
  onOptionAdd: (data: SkillsControlOptionType) => void
  onOptionRemove: (i: number) => void
}

export const SkillsControl = ({
  value = [],
  onChange,
  options = [],
  onOptionAdd,
  onOptionRemove,
}: SkillsControlProps) => {
  const onAdd = (data: string) => {
    const result = {
      name: data,
      is_default: false,
    }
    onOptionAdd(result)

    onChange && onChange([...value, { ...result }])
  }

  const onSelect = (data: SkillsControlValueType, checked: boolean) => {
    let items = []

    if (checked) {
      items = [...value, { ...data }]
    } else {
      items = value.filter((item) => item.id !== data.id)
    }

    onChange && onChange([...items])
  }

  const onRemove = (data: SkillsControlOptionType, index: number) => {
    onOptionRemove(index)

    const items = value.filter((item) => item.name !== data.name)
    onChange && onChange([...items])
  }
  return (
    <>
      <SkillsContainer
        value={value}
        options={options}
        onAdd={onAdd}
        onRemove={onRemove}
        onSelect={onSelect}
      />
    </>
  )
}
