import moment from 'moment'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Accordion } from '@mantine/core'
import { FieldError } from '@/components/FormElements'
import { useBookingFormTimetable } from '@/features/booking/hooks'
import { ValidationsUtils } from '@/utils'
import { WeekDays } from './WeekDays'

export const WeeksScheduleFormSection = () => {
  const { t } = useTranslation()

  const { getWeeks } = useBookingFormTimetable()

  const {
    watch,
    formState: { errors },
  } = useFormContext()

  const watchDateStart = watch('date_start')
  const watchDateEnd = watch('date_end')

  const weeks = getWeeks()

  const isDayDisable = (day: any, weekIndex: number) => {
    let dayBeforeDateStart = false
    let dayAfterDateEnd = false

    // if first week => disabled date before booking start
    if (weekIndex === 0) {
      const weekDate = moment(watchDateStart).isoWeekday(day.isoWeekday).toDate()
      dayBeforeDateStart = !ValidationsUtils.dateEqualOrAfter(weekDate, watchDateStart)
    }

    // if last week => disabled date after booking end
    if (weekIndex === weeks.length - 1) {
      const weekDate = moment(watchDateEnd).isoWeekday(day.isoWeekday).toDate()
      dayAfterDateEnd = ValidationsUtils.dateAfter(weekDate, watchDateEnd)
    }

    return dayBeforeDateStart || dayAfterDateEnd
  }

  return (
    <>
      <Accordion
        multiple
        chevronPosition="left"
        styles={(theme, _params) => ({
          item: {
            borderColor: 'transparent',
          },
          content: {
            padding: '8px 0 8px 0',
          },
        })}
      >
        {weeks.map((item, i) => (
          <React.Fragment key={item.id}>
            <WeekDays
              week={item}
              weekIndex={i}
              weekNumberLabel={weeks.length !== 1}
              isDayDisable={(day) => isDayDisable(day, i)}
            />
          </React.Fragment>
        ))}
      </Accordion>

      <div className={'my-3'}>
        <FieldError error={errors?.timetable?.weeks_schedule} size={'lg'} />
      </div>
    </>
  )
}
