import { geocodeAPI } from '../../api'

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
const GOOGLE_GEOCODE_URL = 'https://maps.googleapis.com/maps/api/geocode/json'
const LANGUAGE = 'en'

class GeocodingService {
  private basicOnFetch = async (url: string) => {
    return fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        return Promise.reject(new Error('Error'))
      })
      .then((json) => {
        return { data: json }
      })
  }

  private mapQuery = (params: {
    address: string
    language?: string
    components?: object | null
  }) => {
    //console.log('params ', JSON.stringify(params))
    const { address, components } = params

    let query = `?`

    if (address) {
      query += `address=${encodeURIComponent(address)}`
    }

    if (components) {
      const result: string[] = []

      Object.entries(components).forEach((entry) => {
        const [key, value] = entry

        if (value) {
          result.push(`${key}:${encodeURIComponent(value)}`)
        }
      })

      if (result.length) {
        const str = result.join('|')

        if (address) {
          query += '&'
        }

        query += `components=${str}`
      }
    }

    if (API_KEY) {
      query += `&key=${API_KEY}`
    }

    if (LANGUAGE) {
      query += `&language=${LANGUAGE}`
    }

    return query
  }

  private getGeocodeDirectly = async (params: {
    address: string
    language?: string
    components?: object | null
  }) => {
    const query = this.mapQuery(params)

    const url = `${GOOGLE_GEOCODE_URL}${query}`

    return this.basicOnFetch(url)
  }

  getGeocode = async (params: {
    address: string
    language?: string
    components?: object | null
  }) => {
    const query = this.mapQuery(params)

    try {
      //console.log('query ', query)
      const response = await geocodeAPI.getGeocode(query)

      const { data } = response

      return { data }
    } catch (error: any) {
      const message = error?.response?.data?.message || 'Error'
      throw new Error(message)
    }
  }
}

export const Geocoding = new GeocodingService()
