import React from 'react'
import { Navigate } from 'react-router-dom'
import { BabysittersRoot } from '@/features/babysitter/routes'
import { BookRoot } from '@/features/book/routes'
import { BookingServicesRoot } from '@/features/booking-service/routes'
import { BookingsRoot } from '@/features/bookings/routes'
import { CalendarRoot } from '@/features/calendar/routes'
import { ChatRoot } from '@/features/chat/routes'
import { CheckInRoot } from '@/features/check-ins/routes'
import { Profile, ProfileComplete } from '@/features/profile/routes'
import { ROLE } from '@/features/user/types'
import { WalletRoot } from '@/features/wallet/routes'
import { GUARD } from '../../guards'

type PrivateRouteType = {
  index?: boolean
  path?: string
  element: React.ReactNode
  roles?: Array<string>
  guards: Array<GUARD>
}

const generalRoutes: PrivateRouteType[] = [
  {
    path: 'profile',
    element: <Profile />,
    roles: [ROLE.Client, ROLE.Babysitter],
    guards: [GUARD.PROFILE_COMPLETE],
  },
  {
    path: 'bookings/*',
    element: <BookingsRoot />,
    roles: [ROLE.Client],
    guards: [],
  },
  {
    path: 'babysitters/*',
    element: <BabysittersRoot />,
    roles: [ROLE.Client],
    guards: [],
  },
  {
    path: 'chat/*',
    element: <ChatRoot />,
    roles: [ROLE.Client, ROLE.Babysitter],
    guards: [GUARD.PROFILE_COMPLETE],
  },
  {
    path: 'wallet',
    element: <WalletRoot />,
    roles: [ROLE.Client, ROLE.Babysitter],
    guards: [GUARD.PROFILE_COMPLETE],
  },
  {
    path: 'check-ins',
    element: <CheckInRoot />,
    roles: [ROLE.Client, ROLE.Babysitter],
    guards: [GUARD.PROFILE_COMPLETE],
  },
  {
    path: 'services/*',
    element: <BookingServicesRoot />,
    roles: [ROLE.Babysitter],
    guards: [GUARD.PROFILE_COMPLETE],
  },
  {
    path: 'calendar/*',
    element: <CalendarRoot />,
    roles: [ROLE.Babysitter],
    guards: [GUARD.PROFILE_COMPLETE],
  },
]

const bookRoutes: PrivateRouteType[] = [
  {
    path: 'book-now/*',
    element: <BookRoot />,
    roles: [ROLE.Client],
    guards: [],
  },
]

const profileCompleteRoutes: PrivateRouteType[] = [
  {
    index: true,
    element: <ProfileComplete />,
    roles: [ROLE.Babysitter],
    guards: [],
  },
  {
    path: '*',
    element: <Navigate to={`/`} replace />,
    roles: [ROLE.Babysitter],
    guards: [],
  },
]

export const getGeneralRoutes = () => {
  return generalRoutes
}

export const getBookRoutes = () => {
  return bookRoutes
}

export const getProfileCompleteRoutes = () => {
  return profileCompleteRoutes
}
