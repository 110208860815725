import { IconCirclePlus } from '@tabler/icons'
import React from 'react'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Anchor, Grid, Group, Title } from '@mantine/core'
import { ActionIconButton } from '@/components/Elements'
import {
  DatePickerController,
  FieldError,
  FormControlActionBlock,
  SelectController,
  TextInputController,
} from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'

export type ChildFieldValues = {
  id?: number
  name: string
  birthday: string | Date | null
}

interface IProps {
  name: string
  control: any
  limit?: number
  translateParams?: object
  meta?: any
  clearErrors?: (name: any) => void
  gridProps?: any
  boldTitle?: boolean

  animation?: boolean
}

export const ChildrenFieldArray = ({
  name,
  control,
  limit,
  translateParams,
  meta,
  clearErrors,
  gridProps,
  boldTitle = false,
  animation = false,
}: IProps) => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()

  const error = meta?.error

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: name,
    keyName: 'fieldId',
  })

  const addRow = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    if (clearErrors) {
      clearErrors(name)
    }

    const fields: ChildFieldValues = {
      name: '',
      birthday: null,
    }

    append(fields)
  }

  const removeRow = (index: number) => {
    remove(index)
  }

  const renderAdd = () => (
    <div className={mobileView ? 'flex flex-col self-start mt-2' : 'flex flex-col mt-4 self-start'}>
      <Anchor href="#" onClick={addRow} className={'font-bold'}>
        <Group>
          <IconCirclePlus />
          {t(animation ? 'event_add_child' : 'add_child')}
        </Group>
      </Anchor>
    </div>
  )

  const renderTitleSection = (index: number) => (
    <Title order={5} my={'sm'}>
      {t(animation ? 'event_child' : 'child')}
    </Title>
  )

  return (
    <div className={mobileView ? 'flex flex-col gap-2' : 'flex flex-col mb-4'}>
      {fields.map((item, index) => (
        <div key={item.fieldId} className={'flex flex-col'}>
          {mobileView ? (
            <>
              <div className={'flex'}>
                <div className={index === 0 ? 'w-100' : 'w-10/12'}>
                  <TextInputController
                    control={control}
                    name={`${name}.${index}.name`}
                    id={`${name}.${index}.name`}
                    size={'md'}
                    label={t(animation ? 'event_child' : 'child')}
                    placeholder={t('name')}
                    styles={
                      boldTitle
                        ? {
                            label: {
                              fontWeight: 'bold',
                              fontSize: '16px !important',
                              marginTop: '16px',
                            },
                          }
                        : {}
                    }
                  />
                </div>
                <div className={'w-2/12'}>
                  <FormControlActionBlock className={'h-100 mt-4'}>
                    <ActionIconButton name={'remove'} onClick={() => removeRow(index)} />
                  </FormControlActionBlock>
                </div>
              </div>
              <DatePickerController
                control={control}
                name={`${name}.${index}.birthday`}
                id={`${name}.${index}.birthday`}
                label={t('date_of_birth')}
                placeholder={t('date_of_birth')}
                size={'md'}
                withinPortal
                dropdownPosition={'bottom-start'}
                mb={'md'}
                mt={'xs'}
              />
            </>
          ) : (
            <>
              {renderTitleSection(index)}
              <Grid grow {...gridProps}>
                <Grid.Col span={9} lg={11} xs={10}>
                  <Grid grow {...gridProps}>
                    <Grid.Col lg={4}>
                      <TextInputController
                        control={control}
                        name={`${name}.${index}.name`}
                        id={`${name}.${index}.name`}
                        size={'lg'}
                        label={t('name')}
                        placeholder={t('name')}
                      />
                    </Grid.Col>
                    <Grid.Col lg={4}>
                      <DatePickerController
                        control={control}
                        name={`${name}.${index}.birthday`}
                        id={`${name}.${index}.birthday`}
                        label={t('date_of_birth')}
                        placeholder={t('date_of_birth')}
                        size={'lg'}
                        withinPortal
                        dropdownPosition={'bottom-start'}
                        mb={'md'}
                      />
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
                <Grid.Col span={3} lg={1} xs={2}>
                  <FormControlActionBlock className={'mb-4'}>
                    <ActionIconButton name={'remove'} onClick={() => removeRow(index)} />
                  </FormControlActionBlock>
                </Grid.Col>
              </Grid>
            </>
          )}
        </div>
      ))}
      {limit ? fields.length < limit && renderAdd() : renderAdd()}

      <FieldError error={error} translateParams={translateParams} />
    </div>
  )
}
