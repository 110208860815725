import { useRatingEntryPopupManagerContext } from '@/features/rating/contexts'
import { RatingEntryCreate } from './RatingEntryCreate'
import { RatingEntryEdit } from './RatingEntryEdit'

export const RatingEntryPopupManager = () => {
  const {
    state: { isCreateScreen, isEditScreen, screenData },
    navigation: { onScreenClose, onScreenDataClean },
  } = useRatingEntryPopupManagerContext()

  return (
    <>
      <RatingEntryCreate
        booking={screenData?.booking}
        visible={isCreateScreen}
        onClose={onScreenClose}
        onDestroy={onScreenDataClean}
      />

      <RatingEntryEdit
        booking={screenData?.booking}
        visible={isEditScreen}
        onClose={onScreenClose}
        onDestroy={onScreenDataClean}
      />
    </>
  )
}
