import React from 'react'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { SegmentedControl, SegmentedControlProps } from '../../SegmentedControl'

interface SegmentedControlControllerProps<T extends FieldValues> extends SegmentedControlProps {
  control: Control<T, any>
  name: FieldPath<T>
  shouldUnregister?: boolean
  defaultValue?: any
  meta?: any
  innerTitle?: boolean
}

export const SegmentedControlController = <T extends FieldValues>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  innerTitle = false,
  ...rest
}: SegmentedControlControllerProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field: { ref, ...field }, fieldState, formState }) => {
        const meta = {
          error: fieldState.error,
        }

        return (
          <SegmentedControl
            innerTitle={innerTitle}
            {...field}
            fieldRef={ref}
            {...rest}
            meta={meta}
          />
        )
      }}
    />
  )
}
