import React from 'react'
import { useAppSelector } from '@/store'

export const useBookingData = () => {
  const { data: booking } = useAppSelector((state) => state.bookings.data)

  return {
    booking,
  }
}
