import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    borderRadius: theme.radius.xl,
    backgroundColor: theme.white,
    display: 'flex',
    overflow: 'hidden',
    height: '100%',

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      flexDirection: 'column',
    },
  },
}))
