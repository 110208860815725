import { IconCamera, IconMail, IconPhone, IconSettings } from '@tabler/icons'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ActionIcon, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { AvatarProvider, Button } from '@/components/Elements'
import { ModalOrDrawerSwitch } from '@/components/Elements/MobileWebSwitchs'
import { FILE_MAX_SIZE } from '@/const/core'
import { useAppState } from '@/features/app/hooks'
import { BabysitterProfileEdit, EditorType } from '@/features/profile/components/ProfileEdit'
import { ProfileSettings } from '@/features/profile/components/Settings'
import { profileAvatarUploadPA } from '@/features/profile/store'
import { useUser } from '@/features/user'
import { useNotify, usePopup } from '@/hooks'
import { FormTypes } from '@/types'
import { FileUtils, FormatUtils, ValidationsUtils } from '@/utils'
import useStyles from './General.styles'

export const General = () => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()
  const [type, setType] = useState<EditorType>(EditorType.ALL)
  const [photoUploading, setPhotoUploading] = useState<boolean>(false)

  const { classes } = useStyles()
  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const theme = useMantineTheme()

  const { user, getName } = useUser()

  const { avatar, email, phone } = user

  const { visible, open, close } = usePopup()

  const inputRef = useRef<HTMLInputElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const selectPhoto = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return

    setPhotoUploading(true)
    const file = event.target.files[0]

    const isValid = ValidationsUtils.fileMaxSize(file, FILE_MAX_SIZE)

    if (!isValid) {
      const message = t('file.max_size.error', {
        text: `${FileUtils.formatBytes(FILE_MAX_SIZE)}`,
      })
      showNotification({ type: 'error', message: message })

      setPhotoUploading(false)
      return
    }

    onUpload(file)

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  const onUpload = async (file: File) => {
    try {
      const data = {
        avatar: file,
      }
      await dispatch(profileAvatarUploadPA.request(data))
      showNotification({
        type: 'success',
        message: t('saved_successfully'),
      })
    } catch (err) {
      const serverError = err as FormTypes.ValidationErrors
      const message = serverError?.message || t('error')

      showNotification({
        type: 'error',
        message: message,
      })
    }
    setPhotoUploading(false)
  }

  const openHandler = (type: EditorType) => {
    setType(type)
    open(true)
  }

  return (
    <>
      <div className={classes.block}>
        {mobileView ? (
          <div className={'w-100'}>
            <div className={'h-full w-full mt-4'}>
              <Stack spacing="xs" justify="flex-end">
                <div className={classes.block}>
                  <div className={classes.avatarWrap}>
                    <AvatarProvider size={80} radius={50} avatar={avatar} />
                    <div className={classes.rightSection}>
                      <ActionIcon
                        size={'md'}
                        color={'primary'}
                        radius="xl"
                        variant={'filled'}
                        onClick={selectPhoto}
                        loading={photoUploading}
                      >
                        <IconCamera color={theme.white} width={20} />
                      </ActionIcon>
                      <input
                        type="file"
                        onChange={onInputChange}
                        ref={inputRef}
                        accept="image/png, image/jpeg"
                        className={'hidden'}
                      />
                      <button ref={buttonRef} type={'submit'} className={'hidden'}>
                        {t('save')}
                      </button>
                    </div>
                  </div>

                  <div className={'h-full w-full flex items-center'}>
                    <Title order={2}>{getName()}</Title>
                  </div>
                </div>

                <Stack spacing="xs" justify="flex-end">
                  {phone && (
                    <div className={classes.infoItem}>
                      <div>
                        <IconPhone size={20} />
                      </div>
                      <Text size={'sm'} className={'ml-2 truncate'}>
                        {FormatUtils.formatPhone(phone)}
                      </Text>
                    </div>
                  )}

                  {email && (
                    <div className={classes.infoItem}>
                      <div>
                        <IconMail size={20} />
                      </div>
                      <Text size={'sm'} className={'ml-2 truncate'}>
                        {email}
                      </Text>
                    </div>
                  )}
                </Stack>

                <div className={'flex w-100 my-2 gap-2'}>
                  <div className={'w-50'}>
                    <Button
                      fullWidth
                      color={'secondary'}
                      size={'md'}
                      variant={'outline'}
                      onClick={() => openHandler(EditorType.SETTINGS)}
                      leftIcon={<IconSettings />}
                      styles={(theme) => ({
                        root: {
                          fontSize: '14px',
                        },
                      })}
                    >
                      {t('settings')}
                    </Button>
                  </div>
                  <div className={'w-50'}>
                    <Button
                      fullWidth
                      color={'primary'}
                      size={'md'}
                      onClick={() => openHandler(EditorType.ALL)}
                      styles={(theme) => ({
                        root: {
                          fontSize: '14px',
                        },
                      })}
                    >
                      {t('edit_profile')}
                    </Button>
                  </div>
                </div>
              </Stack>
            </div>
          </div>
        ) : (
          <>
            <div className={classes.avatarWrap}>
              <AvatarProvider size={94} radius={50} type={'thumb'} avatar={avatar} />
            </div>

            <div className={'h-full w-full'}>
              <Stack spacing="xs" justify="flex-end">
                {getName() && <Title order={3}>{getName()}</Title>}

                {phone && (
                  <div className={classes.infoItem}>
                    <div>
                      <IconPhone size={20} />
                    </div>
                    <Text size={'sm'} className={'ml-2 truncate'}>
                      {FormatUtils.formatPhone(phone)}
                    </Text>
                  </div>
                )}

                {email && (
                  <div className={classes.infoItem}>
                    <div>
                      <IconMail size={20} />
                    </div>
                    <Text size={'sm'} className={'ml-2 truncate'}>
                      {email}
                    </Text>
                  </div>
                )}
              </Stack>
            </div>
          </>
        )}
      </div>
      {type == EditorType.SETTINGS ? (
        <ProfileSettings visible={visible} onClose={close} />
      ) : (
        <ModalOrDrawerSwitch
          opened={visible}
          onClose={close}
          leftIcon={'close'}
          headerBorder={true}
          title={t('edit_profile')}
        >
          <BabysitterProfileEdit onCancel={close} editorType={type} />
        </ModalOrDrawerSwitch>
      )}
    </>
  )
}
