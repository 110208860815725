import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  card: {
    padding: '30px 60px',
    borderRadius: '32px',
    backgroundColor: theme.white,

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      padding: '16px 16px',
    },
  },
}))
