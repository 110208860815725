import React, { useState } from 'react'
import { ScrollArea } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import { CompleteProfileContainer } from '../CompleteProfileContainer'
import { Stepper } from '../Stepper'
import { Header } from './Header'
import useStyles from './Layout.styles'
import { LogoutLink } from './LogoutLink'

export const Layout = () => {
  const { classes } = useStyles()
  const {
    appState: { mobileView },
  } = useAppState()

  const [step, setStep] = useState(0)

  return (
    <div className={classes.section}>
      <div className={classes.infoSection}>
        <Header />
        <ScrollArea className={classes.stepperWap}>
          <Stepper step={step} setStep={setStep} />
        </ScrollArea>
        {!mobileView && <LogoutLink />}
      </div>
      <div className={classes.contentSection}>
        <CompleteProfileContainer step={step} setStep={setStep} />
      </div>
    </div>
  )
}
