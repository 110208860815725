import React from 'react'
import { useTranslation } from 'react-i18next'
import { Title } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import { LanguagesFormSection } from '@/features/profile/components/FormSections'
import { SkillsFormSection } from '@/features/profile/components/FormSections/BabysitterProfileFormSections'

export const LangsSkillsForm = () => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()
  return (
    <div>
      <Title order={mobileView ? 5 : 4}>{t('what_languages_you_speak')}?</Title>

      <div className={'mt-2'}>
        <LanguagesFormSection level />
      </div>

      <Title order={mobileView ? 5 : 4}>
        {t('what_your_skills')} {t('what_you_teach_someone')}
      </Title>

      <div className={'mt-2'}>
        <SkillsFormSection />
      </div>
    </div>
  )
}
