import React from 'react'
import useStyles from './Content.styles'

interface IProps {
  children: React.ReactNode
}

export const Content = ({ children }: IProps) => {
  const { classes } = useStyles()

  return <div className={'h-full'}>{children}</div>
}
