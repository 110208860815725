import React from 'react'
import { Title } from '@mantine/core'
import { GeneralDrawer } from '@/components/Elements'
import { MobileFullModal } from '@/components/Elements/Modals/MobileFullModal'
import { useAppState } from '@/features/app/hooks'
import useStyles from './BookingDrawer.styles'

interface IProps {
  children: React.ReactNode
  title: string
  opened: boolean
  onClose: () => void
}

export const BookingDrawer = ({ children, title, opened, onClose }: IProps) => {
  const {
    appState: { mobileView },
  } = useAppState()
  const { classes } = useStyles()

  return (
    <>
      {mobileView ? (
        <MobileFullModal
          opened={opened}
          onClose={onClose}
          title={title}
          leftIcon="arrow"
          headerBorder={true}
        >
          {children}
        </MobileFullModal>
      ) : (
        <GeneralDrawer
          header={
            <div className={classes.header}>
              <Title className={'truncate'}>{title}</Title>
            </div>
          }
          onClose={onClose}
          opened={opened}
          bodyClass={classes.body}
          styles={(theme, _params) => ({
            title: {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          })}
        >
          {children}
        </GeneralDrawer>
      )}
    </>
  )
}
