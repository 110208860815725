import React, { useReducer } from 'react'
import { useDispatch } from 'react-redux'

export interface State {
  data: any
  loading: boolean
  error: any
}

interface Action {
  type: string
  payload?: any
}

const initialState = {
  data: null,
  loading: false,
  error: null,
}

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        loading: true,
      }
    case 'success':
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case 'error':
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case 'reset':
      return initialState
    default:
      return state
  }
}

export const useAsyncDispatch = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const storeDispatch = useDispatch()

  const onAsyncDispatch = async (action: any, ...args: any[]) => {
    dispatch({ type: 'loading' })

    try {
      const data = await storeDispatch(action(...args))

      dispatch({ type: 'success', payload: data })

      return data
    } catch (error) {
      dispatch({ type: 'error', payload: error })

      throw error
    }
  }

  const resetState = () => {
    dispatch({ type: 'reset' })
  }

  return {
    ...state,
    onAsyncDispatch,
    resetState,
  }
}
