import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  wrapper: {
    width: '100px',
  },

  input: {
    textAlign: 'center',
    fontSize: '14px',
  },
}))
