import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Group } from '@mantine/core'
import { BookingInfoAlert } from '@/features/bookings/components/Elements'
import { BOOKING_STATUS } from '@/features/bookings/consts/booking'
import { BookingRequest } from '@/features/bookings/types/models'
import { Candidates } from './Candidates'
import { Header } from './Header'
import { InfoAlert } from './InfoAlert'

interface IProps {
  data: BookingRequest
  onView: (id: number) => void
  onBabysitterFavoriteSuccess: (data: any) => void
  onCandidateHireSuccess: (bookingId: number, candidate: object) => void
}

export const BookingRequestSection = ({
  data,
  onView,
  onBabysitterFavoriteSuccess,
  onCandidateHireSuccess,
}: IProps) => {
  const { t } = useTranslation()

  const { booking_candidates, status, id, has_unpaid_invoice, booking_open_stripe_invoice } = data

  return (
    <>
      <Header booking={data} onView={onView} />

      {has_unpaid_invoice && (
        <BookingInfoAlert type={'has_unpaid_invoice'} alertProps={{ mt: 'md' }}>
          <Group>
            {t('need_payment')}

            <Button
              size={'xs'}
              component={'a'}
              target={'_blank'}
              href={booking_open_stripe_invoice?.url}
            >
              {t('pay')}
            </Button>
          </Group>
        </BookingInfoAlert>
      )}

      {/* {!!booking_candidates.length && (
        <div className={'pt-3'}>
          <Candidates
            items={booking_candidates}
            onBabysitterFavoriteSuccess={onBabysitterFavoriteSuccess}
            onCandidateHireSuccess={(data) => onCandidateHireSuccess(id, data)}
          />
        </div>
      )} */}

      {!booking_candidates.length &&
        [BOOKING_STATUS.BABYSITTER_NOT_FOUND, BOOKING_STATUS.PAYMENT_LINK_ERROR].includes(
          status
        ) && <InfoAlert data={data} />}
    </>
  )
}
