import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useAppWindowFocus, useMixPanel } from '@/features/app/hooks'
import { appState } from '@/features/app/store'
import { RootNavigator } from '@/routes'

export const initializeTagManager = () => {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER || '',
  }
  TagManager.initialize(tagManagerArgs)
}

export const initializeZendesk = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.zE('messenger', 'hide')
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.zE('messenger:on', 'close', function () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.zE('messenger', 'hide')
  })
}

function App() {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const theme = useMantineTheme()
  const isSmallerThenMd = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`)
  useAppWindowFocus()
  useMixPanel()

  useEffect(() => {
    initializeTagManager()
    initializeZendesk()

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.zE('messenger:set', 'locale', i18n.language)
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.zE('messenger:set', 'locale', i18n.language)
  }, [i18n.language])

  useEffect(() => {
    dispatch(appState.mobileView(isSmallerThenMd))
  }, [isSmallerThenMd])

  return <RootNavigator />
}
export default App
