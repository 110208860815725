import React from 'react'
import Sheet from 'react-modal-sheet'
import { Modal as MantineModal, ModalProps as MantineModalProps, Text, Title } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import useStyles from './Modal.styles'

export interface ModalProps extends MantineModalProps {
  opened: boolean
  onClose: () => void
  header?: React.ReactNode
  title?: React.ReactNode
  children: React.ReactNode
}

export const Modal = ({ opened, onClose, header, title, children, ...props }: ModalProps) => {
  const {
    appState: { mobileView },
  } = useAppState()

  const { classes } = useStyles()

  const closeHandler = (e: any) => {
    e.preventDefault()

    onClose()
  }

  const renderModalTitle = () =>
    header ? (
      header
    ) : title ? (
      <div className={'flex justify-center'}>
        {mobileView ? (
          <Text size={16} weight={500}>
            {title}
          </Text>
        ) : (
          <Title order={2}>{title}</Title>
        )}
      </div>
    ) : null

  return (
    <>
      {!mobileView ? (
        <MantineModal
          classNames={classes}
          {...props}
          onClose={onClose}
          title={renderModalTitle()}
          opened={opened}
        >
          {children}
        </MantineModal>
      ) : (
        <Sheet isOpen={opened} onClose={onClose} detent="content-height" disableDrag={true}>
          <Sheet.Container>
            <Sheet.Header className="react-modal-sheet-header">{renderModalTitle()}</Sheet.Header>
            <Sheet.Content>{children}</Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onTap={closeHandler} />
        </Sheet>
      )}
    </>
  )
}
