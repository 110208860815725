import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ContentCard, MegaTagTitle } from '@/components/Elements'
import { BottomTabsBookNow } from '@/components/Elements/Tabs/BottomTabs/BottomTabsBookNow'
import { useAppState } from '@/features/app/hooks'
import { useAuth } from '@/features/auth'
import { BookLayout } from '@/features/book/components/Book/BookLayout'
import { useHeaderContext } from '@/features/header/contexts/HeaderContext'
import { staticSelects } from '@/features/static/store'
import useStyles from './Book.styles'

export const Book = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const viewport = useRef<HTMLDivElement>()
  const dispatch = useDispatch()
  const { setKey } = useHeaderContext()
  const { isLoggedIn } = useAuth()
  const {
    appState: { mobileView },
  } = useAppState()

  useEffect(() => {
    setKey({ base: true, title: 'book', border: true })
    dispatch(staticSelects.getData())
  }, [])

  return (
    <>
      <MegaTagTitle title={'book'} />
      {!mobileView && !isLoggedIn ? (
        <ContentCard
          title={t('book')}
          footer={<BottomTabsBookNow />}
          scrollable={!mobileView}
          viewport={viewport}
          className={classes.cardWrapper}
        >
          <BookLayout />
        </ContentCard>
      ) : (
        <BookLayout />
      )}
    </>
  )
}
