export enum BOOKING_CANDIDATE_STATUS {
  WAITING_HIRING = 'waiting_hiring',
  HIRED = 'hired',
  DECLINED_CLIENT = 'declined_client',
  DECLINED_BABYSITTER = 'declined_babysitter',
  CANCELED_CLIENT = 'canceled_client',
  CANCELED_BABYSITTER = 'canceled_babysitter',
  ACCEPTED = 'accepted',
  INACTIVE = 'inactive',
}
