import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    height: 'calc(100vh - 32px)',
    width: '364px',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: '100%',
      height: '100%',
    },
  },
}))
