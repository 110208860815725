import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionIcon, useMantineTheme } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { usersFavoriteSubmitPA } from '@/features/users/store'
import { useAsyncDispatch, useNotify } from '@/hooks'

interface IProps {
  id: number | string | undefined
  status: boolean
  onSuccess?: (data: object) => void
}

export const FavoriteControl = ({ id, status = false, onSuccess }: IProps) => {
  const { t } = useTranslation()

  const theme = useMantineTheme()

  const { showNotification } = useNotify()

  const { loading, onAsyncDispatch } = useAsyncDispatch()

  const onSubmit = async (e: any) => {
    if (loading) return

    e.stopPropagation()
    try {
      const newStatus = !status

      const data = await onAsyncDispatch(usersFavoriteSubmitPA.request, { id, status: newStatus })

      onSuccess && onSuccess({ ...data })

      const message = newStatus ? t('added_to_favorites') : t('removed_from_favorites')
      showNotification({
        type: 'success',
        message: message,
      })
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return (
    <ActionIcon
      size={'sm'}
      color={status ? 'primary' : 'gray'}
      radius="xl"
      variant={status ? 'filled' : 'light'}
      onClick={onSubmit}
    >
      <Icon
        name={status ? 'heart' : 'heart-outline'}
        size={16}
        color={status ? theme.white : theme.black}
      />
    </ActionIcon>
  )
}
