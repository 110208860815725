import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router-dom'
import { Button, ContentCard } from '@/components/Elements'
import { useAppState, useEvents } from '@/features/app/hooks'

export const BookingsLayout = () => {
  const {
    appState: { mobileView },
  } = useAppState()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const events = useEvents()

  const handleNew = () => {
    navigate('/book-now')
  }

  return (
    <>
      {!mobileView ? (
        <ContentCard
          title={t('bookings')}
          headerRight={
            <Button color={'secondary'} onClick={handleNew}>
              {t('new_booking')}
            </Button>
          }
        >
          <Outlet />
        </ContentCard>
      ) : (
        <Outlet />
      )}
    </>
  )
}
