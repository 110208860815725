import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { Button } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { EmailsFormSection } from '@/features/wallet/routes/services/wallet-view/email-form/EmailsFormSection'
import { useFormSubmit, useNotify } from '@/hooks'
import { validationSchema } from './validation'

interface IProps {
  onSubmit: (values: object) => Promise<void>
  onCancel: () => void
}

export const EmailForm = ({ onCancel, ...props }: IProps) => {
  const { t } = useTranslation()
  const { showNotification } = useNotify()
  const {
    appState: { mobileView },
  } = useAppState()

  const defaultValues = {
    emails: [],
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const {
    handleSubmit,
    setError,
    trigger,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      const isStepValid = await trigger()
      if (isStepValid) {
        const { emails } = data

        const list = emails.map(function (item: any) {
          return item['email']
        })
        const values = {
          emails: list,
        }

        await onFormSubmit(values)
      } else {
        showNotification({
          type: 'error',
          message: t('please_fill_all_required_fields'),
        })
      }
    } catch (error) {
      showNotification({
        type: 'error',
        message: t('error_submitting_request'),
      })
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <EmailsFormSection />
          </div>
          {mobileView ? (
            <div className={'flex w-100 mt-4'}>
              <Button disabled={isSubmitting} loading={isSubmitting} fullWidth type={'submit'}>
                {t('referral.send')}
              </Button>
            </div>
          ) : (
            <div className={'flex justify-end'}>
              <Group>
                <Button variant="default" color="dark" onClick={onCancel}>
                  {t('cancel')}
                </Button>
                <Button disabled={isSubmitting} loading={isSubmitting} type={'submit'}>
                  {t('referral.send')}
                </Button>
              </Group>
            </div>
          )}
        </form>
      </FormProvider>
    </>
  )
}
