import classNames from 'classnames'
import React from 'react'
import { useAppState } from '@/features/app/hooks'
import { BOOKING_CANDIDATE_STATUS } from '@/features/booking-candidate/consts/candidate'
import { useBookingData } from '@/features/bookings/hooks'
import { BookingModel } from '@/features/bookings/types/models'
import { CandidateAccept, CandidateDecline } from '../../Actions'

export const CandidateStatusBlock = () => {
  const { booking } = useBookingData()

  const {
    appState: { mobileView },
  } = useAppState()

  const { my_booking_candidate } = booking as BookingModel

  if (my_booking_candidate?.status !== BOOKING_CANDIDATE_STATUS.HIRED) {
    return null
  }

  return (
    <div
      className={classNames('flex flex-wrap gap-3', {
        'w-100': mobileView,
      })}
    >
      <>
        {mobileView ? (
          <>
            <CandidateAccept candidate={my_booking_candidate} />
            <CandidateDecline candidate={my_booking_candidate} />
          </>
        ) : (
          <>
            <CandidateDecline candidate={my_booking_candidate} />
            <CandidateAccept candidate={my_booking_candidate} />
          </>
        )}
      </>
    </div>
  )
}
