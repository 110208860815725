import React, { useCallback, useEffect } from 'react'
import { useEcho } from '@/features/socket/hooks'

interface IProps {
  channel: string
  events: object
}

export const useEchoSubscriptionEffect = ({ channel, events }: IProps) => {
  const echo = useEcho()

  const stopListen = useCallback(() => {
    if (echo.init) {
      const eventsNames: string[] = []

      Object.entries(events).forEach((entry) => {
        const [event] = entry
        eventsNames.push(event)
      })

      echo.privateChannelStopListeners(channel, eventsNames)
    }
  }, [echo.init, events])

  useEffect(() => {
    if (echo.init) {
      echo.privateChannelAddListeners(channel, events)
    }

    return () => {
      if (echo.init) {
        stopListen()
      }
    }
  }, [echo.init])
}
