import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'
import { MessageType } from '@/features/chat/types/models'
import useStyles from './Message.styles'

interface IProps {
  message: MessageType
}

export const Message = ({ message }: IProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  const isAttachmentsOnly = message?.files && message?.files?.length > 0 && !message?.body
  const isImageOnly = message?.image && !message?.body

  return (
    <Text color={'gray.6'} className={cx(classes.message, 'truncate')} size={'sm'}>
      {isAttachmentsOnly || isImageOnly || message?.body ? (
        <>
          {message?.is_my && <span>{t('you')}: </span>}

          {isAttachmentsOnly ? (
            <span>{t('attachments')}</span>
          ) : isImageOnly ? (
            <span>{t('image')}</span>
          ) : (
            message?.body
          )}
        </>
      ) : (
        t('no_messages')
      )}
    </Text>
  )
}
