import { IconX } from '@tabler/icons'
import React from 'react'
import { ActionIcon } from '@mantine/core'
import { TagBadge } from '@/components/Elements'
import { DateUtils } from '@/utils'

interface IProps {
  value: Date[]
  onRemove: (data: Date) => void
}

export const DatesTags = ({ value = [], onRemove }: IProps) => {
  const renderRemoveButton = (data: Date) => (
    <ActionIcon
      size={'sm'}
      color="dark"
      radius="xl"
      variant="transparent"
      onClick={() => onRemove(data)}
    >
      <IconX size={16} />
    </ActionIcon>
  )

  return (
    <>
      <div className={'flex flex-wrap gap-2.5'}>
        {value.map((item, index) => (
          <TagBadge key={index} rightSection={renderRemoveButton(item)} color={'gray'}>
            {item ? DateUtils.format(item, 'DD/MM/YY') : ''}
          </TagBadge>
        ))}
      </div>
    </>
  )
}
