import React from 'react'
import { MegaTagTitle } from '@/components/Elements'
import { SpecificBookingsContainer } from '@/features/bookings/components/Bookings/SpecificBookings'
import { SPECIFIC_BOOKING_ALIAS } from '@/features/bookings/consts/specific-bookings'

export const Upcoming = () => {
  return (
    <>
      <MegaTagTitle title={'upcoming'} />

      <SpecificBookingsContainer alias={SPECIFIC_BOOKING_ALIAS.UPCOMING} />
    </>
  )
}
