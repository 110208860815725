import { shouldAutoRemoveFilter } from '@tanstack/react-table'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Chat } from '@/components/Elements/Icon/Chat'
import { ChatModel, MessageType } from '@/features/chat/types/models'
import { useUser } from '@/features/user'
import useStyles from './Body.styles'
import { CountBadge } from './CountBadge'
import { Date } from './Date'
import { Message } from './Message'
import { Name } from './Name'

interface IProps {
  data: ChatModel
  showRatingRequest: boolean
}

export const Body = ({ data, showRatingRequest }: IProps) => {
  const { t } = useTranslation()
  const { isBabysitter } = useUser()
  const { classes } = useStyles()
  //const { isBabysitter } = useUser()
  //const [lastDate, setLastDate] = useState(data?.booking?.date_end)

  const { last_message, participants, unread_count, booking } = data

  const [hour, minute] = booking?.time_start?.split(':') ?? [0, 0]

  const createRateMessage = () => {
    return {
      body: t(isBabysitter() ? 'chat.rate_your_client' : 'chat.rate_your_babysitter'),
      created_at: data.booking.date_end,
      user: {
        first_name: 'Admin',
        is_my: false,
      },
    }
  }

  const lastMessageToShow = showRatingRequest ? createRateMessage() : last_message

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <div className={'flex flex-col grow min-w-0'}>
          {/*isBabysitter() &&
            (booking ? <Booking booking={booking.address} /> : <Name participant={participant} />)}
          {isClient() && <Name participant={participant} />}
          {isBabysitter() && (
            <span className="text-sm text-gray-900">
              {booking_owner?.first_name} {booking_owner?.last_name}
            </span>
          )*/}
          <Name participants={participants} />
          {booking && (
            <span className="text-xs text-gray-500">
              {t('service_date')}:{' '}
              {moment(booking?.date_start).hour(hour).minute(minute).format('DD/MM/YYYY')}
            </span>
          )}
        </div>

        <div className={'ml-1 h-100'}>
          <Date value={lastMessageToShow?.created_at} />
        </div>
      </div>

      <div className={classes.subBar}>
        <div className={'flex grow min-w-0'}>
          <Message message={lastMessageToShow as MessageType} />
        </div>

        {unread_count > 0 && (
          <div className={'ml-1'}>
            <CountBadge>{unread_count}</CountBadge>
          </div>
        )}
      </div>
      {/*showKidsImage && (
        <div className={classes.subBar}>
          <Badge color={'primary'} variant={'filled'} fullWidth>
            {t('send_kids_image')}
          </Badge>
        </div>
      )*/}
    </div>
  )
}
