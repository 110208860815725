import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useAppState } from '@/features/app/hooks'
import { ExceptionDatesController } from '@/features/booking/components/FormElements'

export const ExceptionDatesFormSection = () => {
  const { control, watch } = useFormContext()

  const {
    appState: { mobileView },
  } = useAppState()

  const watchDateStart = watch('date_start')
  const watchDateEnd = watch('date_end')

  return (
    <div className={mobileView ? 'pb-3' : 'my-3 pb-3'}>
      <ExceptionDatesController
        control={control}
        name={'timetable.exception_dates'}
        period={[watchDateStart, watchDateEnd]}
      />
    </div>
  )
}
