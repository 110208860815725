import React from 'react'
import { Text } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'

interface IProps {
  title: string
  children: React.ReactNode
}

export const InfoItem = ({ title, children }: IProps) => {
  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <div className={'flex flex-col'}>
      <div>
        <Text size={mobileView ? 13 : 'sm'} weight={600}>
          {title}
        </Text>
      </div>
      <div className={'mt-2'}>
        <Text size={mobileView ? 13 : 'sm'}>{children}</Text>
      </div>
    </div>
  )
}
