import React from 'react'
import { Link } from 'react-router-dom'
import { Anchor, Group, Stack, Text } from '@mantine/core'
import { AvatarProvider, Icon } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import {
  BookingHoursPerWeekDisplay,
  BookingTimePeriodDisplay,
} from '@/features/bookings/components/Elements'
import { BookingDaysPerWeekDisplay } from '@/features/bookings/components/Elements/BookingDaysPerWeekDisplay/BookingDaysPerWeekDisplay'
import { BOOKING_STATUS } from '@/features/bookings/consts/booking'
import { bookingHelper } from '@/features/bookings/helpers'
import { useBookingPermissions } from '@/features/bookings/hooks'
import { BookingModel } from '@/features/bookings/types/models'
import { useUsersModel } from '@/features/users/hooks'
import { FavoriteControl } from '../ActionBar/Controls'
import { RatingControl } from '../Footer/Controls'
import useStyles from './InfoBlock.styles'

const { bookingModel } = bookingHelper
const getType = bookingHelper.type.getType

interface IProps {
  data: BookingModel
  config: {
    favorite: boolean
    menu: boolean
    chat: boolean
    rating: boolean
    upcomingPanel: boolean
  }
  favoriteControlProps?: any
  ratingControlProps?: any
}

export const InfoBlock = ({ data, config, favoriteControlProps, ratingControlProps }: IProps) => {
  const {
    appState: { mobileView },
  } = useAppState()
  const { isRatingAllow } = useBookingPermissions()

  const { classes } = useStyles()

  const { babysitters, type } = data

  const timePeriod = bookingModel.getTimePeriod(data)

  const { getName } = useUsersModel()

  return (
    <div className={classes.root}>
      {!mobileView && timePeriod && (
        <Text size={'xl'} weight={700}>
          <BookingTimePeriodDisplay timePeriod={timePeriod} />
        </Text>
      )}
      {!mobileView && data.hours_per_week && (
        <Text size={'xl'} weight={700}>
          <BookingHoursPerWeekDisplay hoursPerWeek={data.hours_per_week} />
        </Text>
      )}
      {!mobileView && data.days_per_week && (
        <Text size={'xl'} weight={700}>
          <BookingDaysPerWeekDisplay daysPerWeek={data.days_per_week} />
        </Text>
      )}

      <div className={'flex gap-2'}>
        <Stack className={mobileView ? 'gap-1' : 'gap-1 mt-3'}>
          {babysitters &&
            babysitters.map((item, index) => (
              <Stack spacing={8} key={'info-block-' + index}>
                <Group noWrap>
                  <Anchor component={Link} to={`/babysitters/${item?.id}`}>
                    <AvatarProvider size={mobileView ? 30 : 32} avatar={item?.avatar} />
                  </Anchor>

                  <Anchor component={Link} to={`/babysitters/${item?.id}`} color={'gray'}>
                    <Text size={mobileView ? 14 : 18} weight={600} color={'gray.6'}>
                      {getName(item)}
                    </Text>
                  </Anchor>
                  {config?.favorite && (
                    <FavoriteControl babysitter={item} {...favoriteControlProps} />
                  )}
                </Group>

                {mobileView && !config?.upcomingPanel && data.status === BOOKING_STATUS.FINISHED && (
                  <>
                    {isRatingAllow(data, item) && (
                      <div className={'mb-1'}>
                        <RatingControl booking={data} babysitter={item} {...ratingControlProps} />
                      </div>
                    )}
                  </>
                )}
              </Stack>
            ))}
        </Stack>
        <Icon name={getType(type)?.iconName} size={mobileView ? 18 : 23} />
      </div>
    </div>
  )
}
