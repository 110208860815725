import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Group, Stack, Text } from '@mantine/core'
import { BooleanTextMessage, CurrencyFormat } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { RatingControl } from '@/features/booking-service/components/Services/Cards/GeneralServiceCard/Footer/Controls'
import { bookingServiceServicesSpecificList } from '@/features/booking-service/store'
import { OnetimeRanges } from '@/features/bookings/components/Booking/BookingDetails/BookingTableDetails/OnetimeRanges'
import { BookingStatusBadge } from '@/features/bookings/components/Elements'
import { BOOKING_STATUS, BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { bookingHelper } from '@/features/bookings/helpers'
import { useBookingPermissions } from '@/features/bookings/hooks'
import { bookingsData } from '@/features/bookings/store'
import { BookingModel } from '@/features/bookings/types/models'
//import { LocationAreaDisplay } from '@/features/location/components/Elements'
import { RatingSubmittedActionMeta } from '@/features/rating/hooks'
import { useUser } from '@/features/user'
import { useUsersModel } from '@/features/users/hooks'
import { getAsArrayIfNot } from '@/helpers/common/utils'
import { DateUtils } from '@/utils'
//import { Address } from './Address'
import { Babysitter } from './Babysitter'
import { RatingControl as BabysitterRatingControl } from './Controls'
import useStyles from './Details.styles'

interface IProps {
  data: BookingModel
  config?: any
  ratingProps?: any
}

type ItemType = {
  label: string
  value: React.ReactNode
  hidden?: boolean
}

const defaultConfig = {
  babysitter: false,
  client: false,
  status: true,
  nextPayment: false,
  totalAmount: false,
  babysitterAmount: false,
}

export const BookingTableDetails = ({ data, ratingProps, config = {} }: IProps) => {
  const { t } = useTranslation()
  const { isBabysitter } = useUser()
  const { classes, cx } = useStyles()
  const dispatch = useDispatch()
  const { isRatingAllow } = useBookingPermissions()

  const { getName } = useUsersModel()

  const {
    appState: { mobileView },
  } = useAppState()

  config = {
    ...defaultConfig,
    ...config,
  }

  const {
    user,
    babysitters,
    //city,
    //city_type,
    //city_description,
    //country,
    zip,
    type,
    date_start,
    date_end,
    //comments,
    living_in,
    hours_per_week,
    days_per_week,
    booking_children,
    primary_language,
    secondary_language,
    has_special_needs,
    phone,
    special_needs,
    //other_info,
    care_expectations,
    status,
    next_payment_at,
    total_amount,
    babysitter_amount,
    current_amount,
    current_bbs_amount,
  } = data

  const timePeriod = bookingHelper.bookingModel.getTimePeriod(data)

  const onRatingSubmitted = (rating: any | null, actionMeta: RatingSubmittedActionMeta) => {
    const { payload } = actionMeta
    const { bookingServiceId, bookingId } = payload

    const id = bookingServiceId || bookingId

    dispatch(bookingServiceServicesSpecificList.setServiceClientRating({ id, rating }))
    dispatch(bookingsData.setClientRating({ ...rating }))
  }

  const items: ItemType[] = [
    {
      label: t('baby_sister'),
      value: (
        <Stack className={'gap-2'}>
          {babysitters?.map((babysitter, index) => (
            <Stack className={'gap-1'} key={'table-details' + index}>
              <Group className={'gap-0'}>
                <Babysitter data={babysitter} />
              </Group>
              {isRatingAllow(data, babysitter) && (
                <BabysitterRatingControl booking={data as BookingModel} babysitter={babysitter} />
              )}
            </Stack>
          ))}
          {(!babysitters || babysitters?.length == 0) && (
            <span className={'whitespace-nowrap'}>{'-'}</span>
          )}
        </Stack>
      ),
      hidden: !config?.babysitter || !babysitters,
    },
    {
      label: t('parent'),
      value: (
        <Stack className={'gap-1'}>
          <Group className={'gap-0'}>{getName(user)}</Group>
          {isBabysitter() && data.status === BOOKING_STATUS.FINISHED && data.client_rating && (
            <RatingControl
              showAsTable={false}
              bookingService={data}
              onRatingSubmitted={onRatingSubmitted}
            />
          )}
        </Stack>
      ),
      hidden: !config?.client || !user,
    } /*
    {
      label: t('location'),
      value: <LocationAreaDisplay data={{ city, city_type, city_description, country }} />,
    },
    {
      label: t('address'),
      value: <Address data={data} />,
    },*/,
    {
      label: t('postal_code'),
      value: zip,
    },
    {
      label: t('type'),
      value: bookingHelper.type.getType(type)?.title,
    },
    {
      label: t('start_date'),
      value: DateUtils.formatDate(date_start),
    },
    {
      label: t(type == BOOKING_TYPE.PERMANENT_NANNY ? 'expected_end_date' : 'end_date'),
      value: DateUtils.formatDate(date_end),
    },
    {
      label: t('time'),
      value: (
        <span className={'whitespace-nowrap'}>
          {timePeriod?.time_start}
          {' - '}
          {timePeriod?.time_end}
        </span>
      ),
      hidden: type == BOOKING_TYPE.PERMANENT_NANNY || !timePeriod,
    },
    {
      label: t('hours_per_week'),
      value: <span className={'whitespace-nowrap'}>{hours_per_week}</span>,
      hidden: type != BOOKING_TYPE.PERMANENT_NANNY && !living_in,
    },
    {
      label: t('days_per_week'),
      value: <span className={'whitespace-nowrap'}>{days_per_week}</span>,
      hidden: type != BOOKING_TYPE.PERMANENT_NANNY && living_in,
    },
    {
      label: t('added_dates'),
      value: <OnetimeRanges data={data.timetable.onetime_ranges} />,
      hidden:
        !data ||
        (data.type !== BOOKING_TYPE.ONETIME && data.type !== BOOKING_TYPE.ANIMATION) ||
        !data.timetable.onetime_ranges ||
        data.timetable.onetime_ranges.length == 0,
    } /*
    {
      label: t('comment'),
      value: comments ? <span className={'whitespace-pre-wrap'}>{comments}</span> : '-',
    },*/,
    {
      label: t('children'),
      value: (
        <>
          {booking_children.map((item, i) => (
            <React.Fragment key={i}>
              <Text weight={600}>{item?.name}</Text>
              <Text>{item?.birthday ? DateUtils.formatDate(item?.birthday) : null}</Text>
            </React.Fragment>
          ))}
        </>
      ),
    },
    {
      label: t('primary_language'),
      value: primary_language?.name || '-',
    },
    {
      label: t('secondary_language'),
      value: (
        <Group>
          {getAsArrayIfNot(secondary_language).map((language: any, index) => (
            <Text key={'secondary' + index}>{language?.name || '-'}</Text>
          ))}
        </Group>
      ),
      hidden: !secondary_language || secondary_language.length == 0,
    },
    {
      label: t('kid_has_special_needs'),
      value: <BooleanTextMessage value={has_special_needs} />,
    },
    {
      label: t('special_needs'),
      value: special_needs ? <span className={'whitespace-pre-wrap'}>{special_needs}</span> : '-',
      hidden: !has_special_needs,
    } /*
    {
      label: t('other_requirements_info'),
      value: other_info ? <span className={'whitespace-pre-wrap'}>{other_info}</span> : '-',
    },*/,
    {
      label: t('expectations_for_the_care'),
      value: care_expectations ? (
        <span className={'whitespace-pre-wrap'}>{care_expectations}</span>
      ) : (
        '-'
      ),
    },
    {
      label: t('status'),
      value: <BookingStatusBadge value={status} />,
      hidden: !config?.status,
    },
    {
      label: t('next_payment'),
      value: DateUtils.formatDateTime(next_payment_at),
      hidden: !config?.nextPayment || !next_payment_at,
    },
    {
      label: t('total_amount'),
      value: total_amount ? <CurrencyFormat value={total_amount / 100 || 0} /> : '-',
      hidden: !config?.totalAmount,
    },
    /*{
      label: t('total_amount'),
      value: current_bbs_amount ? <CurrencyFormat value={current_bbs_amount / 100 as number} /> : '-',
      hidden: !config?.babysitterAmount,
    },*/
  ]

  const renderGroup = (label: any, value: any) => (
    <Group className={classNames('gap-1', classes.groupItem)}>
      <Text className={classes.groupItemLabel}>{label}</Text>
      <Text className={classes.groupItemValue}>{value ? value : '-'}</Text>
    </Group>
  )

  return (
    <>
      {mobileView ? (
        <>
          <Stack>
            {isBabysitter() && data.status === BOOKING_STATUS.FINISHED && !data.client_rating && (
              <RatingControl
                showAsTable={mobileView}
                bookingService={data}
                onRatingSubmitted={onRatingSubmitted}
              />
            )}
          </Stack>
          <Stack className={'my-3 gap-2.5'}>
            {items.map(
              ({ label, value, hidden, ...item }, index) =>
                !hidden && <React.Fragment key={index}>{renderGroup(label, value)}</React.Fragment>
            )}
          </Stack>
        </>
      ) : (
        <div className={cx(classes.block, 'overflow-x-auto relative')}>
          {isBabysitter() && data.status === BOOKING_STATUS.FINISHED && !data.client_rating && (
            <RatingControl
              showAsTable={true}
              bookingService={data}
              onRatingSubmitted={onRatingSubmitted}
            />
          )}

          <table className="border-spacing-2 w-full">
            <tbody>
              {items.map(
                ({ label, value, hidden, ...item }, index) =>
                  !hidden && (
                    <tr key={index}>
                      <td className={classes.labelCell}>
                        <span className={classes.label}>{label}</span>
                      </td>

                      <td className={'text-lg'}>{value}</td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}
