import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { BabysittersLayout, BabysittersTabsLayout } from '@/features/babysitter/components/Layouts'
import { BabysitterRoot } from '../babysitter'
import { Available, Favorites } from '../babysitters'

export const BabysittersRoot = () => {
  return (
    <Routes>
      <Route element={<BabysittersLayout />}>
        <Route element={<BabysittersTabsLayout />}>
          <Route index element={<Available />} />
          <Route path={'favorites'} element={<Favorites />} />
        </Route>
      </Route>

      <Route path={':id/*'} element={<BabysitterRoot />} />

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
