import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFetch, useNotify } from '@/hooks'
import { useGeocoding } from '@/packages/google-maps/geocoding/hooks'

export const useLocationGeocode = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const { loading, fetch } = useFetch()

  const { getGeocode } = useGeocoding()

  const onFetch = async (
    params: {
      address: string
      language?: string
      components?: object | null
    },
    onSuccess?: (data: object) => void,
    onError?: (data: boolean) => void
  ) => {
    try {
      const data = await fetch(getGeocode, params)

      if (data?.status === 'OK') {
        onSuccess && onSuccess({ ...data })
        onError && onError(false)
      }

      if (data?.error_message) {
        showNotification({ type: 'error', message: data?.error_message })
      } else if (data?.results && data?.results.length === 0) {
        showNotification({ type: 'error', message: t('location_not_found') })
        onError && onError(true)
      }
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return {
    onFetch,
    loading,
  }
}
