import { yupResolver } from '@hookform/resolvers/yup'
import { IconChevronLeft } from '@tabler/icons'
import React, { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Group } from '@mantine/core'
import { Button as MantineButton } from '@mantine/core'
import { Alert, ContentCard } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { EXPERIENCE_AGE_STEP_RANGE } from '@/features/profile/components/FormElements'
import { experienceAgeStepRangeSliderHelper } from '@/features/profile/components/FormElements'
import { ProfileAvatarManager } from '@/features/profile/components/ProfileAvatarManager'
import { profileBabysitterUpdatePA } from '@/features/profile/store'
import { useNotify } from '@/hooks'
import { FormTypes } from '@/types'
import { DateUtils, ValidationsUtils } from '@/utils'
import useStyles from './CompleteProfileContainer.styles'
import { CertificationsForm, ExperienceForm, GeneralForm, LangsSkillsForm } from './Forms'
import { validationSchema } from './validation'

interface IProps {
  step: number
  setStep: (step: number) => void
}

export const CompleteProfileContainer = ({ step, setStep }: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { showNotification } = useNotify()

  const {
    appState: { mobileView },
  } = useAppState()
  const { classes } = useStyles()

  const steps = [
    {
      title: t('complete_profile'),
      content: (
        <div>
          <ProfileAvatarManager />

          <div className={'mt-4'}>
            <GeneralForm />
          </div>
        </div>
      ),
    },
    { title: t('language_and_skills'), content: <LangsSkillsForm /> },
    { title: t('experience'), content: <ExperienceForm /> },
    { title: t('certifications_and_degrees'), content: <CertificationsForm /> },
  ]

  const defaultValues = {
    first_name: '',
    last_name: '',
    birthday: null,
    phone: '',
    city_id: null,
    occupation_area: '',
    bio: '',

    languages: [],
    skills: [],

    certifications: [],

    experience: [EXPERIENCE_AGE_STEP_RANGE.MIN, EXPERIENCE_AGE_STEP_RANGE.MAX],
    have_car: false,
    have_driving_license: false,
    have_pediatric_certification: false,
    have_special_kids: false,
  }

  const currentValidationSchema = validationSchema[step]

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(currentValidationSchema),
  })

  const {
    handleSubmit,
    trigger,
    setError,
    formState: { isSubmitting },
  } = methods

  const handleNext = async () => {
    const isStepValid = await trigger()
    if (isStepValid) setStep(step + 1)
  }

  const handleBack = () => {
    setStep(step - 1)
  }

  function getStepContent(step: number) {
    return steps[step]
  }

  const [alertError, setAlertError] = useState<string | null>(null)

  const onSubmit: SubmitHandler<any> = async (data) => {
    setAlertError(null)
    try {
      const { birthday, phone, skills, certifications, experience, ...resetValues } = data

      const skillsValue = skills.map(({ id, name }: { id: number; name: string }) => {
        if (id) {
          return { skill_id: id }
        } else {
          return { name }
        }
      })

      const certificationsValue = certifications.map(
        ({ start_date, end_date, ...restItem }: { start_date: Date; end_date: Date }) => ({
          ...restItem,
          start_date: DateUtils.formatDateToServerFormat(start_date),
          end_date: DateUtils.formatDateToServerFormat(end_date),
        })
      )

      const values = {
        ...resetValues,
        phone: phone ? phone.replace(/[^0-9]/g, '') : '',
        birthday: DateUtils.formatDateToServerFormat(birthday),
        skills: skillsValue,
        certifications: certificationsValue,
        experience_min: experienceAgeStepRangeSliderHelper.convertStepToMonth(experience[0]),
        experience_max: experienceAgeStepRangeSliderHelper.convertStepToMonth(experience[1]),
      }

      await dispatch(profileBabysitterUpdatePA.request(values))
      showNotification({
        type: 'success',
        message: t('saved_successfully'),
      })
      navigate('/profile')
    } catch (err) {
      const serverError = err as FormTypes.ValidationErrors
      setAlertError(serverError?.message || t('error'))
      ValidationsUtils.setServerSideErrors(serverError?.errors, setError)
    }
  }

  const renderFooter = () => {
    return (
      <div className={mobileView ? 'flex w-100' : 'flex justify-end'}>
        {mobileView ? (
          <>
            {step !== steps.length - 1 ? (
              <MantineButton className={'w-100'} onClick={handleNext}>
                {t('next')}
              </MantineButton>
            ) : (
              <MantineButton
                className={'w-100'}
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={handleSubmit(onSubmit)}
              >
                {t('complete')}
              </MantineButton>
            )}
          </>
        ) : (
          <Group>
            {step !== 0 && (
              <MantineButton
                disabled={isSubmitting}
                variant="default"
                color="dark"
                leftIcon={<IconChevronLeft />}
                onClick={handleBack}
              >
                {t('back')}
              </MantineButton>
            )}
            {step !== steps.length - 1 ? (
              <MantineButton onClick={handleNext}>{t('next')}</MantineButton>
            ) : (
              <MantineButton
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={handleSubmit(onSubmit)}
              >
                {t('complete')}
              </MantineButton>
            )}
          </Group>
        )}
      </div>
    )
  }

  return (
    <FormProvider {...methods}>
      <form className={mobileView ? '' : 'h-full2'}>
        <ContentCard
          title={getStepContent(step)?.title}
          footer={renderFooter()}
          scrollable={!mobileView}
          className={classes.cardWrapper}
        >
          <div className={classes.wrapper}>
            <>
              {alertError && (
                <Alert type={'error'} mb={'sm'}>
                  {alertError}
                </Alert>
              )}

              {getStepContent(step)?.content}
            </>
          </div>
        </ContentCard>
      </form>
    </FormProvider>
  )
}
