import classNames from 'classnames'
import React from 'react'
import { Group, Stack, Text, Title } from '@mantine/core'
import { useHover } from '@mantine/hooks'
import { useAppState } from '@/features/app/hooks'
import useStyles from './BookLayout.styles'

interface IProps {
  image: any
  text: string
  desc: string
  event: () => void
}
export const BookEventType = ({ image, text, desc, event }: IProps) => {
  const { hovered, ref } = useHover()
  const { classes } = useStyles()
  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <div
      ref={ref}
      onClick={event}
      className={classNames(classes.option, { 'hover-mask': hovered })}
    >
      <Group>
        <img src={image} width="120px" height="120px" className={classes.fitImage} />
        <div className={classes.optionContent}>
          <Stack className={classes.stackNoGap}>
            <Title order={mobileView ? 4 : 3}>{text}</Title>
            <Text fz="sm">{desc}</Text>
          </Stack>
        </div>
      </Group>
    </div>
  )
}
