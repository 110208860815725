import { combineReducers } from 'redux'
import babysitters from './babysitters/reducers'
import data from './data/slice'

const babysitterReducer = combineReducers({
  data,
  babysitters,
})

export default babysitterReducer
