import { ReactNode } from 'react'

type ErrorType = ReactNode & {
  message: string
}

type TranslatePropsType = {
  t: any
  translateParams: object | undefined
}

const getErrorMessage = (error: ErrorType | null, translateProps: TranslatePropsType) => {
  if (!error) {
    return null
  }

  let message = null
  if (typeof error === 'string') {
    message = error
  }
  if (error?.message) {
    message = error.message
  }
  if (message) {
    const { t, translateParams } = translateProps
    message = t(message, translateParams ? { ...translateParams } : {})
  }
  return message
}

export { getErrorMessage }
