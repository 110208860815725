import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './Unread.styles'

export const Unread = () => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  return <div className={classes.root}>{t('unread_messages')}</div>
}
