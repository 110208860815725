import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group, Title } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { GeneralFormSection } from '@/features/profile/components/FormSections'
import { formHelper } from '@/features/profile/helpers'
import { ClientProfile, LanguageType, useUser } from '@/features/user'
import { useFormSubmit, useNotify } from '@/hooks'
import { DateUtils, FormatUtils } from '@/utils'
import {
  ChildrenFormSection,
  InfoFormSection,
  LanguagesFormSection,
  LocationFormSection,
} from './FormSections'
import { validationSchema } from './validation'

const { clientProfile: clientProfileHelper } = formHelper

interface IProps {
  onSubmit: (values: object) => Promise<void>
  onCancel: () => void
}

export const EditForm = ({ onCancel, ...props }: IProps) => {
  const { t } = useTranslation()
  const { showNotification } = useNotify()

  const { user } = useUser()

  const { client_profile, phone, first_name, last_name, ...restUserData } = user

  const {
    appState: { mobileView },
  } = useAppState()

  const {
    city,
    city_type,
    city_description,
    country,
    address,
    zip,
    children,
    languages,
    about_child,
    other_info,
    ...restProfileData
  } = (client_profile as ClientProfile) || {}

  const cityDefaultValue = clientProfileHelper.getCityDefaultValue({
    city,
    city_type,
    city_description,
    country,
  })

  const defaultValues = {
    ...restUserData,
    first_name: first_name || '',
    last_name: last_name || '',
    phone: FormatUtils.formatPhone(phone) || '',
    ...cityDefaultValue,
    address: address || '',
    zip: zip || '',
    children: children || [],
    languages: languages ? clientProfileHelper.getLangDefaultValue(languages) : [],
    about_child: about_child || '',
    other_info: other_info || '',
    ...restProfileData,
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      const {
        phone,
        city_id,
        city_type,
        city_description,
        country_id,
        children,
        languages,
        ...resetValues
      } = data

      const cityValues = clientProfileHelper.mapCitySubmitValue({
        city_id,
        city_type,
        city_description,
        country_id,
      })

      const childrenValue = children.map(({ birthday, ...restItem }: { birthday: Date }) => ({
        ...restItem,
        birthday: DateUtils.formatDateToServerFormat(birthday),
      }))

      const values = {
        ...resetValues,
        phone: phone ? phone.replace(/[^0-9]/g, '') : '',
        ...cityValues,
        children: childrenValue,
        languages: clientProfileHelper.mapLangSubmitValue(languages),
      }

      await onFormSubmit(values)
    } catch (error) {
      showNotification({
        type: 'error',
        message: t('error_submitting_request'),
      })
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.message || t('error')}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          {mobileView && (
            <Title order={4} mb={'sm'}>
              {t('contact_information')}
            </Title>
          )}

          <GeneralFormSection />

          <div className={mobileView ? '' : 'mt-4'}>
            <Title order={4} mb={'sm'}>
              {t('location')}
            </Title>
            <LocationFormSection />
          </div>

          <div className={'mt-4'}>
            <Title order={4} mb={'sm'}>
              {t('child')}
            </Title>
            <ChildrenFormSection />
          </div>

          <div className={mobileView ? 'pt-4' : 'mt-4 pt-4'}>
            <Title order={4} mb={'sm'}>
              {t('languages')}
            </Title>
            <LanguagesFormSection />
          </div>

          <div className={mobileView ? 'mt-3' : 'mt-4'}>
            <InfoFormSection />
          </div>

          <div className={mobileView ? 'flex w-100' : 'flex justify-end'}>
            <Group className={mobileView ? 'w-100 pt-4' : ''}>
              {!mobileView && (
                <Button variant="default" color="dark" onClick={onCancel}>
                  {t('cancel')}
                </Button>
              )}
              <Button fullWidth={mobileView} loading={isSubmitting} type={'submit'}>
                {t('save')}
              </Button>
            </Group>
          </div>
        </form>
      </FormProvider>
    </>
  )
}
