import axios from 'axios'
import { SubmitBabysitterRatingDTO } from './dto'

class BabysitterRatingAPI {
  updateRating = async (id: number, data: SubmitBabysitterRatingDTO) => {
    return axios.put(`api/bookings/${id}/rating`, data)
  }

  deleteRating = async (id: number) => {
    return axios.delete(`api/bookings/${id}/rating`)
  }
}

export const babysitterRatingAPI = new BabysitterRatingAPI()
