import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  tabLabel: {
    fontSize: '10px',
  },

  rightSection: {
    position: 'absolute',
    bottom: '40px',
    width: '16px !important',
  },
  bookNow: {
    width: '100%',
    borderTop: '1px solid grey',
    padding: '8px',
    background: 'white',
  },
}))
